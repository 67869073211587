import {ValidateFieldService} from './validate-field.service';
import {ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot} from '@angular/router';
import {Component, Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class ValidationGuard implements CanDeactivate<Component> {
  canDeactivate(component: Component, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot,
                nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return !!nextState.root.queryParams.skipValidation || this.validation.validateMandatory();
  }

  constructor(private validation: ValidateFieldService, private router: Router) {
  }

}
