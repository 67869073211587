import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EMPTY, iif, of} from 'rxjs';
import {Store} from '@ngrx/store';
import {CbsRollbarService} from '../rollbar/cbs-rollbar.service';
import {CbsService} from '../services/web/cbs.service';
import {ProductOptionsFieldChangeResponse} from '../services/web/product-options-field-change-response';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ValidateFieldService} from '../services/validate-field.service';
import {isCosRequestInProcess, isSearchRequestInProcess, State} from '../reducers';
import {catchError, concatMap, delay, filter, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {SearchActions} from '../actions/search.action';
import {ProductOptionActions} from '../actions/product-options.action';

@Injectable()
export class SearchEffects {

  //noinspection JSUnusedGlobalSymbols
  attemptStartEffect$ = createEffect(() => this.allActions.pipe(
    ofType(SearchActions.attemptStartSearch),
    withLatestFrom(this.store),
    filter(([action, state]) => !isSearchRequestInProcess(state)),
    filter(_ => this.validateService.diagnosticValidation()),
    mergeMap(([action, state]) => iif(() => isCosRequestInProcess(state),
      of(SearchActions.attemptStartSearch()).pipe(delay(100)),
      of(SearchActions.startSearch()))
    )
  ));

  //noinspection JSUnusedGlobalSymbols
  searchEffect$ = createEffect(() => this.allActions.pipe(
    ofType(SearchActions.startSearch),
    switchMap(payload => this.cbsService.search().pipe(
      map((response) => SearchActions.searchReturn({response})),
      catchError(error => this.rollbar.apiError(error, {payload}, undefined, this.store))))
  ));

  //noinspection JSUnusedGlobalSymbols
  filterEffect$ = createEffect(() => this.allActions.pipe(
    ofType(SearchActions.filter),
    switchMap(({changeParams}) =>
      this.cbsService.filter(changeParams).pipe(
        map((response) => SearchActions.searchReturn({response})),
        catchError(error => this.rollbar.apiError(error, {changeParams}, undefined, this.store)))
    )
  ));

  //noinspection JSUnusedGlobalSymbols
  paginateEffect$ = createEffect(() => this.allActions.pipe(
    ofType(SearchActions.paginate),
    switchMap(({pageSize, pageNumber}) =>
      this.cbsService.paginate(pageSize, pageNumber).pipe(
        map((response) => SearchActions.searchReturn({response})),
        catchError(error => this.rollbar.apiError(error, {pageSize, pageNumber}, undefined, this.store)))
    )
  ));

  //noinspection JSUnusedGlobalSymbols
  sortSearchEffect$ = createEffect(() => this.allActions.pipe(
    ofType(SearchActions.sort),
    tap(() => setTimeout(() => this.store.dispatch(SearchActions.showLoadingForSort()), 750)),
    switchMap(({sort}) =>
      this.cbsService.sortSearchResults(sort.active).pipe(
        map((response) => SearchActions.returnSearchSort({response})),
        catchError(error => this.rollbar.apiError(error, {sort}, undefined, this.store)))
    )
  ));

  //noinspection JSUnusedGlobalSymbols
  selectEffect$ = createEffect(() => this.allActions.pipe(
    ofType(SearchActions.selectProduct),
    concatMap(({product}) =>
      this.cbsService.select(product).pipe(
        map(response => this.validateService.validateFields(response)),
        map((response: ProductOptionsFieldChangeResponse) => ProductOptionActions.changeFieldSuccess({response})),
        catchError(error => {
          if (error.status === 406) {
            this.snackBar.open('Backward dependency, product invalid', null, {
              duration: 5000
            });
            this.router.navigate(['/cos-options']);
            this.rollbar.error('Backward dependencies in the search list are not supported.', error, {product});
            return EMPTY;
          } else {
            return this.rollbar.apiError(error, {product}, undefined, this.store);
          }
        }))
    )
  ));

  constructor(private allActions: Actions,
              private cbsService: CbsService,
              private rollbar: CbsRollbarService,
              private router: Router,
              private validateService: ValidateFieldService,
              private store: Store<State>,
              private snackBar: MatSnackBar) {
  }
}
