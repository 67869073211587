import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {CbsRollbarService} from '../rollbar/cbs-rollbar.service';
import {ValidateFieldService} from '../services/validate-field.service';
import {ProductService} from '../services/web/product.service';
import {ProductOptionsFieldChangeResponse} from '../services/web/product-options-field-change-response';
import {catchError, concatMap, map} from 'rxjs/operators';
import {concatMapEmitLast} from '../util/rx-utils';
import {ProductActions} from '../actions/product.action';
import {ProductOptionActions} from '../actions/product-options.action';

@Injectable()
export class ProductEffect {

  //noinspection JSUnusedGlobalSymbols
  deselectProduct$ = createEffect(() => this.allActions.pipe(
    ofType(ProductActions.deselect),
    concatMap(({product}) =>
      this.productService.submitProductDeselect(product).pipe(
        map(response => this.validation.validateFields(response)),
        map((response: ProductOptionsFieldChangeResponse) => ProductOptionActions.changeFieldSuccess({response})),
        catchError(error => this.rollbar.apiError(error, {product})))
    )
  ));

  //noinspection JSUnusedGlobalSymbols
  updateProductQuantity$ = createEffect(() => this.allActions.pipe(
    ofType(ProductActions.updateQuantity),
    concatMap(({product, quantity}) => this.productService.submitUpdateProductQuantity(product, quantity).pipe(
      map(response => this.validation.validateFields(response)),
      map((response: ProductOptionsFieldChangeResponse) => ProductOptionActions.changeFieldSuccess({response})),
      catchError(error => this.rollbar.apiError(error, {product, quantity})))
    )));

  //noinspection JSUnusedGlobalSymbols
  configureNode$ = createEffect(() => this.allActions.pipe(
    ofType(ProductActions.configureNode),
    concatMapEmitLast(({product}) => this.productService.submitConfigureNode(product).pipe(
      map((response: ProductOptionsFieldChangeResponse) => ProductOptionActions.changeFieldSuccess({response})),
      catchError(error => this.rollbar.apiError(error, {product}))))
  ));

  //noinspection JSUnusedGlobalSymbols
  reorderProducts$ = createEffect(() => this.allActions.pipe(
    ofType(ProductActions.reorder),
    concatMapEmitLast(({currentPosition, newPosition}) =>
      this.productService.reorderProducts(currentPosition, newPosition).pipe(
        map((response) => ProductActions.reorderSuccess({response})),
        catchError(error => this.rollbar.apiError(error, {currentPosition, newPosition})))
    )
  ));

  //noinspection JSUnusedGlobalSymbols
  refreshProducts$ = createEffect(() => this.allActions.pipe(
    ofType(ProductActions.refresh),
    concatMap(() =>
      this.productService.refreshProducts().pipe(
        map((response: ProductOptionsFieldChangeResponse) => ProductOptionActions.changeFieldSuccess({response})),
        catchError(error => this.rollbar.apiError(error)))
    )
  ));

  constructor(private allActions: Actions,
              private productService: ProductService,
              private rollbar: CbsRollbarService,
              private validation: ValidateFieldService) {
  }
}

