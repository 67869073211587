<h1 mat-dialog-title>{{'saveAs' | cbsLabel}}</h1>
<mat-dialog-content>
  <mat-form-field style="width: 20rem" *ngIf="itemNumberField$ | async as itemNumber" test-id="ITEM_NUMBER_FIELD">
    {{itemNumber.description}}
    <input matInput autocomplete="off" [(ngModel)]="itemNumberValue">
    <mat-hint align="start" *ngIf="savedItemNumber === itemNumberValue">
      {{'overrideIntuitive' | cbsLabel}}
    </mat-hint>
  </mat-form-field>
  <mat-radio-group style="flex-direction: column; display: inline-flex;" class="m-t-base" test-id="AFTER_SAVE_NAVIGATE_TO_FIELD">
    {{'afterSaveNavigateTo' | cbsLabel}}:
    <mat-radio-button [checked]="true" value="currentPage" test-id="CURRENT_PAGE_OPTION" (change)="updateRedirectLocation('currentPage')">
      {{'currentPage' | cbsLabel}}
    </mat-radio-button>
    <mat-radio-button value="quoteDetails" test-id="QUOTE_DETAILS_OPTION" (change)="updateRedirectLocation('quoteDetails')"
                      *ngIf="itemContainerType == 1">
      {{'quoteDetails' | cbsLabel}}
    </mat-radio-button>
    <mat-radio-button value="quoteDocuments" test-id="QUOTE_DOCUMENTS_OPTION" (change)="updateRedirectLocation('quoteDocuments')"
                      *ngIf="itemContainerType == 1">
      {{'advQuoteDocuments' | cbsLabel}}
    </mat-radio-button>
    <mat-radio-button value="orderDetails" test-id="ORDER_DETAILS_OPTION" (change)="updateRedirectLocation('orderDetails')"
                      *ngIf="itemContainerType == 2">
      {{'orderDetails' | cbsLabel}}
    </mat-radio-button>
    <mat-radio-button value="orderDocuments" test-id="ORDER_DOCUMENTS_OPTION" (change)="updateRedirectLocation('orderDocuments')"
                      *ngIf="itemContainerType == 2">
      {{'advOrderDocuments' | cbsLabel}}
    </mat-radio-button>
  </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button class="c-button" test-id="SAVE_BUTTON" (click)="saveAs()" mat-dialog-close>{{'save' | cbsLabel}}</button>
  <button class="c-text-button" test-id="CANCEL_BUTTON" mat-dialog-close>{{'cancel' | cbsLabel}}</button>
</mat-dialog-actions>
