import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {ParentSubcategory} from '../models/ParentSubcategory';
import {AccordionService} from '../services/accordion.service';
import {FieldContainer} from '../models/FieldContainer';
import {isNullOrUndefined} from '../util/js-utils';
import {WorkflowUtils} from '../reducers/workflow.reducer';
import {trackByField, trackById} from '../util/track-by-util';
import {SEARCH_INPUT_ID} from './search-filter.component';
import {WindowService} from '../services/window.service';

@Component({
  selector: 'cbs-parent-subcategory',
  templateUrl: './parent-subcategory.component.html'
})
export class ParentSubcategoryComponent implements OnInit {

  @Input() pageId: string;
  @Input() parentSubcategory: ParentSubcategory;
  @Input() categoryId: string;
  @Input() fieldContainer: FieldContainer;
  @Input() optionColumnLayout: number;
  @Input() singleColumnProperty: boolean;
  @Input() includeSearch = false;
  @Input() headerTemplate: TemplateRef<Component>;
  @Input() footerTemplate: TemplateRef<Component>;
  @Input() initProductOptionsCollapsed: boolean;

  constructor(private accordionService: AccordionService, private windowService: WindowService) {
  }

  ngOnInit() {
    this.accordionService.initAccordionItem(this.parentSubcategory.id,
      !this.categoryId || this.parentSubcategory.description === '', this.initProductOptionsCollapsed);
    this.optionColumnLayout = this.isMobile() ? 1 : this.optionColumnLayout;
  }

  toggleAccordion(open) {
    if (!!this.categoryId) {
      if (open) {
        this.accordionService.expandAccordion(this.parentSubcategory.id);
      } else {
        this.accordionService.collapseAccordion(this.parentSubcategory.id);
      }
    }
  }

  nonSearchFields() {
    return this.parentSubcategory.fields
      .filter(field => (field.id !== SEARCH_INPUT_ID || this.includeSearch));
  }

  isExpanded() {
    return this.accordionService.isExpanded(this.parentSubcategory.id);
  }

  nextLayer() {
    return !isNullOrUndefined(this.parentSubcategory.subcategories) && this.parentSubcategory.subcategories.length > 0;
  }

  trackByField(index, field) {
    return trackByField(index, field);
  }

  trackBySubcat(index, subcat) {
    return trackById(index, subcat);
  }

  isMobile() {
    return this.windowService.getWidth() <= 640;
  }

  trackById(index, subcat) {
    return trackById(index, subcat);
  }

  isTwoColumnFieldLayout(workflowId: string) {
    return WorkflowUtils.isTwoColumnFieldLayout(workflowId);
  }
}
