<div style="display: flex">
  <div>
    <button test-id="EXPAND_ALL_BUTTON" (click)="toggleAll(allData, true)">{{'expandAll' | cbsLabel}}</button>
    |
    <button test-id="COLLAPSE_ALL_BUTTON" (click)="toggleAll(allData, false)">{{'collapseAll' | cbsLabel}}</button>
  </div>
</div>
<div #agGridParent class="cbs-section p-base" [ngStyle]="gridHeightStyle">
  <ag-grid-angular class="ag-theme-balham"
                   [defaultColDef]="defaultColumnDef"
                   [columnDefs]="columnDefs"
                   [suppressCellSelection]="true"
                   [suppressScrollOnNewData]="true"
                   [singleClickEdit]="true"
                   [rowData]="rowData"
                   [pinnedBottomRowData]="pinnedBottomRowData"
                   [getRowNodeId]="priceSummaryService.getRowNodeId"
                   (gridReady)="onGridReady($event)"
                   (columnResized)="saveColumnSizesAndPositions($event)"
                   (columnMoved)="saveColumnSizesAndPositions($event)"
                   style="text-align: initial; height:100%;"></ag-grid-angular>
</div>
