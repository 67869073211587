<ng-container *ngIf="categories$|async as categories; else loading">
  <ng-container *ngIf="categories.length > 0">
    <div *ngFor="let category of categories; trackBy: trackByCategory" class="m-b-2">
      <cbs-category [category]="category" [singleColumnProperty]="true" [optionColumnLayout]="columnOptionLayout"
                    [initProductOptionsCollapsed]="initProductOptionsCollapsed" [pageId]="selectedPage$ | async"></cbs-category>
    </div>
  </ng-container>
</ng-container>
<ng-template #loading>
  <div class="text-center">
    <cbs-loading [display]="true" [diameter]="50" [strokeWidth]="5"></cbs-loading>
  </div>
</ng-template>
