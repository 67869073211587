import {Injectable} from '@angular/core';
import {getGlobalImagesPath, getImagesPath, getOptionImagesUrl, State} from '../reducers/index';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class ImageService {
  imagesPath: Observable<string>;
  globalImagesPath: Observable<string>;
  optionImagesPath: Observable<string>;

  constructor(private store: Store<State>) {
    this.imagesPath = store.pipe(select(getImagesPath));
    this.globalImagesPath = store.pipe(select(getGlobalImagesPath));
    this.optionImagesPath = store.pipe(select(getOptionImagesUrl));
  }

  url(imageName: string): Observable<string> {
    return this.imagesPath.pipe(map(path => path + imageName));
  }

  globalUrl(imageName: string): Observable<string> {
    return this.globalImagesPath.pipe(map(path => path + imageName));
  }

  optionGraphicUrl(imageUrl: string): Observable<string> {
    return this.optionImagesPath.pipe(map(path => path + imageUrl));
  }

}
