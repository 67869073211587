import {Injectable} from '@angular/core';
import urlJoin from 'proper-url-join';
import {DisplayBreadcrumbs} from '../../reducers/workflow.reducer';
import {Store} from '@ngrx/store';
import {getIfeDomain, State} from '../../reducers/index';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {assignFirstTruthy} from '../../util/rx-utils';

@Injectable()
export class WorkflowService {
  private ifeDomain: string;

  constructor(private httpClient: HttpClient, store: Store<State>, private router: Router) {
    assignFirstTruthy(store, getIfeDomain, ifeDomain => this.ifeDomain = ifeDomain);
  }

  updateBreadcrumbs(breadcrumbs: DisplayBreadcrumbs) {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    const options = {headers: headers, withCredentials: true};
    const targetUrl = urlJoin(this.ifeDomain, '/config/api/v1/cbs/breadcrumb');
    let urlParams = new HttpParams();
    urlParams = urlParams.set('breadcrumb', JSON.stringify(breadcrumbs));
    urlParams = urlParams.set('currentPage', this.router.url);
    return this.httpClient.post(targetUrl, urlParams, options);
  }

}
