import {Component, OnInit} from '@angular/core';
import {getPrice, getPrivileges, getProducts, getSelectedNode, State} from '../reducers/index';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {Product} from '../models/Product';
import {trackById} from '../util/track-by-util';
import {Router} from '@angular/router';
import {DragulaService} from 'ng2-dragula';
import {OptionOverrideComponent} from './option-override.component';
import {MatDialog} from '@angular/material/dialog';
import {ItemMultiplierComponent} from './item-multiplier.component';
import {ConfirmDialogComponent} from './confirm-dialog.component';
import {map} from 'rxjs/operators';
import {SafeStore} from '../util/safe-store';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {ImageService} from '../services/image.service';
import {WorkflowActions} from '../actions/workflow.action';
import {ProductActions} from '../actions/product.action';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ValidateFieldService} from '../services/validate-field.service';

@UntilDestroy()
@Component({
  selector: 'cbs-selected-products',
  templateUrl: './selected-products.component.html',
  styles: [`
    .selected-product-row {
      display: grid;
      grid-gap: 5px;
      grid-template-columns: 1fr 50px auto 2em 2em !important;
    }

    .selected-product-row > div {
      width: 50%;
    }

    .adjust-for-card {
      padding: 0 .75rem;
    }

    .grid-2 {
      grid-column: span 2;
    }

    .vertically-centered {
      margin: auto 0;
    }

    div {
      font-size: .95em;
    }

    .configurable:hover > .configurable-title {
      text-decoration: underline;
    }

    .configurable-title {
      padding-right: 10px;
    }

    .configurable:hover {
      cursor: pointer;
    }

    @supports (display:grid) {
      .selected-product-row > div {
        width: auto;
      }
    }
  `]
})
export class SelectedProductsComponent implements OnInit {

  products$: Observable<Product[]>;
  price$: Observable<string>;
  selectedNode: string;
  dragging$: Observable<any>;
  overrideDescription: boolean;
  overridePrice: boolean;
  overrideRFQ: boolean;
  viewPricing: boolean;
  private safeStore: SafeStore<State>;

  constructor(private store: Store<State>, private router: Router, private dragula: DragulaService, private dialog: MatDialog,
              public image: ImageService, private sanitizer: DomSanitizer, private validateFieldService: ValidateFieldService) {
    this.safeStore = new SafeStore(this, this.store);
    this.dragging$ = this.dragula.drop.asObservable().pipe(map((value) => {
      const currentPosition = value[1]['attributes']['data-index'].value;
      let newPosition = value[4] && value[4]['attributes']['data-index'] ? value[4]['attributes']['data-index'].value : -1;
      if (currentPosition < newPosition) {
        newPosition -= 1;
      }
      this.store.dispatch(ProductActions.reorder({currentPosition: currentPosition, newPosition: newPosition}));
    }));
  }

  ngOnInit() {
    this.products$ = this.store.pipe(select(getProducts));
    this.price$ = this.store.pipe(select(getPrice));
    this.safeStore.subscribe(getSelectedNode, nodeId => {
      if (+nodeId > 1) {
        this.selectedNode = nodeId;
      } else {
        this.store.dispatch(WorkflowActions.displayBreadcrumbs({
          breadcrumbs: {configure: {display: false, target: 'parts-configuration'}}
        }));
      }
    });
    this.safeStore.subscribe(getPrivileges, privileges => {
      this.overrideDescription = privileges['overrideDescription'];
      this.overridePrice = privileges['overridePrice'];
      this.overrideRFQ = privileges['overrideRFQ'];
      this.viewPricing = privileges['viewPricingInConfigurator'];
    });
  }

  deselectProduct(product: Product) {
    const popup = ConfirmDialogComponent;
    const dialogRef = this.dialog.open(popup, {data: {confirmationPrompt: 'doYouWantToDelete'}});
    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(ProductActions.deselect({product}));
        if (product.nestedConfig) {
          if (product.nodeID === this.selectedNode) {
            this.store.dispatch(WorkflowActions.displayBreadcrumbs({
              breadcrumbs: {configure: {display: false, target: 'parts-configuration'}}
            }));
            this.router.navigate(['/parts-search'], {queryParams: {skipValidation: true}});
          }
        }
      }
    });
  }

  configure(product: Product) {
    if (product.nestedConfig && this.validateFieldService.validateMandatory()) {
      this.store.dispatch(ProductActions.configureNode({product}));
      this.router.navigate(['/parts-configuration']);
      this.selectedNode = product.nodeID;
    }
  }

  updateQuantity(product: Product, quantity: string | number) {
    this.store.dispatch(ProductActions.updateQuantity({product, quantity}));
  }

  displayPriceCol(products: Product[]): boolean {
    let display = false;
    products.forEach(product => {
      if (product.price) {
        display = true;
      }
    });
    return display && this.viewPricing;
  }

  priceColWidth(products: Product[]): number {
    let max = 5;
    products.forEach(product => {
      if (product.price) {
        max = product.price.length > max ? product.price.length : max;
      }
    });
    return max;
  }

  trackByProduct(index, product) {
    return trackById(index, product) + product.reviewed + index;
  }

  isSelected(product: Product): boolean {
    return this.selectedNode === product.nodeID;
  }

  openOverrideDialog(product: Product) {
    this.dialog.open(OptionOverrideComponent, {
      panelClass: 'modal',
      data: {
        choice: product, parentSubcatId: product.parentObjectID,
        overridePrice: this.overridePrice || this.overrideRFQ && product.rfq,
        overrideDescription: this.overrideDescription && product.overrideDesc
      }
    });
  }

  openMultiplierDialog() {
    const test = ItemMultiplierComponent;
    const dialogRef = this.dialog.open(test, {
      panelClass: 'modal',
      data: {}
    });
  }

  allReviewed(products: Product[]): boolean {
    return products.filter(product => product.reviewed === false).length === 0;
  }

  canOverride(product: Product) {
    return this.overrideDescription && product.overrideDesc
      || this.overridePrice && product.price
      || this.overrideRFQ && product.price && product.rfq;
  }

  productLoading(product: Product) {
    return !product.displayLoading;
  }

  isNotDeselected(product: Product) {
    return !product.isDeselected;
  }

  openAndNoPropogation(event, element) {
    element.open();
    if (event) {
      event.stopPropagation();
    }
  }

  getGraphicUrl(graphicUrl): Observable<SafeUrl> {
    return this.image.optionGraphicUrl(encodeURI(graphicUrl))
      .pipe(map(url => this.sanitizer.bypassSecurityTrustUrl(url)));
  }
}
