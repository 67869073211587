import {SaveVerificationComponent} from '../../components/save-verification.component';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {getPrivileges, isDirty, State} from '../../reducers/index';
import {MatDialog} from '@angular/material/dialog';
import {WindowService} from '../window.service';
import {assignFirstTruthy} from '../../util/rx-utils';
import {SafeStore} from '../../util/safe-store';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class SaveVerificationService {
  private dirty: boolean;
  private savePrivilege: boolean;

  constructor(private windowService: WindowService, private store: Store<State>, private dialog: MatDialog) {
    const safeStore = new SafeStore(this, this.store);
    safeStore.select(isDirty).subscribe(dirty => this.dirty = dirty);
    assignFirstTruthy(this.store, getPrivileges, privileges => {
      this.savePrivilege = privileges['savePrivilege'];
    });
  }

  verifyLink(url) {
    if (this.dirty && this.savePrivilege) {
      const popup = SaveVerificationComponent;
      const dialogRef = this.dialog.open(popup, {});
      return dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.windowService.getWindow().location.href = url;
        }
      });
    } else {
      this.windowService.getWindow().location.href = url;
    }
  }
}
