<ng-container>
  <details [open]="isExpanded()" #details (toggle)="toggleAccordion(details.open)"
           [id]="parentSubcategory.id" class="parentSubCategory">
    <summary style="z-index: 1"
             [ngStyle]="{display: parentSubcategory.description && categoryId ? 'list-item': 'none'}">{{parentSubcategory.description}}</summary>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </details>
</ng-container>

<ng-template #content>
  <div class="cbs-section-background">
    <div *ngIf="!categoryId && parentSubcategory.description" class="cbs-section-header p-base"><b>{{parentSubcategory.description}}</b>
    </div>
    <div class="p-h-2 p-v-base">
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
      <ng-container *ngIf="nextLayer()">
        <div class="grid-container"
             [ngClass]="{'multi-column': optionColumnLayout > 1, 'single-column': optionColumnLayout <= 1}">
          <div *ngFor="let parentSubcat of parentSubcategory.subcategories; trackBy: trackBySubcat"
               class="grid-item"
               [ngClass]="['row-span-' + parentSubcat.rowSpan, 'column-span-' + parentSubcat.colSpan, 'row-' + parentSubcat.rowPos, 'column-' + parentSubcat.colPos, 'mobile-' + parentSubcat.mobilePos]">
            <cbs-subcategory
              [parentSubcategory]="parentSubcategory" [subcategory]="parentSubcat"
              [optionColumnLayout]="optionColumnLayout" [includeSearch]="includeSearch"
              [categoryId]="categoryId" [fieldContainer]="fieldContainer" [pageId]="pageId"></cbs-subcategory>
          </div>
        </div>
      </ng-container>
      <div class="grid-container"
           [ngClass]="{'multi-column': optionColumnLayout > 1, 'single-column': optionColumnLayout <= 1}">
        <div *ngFor="let field of nonSearchFields(); trackBy: trackByField"
             [ngClass]="['row-span-' + field.rowSpan, 'column-span-' + field.colSpan, 'row-' + field.rowPos, 'column-' + field.colPos, 'mobile-' + field.mobilePos]"
             class="grid-item field-item">
          <cbs-field style="width: 100%" [field]="field"
                     [categoryId]="categoryId" [parentSubcatId]="parentSubcategory.id" [pageId]="pageId"
                     [fieldContainer]="fieldContainer" [optionColumnLayout]="optionColumnLayout"></cbs-field>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </div>
  </div>
</ng-template>
