<mat-table #table [dataSource]="searchResults" matSort matSortDisableClear="true" [matSortActive]="sorting.active"
           id="search-results-table"
           [matSortDirection]="sorting.direction" (matSortChange)="sortSearchResults($event)" style="height: 100%; position: relative;">
  <ng-container *ngFor="let column of dataColumns">
    <ng-container matColumnDef="{{column}}">
      <mat-header-cell *matHeaderCellDef [ngClass]="{'u-no-wrap': noWrap(column)}"
                       [ngStyle]="{'text-align': getAlignment(column), 'width': adjustedWidth(dataColumns, column) + '%', 'flex-basis': adjustedWidth(dataColumns, column) + '%', 'margin': 'auto'}"
                       [start]="nextSortDirection( searchDesign?.allSearchReturns, column)" mat-sort-header>
        {{findTitle(column, searchDesign)}}
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [ngStyle]="{'text-align': getAlignment(column), 'width': adjustedWidth(dataColumns, column) + '%'}"
                [ngClass]="{'u-no-wrap': noWrap(column)}"
                matTooltip="{{row.nearMiss && isNearMissCol(column) ? ('clickHereFor' | cbsLabel) + ' ' + ('nearMissReasons' | cbsLabel) : null}}"
                (click)="isNearMissCol(column) && row.nearMiss && displayNearMissReasons($event, row)">
        <span [ngClass]="{'fa': isNearMissCol(column),
        'near-miss-icon': row.nearMiss && isNearMissCol(column),
        'check-icon': !row.nearMiss && isNearMissCol(column)}" [innerHtml]="tableContent(column, row)">
        </span>
        <ng-container *ngIf="findSearchComment(row, column) || findSearchGraphic(row, column)">
          <sup [ngbPopover]="searchReturnTooltip" placement="right bottom left">
            <i class="fas fa-info-circle fa-fw"></i>
          </sup>
          <ng-template #searchReturnTooltip>
            <div>
              <img *ngIf="findSearchGraphic(row, column)"
                   [src]="getGraphicUrl(findSearchGraphic(row, column))|async"/>
              <span>{{findSearchComment(row, column)}}</span>
            </div>
          </ng-template>
        </ng-container>
      </mat-cell>
    </ng-container>
  </ng-container>
  <mat-header-row *matHeaderRowDef="dataColumns; sticky: stickyTableHeader" [ngStyle]="{'top': headerTopVal}"></mat-header-row>
  <ng-container *ngIf="productConfiguration$|async; else noLink">
    <mat-row *matRowDef="let row; columns: dataColumns;" style="cursor: pointer"
             (click)="selectProduct(row)" routerLink="/product-options">
    </mat-row>
  </ng-container>
  <ng-template #noLink>
    <mat-row *matRowDef="let row; columns: dataColumns;" (click)="selectProduct(row)" style="cursor: pointer">
    </mat-row>
  </ng-template>
</mat-table>
<mat-paginator #paginator
               [length]="totalResults"
               [pageIndex]="pageNumber"
               [pageSize]="pageSize"
               (page)="updatePaginator($event)"
               [pageSizeOptions]="[5, 10, 25, 100]">
</mat-paginator>
<ng-container *ngIf="getScroll() | async"></ng-container>
