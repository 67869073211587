import {Component, OnInit} from '@angular/core';
import {getProperties, State} from '../reducers/index';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {WorkflowActions} from '../actions/workflow.action';

@Component({
  selector: 'cbs-product-options-route',
  templateUrl: 'product-options-route.component.html'
})
export class ProductOptionsRouteComponent implements OnInit {

  maxColumnOptionLayout: Observable<number>;

  constructor(private store: Store<State>) {
  }

  ngOnInit(): void {
    this.store.dispatch(WorkflowActions.displayBreadcrumbs({
      breadcrumbs: {product: {display: true, target: 'product-options'}}
    }));
    this.store.dispatch(WorkflowActions.displayData({
      payload: {
        displayPrice: true,
        displaySaveButton: true,
        displaySearchButton: false,
        createCustomOptions: true
      }
    }));
    this.maxColumnOptionLayout = this.store.pipe(
      select(getProperties),
      map(properties => properties.po_singleColumn ? 1 : 4));
  }
}
