<ng-container *ngIf="properties$| async as properties">
  <a (click)="openHeader()" role="Menuitem" aria-haspopup="true">
    <ng-container *ngIf="!(properties.headerInfoLinkLabelID && properties.headerInfoLinkLabelID != ''); else override">
      <ng-container *ngIf="itemNumber$|async; else default; let itemNumber">
        {{'item' | cbsLabel}}: {{itemNumber.length > 30 ? (itemNumber | slice:0:30) + '...' : (itemNumber)}}
      </ng-container>
    </ng-container>
    <ng-template #default>
      {{'headerInformation' | cbsLabel}}
    </ng-template>
    <ng-template #override>
      {{properties.headerInfoLinkLabelID | cbsLabel}}
    </ng-template>
  </a>
</ng-container>
