import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {Category} from '../models/Category';
import {getPrivileges, getProduct, getProductOptionCategories, State} from '../reducers/index';
import {Product} from '../models/Product';
import {MatDialog} from '@angular/material/dialog';
import {OptionOverrideComponent} from './option-override.component';
import {SafeStore} from '../util/safe-store';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'cbs-product-options-title',
  templateUrl: './product-options-title.component.html',
  styles: [`
    .product-options-title {
      padding-bottom: 10px;
    }

    .product-options-title__title {
      display: inline;
      line-height: 1.1;
    }

    .product-options-title__override {
      padding: 0 10px;
    }
  `]
})
export class ProductOptionsTitleComponent implements OnInit {

  product$: Observable<Product>;
  categories$: Observable<Category[]>;
  private safeStore: SafeStore<State>;
  overridePrice: boolean;
  overrideRFQ: boolean;

  constructor(private store: Store<State>, private dialog: MatDialog) {
    this.categories$ = this.store.pipe(select(getProductOptionCategories));
    this.product$ = this.store.pipe(select(getProduct));
    this.safeStore = new SafeStore(this, this.store);
  }

  ngOnInit() {
    this.safeStore.subscribe(getPrivileges, privileges => {
      this.overridePrice = privileges['overridePrice'];
      this.overrideRFQ = privileges['overrideRFQ'];
    });
  }

  canOverride(product: Product) {
    return this.overridePrice && product.price
      || this.overrideRFQ && product.price && product.rfq;
  }

  openOverrideDialog(product: Product) {
    this.dialog.open(OptionOverrideComponent, {
      panelClass: 'modal',
      data: {
        choice: product,
        isProduct: true,
        overridePrice: this.overridePrice || this.overrideRFQ && product.rfq,
        overrideDescription: false,
        overrideQuantity: false,
        overrideLeadTime: false,
        overrideSource: false
      }
    });
  }
}
