// Util functions
import {ProductOptionsState} from '../reducers/product-options.reducer';
import {
  FieldId, TempChangeCheckboxPayload, TempChangeDropdownPayload, TempChangeFieldValuePayload, TempChangeNumericFieldPayload
} from '../actions/field.action';
import {Field} from '../models/Field';
import {ParentSubcategory} from '../models/ParentSubcategory';
import {Category} from '../models/Category';
import {CosState} from '../reducers/cos.reducer';
import {HeaderState} from '../reducers/header.reducer';
import {FieldContainer} from '../models/FieldContainer';
import {Choice} from '../models/Choice';
import produce from 'immer';
import {isNullOrUndefined} from './js-utils';
import {Page} from '../models/Page';

export function updateFieldValue<T>(state: T, payload: TempChangeFieldValuePayload): T {
  return produce(state, draftState => {
    const field = findFieldInStateByPayload(draftState, payload);
    if (!isNullOrUndefined(field)) {
      field.value = payload.value;
    }
  });
}

export function updateFieldNumericValue<T>(state: T, payload: TempChangeNumericFieldPayload): T {
  return produce(state, draftState => {
    const field = findFieldInStateByPayload(draftState, payload);
    if (!isNullOrUndefined(field)) {
      field.numericValue = payload.numericValue;
    }
  });
}

export function updateOptionAttributeValue<T>(state: T, payload: any): T {
  return produce(state, draftState => {
    const field = findFieldInStateByPayload(draftState, payload);
    if (payload.choiceId) {
      const choice = field.choices.find(choice => choice.id === payload.choiceId);
    } else {
      if (!isNullOrUndefined(field)) {
        field[payload.attributeName] = payload.value;
      }
    }
  });
}

export function updatePOFieldInvalid(state: ProductOptionsState, payload: FieldId): ProductOptionsState {
  return produce(state, draftState => {
    const [field, subcategory, parentSubcategory, category, page] = getFieldHierarchyInProductOptionsStateByPayload(draftState, payload);
    if (!isNullOrUndefined(field)) {
      field.invalid = true;
    }
    if (!isNullOrUndefined(parentSubcategory)) {
      parentSubcategory.invalid = true;
    }
    if (!isNullOrUndefined(category)) {
      category.invalid = true;
    }
    if (!isNullOrUndefined(page)) {
      page.invalid = true;
    }
  });
}

export function updateFieldInvalid<T>(state: T, payload: FieldId): T {
  return produce(state, draftState => {
    const field = findFieldInStateByPayload(draftState, payload);
    if (!isNullOrUndefined(field)) {
      field.invalid = true;
    }
  });
}

export function updateDropdown<T>(state: T, payload: TempChangeDropdownPayload): T {
  return produce(state, draftState => {
    const field = findFieldInStateByPayload(draftState, payload);
    if (!isNullOrUndefined(field)) {
      field.choices.forEach(choice => {
        if (choice.id === payload.newSelectedChoiceId) {
          choice.selected = true;
        }
        if (choice.id === payload.previousSelectedChoiceId) {
          choice.selected = false;
        }
      });
    }
  });
}

export function updateCheckbox<T>(state: T, payload: TempChangeCheckboxPayload): T {
  return produce(state, draftState => {
    const field = findFieldInStateByPayload(draftState, payload);
    if (!isNullOrUndefined(field)) {
      field.choices.filter((choice: Choice) => choice.id === payload.choiceId).forEach((choice: Choice) => {
        choice.selected = payload.choiceSelected;
        choice.displayLoading = true;
      });
    }
  });
}

export function updateFieldLoadingImage<T>(state: T, payload: FieldId): T {
  return produce(state, draftState => {
    const field = findFieldInStateByPayload(draftState, payload);
    if (!isNullOrUndefined(field)) {
      field.displayLoading = true;
    }
  });
}

export function updateChoiceDisplayLoading<T>(state: T, payload, value: boolean): T {
  return produce(state, draftState => {
    const field = findFieldInStateByPayload(draftState, payload);
    if (!isNullOrUndefined(field) && field.choices) {
      const choice = field.choices.find((choice: Choice) => choice.id === payload.choiceId);
      if (!isNullOrUndefined(choice)) {
        choice.displayLoading = value;
      }
    }
  });
}

export function findFieldInStateByPayload(state, payload: FieldId): Field {
  switch (payload.fieldContainer) {
    case FieldContainer.Product:
      return findFieldInProductOptionsStateByPayload(state, payload);
    case FieldContainer.Cos:
      return findFieldInCosStateByPayload(state, payload);
    case FieldContainer.Header:
      return findFieldInHeaderStateByPayload(state, payload);
    case FieldContainer.EmailRfq:
      return findFieldInEmailRfqStateByPayload(state, payload);
    default:
      console.log('FieldContainer ', payload.fieldContainer, ' not found');
  }
}

export function findFieldInProductOptionsStateByPayload(state: ProductOptionsState, payload: FieldId): Field {
  return getFieldHierarchyInProductOptionsStateByPayload(state, payload)[0];
}

function getFieldHierarchyInProductOptionsStateByPayload(state: ProductOptionsState, payload: FieldId): any[] {
  let page, category, parentSubcategory, subcategory, field;
  page = findPageById(state.pages, payload.pageId);
  if (page) {
    category = findCategoryById(page.categories, payload.categoryId);
  } else {
    category = findCategoryById(state.categories, payload.categoryId);
  }
  parentSubcategory = findParentSubcategoryInCategoryById(category, payload.parentSubcatId);
  subcategory = findSubcategoryInParentById(parentSubcategory, payload.subcatId);
  field = findFieldInSubcategoryById(subcategory, payload.fieldId);
  return [field, subcategory, parentSubcategory, category, page];
}

export function findFieldInCosStateByPayload(state: CosState, payload: FieldId): Field {
  let subcategory;
  subcategory = findSubcategoryInParentById(state.parentSubcategory, payload.subcatId);
  return findFieldInSubcategoryById(subcategory, payload.fieldId);
}

export function findFieldInHeaderStateByPayload(state: HeaderState, payload: FieldId): Field {
  return state.headers.find((field: Field) => field.id === payload.fieldId);
}
export function findFieldInEmailRfqStateByPayload(state: ProductOptionsState, payload: FieldId): Field {
  return state.additionalEmailRfqFields.find((field: Field) => field.id === payload.fieldId);
}

export function findPageById(pages, pageId: string): Page {
  if (pages) {
    return pages.find((page: Page) => page.id === pageId);
  }
}

export function findCategoryById(categories, categoryId: string): Category {
  return categories.find((category: Category) => category.id === categoryId);
}

export function findParentSubcategoryInCategoryById(category: Category, parentSubcatId: string) {
  return category.parentSubcategories.find((subcat: ParentSubcategory) => subcat.id === parentSubcatId);
}

export function findSubcategoryInParentById(parentSubcategory: ParentSubcategory, subcatId?: string) {
  if (!isNullOrUndefined(subcatId)) {
    return parentSubcategory.subcategories.find((subcat: ParentSubcategory) => subcat.id === subcatId);
  }
  return parentSubcategory;
}

export function findFieldInSubcategoryById(subcategory: ParentSubcategory, fieldId: string) {
  return subcategory.fields.find((field: Field) => field.id === fieldId);
}

