<ng-container *ngIf="categories$ | async as categories">
  <div *ngIf="showExpandCollapseAll" style="padding: 0 0 10px 10px">
    <button test-id="EXPAND_ALL_BUTTON" (click)="expandAll()">{{'expandAll' | cbsLabel}}</button>
    |
    <button test-id="COLLAPSE_ALL_BUTTON" (click)="collapseAll()">{{'collapseAll' | cbsLabel}}</button>
  </div>
  <cbs-category-menu-category *ngFor="let category of categories; trackBy:trackByCategoryId"
                              [category]="category" [strategy]="strategy"
                              [showCategoryLevel]="categories.length > 1"></cbs-category-menu-category>
</ng-container>
