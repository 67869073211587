import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs/index';
import {getProductOptionCategories, State} from '../reducers';
import {select, Store} from '@ngrx/store';
import {MatDialog} from '@angular/material/dialog';
import {Category} from '../models/Category';
import {filter, map} from 'rxjs/operators';
import {ProductOptionActions} from '../actions/product-options.action';

@Component({
  selector: 'cbs-custom-option',
  templateUrl: './custom-option-button.component.html',
})
export class CustomOptionButtonComponent implements OnInit {
  categories$: Observable<Category[]>;
  customOptionsEnabled = false;
  @Output() checkDisplay = new EventEmitter;

  constructor(private store: Store<State>, private dialog: MatDialog) {
  }

  ngOnInit() {
    this.categories$ = this.store.pipe(select(getProductOptionCategories), filter(categories => !!categories), map(categories => {
      this.customOptionsEnabled = categories.some(category => {
        return category.parentSubcategories.some(subcat => subcat.addedOptions);
      });
      setTimeout(() => this.checkDisplay.emit(this.customOptionsEnabled), 0);
      return categories;
    }));
  }

  addCustomOption(subcategory, type) {
    this.store.dispatch(ProductOptionActions.addCustomOption({payload: {...subcategory, type}}));
  }

  customSubcategories(category: Category) {
    return category.parentSubcategories.filter(subcat => subcat.addedOptions);
  }
}
