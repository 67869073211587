<h1 mat-dialog-title>{{'saveToNewProject' | cbsLabel}}</h1>
<mat-dialog-content>
  <label>
    {{'projectName' | cbsLabel}}:
    <mat-form-field floatLabel="never" test-id="PROJECT_NAME_FIELD">
      <input matInput [(ngModel)]="newProjectName">
    </mat-form-field>
  </label>
  <label>
    {{'itemNumber' | cbsLabel}}:
    <mat-form-field floatLabel="never" test-id="ITEM_NUMBER_FIELD">
      <input matInput [(ngModel)]="itemNumberValue">
    </mat-form-field>
  </label>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button class="c-button" [disabled]="!newProjectName" test-id="SAVE_BUTTON"
          (click)="saveToNewProject()" mat-dialog-close>{{'save' | cbsLabel}}
  </button>
  <button class="c-text-button" test-id="CANCEL_BUTTON" mat-dialog-close>{{'cancel' | cbsLabel}}</button>
</mat-dialog-actions>
