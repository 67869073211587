import {SystemProperties} from '../models/SystemProperties';
import {createAction, props} from '@ngrx/store';

export namespace SystemPropertiesActions {
  export const init = createAction(
    '[systemProperties] Init',
    props<{ systemProperties: SystemProperties }>()
  );
}

