import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EMPTY, from, of} from 'rxjs';
import {CbsActions, CbsInitPayload} from '../actions/cbs.action';
import {Action, Store} from '@ngrx/store';
import {CbsInitResponse, CbsService} from '../services/web/cbs.service';
import {CosActions} from '../actions/cos.action';
import {Router} from '@angular/router';
import {getDocumentLinks, getIfeDomain, getProperties, getWorkflowId, State} from '../reducers';
import {dispatchFetchDocLinksAction} from '../actions/documents-action-helpers';
import {COSInitResponse} from '../services/web/cos-init-response';
import {ProductOptionsInitResponse} from '../services/web/product-options-init-response';
import {CbsRollbarService} from '../rollbar/cbs-rollbar.service';
import {ValidateFieldService} from '../services/validate-field.service';
import {messageAction} from '../actions/action-utils';
import {isNotSupported} from '../util/workflow-utils';
import {Angulartics2} from 'angulartics2';
import {DiscountActions} from '../actions/discount.action';
import {catchError, filter, map, switchMap, withLatestFrom} from 'rxjs/operators';
import urlJoin from 'proper-url-join';
import {assignFirstTruthy, concatMapEmitLast, firstTruthy} from '../util/rx-utils';
import {CONFIG_CONTEXT_PATH} from '../util/url-util';
import {IfeIntegrationActions} from '../actions/ife-integration-info.action';
import {ProductOptionActions} from '../actions/product-options.action';
import {ChangeFieldPayload} from '../actions/field.action';
import {SearchActions} from '../actions/search.action';
import {LabelActions} from '../actions/labels.action';
import {SystemPropertiesActions} from '../actions/system-properties.action';
import {PropertiesActions} from '../actions/properties.action';
import {WorkflowActions} from '../actions/workflow.action';
import {HeaderActions} from '../actions/header.action';

@Injectable()
export class CbsEffects {
  ifeDomain: string;

  //noinspection JSUnusedGlobalSymbols
  cbsInit$ = createEffect(() => this.allActions.pipe(
    ofType(CbsActions.initFromConfig),
    switchMap(({payload}) =>
      this.cbsService.init(payload.configInitUrl).pipe(
        map(response => this.validation.validateFields(response)),
        switchMap(response => this.buildInitSuccessActions(response, payload)),
        catchError(error => from([IfeIntegrationActions.initFromResponseFailed({error})]))
      )
    )
  ));

  //noinspection JSUnusedGlobalSymbols
  initSuccess$ = createEffect(() => this.allActions.pipe(
    ofType(CosActions.initSuccess),
    withLatestFrom(this.store),
    switchMap(([{response}, state]) => {
      if (isNotSupported(getWorkflowId(state), 'search')) {
        return of(ProductOptionActions.changeField({
          payload: {parentSubcatId: response.parentSubcategory.id, changeParams: [[]]} as ChangeFieldPayload
        }));
      } else if (getProperties(state).eachCosChangeRunsSearch) {
        return of(SearchActions.attemptStartSearch());
      } else {
        return EMPTY;
      }
    })
  ));

  //noinspection JSUnusedGlobalSymbols
  saveItem$ = createEffect(() => this.allActions.pipe(
    ofType(CbsActions.itemSave),
    withLatestFrom(this.store),
    concatMapEmitLast(([{asyncSave}, state]) =>
      this.cbsService.saveItem(asyncSave).pipe(
        map(response => {
          this.angulartics2.eventTrack.next({action: 'saveSuccess', properties: {event: 'save'}});
          this.onSaveItemSuccess(state, response);
          return CbsActions.itemSaveSuccess({response});
        }),
        catchError(error => {
          return this.processItemSaveFailure(error);
        }))
    )
  ));

  //noinspection JSUnusedGlobalSymbols
  saveItemAs$ = createEffect(() => this.allActions.pipe(
    ofType(CbsActions.itemSaveAs),
    withLatestFrom(this.store),
    concatMapEmitLast(([{payload}]) =>
      (payload.saveAs ? this.cbsService.saveItemAs(payload.changeParams) : this.cbsService.saveItem(true)).pipe(
        map(response => {
          this.angulartics2.eventTrack.next({action: 'saveAsSuccess', properties: {event: 'save'}});
          this.onSaveItemAsSuccess(payload.redirectTarget);
          return payload.saveAs ? CbsActions.itemSaveAsSuccess({response}) : CbsActions.itemSaveSuccess({response});
        }),
        catchError(error => {
          return this.processItemSaveFailure(error);
        }))
    )
  ));

  //noinspection JSUnusedGlobalSymbols
  saveToNewQuoteOrOrder$ = createEffect(() => this.allActions.pipe(
    ofType(CbsActions.saveToNewQuote, CbsActions.saveToNewOrder),
    withLatestFrom(this.store),
    concatMapEmitLast(([action]) =>
      this.cbsService.saveToNewQuoteOrOrder(action.objectId).pipe(
        map(response => {
          this.angulartics2.eventTrack.next({action: 'saveSuccess', properties: {event: 'save'}});
          if (action.type === CbsActions.saveToNewOrder.type) {
            return CbsActions.saveToNewOrderSuccess({response});
          } else {
            return CbsActions.saveToNewQuoteSuccess({response});
          }
        }),
        catchError(error => {
          return this.processItemSaveFailure(error);
        }))
    )
  ));

  //noinspection JSUnusedGlobalSymbols
  saveToNewProject$ = createEffect(() => this.allActions.pipe(
    ofType(CbsActions.saveToNewProject),
    withLatestFrom(this.store),
    concatMapEmitLast(([{payload}]) =>
      this.cbsService.saveToNewProject(payload).pipe(
        map(response => {
          this.angulartics2.eventTrack.next({action: 'saveSuccess', properties: {event: 'save'}});
          return CbsActions.saveToNewProjectSuccess({response});
        }),
        catchError(error => {
          return this.processItemSaveFailure(error);
        }))
    )
  ));

  //noinspection JSUnusedGlobalSymbols
  message$ = createEffect(() => this.allActions.pipe(
    ofType(ProductOptionActions.changeFieldSuccess, CosActions.initSuccess),
    filter(({response}) => response && (response as any).message),
    map(({response}) => messageAction(response as any)) // Need to cast as any because interfaces don't contain `message`
  ), {dispatch: false});

  //noinspection JSUnusedGlobalSymbols
  updateDiscount$ = createEffect(() => this.allActions.pipe(
    ofType(DiscountActions.submitDiscounts),
    concatMapEmitLast(({changeList}) =>
      this.cbsService.updateDiscount(changeList).pipe(
        map((response) => DiscountActions.updateDiscountSuccess({response})),
        catchError(error => this.rollbar.apiError(error, {changeList}, undefined, this.store)))
    )
  ));

  //noinspection JSUnusedGlobalSymbols
  sendRfqEmail$ = createEffect(() => this.allActions.pipe(
    ofType(CbsActions.sendRfqEmail),
    concatMapEmitLast(({payload}) =>
      this.cbsService.sendRfqEmail(payload).pipe(
        map(response => {
          return CbsActions.rfqEmailSent();
        }),
        catchError(error => this.rollbar.apiError(error, {payload}, undefined, this.store)))
    )
  ));

  private processItemSaveFailure(error) {
    this.rollbar.apiError(error, undefined, 'Failed to save item.', this.store);
    this.angulartics2.eventTrack.next({action: 'saveFail', properties: {event: 'save'}});
    return from([CbsActions.itemSaveFail()]);
  }

  private onSaveItemSuccess(state: State, response: any) {
    if (state.properties.save_SuccessDo === 'displayDocuments') {
      this.router.navigate(['/documents']);
    } else if (state.properties.save_SuccessShowRtfDocId && state.properties.save_SuccessShowRtfDocId !== '') {
      dispatchFetchDocLinksAction(this.store);

      this.store.select(getDocumentLinks).pipe(
        filter(links => links && links.length > 0),
        map(links => links.find(link => link.labelId === state.properties.save_SuccessShowRtfDocId)),
        firstTruthy())
        .subscribe((link) => window.open(urlJoin(getIfeDomain(state), CONFIG_CONTEXT_PATH, link.directRtfUrl)));
    } else if (state.ifeIntegrationInfo.customOriginUrl) {
      const redirectUrl = new URL(state.ifeIntegrationInfo.customOriginUrl);
      redirectUrl.searchParams.append('itemID', response.itemId);
      window.location.href = redirectUrl.toString();
    }
  }

  private onSaveItemAsSuccess(redirectTarget) {
    switch (redirectTarget) {
      case 'currentPage':
        break;
      case 'quoteDetails':
        location.href = urlJoin(this.ifeDomain,
          'config/app/loading?targetServlet=quoteManager&targetFrame=top&saveType=saveAndReturnToQuoteDetails');
        break;
      case 'quoteDocuments':
        location.href = urlJoin(this.ifeDomain,
          'config/app/loading?targetServlet=quoteManager&targetFrame=top&saveType=saveAndReturnToQuoteDocs');
        break;
      case 'orderDetails':
        location.href = urlJoin(this.ifeDomain,
          'config/app/loading?targetServlet=quoteManager&targetFrame=top&saveType=saveAndReturnToOrderDetails');
        break;
      case 'orderDocuments':
        // Looks wrong but is consistent in behavior with quick select, leaving seperate for clarity
        location.href = urlJoin(this.ifeDomain,
          'config/app/loading?targetServlet=quoteManager&targetFrame=top&saveType=saveAndReturnToQuoteDocs');
        break;
    }
  }

  buildInitSuccessActions(response: CbsInitResponse, payload: CbsInitPayload) {
    const actions: Action[] = [
      LabelActions.init({labels: response.labels}),
      SystemPropertiesActions.init({systemProperties: response.systemProperties}),
      IfeIntegrationActions.initFromResponse({userInfo: response.cbsUserInfo}),
      PropertiesActions.initUIInfoFromResponse({uiInfo: response.cbsUIInfo}),
      PropertiesActions.devOverride({payload}),
      WorkflowActions.init({payload: {workflowId: response.cbsUIInfo.iq_CbsWorkflowID}}),
      HeaderActions.init({response}),
      WorkflowActions.displayBreadcrumbs({breadcrumbs: response.breadcrumbs})
    ];
    if (response.globalNotificationMessage && response.globalNotificationMessage !== '' ||
      response.orgNotificationMessage && response.orgNotificationMessage !== '') {
      actions.push(IfeIntegrationActions.notificationMessage({
        orgNotificationMessage: response.orgNotificationMessage,
        globalNotificationMessage: response.globalNotificationMessage
      }));
    }
    if (CbsEffects.isProductOptionsResponse(response)) {
      actions.push(ProductOptionActions.initSuccess({response}));
    }
    if (CbsEffects.isCosInitResponse(response)) {
      actions.push(CosActions.initSuccess({response}));
    }
    if (response.display) {
      // navigate by url in order to include query params (for pages)
      void this.router.navigateByUrl(response.display);
    }
    return from(actions);
  }

  static isProductOptionsResponse(response: any): response is ProductOptionsInitResponse {
    // todo add workflow object and check workflow.supports.productOptions
    return response.categories || response.product || response.pages;
  }

  static isCosInitResponse(response: any): response is COSInitResponse {
    // todo add workflow object and check workflow.supports.productOptions
    return response.parentSubcategory;
  }

  constructor(private allActions: Actions,
              private store: Store<State>,
              private cbsService: CbsService,
              private router: Router,
              private rollbar: CbsRollbarService,
              private validation: ValidateFieldService,
              private angulartics2: Angulartics2) {
    assignFirstTruthy(this.store, getIfeDomain, (ifeDomain) => this.ifeDomain = ifeDomain);
  }
}
