export const workflows: { [workflowId: string]: WorkflowSupports } = {
  productOptionsOnly: {search: false},
  cosProductOptionsSinglePage: {search: false, validateAll: true},
  basicPartsSearch: {
    linearBreadcrumbs: false,
    cosSearchFilter: false,
    productDocumentsLink: false,
    productConfiguration: false
  }
};

export interface WorkflowSupports {
  search?: boolean;
  validateAll?: boolean;
  linearBreadcrumbs?: boolean;
  cosSearchFilter?: boolean;
  productDocumentsLink?: boolean;
  productConfiguration?: boolean;
}

export function isSupported(workflowId, action): boolean {
  return workflows[workflowId] && workflows[workflowId][action] === true;
}

export function isNotSupported(workflowId, action): boolean {
  return workflows[workflowId] && workflows[workflowId][action] === false;
}
