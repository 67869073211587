<div *ngIf="showPages && pages$|async as pages;">
  <ng-container *ngIf="pages.length > 0; else configurePage">
    <a *ngFor="let page of pages" [ngClass]="{'is-current': isCustomPageActive(page) }"
       [attr.aria-selected]="isCustomPageActive(page)" [routerLink]="configRoot"
       [queryParams]="{page: page.id}" style="display: flex" attr.test-id="{{page.id}}">
      <span style="flex-grow: 1">{{page.description}}</span>
      <span *ngIf="page.invalid" matTooltip="{{'invalidFieldValue' | cbsLabel}}"><i class="fas fa-exclamation-triangle"></i></span>
    </a>
  </ng-container>
  <a *ngIf="priceSummaryColumns$|async" [ngClass]="{'is-current': isPriceSummaryPageActive() }" id="priceSummary_tab"
     aria-controls="priceSummary_tab" role="tab" [attr.aria-selected]="isPriceSummaryPageActive()"
     routerLink="/price-summary">{{'priceSummary' | cbsLabel}}</a>
  <a *ngIf="viewDocsPrivilege$|async" [ngClass]="{'is-current': isDocumentsPageActive() }" id="documents_tab"
     aria-controls="documents_tab" role="tab" [attr.aria-selected]="isDocumentsPageActive()"
     routerLink="/documents">{{'documents' | cbsLabel}}</a>
</div>
<ng-container *ngIf="showCategoryMenu && !isDocumentsPageActive() && !isPriceSummaryPageActive()">
  <hr *ngIf="showPages && pages$|async"/>
  <cbs-category-menu [strategy]="categoryMenuStrategy" [ngStyle]="{'padding-top': showPages ? 0 : '100px'}"></cbs-category-menu>
</ng-container>

<ng-template #configurePage>
  <a [ngClass]="{'is-current': isConfigurePageActive() }" id="configure_tab" aria-controls="configure_tab" role="tab"
     [attr.aria-selected]="isConfigurePageActive()" routerLink="{{configRoot}}">
    <span>{{'Configure' | cbsLabel}}</span>
  </a>
</ng-template>
