import {createAction, props} from '@ngrx/store';
import {DocumentLink} from '../models/DocumentLink';

export namespace DocumentsActions {
  export const fetchDocLinks = createAction(
    '[documents] Fetch Doc Links',
    props<{ url: string }>()
  );

  export const fetchDocLinksSuccess = createAction(
    '[documents] Fetch Doc Links Success',
    props<{ links: DocumentLink[] }>()
  );

  export const fetchDocLinksFailure = createAction(
    '[documents] Fetch Doc Links Failure'
  );

  export const updateSelectedDocLinks = createAction(
    '[documents] Update Selected Doc Link',
    props<{ links: any }>()
  );
}
