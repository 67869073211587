import {Component, Input} from '@angular/core';
import {
  getCustomOriginUrl,
  getDisplaySaveButton, getDisplaySearchButton, getIfeIntegrationInfo, getItemContainerType, getPrivileges, getProperties, getSearchDesign,
  isNewItem,
  State
} from '../reducers/index';
import {select, Store} from '@ngrx/store';
import {combineLatest, Observable} from 'rxjs';
import {PropertiesState} from '../reducers/properties.reducer';
import {getCreateCustomOptions, getFromPortal, getOriginName, isOpenAccess, isSaveSuccess} from '../reducers';
import {filter, map} from 'rxjs/operators';
import {SafeStore} from '../util/safe-store';
import {getItemNumber$} from '../util/field-util';
import {CbsActions} from '../actions/cbs.action';
import {isNullOrUndefined} from '../util/js-utils';
import {UntilDestroy} from '@ngneat/until-destroy';
import {EmailRfqComponent} from './email-rfq.component';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {PartNumberDialogComponent} from './part-number/part-number-dialog.component';
import {SearchActions} from '../actions/search.action';
import {ProductActions} from '../actions/product.action';
import {ConfirmDialogComponent} from './confirm-dialog.component';
import {WindowService} from '../services/window.service';
import {selectValue} from '../util/rx-utils';
import urlJoin from 'proper-url-join';

@UntilDestroy()
@Component({
  selector: 'cbs-button-bar',
  templateUrl: './button-bar.component.html',
  styles: [`
    :host {
      display: block;
    }
  `]
})
export class ButtonBarComponent {
  @Input() page: string;
  properties$: Observable<PropertiesState>;
  displaySearchButton$: Observable<boolean>;
  displaySaveButton$: Observable<boolean>;
  displayCustomOptionButton$: Observable<boolean>;
  displaySaveSuccess = false;
  displaySaveFail = false;
  itemNumber$: Observable<string>;
  originName$: Observable<string>;
  fromPortal$: Observable<boolean>;
  openAccessUser$: Observable<boolean>;
  displayCustomOptionButton = true;
  private safeStore: SafeStore<State>;
  hasBeenSavedToLabel: string;
  saveMessageTimeout: any;
  displayManagePartNumberAliasButton = false;
  customOriginUrl$: Observable<string>;
  isNewItem$: Observable<boolean>;

  constructor(private store: Store<State>, private dialog: MatDialog, private router: Router, private windowService: WindowService) {
    this.safeStore = new SafeStore(this, this.store);
    this.properties$ = this.store.pipe(select(getProperties));
    this.displaySearchButton$ = this.store.pipe(select(getDisplaySearchButton));
    this.displaySaveButton$ = this.store.pipe(select(getDisplaySaveButton));
    this.displayCustomOptionButton$ = this.store.pipe(select(getCreateCustomOptions));
    this.openAccessUser$ = this.store.pipe(select(isOpenAccess));
    this.safeStore.select(isSaveSuccess).pipe(
      filter(saveSuccess => {
        return !isNullOrUndefined(saveSuccess);
      }))
      .subscribe(saveSuccess => {
        if (saveSuccess === true) {
          this.displaySaveSuccess = true;
        } else {
          this.displaySaveFail = true;
        }
        this.saveMessageTimeout = setTimeout(() => this.closeSaveMessage(), 5000);
      });
    this.itemNumber$ = getItemNumber$(this.store);
    this.originName$ = this.store.pipe(select(getOriginName));
    this.fromPortal$ = this.store.pipe(select(getFromPortal));
    this.safeStore.subscribe(getItemContainerType, itemContainerType => {
      switch (itemContainerType) {
        case 1:
          this.hasBeenSavedToLabel = 'hasBeenSavedToQuote';
          break;
        case 2:
          this.hasBeenSavedToLabel = 'hasBeenSavedToOrder';
          break;
        case 3:
          this.hasBeenSavedToLabel = 'hasBeenSavedToProject';
          break;
      }
    });
    combineLatest([
      this.safeStore.select(getSearchDesign),
      this.safeStore.select(getPrivileges)
    ]).subscribe(([design, privileges]) => {
      if (design?.searchMode === 'BasicPartsSearch' && design?.partNumberAliasLookupEnabled) {
        this.displayManagePartNumberAliasButton = (privileges['editPartNumberAliasOrg'] || privileges['editPartNumberAliasOrgUnit']);
      }
    });
    this.customOriginUrl$ = this.store.pipe(select(getCustomOriginUrl));
    this.isNewItem$ = store.pipe(select(isNewItem));
  }

  updateCustomOptionDisplay($event) {
    this.displayCustomOptionButton = $event;
  }

  closeSaveMessage() {
    clearTimeout(this.saveMessageTimeout);
    this.displaySaveSuccess = false;
    this.displaySaveFail = false;
    this.store.dispatch(CbsActions.resetSaveSuccess());
  }

  openEmailDialog() {
    this.dialog.open(EmailRfqComponent, {
      panelClass: 'modal',
      data: {}
    });
  }

  isPartsSearchPageActive() {
    return (this.router.url === '/parts-search');
  }

  openManagePartNumberAliases() {
    this.dialog.open(PartNumberDialogComponent, {
      panelClass: 'modal',
      disableClose: true,
      position: {
        top: '100px'
      },
      data: {}
    }).afterClosed().subscribe(() => {
      this.store.dispatch(SearchActions.attemptStartSearch());
      this.store.dispatch(ProductActions.refresh());
    });
  }

  cancel() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {confirmationPrompt: 'cancelButtonConfirm'}
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.windowService.getWindow().location.href = selectValue(this.store, getCustomOriginUrl);
      }
    });
  }

  startOver() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {confirmationPrompt: 'startOverDialog'}
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const ife = selectValue(this.store, getIfeIntegrationInfo);
        this.windowService.getWindow().location.href = urlJoin(ife.ifeDomain, 'config/newItem?newItemID=' + ife.newItemChoiceId);
      }
    });
  }
}
