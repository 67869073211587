import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

export namespace IfeIntegrationActions {
  export const initFromUrl = createAction(
    '[ife-integration-info] Init from URL',
    props<{ url: string }>()
  );

  export const initFromResponse = createAction(
    '[ife-integration-info] Init from Response',
    props<{ userInfo: any }>()
  );

  export const initFromResponseFailed = createAction(
    '[ife-integration-info] Init from Response Failed',
    props<{ error: HttpErrorResponse }>()
  );

  export const notificationMessage = createAction(
    '[ife-integration-info] Init Notification Message',
    props<{ orgNotificationMessage: string, globalNotificationMessage: string }>()
  );
}
