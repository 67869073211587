import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {DataSource} from '@angular/cdk/collections';
import {select, Store} from '@ngrx/store';
import {
  getLabels, getPageNumber, getPageSize, getProperties, getSearchResults, getTotalResults, getWorkflowId, State
} from '../reducers/index';
import {fromEvent, interval, Observable} from 'rxjs';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {isNotSupported} from '../util/workflow-utils';
import {NearMissReasonsComponent} from './near-miss-reasons.component';
import {LocalStorageService} from 'angular-2-local-storage';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {ImageService} from '../services/image.service';
import {filter, map, tap, throttle} from 'rxjs/operators';
import {SafeStore} from '../util/safe-store';
import {isTruthy} from '../util/js-utils';
import {SearchActions} from '../actions/search.action';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {selectValue} from '../util/rx-utils';

const COLUMN_SIZES = {
  'X-SMALL': 5,
  'SMALL': 10,
  'MEDIUM': 15,
  'LARGE': 20,
  'X-LARGE': 40
};

@UntilDestroy()
@Component({
  selector: 'cbs-search-results-table',
  templateUrl: './search-results-table.component.html',
  styles: [`:host ::ng-deep .mat-form-field-flex,
  :host ::ng-deep .mat-form-field-infix,
  :host ::ng-deep .mat-select-trigger {
    background: none
  }

  .u-no-wrap {
    white-space: nowrap;
  }
  `]
})
export class SearchResultsTableComponent implements OnInit {

  @Input() sorting: Sort;
  @Input() dataColumns: string[];
  @Input() searchDesign: any;
  totalResults: number;
  pageSize: number;
  pageNumber: number;
  searchResults: SearchData;
  productConfiguration$: Observable<boolean>;
  @ViewChild('paginator', {static: true}) paginator: MatPaginator;
  headerTopVal: string;
  stickyTableHeader = false;
  private safeStore: SafeStore<State>;

  constructor(private store: Store<State>,
              private dialog: MatDialog,
              private localStorageService: LocalStorageService,
              public image: ImageService,
              private sanitizer: DomSanitizer) {

    this.safeStore = new SafeStore(this, this.store);
    if (!this.localStorageService.get('paginatorSize')) {
      this.localStorageService.set('paginatorSize', 10);
    }
    this.safeStore.select(getPageSize).subscribe(pageSize => {
      this.pageSize = pageSize;
    });
    this.safeStore.select(getPageNumber).subscribe(pageNumber => {
      this.pageNumber = pageNumber;
    });
  }

  ngOnInit() {
    this.searchResults = new SearchData(this.store, this.paginator);
    this.safeStore.select(getTotalResults).pipe(filter(isTruthy))
      .subscribe(totalResults => this.totalResults = totalResults);
    this.store.pipe(select(getLabels), filter(isTruthy),
      tap(labels => this.paginator._intl.itemsPerPageLabel = labels['resultsPerPage']));
    this.productConfiguration$ = this.store.pipe(select(getWorkflowId),
      map(workflowId => !isNotSupported(workflowId, 'productConfiguration')));
    setTimeout(() => {
      this.headerTopVal = this.getHeaderTopOffset() + 'px';
    }, 0);
    this.stickyTableHeader = selectValue(this.store, getProperties)?.stickyTableHeader;
  }

  findTitle(column: string): string {
    const headerItem = this.findSearchReturn(column);
    if (headerItem && headerItem.uomSymbol && !headerItem.price) {
      return headerItem.description + ' (' + headerItem.uomSymbol + ')';
    }
    return headerItem && headerItem.description;
  }

  noWrap(column: string): boolean {
    const currentItem = this.findSearchReturn(column);
    return currentItem && currentItem.nowrap;
  }

  getAlignment(column: string): string {
    const currentItem = this.findSearchReturn(column);
    return (currentItem && currentItem.displayAlignment) || 'center';
  }

  tableContent(column: string, row: any): string {
    return row.searchReturns[column];
  }

  isNearMissCol(column: string): boolean {
    return '_nearMiss_' === column;
  }

  selectProduct(selectedRow: any) {
    const product = selectedRow.searchResultComponents
      .map(component => component.componentId + ':' + component.searchClassId)
      .join(',');
    this.store.dispatch(SearchActions.selectProduct({product}));
  }

  nextSortDirection(allSearchReturns: any, columnId: string): string {
    const currentItem = this.findSearchReturn(columnId);
    return (currentItem && currentItem.defaultSortOrder.toLowerCase()) || 'asc';
  }

  sortSearchResults(sort: Sort) {
    if (sort.active) {
      this.store.dispatch(SearchActions.sort({sort}));
    }
  }

  displayNearMissReasons($event, row: any) {
    $event.stopPropagation();
    this.dialog.open(NearMissReasonsComponent, {data: row.nearMissReasons});
  }

  adjustedWidth(columns, column) {
    let fullWidth = 0, multiplier;
    columns.forEach(col => {
      fullWidth += this.getWidth(col);
    });
    if (fullWidth < 100) {
      multiplier = 100 / fullWidth;
    }
    return multiplier ? this.getWidth(column) * multiplier : this.getWidth(column);
  }

  getWidth(column: string) {
    let width;
    if (this.isNearMissCol(column)) {
      width = 'X-SMALL';
    } else {
      const currentItem = this.findSearchReturn(column);
      width = (currentItem && currentItem.columnWidth) || 'MEDIUM';
    }
    return COLUMN_SIZES[width];
  }

  updatePaginator(event: PageEvent) {
    this.store.dispatch(SearchActions.paginate({pageSize: event.pageSize, pageNumber: event.pageIndex}));
  }

  findSearchReturn(id: string) {
    return this.searchDesign.allSearchReturns.find(item => id === item.id);
  }

  findSearchComment(row, column) {
    return row.searchReturns[column + '_OptionComments'];
  }

  findSearchGraphic(row, column) {
    return row.searchReturns[column + '_OptionGraphics'];
  }

  getGraphicUrl(graphicUrl): Observable<SafeUrl> {
    return this.image.optionGraphicUrl(encodeURI(graphicUrl))
      .pipe(map(url => this.sanitizer.bypassSecurityTrustUrl(url)));
  }

  getScroll() {
    return fromEvent(document, 'scroll').pipe(
      throttle(val => interval(50)),
      tap((val) => {
        this.headerTopVal = this.getHeaderTopOffset() + 'px';
      })
    );
  }

  getHeaderTopOffset(): number {
    if (document.getElementById('search-results-table').getBoundingClientRect()['y'] < 0) {
      return 0 - document.getElementById('search-results-table').getBoundingClientRect()['y'];
    } else {
      return 0;
    }
  }
}

class SearchData extends DataSource<any> {

  constructor(private store: Store<State>, private paginator: MatPaginator) {
    super();
  }

  connect(): Observable<any[]> {
    return this.store.pipe(select(getSearchResults), filter(isTruthy), untilDestroyed(this, 'disconnect'));
  }

  disconnect() {
  }
}
