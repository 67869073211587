import {filter, map} from 'rxjs/operators';
import {Component, Input} from '@angular/core';
import {Category} from '../models/Category';
import {AccordionService} from '../services/accordion.service';
import {FieldContainer} from '../models/FieldContainer';
import {Observable} from 'rxjs';
import {getProductOptionCategories, getProperties, State} from '../reducers/index';
import {select, Store} from '@ngrx/store';
import {trackById} from '../util/track-by-util';
import {selectFirstTruthy} from '../util/rx-utils';

@Component({
  selector: 'cbs-category',
  templateUrl: './category.component.html'
})
export class CategoryComponent {
  @Input() pageId: string;
  @Input() category: Category;
  @Input() optionColumnLayout: number;
  @Input() singleColumnProperty: boolean;
  @Input() initProductOptionsCollapsed: Observable<boolean>;
  fieldContainer = FieldContainer.Product;
  hideExpandCollapseAll$: Observable<boolean>;

  constructor(private accordionService: AccordionService, private store: Store<State>) {
    this.hideExpandCollapseAll$ = this.store.pipe(select(getProperties),
      map(properties => properties.po_hideExpandCollapseAll || properties.po_onlyOneOpenAccordion));
  }

  expandAll(subcatIds) {
    this.accordionService.expandAll(subcatIds);
  }

  collapseAll(subcatIds) {
    this.accordionService.collapseAll(subcatIds);
  }

  firstCategory(): Observable<boolean> {
    return this.store.pipe(select(getProductOptionCategories), filter(categories => !!categories),
      map(categories => categories[0] === this.category));
  }

  expandAllButtonId() {
    return `expandAll`;
  }

  collapseAllButtonId() {
    return `collapseAll`;
  }

  parentSubcategoryIds() {
    return this.category.parentSubcategories.map(p => p.id);
  }

  allCollapsableParentSubcatIds(): Observable<string[]> {
    return selectFirstTruthy(this.store, getProductOptionCategories).pipe(
      map(categories => {
        const idMap = categories
          .map(category => category.parentSubcategories.filter(p => !(p.description === '')).map(p => p.id));
        if (idMap.length > 0) {
          return idMap.reduce((ids, catIds) => ids.concat(catIds));
        } else {
          return [];
        }
      }));
  }

  trackByParentSubcat(index, parentSubcat) {
    return trackById(index, parentSubcat);
  }

}
