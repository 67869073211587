import {Sort} from '@angular/material/sort';
import {createReducer, on} from '@ngrx/store';
import {SearchActions} from '../actions/search.action';
import {CosActions} from '../actions/cos.action';

export interface SearchState {
  results: any[];
  pageSize: number;
  pageNumber: number;
  totalResults: number;
  showLoading: boolean;
  requestInProcess: boolean;
  design: any;
  sort?: Sort;
  dirty: boolean;
}

const initialState: SearchState = {
  results: null,
  showLoading: false,
  requestInProcess: false,
  design: null,
  sort: null,
  dirty: true,
  pageSize: 10,
  pageNumber: 0,
  totalResults: 0
};

export const searchReducer = createReducer(
  initialState,
  on(CosActions.initSuccess, (state, {response}) => ({...state, design: response.searchDesign})),
  on(SearchActions.attemptStartSearch, (state) => ({...state, showLoading: true})),
  on(SearchActions.showLoadingForSort, (state) => ({...state, showLoading: state.requestInProcess})),
  on(SearchActions.sort, (state) => ({...state, requestInProcess: true})),
  on(
    SearchActions.startSearch,
    SearchActions.filter,
    (state) => ({...state, showLoading: true, requestInProcess: true})
  ),
  on(SearchActions.searchNeeded, (state) => ({...state, dirty: true, showLoading: true})),
  on(SearchActions.searchReturn, (state, {response}) => ({
    ...state,
    results: response.results,
    design: response.searchDesign || state.design,
    showLoading: false,
    requestInProcess: false,
    dirty: false,
    totalResults: response.totalResults || state.totalResults,
    pageNumber: response.pageNumber,
    pageSize: response.pageSize || state.pageSize,
    sort: createSort(response, state)
  })),
  on(SearchActions.returnSearchSort, (state, {response}) => ({
    ...state,
    results: response.results,
    showLoading: false,
    requestInProcess: false,
    pageNumber: response.pageNumber,
    sort: createSort(response, state)
  })),
  on(SearchActions.paginate, (state, {pageSize, pageNumber}) => ({
    ...state,
    pageSize: pageSize,
    pageNumber: pageNumber
  }))
);

function createSort(response, state) {
  return response.currentSort[0]
    ? {active: response.currentSort[0].searchReturnId, direction: response.currentSort[0].order.toLowerCase()}
    : state.sort;
}

export class SearchSelectors {
  static getSearchResults = (state: SearchState) => state.results;
  static isShowLoading = (state: SearchState) => state.showLoading;
  static isRequestInProcess = (state: SearchState) => state.requestInProcess;
  static getSearchDesign = (state: SearchState) => state.design;
  static getSearchSort = (state: SearchState) => state.sort;
  static getSearchDirty = (state: SearchState) => state.dirty;
  static getPageNumber = (state: SearchState) => state.pageNumber;
  static getPageSize = (state: SearchState) => state.pageSize;
  static getTotalResults = (state: SearchState) => state.totalResults;
}
