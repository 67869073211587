export const CONFIG_CONTEXT_PATH = 'config';

export class UrlUtil {

  static getCurrentUrl() {
    return window.location.href;
  }

  static parseUrlSafely(urlVal: string): URL {
    try {
      return new URL(urlVal);
    } catch (ex) {
      // happens for some of the tests using TestBed
      return new URL('http://localhost');
    }
  }

  static parseUrlQuery(urlVal: string): URLSearchParams {
    return UrlUtil.parseUrlSafely(urlVal).searchParams;
  }

  static getHost(): string {
    return new URL(UrlUtil.getCurrentUrl()).host;
  }
}
