<mat-dialog-content>
  <div class="flex-grid" style="width: 50rem; max-width: 100%;">
    <label>
      {{'emailAddress' | cbsLabel}}:
      <div class="mat-align">
        <mat-form-field floatLabel="never">
          <input matInput [id]="'rfq-email-from'" [(ngModel)]="email"
                 class="expand" #rfqEmailFrom>
        </mat-form-field>
      </div>
    </label>
    <label>
      {{'name' | cbsLabel}}:
      <div class="mat-align">
        <mat-form-field floatLabel="never">
          <input matInput [id]="'rfq-email-name'" [placeholder]="'FirstName LastName'" [(ngModel)]="name"
                 class="expand" #rfqEmailName>
        </mat-form-field>
      </div>
    </label>
  </div>
  <label>
    {{'body' | cbsLabel}}:
    <div class="mat-align">
      <mat-form-field floatLabel="never">
        <textarea matInput [id]="'rfq-email-comments'" [(ngModel)]="comments" #rfqEmailComments> </textarea>
      </mat-form-field>
    </div>
  </label>

  <div class="flex-grid" style="width: 50rem; max-width: 100%;">
    <div *ngFor="let field of additionalEmailRfqFields" class="grid-item field-item">
      <cbs-field style=" width: 100%" [field]="field" [categoryId]="undefined" [parentSubcatId]="undefined"
                 [subcatId]="undefined" [fieldContainer]="fieldContainer" [optionColumnLayout]="2">
      </cbs-field>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="center">
  <button class="c-button" test-id="SEND_BUTTON" (click)="sendEmailRfq(additionalEmailRfqFields)" mat-dialog-close>
    {{'send' | cbsLabel}}
  </button>
  <button class="c-text-button" mat-dialog-close test-id="CANCEL_BUTTON">
    {{'cancel' | cbsLabel}}
  </button>
</mat-dialog-actions>
