import {createReducer, on} from '@ngrx/store';
import {DisplayDataPayload, WorkflowActions} from '../actions/workflow.action';

export interface WorkflowState {
  workflowId: string;
  displayBreadcrumbs: DisplayBreadcrumbs;
  displayPrice: boolean;
  displaySaveButton: boolean;
  displaySearchButton: boolean;
  createCustomOptions: boolean;
}

export interface DisplayBreadcrumbs {
  [breadcrumbId: string]: Breadcrumb;
}

export interface Breadcrumb {
  display: boolean,
  target: string
}

const initialState = <WorkflowState>{
  workflowId: '',
  displayBreadcrumbs: {},
  displayPrice: true,
  displaySaveButton: false,
  displaySearchButton: false,
  createCustomOptions: false
};

export const workflowReducer = createReducer(
  initialState,
  on(WorkflowActions.init, (state, {payload}) => updateWorkflow(payload, state)),
  on(WorkflowActions.displayBreadcrumbs, (state, {breadcrumbs}) => updateDisplayBreadcrumbs(breadcrumbs, state)),
  // on(WorkflowActions.displayPrice, (state, payload: any) => updateDisplayPrice(payload, state)),
  on(WorkflowActions.displayData, (state, {payload}) => updateDisplayData(payload, state))
);

function updateWorkflow(payload, state: WorkflowState) {
  const workflowId = payload.iq_CbsWorkflowID || payload.workflowId;
  return {
    ...state,
    workflowId
  };
}

function updateDisplayData(payload: DisplayDataPayload, state: WorkflowState) {
  return {
    ...state,
    displayPrice: payload.displayPrice,
    displaySaveButton: payload.displaySaveButton,
    displaySearchButton: payload.displaySearchButton,
    createCustomOptions: payload.createCustomOptions
  };
}

function updateDisplayBreadcrumbs(payload: DisplayBreadcrumbs, state: WorkflowState) {
  return {
    ...state,
    displayBreadcrumbs: {
      ...state.displayBreadcrumbs,
      ...payload
    }
  };
}

export class WorkflowSelectors {
  static getWorkflowId = (state: WorkflowState) => state.workflowId;
  static getDisplayBreadcrumbs = (state: WorkflowState) => state.displayBreadcrumbs;
  static getDisplayPrice = (state: WorkflowState) => state.displayPrice;
  static getDisplaySearchButton = (state: WorkflowState) => state.displaySearchButton;
  static getDisplaySaveButton = (state: WorkflowState) => state.displaySaveButton;
  static getCreateCustomOptions = (state: WorkflowState) => state.createCustomOptions;
}

export class WorkflowUtils {
  static isTwoColumnFieldLayout(workflowId: string) {
    return workflowId !== 'cosFilterSearch';
  }
}

