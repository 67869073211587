import {map, startWith} from 'rxjs/operators';
import {Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Field} from '../models/Field';
import {Choice} from '../models/Choice';
import {select, Store} from '@ngrx/store';
import {getIfeDomain, getPrivileges, getProperties, State} from '../reducers/index';
import {FieldContainer} from '../models/FieldContainer';
import {isNullOrUndefined} from '../util/js-utils';
import {ValidateFieldService} from '../services/validate-field.service';
import {WorkflowService} from '../services/web/workflow.service';
import {ImageService} from '../services/image.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Observable} from 'rxjs';
import {FormControl} from '@angular/forms';

import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {MatDialog} from '@angular/material/dialog';
import {PageEvent} from '@angular/material/paginator';
import {OptionOverrideComponent} from './option-override.component';
import {WindowService} from '../services/window.service';
import {trackById} from '../util/track-by-util';
import {NgbPopoverConfig} from '@ng-bootstrap/ng-bootstrap';
import urlJoin from 'proper-url-join';
import {ConfirmDialogComponent} from './confirm-dialog.component';
import {assignFirstTruthy} from '../util/rx-utils';
import {SafeStore} from '../util/safe-store';
import {isOptionGraphicDisplay_Comment, isOptionGraphicDisplay_Inline} from 'app/util/field-util';
import {FieldActions} from '../actions/field.action';
import {ProductOptionActions} from '../actions/product-options.action';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'cbs-field',
  templateUrl: './field.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `.graphic-top, .graphic-top > .mat-option-text {
      text-align: center;
      height: auto !important;
      width: 100%;
    }

    .graphic-left, .graphic-left > .mat-option-text {
      height: auto !important;
    }

    .graphic-top > label > .mat-radio-label-content > img, .graphic-top > label > .mat-checkbox-label > img,
    .graphic-top > .mat-option-text > img, .graphic-top > img {
      margin: auto;
      display: block;
    }

    .graphic-top > .mat-option-text > img {
      margin-top: 1rem;
    }

    .graphic-top > img {
      margin-top: .25rem;
    }

    .graphic-left {
      display: inline-flex;
      position: relative;
      align-items: center;
    }

    .graphic-left > label > .mat-radio-label-content > img, .graphic-left > label > .mat-checkbox-label > img {
      float: left;
      margin-right: 5px;
    }

    .graphic-left > .selected-option-text, .graphic-left > .mat-option-text > .selected-option-text {
      display: inline;
      position: absolute;
      white-space: normal;
      top: 50%;
      transform: translate(0, -50%);
      padding-left: .25rem;
      line-height: 1.5em;
    }

    .graphic-left, .graphic-top, .graphic-left > label, .graphic-top > label,
    .graphic-left > label > .mat-radio-label-content, .graphic-top > label > .mat-radio-label-content {
      width: 100%;
    }

    .option-description {
      white-space: pre-line;
    }

    .option-price {
      display: table-cell;
      white-space: nowrap;
      text-align: left;
      vertical-align: top;
    }

    .hidden-input-padding {
      padding-top: 1px;
    }
    `]
})
export class FieldComponent implements OnInit {

  @Input() field: Field;
  @Input() parentSubcatId: string;
  @Input() subcatId: string = null;
  @Input() pageId: string;
  @Input() categoryId: string;
  @Input() fieldContainer: FieldContainer;
  static currentFocus: FieldComponent;
  uomChange: boolean;
  fieldChange: boolean;
  private _selectValue: string;
  focusCount: number;
  @Input() optionColumnLayout: number;
  overrideDescription: boolean;
  overridePrice: boolean;
  overrideRFQ: boolean;
  @ViewChild(MatAutocompleteTrigger, {static: false}) autoComplete: MatAutocompleteTrigger;
  displayPartNumber$: Observable<boolean>;
  alternateShading: boolean;
  viewPricing: boolean;
  viewOptionOverride: boolean;
  choicesPage = 0;
  chartData;
  chartUrl: SafeUrl;

  filterControl: FormControl;

  autoFilter$: Observable<any>;

  numRows = {
    'X-SMALL': 1,
    'SMALL': 3,
    'MEDIUM': 5,
    'LARGE': 7,
  };
  graphicSize = {
    'SMALL': '40px',
    'MEDIUM': '80px',
    'ACTUAL': ''
  };

  static previousChoicesPage = 0;

  choicesPerPage = 10;
  private ifeDomain: string;
  private safeStore: SafeStore<State>;

  constructor(private store: Store<State>,
              private validateFieldService: ValidateFieldService,
              workflowService: WorkflowService,
              public image: ImageService,
              private sanitizer: DomSanitizer,
              private dialog: MatDialog,
              private windowService: WindowService,
              config: NgbPopoverConfig) {
    this.safeStore = new SafeStore(this, this.store);
    config.placement = 'right';
    config.triggers = 'hover';
    assignFirstTruthy(this.store, getIfeDomain, ifeDomain => this.ifeDomain = ifeDomain);
  }

  ngOnInit() {
    if (this.field.paginationLimit) {
      this.choicesPerPage = this.field.paginationLimit;
    }
    this.updateSelectValue();
    this.focusCount = 0;
    this.filterControl = new FormControl({value: null});
    this.autoFilter$ = this.filterControl.valueChanges.pipe(startWith('')).pipe(
      map(choice => choice && typeof choice === 'object' ? choice.description : choice),
      map(desc => desc ? this.filterChoices(desc) : this.field.choices.slice()));
    this.safeStore.subscribe(getPrivileges, privileges => {
      this.viewOptionOverride = privileges['viewOptionOverrides'];
      this.overrideDescription = privileges['overrideDescription'];
      this.overridePrice = privileges['overridePrice'];
      this.overrideRFQ = privileges['overrideRFQ'];
      this.viewPricing = privileges['viewPricingInConfigurator'];
    });
    this.displayPartNumber$ = this.store.pipe(select(getProperties), map(properties => {
      this.alternateShading = properties.po_rowShading;
      return properties.po_displayPartNumber;
    }));
    this.choicesPerPage = this.field.paginationLimit;
    if (this.isPaginated()) {
      const index = this.field.choices.indexOf(this.selectedChoice());
      this.choicesPage = Math.floor(index / this.choicesPerPage);
      if (this.field.displayType === 'MULTIPICK') {
        this.choicesPage = FieldComponent.previousChoicesPage;
      }
    }
    if (this.field.displayType === 'CHART') {
      const chartUrl = urlJoin(this.ifeDomain, `/config/api/v1/charts/chartData`);
      this.chartUrl = this.sanitizer.bypassSecurityTrustResourceUrl(chartUrl + '?chartType=' + this.field.value);
    }
  }

  filterChoices(filterString: string) {
    return this.field.choices.filter(choice =>
      choice.description.toLowerCase().indexOf(filterString.toLowerCase()) >= 0);
  }

  updateSelectValue() {
    if (this.hasUnitOfMeasure()) {
      this._selectValue = this.selectedUnitOfMeasure().httpValue;
    } else if (this.field.displayType === 'DROPDOWN') {
      this._selectValue = this.selectedChoice() && this.selectedChoice().httpValue;
    } else {
      this._selectValue = this.field.value;
    }
  }

  get selectValue(): string {
    return this._selectValue;
  }

  set selectValue(value: string) {
    this._selectValue = value;
  }

  isInvalid(): boolean {
    if (!(this.field.invalid === undefined)) {
      return this.field.invalid;
    }
    return false;
  }

  setFocus() {
    FieldComponent.currentFocus = this;
  }

  // Avoids empty state changes by verifying the content changed
  uomChanged() {
    this.uomChange = true;
  }

  fieldChanged() {
    this.fieldChange = true;
  }

  checkSubmit(num, uom) {
    if (this.uomChange || this.fieldChange) {
      const prevFocus = FieldComponent.currentFocus;
      if (prevFocus != null) {
        FieldComponent.currentFocus = null;
        setTimeout(() => {
          if (FieldComponent.currentFocus === this) {
          } else {
            this.uomSubmit(num, uom, prevFocus);
          }
        }, 250);
      }
    }
  }

  uomSubmit(num, uom, focusField: FieldComponent = this, pageIndex?: number) {
    if (pageIndex) {
      FieldComponent.previousChoicesPage = pageIndex;
    }
    if (this.fieldChange && this.uomChange) {
      focusField.submitUOMNumericChange(num, uom);
    } else if (this.fieldChange) {
      focusField.submitNumericFieldChange(num);
    } else {
      focusField.submitUOMChange(uom);
    }
    this.uomChange = false;
    this.fieldChange = false;
  }

  // todo - create a type for change params to use in validateFieldService?
  submitChange(params: string[][]) {
    if (this.fieldContainer !== FieldContainer.Header) {
      if (this.validateFieldService.validateParameters(params)) {
        this.store.dispatch(FieldActions.changeField({
          categoryId: this.categoryId,
          parentSubcatId: this.parentSubcatId,
          subcatId: this.subcatId,
          fieldId: this.field.id,
          changeParams: params,
          fieldContainer: this.fieldContainer,
          pageId: this.pageId
        }));
      } else {
        this.store.dispatch(FieldActions.invalidField({
          categoryId: this.categoryId,
          parentSubcatId: this.parentSubcatId,
          subcatId: this.subcatId,
          fieldId: this.field.id,
          fieldContainer: this.fieldContainer,
          pageId: this.pageId
        }));
      }
    } else {
      this.store.dispatch(FieldActions.changeField({
        categoryId: this.categoryId,
        parentSubcatId: this.parentSubcatId,
        subcatId: this.subcatId,
        fieldId: this.field.id,
        changeParams: params,
        fieldContainer: this.fieldContainer
      }));
    }
  }

  submitUOMNumericChange(num, uom) {
    this.dispatchTempChangeFieldValueAction(num);
    const params = [[this.field.httpNumericValueName, num], [this.field.unitOfMeasureField.httpName, uom]];
    this.submitChange(params);
  }

  submitUOMChange(uom, option?) {
    if (!option) {
      option = this.field;
    }
    const params = [[option.unitOfMeasureField.httpName, uom]];
    this.submitChange(params);
  }

  submitTextFieldChange(value) {
    this.dispatchTempChangeFieldValueAction(value);

    const params = [[this.field.httpName, value]];
    this.submitChange(params);
  }

  submitNumericFieldChangeValidatingQty(numericFieldVar) {
    let value = numericFieldVar.value;
    const validatedValue = parseInt(numericFieldVar.value, 10);
    if (this.field.id.includes('Qty') && validatedValue === 0) {
      value = '1';
      numericFieldVar.value = value;
    }
    this.submitNumericFieldChange(value);
  }

  submitNumericFieldChange(value) {
    this.dispatchTempChangeFieldValueAction(value);
    const params = [[this.field.httpNumericValueName, value]];
    this.submitChange(params);
  }
  
  numberOnlyValidatingQty(event): boolean {
    if (this.field.id.includes('Qty')){
      return this.numberOnly(event);
    } else {
      return true;
    }
  }
  
  numberOnly(event): boolean {
    const charCode = event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  private dispatchTempChangeFieldValueAction(value) {
    if (this.field.displayType.includes('NUMERIC')) {
      this.store.dispatch(FieldActions.tempChangeNumericField({
        categoryId: this.categoryId,
        parentSubcatId: this.parentSubcatId,
        fieldId: this.field.id,
        subcatId: this.subcatId,
        numericValue: this.validateFieldService.parseValue(value, this.field.numericValue),
        value: value,
        fieldContainer: this.fieldContainer,
        pageId: this.pageId
      }));
    } else {
      this.store.dispatch(FieldActions.tempChangeFieldValue({
        categoryId: this.categoryId,
        parentSubcatId: this.parentSubcatId,
        subcatId: this.subcatId,
        fieldId: this.field.id,
        value: value,
        fieldContainer: this.fieldContainer,
        pageId: this.pageId
      }));
    }

  }

  submitDropdownFieldChange(value) {
    if (!!this.selectedChoice() && value === this.selectedChoice().httpValue) {
      return;
    }
    this.store.dispatch(FieldActions.tempChangeDropdownField({
      categoryId: this.categoryId,
      parentSubcatId: this.parentSubcatId,
      fieldId: this.field.id,
      subcatId: this.subcatId,
      previousSelectedChoiceId: this.selectedChoiceId(),
      newSelectedChoiceId: this.getChoiceIdByHttpValue(value),
      fieldContainer: this.fieldContainer,
      pageId: this.pageId
    }));

    const params = [[this.field.httpName, value]];
    this.submitChange(params);
  }

  submitMultipickFieldChange(choice: Choice, pageIndex?: number) {
    const toggleSelected = !choice.selected;
    if (!isNullOrUndefined(pageIndex)) {
      FieldComponent.previousChoicesPage = pageIndex;
    }
    this.store.dispatch(FieldActions.tempChangeCheckboxField({
      categoryId: this.categoryId,
      parentSubcatId: this.parentSubcatId,
      fieldId: this.field.id,
      subcatId: this.subcatId,
      choiceId: choice.id,
      choiceSelected: toggleSelected,
      fieldContainer: this.fieldContainer,
      pageId: this.pageId
    }));

    const params = [
      [choice.httpName, String(toggleSelected)],
      [choice.httpDirtyName, 'true'],
      ['quantity_' + (choice.httpValue || choice.id), '' + choice.quantity]

    ];
    this.submitChange(params);
  }

  submitNumericChoiceChange(choice, value, pageIndex?: number) {
    if (pageIndex) {
      FieldComponent.previousChoicesPage = pageIndex;
    }
    const params = [
      [choice.httpNumericValueName, value],
      [choice.httpDirtyName, 'true']];
    this.submitChange(params);
    choice.displayLoading = true;
  }

  /**
   * Helper for *ngSwitchCase
   * See https://stackoverflow.com/questions/40176061/two-switch-case-values-in-angular2/40177408#40177408
   */
  isNumericDisplay() {
    const type = this.field.displayType;
    return type === 'NUMERIC_SPINNER' || type === 'NUMERIC_INPUT' ? type : '';
  }

  hasUnitOfMeasure(option?) {
    if (!option) {
      option = this.field;
    }
    return option.unitOfMeasureField != null && option.unitOfMeasureField.choices.length > 0;
  }

  hasUnitOfMeasureChoices(option?) {
    if (!option) {
      option = this.field;
    }
    return option.unitOfMeasureField.choices.length > 1;
  }

  unitOfMeasureSymbol(option?) {
    return this.selectedUnitOfMeasure(option).description;
  }

  selectedUnitOfMeasure(option?) {
    if (!option) {
      option = this.field;
    }
    return option.unitOfMeasureField.choices
      .find(choice => choice.selected);
  }

  fieldDescriptionDisplay() {
    return this.field.description || '\u00A0';
  }

  displayTitle(): boolean {
    return !(this.field.menuType && this.field.menuType.includes('notitle'));
  }

  private selectedChoiceId() {
    const choice = this.field.choices
      .find(c => c.selected);

    return choice && choice.id;
  }

  public selectedChoice() {
    const choice = this.field.choices
      .find(c => c.selected);

    if (this.field.choices.length === 1) {
      return this.field.choices[0];
    }
    return choice || null;
  }

  isDisabled(allowSingletonList?: boolean) {
    return !this.field.editable || (this.field.choices && (allowSingletonList ? false : this.field.choices.length === 1));
  }

  private getChoiceIdByHttpValue(value: string) {
    const choice = this.field.choices
      .find(c => c.httpValue === value);

    return choice.id || null;
  }

  getGraphicUrl(graphicUrl): Observable<SafeUrl> {
    return this.image.optionGraphicUrl(encodeURI(graphicUrl))
      .pipe(map(url => this.sanitizer.bypassSecurityTrustUrl(url)));
  }

  displayFn(choice: Choice) {
    return choice && choice.description;
  }

  displayVal(selectedChoice) {
    if (this.field.displayType === 'AUTOCOMPLETE') {
      return selectedChoice?.description;
    } else if (this.field.displayType === 'DROPDOWN') {
      return selectedChoice ? selectedChoice.description : 'Missing selected option, please update kb';
    }
  }

  verify(val) {
    document.getElementById(this.field.id).blur();
    if (val.length === 0) {
      return;
    }
    const topChoice = this.filterChoices(val)[0];
    if (topChoice) {
      this.submitDropdownFieldChange(topChoice.httpValue);
    }
    this.autoComplete.closePanel();
    this.filterControl.setValue('');
  }

  autoCompleteSelected(event: MatAutocompleteSelectedEvent) {
    const choice = event.option.value;
    this.submitDropdownFieldChange(choice.httpValue);
    this.filterControl.setValue('');
    document.getElementById(this.field.id).blur();
  }

  preventDropdown($event) {
    $event.stopPropagation();
  }

  openOverrideDialog(choice) {
    this.dialog.open(OptionOverrideComponent, {
      panelClass: 'modal',
      data: {
        choice: choice, parentSubcatId: this.parentSubcatId, subcatId: this.subcatId, categoryId: this.categoryId,
        fieldId: this.field.id,
        overridePrice: this.overridePrice || this.overrideRFQ && choice.rfq,
        overrideDescription: this.overrideDescription && choice.overrideDesc
      }
    });
  }

  canOverride() {
    return this.viewOptionOverride && !this.isCosField();
  }

  getPriceWidth() {
    let max = 0;
    if (!this.viewPricing) {
      return 0;
    }
    this.field.choices.forEach(choice => {
      if (choice.price) {
        max = choice.price.length > max ? choice.price.length : max;
      }
      if (choice.rfq) {
        max = 7 > max ? 7 : max;
      }
    });
    if (max > 0 && max < 5) {
      max = 5;
    }
    return max * .7;
  }

  getPartNumberWidth(display: boolean) {
    let max = 0;
    if (!display) {
      return 0;
    }
    this.field.choices.forEach(choice => {
      if (choice.partNumber) {
        max = choice.partNumber.length > max ? choice.partNumber.length : max;
      }
    });
    return max * .8;
  }

  additionalSpace(option): number {
    let sum = this.canOverride() ? 1.5 : 0;
    sum += (option.quantity > 1 && option.selected) ? this.quantitySpace(option.quantity) : 0;
    return sum;
  }

  quantitySpace(quantity): number {
    return Math.ceil(Math.log10(quantity + 1)) * .8;
  }

  isMobile() {
    return this.windowService.getWidth() <= 640;
  }

  trackByChoice(index, choice) {
    return trackById(index, choice);
  }

  paginatedChoices() {
    if (this.isPaginated()) {
      return this.field.choices.slice(this.choicesPage * this.choicesPerPage, (this.choicesPage + 1) * this.choicesPerPage);
    } else {
      return this.field.choices;
    }
  }

  isPaginated(): boolean {
    return this.choicesPerPage > 0 && this.field.choices.length > this.choicesPerPage;
  }

  updateChoices(event: PageEvent) {
    this.choicesPage = event.pageIndex;
  }

  deleteCustomField(field) {
    const popup = ConfirmDialogComponent;
    const dialogRef = this.dialog.open(popup, {data: {confirmationPrompt: 'doYouWantToDelete'}});
    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(ProductOptionActions.deleteCustomOption({payload: {...field, categoryId: this.categoryId}}));
      }
    });
  }

  isCosField() {
    return this.parentSubcatId === 'COSPage';
  }

  isOptionGraphicDisplay_Inline(container: Field | Choice) {
    return isOptionGraphicDisplay_Inline(container);
  }

  isOptionGraphicDisplay_Comment(container: Field | Choice) {
    return isOptionGraphicDisplay_Comment(container);
  }

  displayQuantity(option: Field | Choice) {
    if (!isNullOrUndefined((<Choice>option).selected) && !(<Choice>option).selected) {
      return false;
    }
    return option.price && (option.quantity > 1 || option.quantityEditable);
  }
}
