import {Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {getProductOptionCategories, State} from '../reducers';
import {Observable} from 'rxjs';
import {Category} from '../models/Category';
import {CategoryMenuService} from '../services/category-menu.service';
import {filter, map, tap} from 'rxjs/operators';
import {CategoryMenuStrategy} from '../reducers/properties.reducer';
import {isTruthy} from '../util/js-utils';

@Component({
  selector: 'cbs-category-menu',
  templateUrl: './category-menu-menu.component.html',
  styles: [`
    :host {
      flex: 1 1 auto;
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 10px;
      word-break: break-word;
    }
  `],
  providers: [CategoryMenuService]
})
export class CategoryMenuMenuComponent implements OnInit {
  @Input() strategy: CategoryMenuStrategy;
  categories$: Observable<Category[]>;
  showExpandCollapseAll = false;

  constructor(private store: Store<State>, private categoryMenuService: CategoryMenuService) {
  }

  ngOnInit(): void {
    this.categories$ = this.store.select(getProductOptionCategories).pipe(
      filter(isTruthy),
      map(categories => {
        // if there is only 1 category, we show the subcategories at the top level
        if (categories.length > 1) {
          return categories.filter(category => category.description);
        } else {
          return categories;
        }
      }),
      tap(categories => this.showExpandCollapseAll = this.strategy === CategoryMenuStrategy.SUB_CATEGORY && categories.length > 1)
    );
  }

  expandAll() {
    this.categoryMenuService.expandAll();
  }

  collapseAll() {
    this.categoryMenuService.collapseAll();
  }

  trackByCategoryId(index: number, item: Category) {
    return item.id;
  }
}
