import {createAction, props} from '@ngrx/store';
import {ItemSaveResponse} from '../services/web/item-save-response';

export namespace CbsActions {
  export const initFromConfig = createAction(
    '[cbs] Init From Config',
    props<{ payload: CbsInitPayload }>()
  );

  export const sendRfqEmail = createAction(
    '[cbs] Send RFQ Email',
    props<{ payload: RfqEmailPayload }>()
  );

  export const rfqEmailSent = createAction(
    '[cbs] RFQ Email Sent',
  );

  export const itemSave = createAction(
    '[cbs] Item Save',
    (asyncSave?: boolean) => (asyncSave === undefined ? {asyncSave: true} : {asyncSave})
  );

  export const itemSaveAs = createAction(
    '[cbs] Item Save As',
    props<{ payload: SaveAsPayload }>()
  );

  export const saveToNewQuote = createAction(
    '[cbs] Save To New Quote',
    props<{ objectId: number }>()
  );

  export const saveToNewOrder = createAction(
    '[cbs] Save To New Order',
    props<{ objectId: number }>()
  );

  export const saveToNewProject = createAction(
    '[cbs] Save To New Project',
    props<{ payload: SaveToNewProjectPayload }>()
  );

  export const itemSaveSuccess = createAction(
    '[cbs] Item Save Success',
    props<{ response: any }>() // Type is any because ItemSaveResponse interface doesn't contain all properties
  );

  export const itemSaveAsSuccess = createAction(
    '[cbs] Item Save As Success',
    props<{ response: ItemSaveResponse }>()
  );

  export const saveToNewQuoteSuccess = createAction(
    '[cbs] Save To New Quote Success',
    props<{ response: ItemSaveResponse }>()
  );

  export const saveToNewOrderSuccess = createAction(
    '[cbs] Save To New Order Success',
    props<{ response: ItemSaveResponse }>()
  );

  export const saveToNewProjectSuccess = createAction(
    '[cbs] Save To New Project Success',
    props<{ response: ItemSaveResponse }>()
  );

  export const itemSaveFail = createAction(
    '[cbs] Item Save Fail'
  );

  export const resetSaveSuccess = createAction(
    '[cbs] Item Save Success Reset'
  );
}

export interface CbsInitPayload {
  configInitUrl: string;
  configBaseDir: string;
  orgFolderHome: string;
}

export interface RfqEmailPayload {
  name: string,
  email: string,
  comments: string,
  additionalData: any
}

export interface SaveAsPayload {
  changeParams: string[][];
  redirectTarget: string;
  saveAs: boolean;
}

export interface SaveToNewProjectPayload {
  newProjectName: string;
  itemNumber?: string;
}
