import {Component, HostBinding, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {UrlUtil} from '../util/url-util';
import {
  getIfeIntegrationInfo, getLabels, getProperties, isCfgSessionValid, isInitFromResponse, isInitUrlValid, State
} from '../reducers';
import {getAnalyticsScripts} from '../reducers';
import {Observable} from 'rxjs';
import {LocalStorageService} from 'angular-2-local-storage';
import {Router} from '@angular/router';
import {IfeIntegrationInfoSelectors, IfeIntegrationInfoState} from 'app/reducers/ife-integration-info.reducer';
import {GoogleTagManagerService} from '../services/google-tag-manager.service';
import {filter, first} from 'rxjs/operators';
import {selectFirstTruthy} from '../util/rx-utils';
import {CbsActions} from '../actions/cbs.action';
import {IfeIntegrationActions} from '../actions/ife-integration-info.action';
import {isTruthy} from '../util/js-utils';
import {injectAnalyticsScripts} from '../util/analytics-scripts-injector';
import {CbsRollbarService} from '../rollbar/cbs-rollbar.service';

@Component({
  selector: 'cbs-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  @HostBinding('class.widescreen') widescreen = false;
  cfgSessionValid$: Observable<boolean>;
  initUrlValid$: Observable<boolean>;
  initFromResponse$: Observable<boolean>;

  constructor(private store: Store<State>,
              private localStorageService: LocalStorageService,
              private router: Router,
              private googleTagManager: GoogleTagManagerService,
              private rollbar: CbsRollbarService) {
    this.initUrlValid$ = this.store.pipe(select(isInitUrlValid));
    this.cfgSessionValid$ = this.store.pipe(select(isCfgSessionValid));
    this.initFromResponse$ = this.store.pipe(select(isInitFromResponse));
    this.store.select(getProperties).subscribe(props => {
      this.widescreen = props.displayWidescreen || false;
    });
    this.injectAnalyticsScripts();
  }

  ngOnInit() {
    this.dispatchInitFromUrlAction();
    this.dispatchPropertiesInitAction();
    this.dispatchCbsInitAction();
    this.checkInitAlerts();
  }

  private dispatchInitFromUrlAction() {
    let currentUrl = UrlUtil.getCurrentUrl();
    if (currentUrl.includes('orgFolderHome')) {
      this.localStorageService.set('urlInitKey', currentUrl);
    } else {
      currentUrl = <string>this.localStorageService.get('urlInitKey');
      this.router.navigate(['/']);
    }
    this.store.dispatch(IfeIntegrationActions.initFromUrl({url: currentUrl}));
  }

  private dispatchPropertiesInitAction() {
    this.store.pipe(select(getIfeIntegrationInfo),
      first(ifeIntegrationInfo => ifeIntegrationInfo && ifeIntegrationInfo.initFromUrl))
      .subscribe((ifeIntegrationInfo: IfeIntegrationInfoState) => {
        // this.googleTagManager.push({org: ifeIntegrationInfo.orgFolderHome});
        if (IfeIntegrationInfoSelectors.isInitUrlValid(ifeIntegrationInfo)) {
          this.store.dispatch(CbsActions.initFromConfig({
            payload: {
              configInitUrl: ifeIntegrationInfo.configInitUrl,
              configBaseDir: ifeIntegrationInfo.configBaseDir,
              orgFolderHome: ifeIntegrationInfo.orgFolderHome
            }
          }));
        }
      });
  }

  private dispatchCbsInitAction() {
  }

  private checkInitAlerts() {
    // check knowledge base changes
    this.store.pipe(select(getIfeIntegrationInfo),
      first(ifeIntegrationInfo => ifeIntegrationInfo && ifeIntegrationInfo.knowledgeBaseChanged))
      .subscribe((ifeIntegrationInfo: IfeIntegrationInfoState) => {
        if (IfeIntegrationInfoSelectors.isKnowledgeBaseChanged(ifeIntegrationInfo)) {
          selectFirstTruthy(this.store, getLabels)
            .subscribe(labels => {
              let knowledgeBaseChangedMessage;
              knowledgeBaseChangedMessage = labels['knowledgebaseChanged'] + '\n\n' + labels['knowledgebaseChanged2'];
              alert(knowledgeBaseChangedMessage);
            });
        }
      });
  }

  private injectAnalyticsScripts(): void {
    this.store.select(getAnalyticsScripts).pipe(filter(isTruthy)).subscribe((scripts) => {
      try {
        injectAnalyticsScripts(scripts);
      } catch (error) {
        this.rollbar.error('Fail to inject analytics scripts.', error, {scripts});
      }
    });
  }
}
