import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {getHeaders, getItemContainerType, getSavedItemNumber, State} from '../reducers';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs/index';
import {Field} from '../models/Field';
import {map, tap} from 'rxjs/operators';
import {ValidateFieldService} from '../services/validate-field.service';
import {assignFirstTruthy} from '../util/rx-utils';
import {CbsActions} from '../actions/cbs.action';

@Component({
  selector: 'cbs-save-as',
  templateUrl: './save-as.component.html',
})
export class SaveAsComponent implements OnInit {

  itemNumberField$: Observable<Field>;
  itemNumber: Field;
  itemNumberValue: string;
  savedItemNumber: string;
  redirectLocation: string;
  itemContainerType: number;

  constructor(private store: Store<State>, private validateFieldService: ValidateFieldService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.itemNumberField$ = this.store.pipe(select(getHeaders), map(fields => fields.find(field => field.id === 'ItemNumberInput')),
      tap(itemNumber => {
        this.itemNumber = itemNumber;
        this.itemNumberValue = itemNumber ? itemNumber.value : '';
      }));
    this.redirectLocation = 'currentLocation';
    assignFirstTruthy(this.store, getSavedItemNumber, savedItemNumber => this.savedItemNumber = savedItemNumber);
    assignFirstTruthy(this.store, getItemContainerType, itemContainerType => this.itemContainerType = itemContainerType);
  }

  saveAs() {
    setTimeout(() => {
      if (this.validateFieldService.validateMandatory()) {
        this.store.dispatch(CbsActions.itemSaveAs({
          payload: {
            changeParams: [[this.itemNumber.httpName, this.itemNumberValue], ['asyncSave', 'false']],
            redirectTarget: this.redirectLocation, saveAs: this.itemNumberValue !== this.savedItemNumber
          }
        }));
      }
    }, 300);
  }

  updateRedirectLocation(value) {
    this.redirectLocation = value;
  }
}
