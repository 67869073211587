import {PropertiesState} from '../reducers/properties.reducer';

export const weg = {
  all: <PropertiesState> {
    labelOverride: {
      Configure: 'Summary',
    },
    po_initAccordionsCollapsed: true,
    po_onlyOneOpenAccordion: true,
    displayWidescreen: true,
  }
};
