import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {State} from '../reducers/index';
import {WorkflowActions} from '../actions/workflow.action';

@Component({
  selector: 'cbs-cos-product-options-route',
  templateUrl: './cos-product-options-route.component.html'
})
export class CosProductOptionsRouteComponent implements OnInit {

  constructor(private store: Store<State>) {
  }

  ngOnInit(): void {
    this.store.dispatch(WorkflowActions.displayBreadcrumbs({
      breadcrumbs: {product: {display: true, target: 'cos-product-options'}}
    }));
    this.store.dispatch(WorkflowActions.displayData({
      payload: {
        displayPrice: true,
        displaySaveButton: true,
        displaySearchButton: false,
        createCustomOptions: true
      }
    }));
  }

}
