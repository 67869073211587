import {Component, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
  selector: 'cbs-invalid-field',
  templateUrl: './invalid-field.component.html',
})
export class InvalidFieldComponent {
  message: string;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
    if (data.invalidMandatory) {
      this.message = 'pleasePopulateMandatory';
    } else {
      this.message = 'invalidFieldValue';
    }
  }
}
