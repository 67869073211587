<ng-container *ngIf="categories$|async as categories">
  <button class="c-button" *ngIf="customOptionsEnabled" [matMenuTriggerFor]="customOptionsTypeMenu" test-id="ADD_SPECIAL_BUTTON">
    <i class="fas fa-plus-circle m-r-base"></i>
    <span>{{'addSpecial' | cbsLabel}}</span>
  </button>
  <mat-menu #customOptionsTypeMenu>
    <button mat-menu-item [matMenuTriggerFor]="subcatMenu" test-id="ADD_SPECIAL_BUYOUT_BUTTON"
            [matMenuTriggerData]="{optionType: 'addSpecialBuyout'}">{{'addSpecialBuyout' | cbsLabel}}</button>
    <button mat-menu-item [matMenuTriggerFor]="subcatMenu" test-id="ADD_SPECIAL_MANUFACTURED_BUTTON"
            [matMenuTriggerData]="{optionType: 'addSpecialMfg'}">{{'addSpecialMfg' | cbsLabel}}</button>
  </mat-menu>
  <mat-menu #subcatMenu="matMenu">
    <ng-template matMenuContent let-type="optionType">
      <ng-container *ngFor="let category of categories">
        <ng-container *ngFor="let subcategory of customSubcategories(category)">
          <a mat-menu-item
             (click)="addCustomOption(subcategory, type)">{{subcategory.description === '' ? 'Untitled' : subcategory.description}}</a>
        </ng-container>
      </ng-container>
    </ng-template>
  </mat-menu>
</ng-container>
