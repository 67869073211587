import {Component, Input} from '@angular/core';
import {
  getFromPortal, getFromPortalCenter, getIfeIntegrationInfo, getItemContainerType, getPrivileges, getProperties, getSaveInProgress,
  getSaveMenuType, isNewItem, isSaveToNewProjectAllowed, State,
} from '../reducers/index';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {PropertiesState} from '../reducers/properties.reducer';
import {ValidateFieldService} from '../services/validate-field.service';
import {map, withLatestFrom} from 'rxjs/operators';
import {SaveAsComponent} from './save-as.component';
import {MatDialog} from '@angular/material/dialog';
import {SafeStore} from '../util/safe-store';
import {SaveProjectComponent} from './save-project.component';
import {ItemcontainerUtil} from '../util/itemcontainer-util';
import {CbsActions} from '../actions/cbs.action';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'cbs-save-button',
  templateUrl: './save-button.component.html'
})
export class SaveButtonComponent {
  @Input() location: string;
  properties$: Observable<PropertiesState>;
  saveInProgress$: Observable<boolean>;
  savePrivilege$: Observable<boolean>;
  fromPortal$: Observable<boolean>;
  fromPortalCenter$: Observable<boolean>;
  isNewItem$: Observable<boolean>;
  saveMenuType$: Observable<string>;
  saveToNewProjectAllowed$: Observable<boolean>;
  saveToNewOrderAllowed$: Observable<boolean>;
  safeStore: SafeStore<State>;
  isItemContainerTypeProject: boolean;
  createNewQuotePrivilege: boolean;
  newQuoteLink: string;
  newOrderLink: string;

  constructor(private store: Store<State>,
              private validateFieldService: ValidateFieldService,
              private dialog: MatDialog) {
    this.safeStore = new SafeStore(this, this.store);
    this.properties$ = store.pipe(select(getProperties));
    this.saveInProgress$ = store.pipe(select(getSaveInProgress));
    this.savePrivilege$ = store.pipe(select(getPrivileges), map(privileges => privileges['savePrivilege']));
    this.fromPortal$ = store.pipe(select(getFromPortal));
    this.fromPortalCenter$ = store.pipe(select(getFromPortalCenter));
    this.isNewItem$ = store.pipe(select(isNewItem));
    this.saveMenuType$ = store.pipe(select(getSaveMenuType));
    this.saveToNewProjectAllowed$ = store.pipe(select(isSaveToNewProjectAllowed));
    this.saveToNewOrderAllowed$ = store.pipe(select(getPrivileges), map(privileges => privileges['createNewOrderPrivilege']));
    this.safeStore.subscribe(getItemContainerType,
      itemContainerType => this.isItemContainerTypeProject = ItemcontainerUtil.isProject(itemContainerType));
    this.safeStore.subscribe(getPrivileges, privileges => this.createNewQuotePrivilege = privileges['createNewQuotePrivilege']);
    this.safeStore.subscribe(getIfeIntegrationInfo,
      ife => {
        this.newQuoteLink = ife.ifeDomain + '/itemmanager/app/quotedetailsedit'
          + '?previousApp=CFG&SelectedItemContainerID=-1&newQuoteInitialLoad=true&externalNewQuote=true&usePostMessage=true&isPopup=true'
          + '&UID=' + ife.uid;
        this.newOrderLink = ife.ifeDomain + '/itemmanager/app/orderdetailsedit'
          + '?previousApp=CFG&SelectedItemContainerID=-1&newOrderInitialLoad=true&isExternalNew=true&usePostMessage=true&isPopup=true'
          + '&UID=' + ife.uid;
      });
  }

  processSave = () => {
    setTimeout(() => {
      if (this.validateFieldService.validateMandatory()) {
        this.store.dispatch(CbsActions.itemSave());
      }
    }, 300);
  };

  openSaveToNewProjectDialog = () => {
    this.dialog.open(SaveProjectComponent, {
      panelClass: 'modal'
    });
  };

  openSaveAsPopup = () => {
    this.dialog.open(SaveAsComponent, {
      panelClass: 'modal'
    });
  };

  openNewQuoteOrOrderWindow = (newWindowLink) => {
    window.open(newWindowLink);
  };

  savePrivilegeWithoutItemContainer$() {
    return this.savePrivilege$.pipe(
      withLatestFrom(this.saveToNewProjectAllowed$),
      map(([savePrivilege, saveToNewProjectAllowed]) => savePrivilege || saveToNewProjectAllowed || this.createNewQuotePrivilege));
  }

}
