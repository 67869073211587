import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'cbs-near-miss-reasons',
  templateUrl: './near-miss-reasons.component.html',
})
export class NearMissReasonsComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<NearMissReasonsComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

}
