import {Component, OnInit} from '@angular/core';
import {State} from '../reducers/index';
import {Store} from '@ngrx/store';
import {WorkflowActions} from '../actions/workflow.action';

@Component({
  selector: 'cbs-search-route',
  templateUrl: './search-route.component.html'
})
export class SearchRouteComponent implements OnInit {

  constructor(private store: Store<State>) {
  }

  ngOnInit(): void {
    this.store.dispatch(WorkflowActions.displayBreadcrumbs({
      breadcrumbs: {search: {display: true, target: 'search'}}
    }));
    this.store.dispatch(WorkflowActions.displayData({
      payload: {
        displayPrice: false,
        displaySaveButton: false,
        displaySearchButton: false,
        createCustomOptions: false
      }
    }));
  }

}
