import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Observable, Subject} from 'rxjs';
import {ColDef, GridApi, GridReadyEvent, NewValueParams} from 'ag-grid-community';
import {SuppressKeyboardEventParams} from 'ag-grid-community/dist/lib/entities/colDef';
import {PartNumberAliasService} from '../../services/web/part-number-alias.service';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDialogComponent} from '../confirm-dialog.component';
import {debounceTime} from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'cbs-part-number-existing',
  templateUrl: './part-number-existing.component.html',
  styles: [`
    :host {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  `]
})
export class PartNumberExistingComponent implements OnInit, OnChanges {

  @Input()
  orgUnit: any;

  private gridApi: GridApi;

  defaultColDef: ColDef = {
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    flex: 1,
    suppressKeyboardEvent: (params) => this.handleKeyboardEvent(params)
  };

  columnDefs: ColDef[] = [
    {
      headerName: this.aliasService.partNumberLabel,
      field: 'partNumber'
    },
    {
      headerName: this.aliasService.aliasLabel,
      field: 'alias',
      editable: true,
      onCellValueChanged: (newValueParams: NewValueParams) => {
        this.aliasService.setDirty(true);
        this.dirtyRows[newValueParams.data.partNumber] = newValueParams.newValue;
      }
    }
  ];

  rowData: Observable<any[]>;
  saving = false;
  dirtyRows = {};
  filterValue = new Subject();

  constructor(public aliasService: PartNumberAliasService, private dialog: MatDialog) {
  }

  ngOnInit() {
    this.rowData = this.aliasService.getPartNumberAliases(this.orgUnit?.id);
    this.filterValue.pipe(debounceTime(300)).subscribe((event: InputEvent) => {
      this.gridApi.setQuickFilter((((event.target) as HTMLInputElement).value));
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.orgUnit.previousValue) {
      this.rowData = this.aliasService.getPartNumberAliases(this.orgUnit.id);
    }
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  handleKeyboardEvent(params: SuppressKeyboardEventParams) {
    const event = params.event;
    if (!params.editing) {
      if (event.key === 'Delete') {
        this.deleteSelected();
        return true;
      }
    }
    return false;
  }

  selectAll() {
    this.gridApi.selectAll();
  }

  deleteSelected() {
    const rowsToDelete = this.gridApi.getSelectedRows();
    if (rowsToDelete && rowsToDelete.length) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {data: {confirmationPrompt: 'doYouWantToDelete'}});
      return dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.gridApi.showLoadingOverlay();
          this.aliasService.savePartNumberAliases(rowsToDelete.map(row => {
            return {
              orgUnit: this.orgUnit.id,
              partNumber: row.partNumber
              // leaving alias unset will delete mapping
            };
          })).subscribe(() => {
            this.rowData = this.aliasService.getPartNumberAliases(this.orgUnit.id);
            this.gridApi.hideOverlay();
          });
        }
      });
    }
  }

  copySelected() {
    this.aliasService.copyRowsToClipboard(this.gridApi.getSelectedRows());
    this.gridApi.deselectAll();
  }

  save() {
    const toSave = [];
    Object.keys(this.dirtyRows).forEach(partNumber => {
      toSave.push({
        orgUnit: this.orgUnit.id,
        partNumber,
        alias: this.dirtyRows[partNumber]
      });
    });
    this.aliasService.savePartNumberAliases(toSave).subscribe();
    this.aliasService.setDirty(false);
    this.dirtyRows = {};
  }

  cancel() {
    this.rowData = this.aliasService.getPartNumberAliases(this.orgUnit.id);
    this.aliasService.setDirty(false);
    this.dirtyRows = {};
  }

  filter() {
    console.log('filter');
  }
}
