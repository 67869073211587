import {Component, OnInit} from '@angular/core';
import {CbsRollbarService} from '../rollbar/cbs-rollbar.service';
import {getError, State} from '../reducers/index';
import {Store} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';
import {assignFirstTruthy} from '../util/rx-utils';

@Component({
  selector: 'cbs-app-init-error',
  templateUrl: './app-init-error.component.html'
})
export class AppInitErrorComponent implements OnInit {

  e: HttpErrorResponse;

  constructor(private rollbar: CbsRollbarService, private store: Store<State>) {
    assignFirstTruthy(this.store, getError, error => this.e = error);
  }

  ngOnInit() {
    this.rollbar.initError(this.e);
  }

}
