import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ProductOptionsFieldChangeResponse} from './product-options-field-change-response';
import {Store} from '@ngrx/store';
import {getIfeDomain, State} from '../../reducers/index';
import urlJoin from 'proper-url-join';
import {CbsRollbarService} from '../../rollbar/cbs-rollbar.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ProductOptionUtils} from '../../util/product-options-utils';
import {OptionOverrideComponent} from '../../components/option-override.component';
import {MatDialog} from '@angular/material/dialog';
import {assignFirstTruthy} from '../../util/rx-utils';
import {HttpFormUrlEncodingCodec} from '../../util/http-form-url-encoding-codec';

@Injectable()
export class ProductOptionsService {
  private ifeDomain: string;

  constructor(private httpClient: HttpClient, private store: Store<State>, private rollbar: CbsRollbarService,
              private dialog: MatDialog) {
    assignFirstTruthy(this.store, getIfeDomain, ifeDomain => this.ifeDomain = ifeDomain);
  }

  submitFieldChange(parentSubcatId: string, params: string[][]): Observable<ProductOptionsFieldChangeResponse> {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    const options = {headers: headers, withCredentials: true};
    let urlParams = new HttpParams({encoder: new HttpFormUrlEncodingCodec()});
    params.forEach(([key, value]) => urlParams = urlParams.set(key, value));
    return this.httpClient
      .post(getOptionSubmitUrl(this.ifeDomain, parentSubcatId), urlParams, options)
      .pipe(map(data => <ProductOptionsFieldChangeResponse>data));
  }

  addCustomOption(payload): Observable<Response> {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    const options = {headers: headers, withCredentials: true};
    let urlParams = new HttpParams();
    urlParams = urlParams.set('subcategoryId', payload.id);
    urlParams = urlParams.set('specialOptionType', payload.type);
    return this.httpClient
      .post(urlJoin(this.ifeDomain, '/config/api/v1/cbs/addCustomOption'), urlParams, options)
      .pipe(map(data => {
        const fieldData = ProductOptionUtils.findFieldInCategoriesWithData(data['newSpecialId'], data['categories']);
        if (!!fieldData) {
          this.dialog.open(OptionOverrideComponent, {
            panelClass: 'modal',
            data: {
              choice: fieldData.field,
              parentSubcatId: fieldData.parentSubcatId,
              subcatId: fieldData.subcatId,
              categoryId: fieldData.categoryId,
              fieldId: fieldData.field.id,
              overridePrice: true,
              overrideDescription: true,
              isCustomOptionInit: true
            }
          });
        } else {
          alert('Field not created properly, update knowledge base to support more custom options');
        }
        return <Response>data;
      }));
  }

  deleteCustomOption(payload): Observable<Response> {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    const options = {headers: headers, withCredentials: true};
    let urlParams = new HttpParams();
    urlParams = urlParams.set('fieldId', payload.id);
    urlParams = urlParams.set('categoryId', payload.categoryId);
    return this.httpClient
      .post(urlJoin(this.ifeDomain, '/config/api/v1/cbs/deleteCustomOption'), urlParams, options)
      .pipe(map(data => {
        return <Response>data;
      }));
  }
}

function getOptionSubmitUrl(ifeDomain: string, subcategoryId: string): string {
  return urlJoin(ifeDomain, `/config/api/v1/quickconfig/subcategory/${subcategoryId}/option`);
}
