import {Component, Input, OnInit} from '@angular/core';
import {getProductOptionPages, getProperties, getWorkflow, State} from '../reducers/index';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {map, withLatestFrom} from 'rxjs/operators';
import {selectFirstTruthy} from '../util/rx-utils';
import {CategoryMenuStrategy, SubNavStrategy} from '../reducers/properties.reducer';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {SafeStore} from '../util/safe-store';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductOptionActions} from '../actions/product-options.action';
import {WorkflowActions} from '../actions/workflow.action';

@UntilDestroy()
@Component({
  selector: 'cbs-product-content',
  templateUrl: './product-content.component.html',
  styles: [`
    .product-content__button-bar--float {
      float: right;
      padding-left: 8px;
    }

    .product-content__title--clearfix::after {
      content: "";
      clear: right;
      display: table;
    }
  `]
})
export class ProductContentComponent implements OnInit {

  @Input() buttonsAlreadyDisplayed = false;
  showLeftNav = false;
  showTabs = false;
  configTarget$: Observable<string>;
  displayProductTitle$: Observable<boolean>;
  selectedPage: string;

  constructor(private store: Store<State>, private router: Router, private route: ActivatedRoute) {
    this.displayProductTitle$ = this.store.pipe(select(getProperties), map(properties => properties.po_displayProductTitle));
  }

  ngOnInit() {
    const safeStore = new SafeStore(this, this.store);
    safeStore.subscribe(getProperties, (properties) => {
      this.showLeftNav = properties.po_subnavStrategy === SubNavStrategy.LEFT;
      this.showTabs = properties.po_subnavStrategy === SubNavStrategy.TAB;
    });
    this.configTarget$ = selectFirstTruthy(this.store, getWorkflow).pipe(map(workflow => {
      if (workflow.workflowId === 'cosProductOptionsSinglePage') {
        return '/cos-product-options';
      } else {
        return '/product-options';
      }
    }));
    this.route.queryParams.pipe(
      untilDestroyed(this),
      withLatestFrom(this.configTarget$)
    ).subscribe(([params, configTarget]) => {
      if (params.page) {
        this.selectPage(params.page);
      } else if (this.router.url === configTarget) {
        selectFirstTruthy(this.store, getProductOptionPages).subscribe((pages) => {
          if (pages && pages.length > 0) {
            void this.router.navigate([configTarget], {queryParams: {page: pages[0].id}});
          }
        });
      }
    });
  }

  private selectPage(pageId: string) {
    this.store.dispatch(ProductOptionActions.changePage({pageId: pageId}));
    this.selectedPage = pageId;
    // updates the current page (with the query param), but no need to update the breadcrumbs
    this.store.dispatch(WorkflowActions.displayBreadcrumbs({
      breadcrumbs: {}
    }));
  }
}
