<div class="c-card main-content">
  <div class="c-card-with-sidebar g-card-with-sidebar">
    <aside *ngIf="showLeftNav" class="p-b-base">
      <cbs-left-nav class="sticky-content" [configRoot]="configTarget$|async" [selectedPage]="selectedPage"></cbs-left-nav>
    </aside>
    <div class="p-h-2">
      <ng-container *ngTemplateOutlet="buttonsAndTitle;context:{displayTitle: displayProductTitle$ | async}"></ng-container>
      <ng-template #buttonsAndTitle let-displayTitle="displayTitle">
        <div class="sticky-content p-t-base" [ngClass]="{'cbs-card-background': displayTitle || showTabs}">
          <cbs-button-bar *ngIf="!buttonsAlreadyDisplayed"
                          [ngClass]="{'product-content__button-bar--float': displayTitle}"></cbs-button-bar>
          <cbs-product-options-title *ngIf="displayTitle" class="product-content__title--clearfix"></cbs-product-options-title>
          <cbs-tab-nav *ngIf="showTabs" [configRoot]="configTarget$|async"></cbs-tab-nav>
        </div>
      </ng-template>
      <ng-content></ng-content>
    </div>
  </div>
</div>
