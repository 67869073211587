import {Component, HostBinding, Input} from '@angular/core';
import {ParentSubcategory} from '../models/ParentSubcategory';
import {CategoryMenuService} from '../services/category-menu.service';

@Component({
  selector: 'cbs-category-menu-subcategory',
  templateUrl: './category-menu-subcategory.component.html',
  styles: [`
    :host {
      margin-bottom: 0 !important;
      display: block;
      float: inherit !important;
    }

    :host.category-menu--indented {
      margin-left: 25px;
    }

    .category-menu__item {
      cursor: pointer;
      display: flex;
    }

    .category-menu__title {
      flex-grow: 1;
    }
  `]
})
export class CategoryMenuSubCategoryComponent {
  @HostBinding('class.category-menu--indented') @Input() showCategoryLevel = true;
  @Input() subCategory: ParentSubcategory;

  constructor(private categoryMenuService: CategoryMenuService) {
  }

  isActive() {
    return this.categoryMenuService.isSelected(this.subCategory);
  }

  select() {
    this.categoryMenuService.selectSubCategory(this.subCategory);
  }
}
