import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {State} from '../reducers';
import {CbsActions} from '../actions/cbs.action';

function _window(): any {
  return window;
}

@Injectable()
export class WindowService {

  constructor(store: Store<State>) {
    _window().addEventListener('message', (event) => this.receiveMessage(event, store), false);
  }

  getWindow(): any {
    return _window();
  }

  getWidth(): number {
    return window.innerWidth;
  }

  private receiveMessage(event, store) {
    const origin = event.origin || event.originalEvent.origin; // For Chrome, the origin property is in the event.originalEvent object.
    if (!origin.match('intelliquip.com$') && !origin.match('http://localhost(:4201)?$')) {
      return;
    }
    if (event.data === 'refresh') {
      return window.location.reload();
    }
    if (event.data.message === 'saveToNewQuote') {
      store.dispatch(CbsActions.saveToNewQuote({objectId: event.data.quoteId}));
    } else if (event.data.message === 'saveToNewOrder') {
      store.dispatch(CbsActions.saveToNewOrder({objectId: event.data.orderId}));
    }
  }
}
