import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EMPTY, of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {CosService} from '../services/web/cos.service';
import {CosFieldChangeResponse} from '../services/web/cos-field-change-response';
import {CbsRollbarService} from '../rollbar/cbs-rollbar.service';
import {ValidateFieldService} from '../services/validate-field.service';
import {getProductOptionCategories, getProperties, getSearchDesign, getWorkflowId, State} from '../reducers';
import {isNotSupported} from '../util/workflow-utils';
import {catchError, map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';
import {isPartsSearch} from '../util/search-utils';
import {SafeStore} from '../util/safe-store';
import {ChangeFieldPayload} from '../actions/field.action';
import {concatMapEmitLast} from '../util/rx-utils';
import {CosActions} from '../actions/cos.action';
import {SearchActions} from '../actions/search.action';
import {ProductOptionActions} from '../actions/product-options.action';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class CosEffects {

  private safeStore: SafeStore<State>;
  workflowId: string;
  searchMode: string;
  eachCosChangeRunsSearch: boolean;

  //noinspection JSUnusedGlobalSymbols
  changeCosField$ = createEffect(() => this.allActions.pipe(
    ofType(CosActions.changeField),
    tap(() => this.store.dispatch(SearchActions.searchNeeded())),
    concatMapEmitLast(({payload}) => this.cosService.submitCosFieldChange(payload.changeParams).pipe(
      tap(response => this.validation.validateFields(response)),
      catchError(error => this.rollbar.apiError(error, {payload}, undefined, this.store)))),
    map((response: CosFieldChangeResponse) => CosActions.changeFieldSuccess({response}))
  ));

  //noinspection JSUnusedGlobalSymbols
  changeSuccessSearch$ = createEffect(() => this.allActions.pipe(
    ofType(CosActions.changeFieldSuccess),
    mergeMap(() => {
      if (!isNotSupported(this.workflowId, 'search') && this.eachCosChangeRunsSearch) {
        return of(SearchActions.attemptStartSearch());
      } else {
        return EMPTY;
      }
    })
  ));

  //noinspection JSUnusedGlobalSymbols
  changeSuccessUpdate$ = createEffect(() => this.allActions.pipe(
    ofType(CosActions.changeFieldSuccess),
    withLatestFrom(this.store.pipe(select(getProductOptionCategories))),
    mergeMap(([{response}, poCategories]) => {
      if (poCategories && poCategories.length > 0 && !isPartsSearch(this.searchMode)) {
        return of(ProductOptionActions.changeField({
          payload: {
            parentSubcatId: response.parentSubcategory.id,
            changeParams: [[]]
          } as ChangeFieldPayload
        }));
      } else {
        return EMPTY;
      }
    })
  ));

  constructor(private allActions: Actions,
              private cosService: CosService,
              private rollbar: CbsRollbarService,
              private validation: ValidateFieldService,
              private store: Store<State>) {

    this.safeStore = new SafeStore(this, this.store);
    this.safeStore.subscribe(getWorkflowId, workflowId => this.workflowId = workflowId);
    this.safeStore.subscribe(getSearchDesign, design => this.searchMode = design && design.searchMode);
    this.safeStore.subscribe(getProperties, properties => this.eachCosChangeRunsSearch = properties && properties.eachCosChangeRunsSearch);
  }

}
