import {Component, OnInit} from '@angular/core';
import {ImageService} from 'app/services/image.service';
import {select, Store} from '@ngrx/store';
import {getDisplayPrice, getPrice, getProducts, State} from '../reducers/index';
import {Observable} from 'rxjs';
import {Product} from '../models/Product';
import {ItemMultiplierComponent} from './item-multiplier.component';
import {MatDialog} from '@angular/material/dialog';
import {getDiscountList, getLeadTimeTotal, getPrivileges} from '../reducers';
import {SafeStore} from '../util/safe-store';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'cbs-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  price$: Observable<string>;
  displayPrice$: Observable<boolean>;
  leadTimeTotal$: Observable<string>;
  products: Product[];
  overridePrice: boolean;
  viewItemOverrides: boolean;
  hasDiscounts: boolean;
  currentYear: number;
  private safeStore: SafeStore<State>;

  constructor(public image: ImageService, private store: Store<State>, private dialog: MatDialog) {
    this.safeStore = new SafeStore(this, this.store);
  }

  ngOnInit() {
    this.price$ = this.store.pipe(select(getPrice));
    this.displayPrice$ = this.store.pipe(select(getDisplayPrice));
    this.leadTimeTotal$ = this.store.pipe(select(getLeadTimeTotal));
    this.currentYear = new Date().getFullYear();
    this.safeStore.subscribe(getProducts, products => this.products = products);
    this.safeStore.subscribe(getPrivileges, privileges => {
      this.overridePrice = privileges['overridePrice'];
      this.viewItemOverrides = privileges['viewItemOverrides'];
    });
    this.safeStore.subscribe(getDiscountList, list => this.hasDiscounts = list && list.discountIDList.length > 0);
  }

  priceDisplay(price, label) {
    return this.allReviewed() ? label || price : '-';
  }

  allReviewed(): boolean {
    if (this.products) {
      return this.products.filter(product => product.reviewed === false).length === 0;
    }
    return true;
  }

  openMultiplierDialog() {
    const test = ItemMultiplierComponent;
    this.dialog.open(test, {
      panelClass: 'modal',
      data: {}
    });
  }

}
