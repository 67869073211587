import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {getIfeDomain, getLabels, State} from '../../reducers/index';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import urlJoin from 'proper-url-join';
import {assignFirstTruthy, selectFirstTruthy} from '../../util/rx-utils';
import {isNullOrUndefined} from '../../util/js-utils';
import {map} from 'rxjs/operators';
import {Clipboard} from '@angular/cdk/clipboard';

@Injectable()
export class PartNumberAliasService {
  private ifeDomain: string;
  private dirty = false;
  public partNumberLabel;
  public aliasLabel;
  public overlayTemplate;

  constructor(private httpClient: HttpClient, private store: Store<State>, private clipboard: Clipboard) {
    assignFirstTruthy(this.store, getIfeDomain, (ifeDomain: string) => this.ifeDomain = ifeDomain);
    selectFirstTruthy(this.store, getLabels).subscribe(labels => {
      this.partNumberLabel = labels['partNumber'];
      this.aliasLabel = labels['partNumberAlias'];
      this.overlayTemplate = '<span class="ag-overlay-loading-center">' + labels.loading + '</span>';
    });
  }

  getPartNumberAliases(orgUnitId: number): Observable<any[]> {
    const options: any = {query: {pageSize: -1}};
    if (!isNullOrUndefined(orgUnitId)) {
      options.query.orgUnit = orgUnitId;
    }
    const url = urlJoin(this.ifeDomain, '/config/api/v1/part-mappings', options);
    return this.httpClient.get<any[]>(url, {withCredentials: true})
      .pipe(map((r: any) => r.results));
  }

  getMissingPartNumbers(orgUnitId: number): Observable<any[]> {
    const options: any = {query: {pageSize: -1}};
    if (!isNullOrUndefined(orgUnitId)) {
      options.query.orgUnit = orgUnitId;
    }
    const url = urlJoin(this.ifeDomain, '/config/api/v1/part-mappings/missing', options);
    return this.httpClient.get<string[]>(url, {withCredentials: true})
      .pipe(map(value => {
        return value.map(item => ({partNumber: item, alias: ''}));
      }));
  }

  savePartNumberAliases(partNumberAliases: { orgUnit: number; partNumber: string, alias?: string }[]): Observable<any> {
    let formData: any = new HttpParams();
    formData = formData.set('mappings', JSON.stringify(partNumberAliases));
    return this.doPost(formData);
  }

  private doPost(formData: any) {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    const options = {headers: headers, withCredentials: true};
    const url = urlJoin(this.ifeDomain, '/config/api/v1/part-mappings');
    return this.httpClient.post(url, formData, options);
  }

  getAdmin(): Observable<any[]> {
    const url = urlJoin(this.ifeDomain, '/config/api/v1/part-mappings/admin');
    return this.httpClient.get<any[]>(url, {withCredentials: true});
  }

  isDirty() {
    return this.dirty;
  }

  setDirty(dirty: boolean) {
    this.dirty = dirty;
  }

  copyRowsToClipboard(rowsToCopy: any[]) {
    if (rowsToCopy && rowsToCopy.length) {
      const data = rowsToCopy.map(row => {
        return row.partNumber + '\t' + row.alias + '\r\n';
      }).join('');
      const pending = this.clipboard.beginCopy(data);
      let remainingAttempts = 3;
      const attemptCopy = () => {
        const result = pending.copy();
        if (!result && --remainingAttempts) {
          setTimeout(attemptCopy);
        } else {
          pending.destroy(); // destroy when done!
        }
      };
      attemptCopy();
    }
  }
}
