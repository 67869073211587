import {isNullOrUndefined} from './js-utils';

export function trackByField(index, field): string {
  let retval;
  if (!isNullOrUndefined(field.unitOfMeasureField)) {
    retval = field.id + ':::' + field.unitOfMeasureField.choices.find(c => c.selected).id;
  } else if (!isNullOrUndefined(field.choices)) {
    const selected = field.choices.find(c => c.selected);
    if (!isNullOrUndefined(selected)) {
      retval = field.id + ':::' + selected.id;
    } else {
      retval = field.id;
    }
  } else {
    retval = field ? field.id : undefined;
  }
  return retval;
}

export function trackById(index, object): string {
  return object ? object.id : undefined;
}
