import { Component, OnInit } from '@angular/core';
import {select, Store} from '@ngrx/store';
import {
  State, getGlobalNotificationMessage,
  getOrgNotificationMessage
} from '../reducers/index';
import {Observable} from 'rxjs';

@Component({
  selector: 'cbs-global-notifications',
  templateUrl: './global-notifications.component.html',
})
export class GlobalNotificationsComponent implements OnInit {

  globalNotificationMessage$: Observable<string>;
  orgNotificationMessage$: Observable<string>;

  constructor(private store: Store<State>) {
    this.globalNotificationMessage$ = this.store.pipe(select(getGlobalNotificationMessage));
    this.orgNotificationMessage$ = this.store.pipe(select(getOrgNotificationMessage));
  }

  ngOnInit() {
  }

}
