<div style="display: flex; align-items: flex-end">
  <h2 [id]="category.id" class="m-none" style="flex-grow: 1">{{category.description}}</h2>
  <ng-container *ngIf="allCollapsableParentSubcatIds()|async as collapseableSubcatIds;">
    <ng-container *ngIf="!(hideExpandCollapseAll$ | async) && collapseableSubcatIds.length > 0">
      <div *ngIf="firstCategory()|async">
        <button [id]="expandAllButtonId()" (click)="expandAll(collapseableSubcatIds)">{{'expandAll' | cbsLabel}}</button>
        |
        <button [id]="collapseAllButtonId()" (click)="collapseAll(collapseableSubcatIds)">{{'collapseAll' | cbsLabel}}</button>
      </div>
    </ng-container>
  </ng-container>
</div>
<cbs-parent-subcategory
  *ngFor="let parentSubcategory of category.parentSubcategories; trackBy: trackByParentSubcat"
  [parentSubcategory]="parentSubcategory" [initProductOptionsCollapsed]="initProductOptionsCollapsed | async"
  [categoryId]="category.id" [fieldContainer]="fieldContainer" [singleColumnProperty]="singleColumnProperty"
  [optionColumnLayout]="optionColumnLayout" [pageId]="pageId"></cbs-parent-subcategory>
