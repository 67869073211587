import {acme} from '../properties/acme.properties';
import {watertronics} from '../properties/watertronics.properties';
import {grundfos} from '../properties/grundfos.properties';
import {weg} from '../properties/weg.properties';
import {Labels} from '../models/Labels';
import {gemc} from '../properties/gemc.properties';
import {pumpworks610} from '../properties/pumpworks610.properties';
import {createReducer, on} from '@ngrx/store';
import {PropertiesActions, PropertiesInitPayload} from '../actions/properties.action';

export enum CategoryMenuStrategy {
  CATEGORY = 'categoryLevelOnly',
  SUB_CATEGORY = 'categoryParentSubcategory'
}

export enum SubNavStrategy {
  NONE = 'none',
  TAB = 'tabNav',
  LEFT = 'leftNav'
}

export interface PropertiesState {
  init?: boolean;
  iq_CbsWorkflowID?: string;
  po_subnavStrategy?: SubNavStrategy;
  po_categoryMenuStrategy?: CategoryMenuStrategy;
  save_SuccessDo?: string;
  save_SuccessShowRtfDocId?: string;
  headerInfoLinkLabelID?: string;
  labelOverride?: Labels;
  cos_singleColumn?: boolean;
  po_singleColumn?: boolean;
  po_hideExpandCollapseAll?: boolean;
  po_displayPartNumber?: boolean;
  itemMultiplier_hideMargin?: boolean;
  po_rowShading?: boolean;
  po_initAccordionsCollapsed?: boolean;
  po_onlyOneOpenAccordion?: boolean;
  hideNewItemButton?: boolean;
  stickyTableHeader?: boolean;
  eachCosChangeRunsSearch?: boolean;
  po_displayProductTitle?: boolean;
  cos_filterTwoColumns?: boolean;

  // Styling override properties
  displayWidescreen?: boolean;
}

const initialState: PropertiesState = {};

export const propertiesReducer = createReducer(
  initialState,
  on(PropertiesActions.initUIInfoFromResponse, (state, {uiInfo}) => ({...state, ...uiInfo, init: true})),
  on(PropertiesActions.devOverride, (state, {payload}) => ({...state, ...initProperties(payload)}))
);

function initProperties(payload: PropertiesInitPayload): PropertiesState {
  let props;
  switch (payload.orgFolderHome) {
    case 'acme':
      props = {
        ...acme['all'],
        ...acme[payload.configBaseDir]
      };
      break;
    case 'gemc':
      props = {
        ...gemc['all'],
        ...gemc[payload.configBaseDir]
      };
      break;
    case 'grundfos':
      props = {
        ...grundfos['all'],
        ...grundfos[payload.configBaseDir]
      };
      break;
    case 'pumpworks610':
      props = {
        ...pumpworks610['all'],
        ...pumpworks610[payload.configBaseDir]
      };
      break;
    case 'watertronics':
      props = {
        ...watertronics['all'],
        ...watertronics[payload.configBaseDir]
      };
      break;
    case 'weg':
      props = {
        ...weg['all'],
        ...weg[payload.configBaseDir]
      };
      break;

  }
  return props || initialState;
}

export class PropertiesSelectors {
  static isInit = (state: PropertiesState) => state.init;
  static getLabelOverrides = (state: PropertiesState) => state.labelOverride;
}
