<div class="c-intelliquip-footer" style="padding: 0; margin: 0; z-index: 1">
  <div class="flex-grid flex-shrink flex-y-end" style="flex-wrap: nowrap">
    <div class="text-center " style="flex: 1 1 auto">
      <a target="_blank" href="http://www.intelliquip.com">
        <img alt="Powered by Intelliquip"
             [src]="image.globalUrl('pbIEQ.png') | async">
      </a>
      <span class="hide-for-small-only">
        ©2000-{{currentYear}}
      </span>
    </div>
    <div class="text-right hide-for-small-only">
      <ng-container *ngTemplateOutlet="leadTimeDisplay"></ng-container>
      <ng-container *ngTemplateOutlet="priceDisplayTemplate"></ng-container>
    </div>
    <div class="text-right show-for-small-only">
      <ng-container *ngTemplateOutlet="leadTimeDisplay"></ng-container>
      <ng-container *ngTemplateOutlet="priceDisplayTemplate"></ng-container>
    </div>
    <ng-template #priceDisplayTemplate>
      <ng-container *ngIf="displayPrice$|async">
            <span *ngIf="price$|async as price" class="footer-price-display">
              {{'price' | cbsLabel}}: {{priceDisplay(price, price.toLowerCase() | cbsLabel)}}
              <button *ngIf="hasDiscounts && viewItemOverrides" test-id="EDIT_ITEM_MULTIPLIERS_BUTTON"
                      (click)="openMultiplierDialog()" matTooltip="{{'editItemMultipliers' | cbsLabel}}"
                      style="padding: 0 10px; grid-column: 4; outline: none; width: 2em"><span><i class="far fa-edit"></i></span>
              </button>
            </span>
      </ng-container>
    </ng-template>
    <ng-template #leadTimeDisplay>
      <ng-container *ngIf="displayPrice$|async">
            <span *ngIf="leadTimeTotal$|async as leadTimeTotal">
              {{'leadTime' | cbsLabel}}: {{leadTimeTotal}} {{'leadTimeSuffix' | cbsLabel}}
            </span>
      </ng-container>
    </ng-template>
  </div>
</div>
