import {Component, OnInit} from '@angular/core';
import {getProperties, State} from '../reducers/index';
import {select, Store} from '@ngrx/store';
import {getSearchDesign} from '../reducers';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs/index';
import {WorkflowActions} from '../actions/workflow.action';
import {assignFirstTruthy} from '../util/rx-utils';

@Component({
  selector: 'cbs-cos-filter-search-route',
  templateUrl: 'cos-filter-search-route.component.html'
})
export class CosFilterSearchRouteComponent implements OnInit {

  location$: Observable<string>;
  cos_filterColumnCount

  constructor(private store: Store<State>) {
    assignFirstTruthy(store, getProperties, properties => this.cos_filterColumnCount = properties.cos_filterTwoColumns ? 2 : 1);
  }

  ngOnInit(): void {
    this.store.dispatch(WorkflowActions.displayBreadcrumbs({
      breadcrumbs: {search: {display: true, target: 'cos-filter-search'}}
    }));
    this.store.dispatch(WorkflowActions.displayData({
      payload: {
        displayPrice: false,
        displaySaveButton: false,
        displaySearchButton: false,
        createCustomOptions: false
      }
    }));
    this.location$ = this.store.pipe(select(getSearchDesign), map(design => design.searchBarLocation || 'default'));
  }
}
