import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ProductOptionsService} from '../services/web/product-options.service';
import {ProductOptionsFieldChangeResponse} from '../services/web/product-options-field-change-response';
import {Store} from '@ngrx/store';
import {CbsRollbarService} from '../rollbar/cbs-rollbar.service';
import {ValidateFieldService} from '../services/validate-field.service';
import {State} from '../reducers';
import {catchError, map} from 'rxjs/operators';
import {concatMapEmitLast} from '../util/rx-utils';
import {ProductOptionActions} from '../actions/product-options.action';
import {CbsActions} from '../actions/cbs.action';
import {EmailRfqActions} from '../actions/email-rfq.action';

/**
 * Effects offer a way to isolate and easily test side-effects.
 */
@Injectable()
export class ProductOptionsEffects {

  //noinspection JSUnusedGlobalSymbols
  changeField$ = createEffect(() => this.allActions.pipe(
    ofType(ProductOptionActions.changeField, EmailRfqActions.changeField),
    concatMapEmitLast(({payload}) =>
      this.productOptionsService.submitFieldChange(payload.parentSubcatId, payload.changeParams).pipe(
        map(response => this.validation.validateFields(response)),
        map((response: ProductOptionsFieldChangeResponse) => ProductOptionActions.changeFieldSuccess({response})),
        catchError(error => this.rollbar.apiError(error, {payload}, undefined, this.store)))
    )
  ));
  //noinspection JSUnusedGlobalSymbols
  changeAttribute$ = createEffect(() => this.allActions.pipe(
    ofType(ProductOptionActions.changeAttribute),
    concatMapEmitLast(({payload}) => {
      return this.productOptionsService.submitFieldChange(payload.parentSubcatId, payload.changeParams).pipe(
        map((response: ProductOptionsFieldChangeResponse) => ProductOptionActions.changeFieldSuccess({response})),
        catchError(error => this.rollbar.apiError(error, {payload}, undefined, this.store)))
    })
  ));

  //noinspection JSUnusedGlobalSymbols
  choiceOverride$ = createEffect(() => this.allActions.pipe(
    ofType(ProductOptionActions.choiceOverride),
    concatMapEmitLast(({payload}) => {
        const params = [
          ['description_' + (payload.choice.httpValue || payload.choice.id), payload.description],
          ['quantity_' + (payload.choice.httpValue || payload.choice.id), payload.quantity],
          ['leadTime_' + (payload.choice.httpValue || payload.choice.id), payload.leadTime],
          ['source_' + (payload.choice.httpValue || payload.choice.id), payload.source],
          ['selectionType_' + (payload.choice.httpValue || payload.choice.id), payload.selectionType],
          ['showCus_' + (payload.choice.httpValue || payload.choice.id), payload.showCus]];
        if (payload.price || payload.price === '') {
          params.push(['basePriceOverride_' + (payload.choice.httpValue || payload.choice.id), payload.price]);
          params.push(['ovrSourceCurrency_' + (payload.choice.httpValue || payload.choice.id), payload.choice.currencyCode]);
        }
        if (payload.price && payload.price !== '') {
          params.push(['priceCalcMethod_' + (payload.choice.httpValue || payload.choice.id), 'EnterPrice']);
        }
        return this.productOptionsService.submitFieldChange(payload.parentSubcatId, params).pipe(
          map(response => this.validation.validateFields(response)),
          map((response: ProductOptionsFieldChangeResponse) => ProductOptionActions.changeFieldSuccess({response})),
          catchError(error => this.rollbar.apiError(error, {payload}, undefined, this.store)));
      }
    )
  ));

  //noinspection JSUnusedGlobalSymbols
  addCustomOption$ = createEffect(() => this.allActions.pipe(
    ofType(ProductOptionActions.addCustomOption),
    concatMapEmitLast(({payload}) => {
        return this.productOptionsService.addCustomOption(payload).pipe(
          map((response: Response) => ProductOptionActions.addCustomOptionSuccess({response})),
          catchError(error => this.rollbar.apiError(error, {payload}, undefined, this.store)));
      }
    )
  ));

  //noinspection JSUnusedGlobalSymbols
  deleteCustomOption$ = createEffect(() => this.allActions.pipe(
    ofType(ProductOptionActions.deleteCustomOption),
    concatMapEmitLast(({payload}) => {
        return this.productOptionsService.deleteCustomOption(payload).pipe(
          map((response: Response) => ProductOptionActions.deleteCustomOptionSuccess({response})),
          catchError(error => this.rollbar.apiError(error, {payload}, undefined, this.store)));
      }
    )
  ));

  constructor(private allActions: Actions,
              private productOptionsService: ProductOptionsService,
              private rollbar: CbsRollbarService,
              private validation: ValidateFieldService,
              private store: Store<State>) {
  }
}
