import {filter, map} from 'rxjs/operators';
import {Component, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {Category} from '../models/Category';
import {getProductOptionCategories, getProperties, getSelectedPage, State} from '../reducers/index';

@Component({
  selector: 'cbs-product-options',
  templateUrl: './product-options.component.html'
})
export class ProductOptionsComponent {
  selectedPage$: Observable<string>;
  categories$: Observable<Category[]>;
  initProductOptionsCollapsed: Observable<boolean>;
  @Input() columnOptionLayout = 4;

  constructor(private store: Store<State>) {
    this.selectedPage$ = this.store.pipe(select(getSelectedPage));
    this.categories$ = this.store.pipe(select(getProductOptionCategories));
    this.initProductOptionsCollapsed = this.store.pipe(select(getProperties), filter(props => !!props),
      map(props => props.po_initAccordionsCollapsed));
  }

  trackByCategory(index, category) {
    return category ? category.id : undefined;
  }
}
