<div class="c-card main-content">
  <cbs-button-bar class="p-v-none"></cbs-button-bar>
  <div class="flex-grid p-v-none">
    <div class="flex-2 sticky-content m-t-base">
      <cbs-selected-products></cbs-selected-products>
    </div>
    <div class="flex-3">
      <cbs-product-options [columnOptionLayout]="maxColumnOptionLayout | async"></cbs-product-options>
    </div>
  </div>
</div>
