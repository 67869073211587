import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {getIfeDomain, State} from '../../reducers/index';
import urlJoin from 'proper-url-join';
import {CbsRollbarService} from '../../rollbar/cbs-rollbar.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Product} from '../../models/Product';
import {map} from 'rxjs/operators';
import {assignFirstTruthy} from '../../util/rx-utils';

@Injectable()
export class ProductService {
  private ifeDomain: string;

  constructor(private httpClient: HttpClient, private store: Store<State>, private rollbar: CbsRollbarService) {
    assignFirstTruthy(this.store, getIfeDomain, ifeDomain => this.ifeDomain = ifeDomain);
  }

  submitProductDeselect(product: Product) {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    const options = {headers: headers, withCredentials: true};
    let urlParams = new HttpParams();
    urlParams = urlParams.set('product', product.id);
    urlParams = urlParams.set('node', product.nodeID);

    return this.httpClient
      .post(urlJoin(getCbsProductSubmitUrl(this.ifeDomain), 'deselectProduct'), urlParams, options)
      .pipe(map(data => data));
  }

  submitUpdateProductQuantity(product, quantity) {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    const options = {headers: headers, withCredentials: true};
    let urlParams = new HttpParams();
    urlParams = urlParams.set('product', product.id);
    urlParams = urlParams.set('node', product.nodeID);
    urlParams = urlParams.set('quantity', quantity);
    return this.httpClient
      .post(urlJoin(getCbsProductSubmitUrl(this.ifeDomain), 'updateProductQuantity'), urlParams, options)
      .pipe(map(data => data));
  }

  submitConfigureNode(product) {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    const options = {headers: headers, withCredentials: true};
    let urlParams = new HttpParams();
    urlParams = urlParams.set('product', product.id);
    urlParams = urlParams.set('node', product.nodeID);
    return this.httpClient
      .post(urlJoin(getCbsProductSubmitUrl(this.ifeDomain), 'configureNode'), urlParams, options)
      .pipe(map(data => data));
  }

  reorderProducts(currentPosition: number, newPosition: number) {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    const options = {headers: headers, withCredentials: true};
    let urlParams = new HttpParams();
    urlParams = urlParams.set('currentPosition', String(currentPosition));
    urlParams = urlParams.set('newPosition', String(newPosition));
    return this.httpClient
      .post(urlJoin(getCbsProductSubmitUrl(this.ifeDomain), 'reorderProducts'), urlParams, options)
      .pipe(map(data => data));
  }

  refreshProducts() {
    const options = {withCredentials: true};
    return this.httpClient
      .get(urlJoin(getCbsProductSubmitUrl(this.ifeDomain), 'refreshProducts'), options)
      .pipe(map(data => data));
  }

}

function getCbsProductSubmitUrl(ifeDomain: string): string {
  return urlJoin(ifeDomain, '/config/api/v1/cbs');
}
