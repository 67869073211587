import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {getProperties, State} from '../reducers/index';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {HeaderComponent} from './header.component';

import {PropertiesState} from '../reducers/properties.reducer';
import {getItemNumber$} from '../util/field-util';

@Component({
  selector: 'cbs-header-link',
  templateUrl: './header-link.component.html'
})
export class HeaderLinkComponent implements OnInit {
  properties$: Observable<PropertiesState>;
  itemNumber$: Observable<string>;

  constructor(private store: Store<State>, private dialog: MatDialog) {
    this.itemNumber$ = getItemNumber$(this.store);
    this.properties$ = this.store.pipe(select(getProperties));
  }

  ngOnInit() {
  }

  openHeader() {
    this.dialog.open(HeaderComponent, {
      panelClass: 'modal',
    });
  }
}
