import {createAction, props} from '@ngrx/store';
import {CbsInitPayload} from './cbs.action';
import {PropertiesState} from '../reducers/properties.reducer';

export interface PropertiesInitPayload {
  orgFolderHome: string;
  configBaseDir: string;
}

export namespace PropertiesActions {
  export const initUIInfoFromResponse = createAction(
    '[properties] Init User Interface Properties from Response',
    props<{ uiInfo: PropertiesState }>()
  );

  export const devOverride = createAction(
    '[properties] Override Properties with dev defined property files',
    props<{ payload: CbsInitPayload }>()
  );
}
