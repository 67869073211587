import {Field} from '../models/Field';
import {Choice} from '../models/Choice';
import {getHeaderInfo, State} from '../reducers';
import {select, Store} from '@ngrx/store';
import {map} from 'rxjs/operators';

export function isOptionGraphicDisplay_Comment(container: (Field | Choice)) {
  return container && container.optionGraphic && container.graphicDisplayLocation === 'COMMENT';
}

export function isOptionGraphicDisplay_Inline(container: (Field | Choice)) {
  return container && container.optionGraphic && container.graphicDisplayLocation !== 'COMMENT';
}

export function getItemNumber$(store: Store<State>) {
  return store.pipe(select(getHeaderInfo),
    map(header => {
      if (header) {
        const targetField = header.find(field => field.id === 'ItemNumberInput');
        return targetField && targetField.value;
      } else {
        return null;
      }
    }));
}

export const GRAPHIC_SIZE = {
  'SMALL': '40px',
  'MEDIUM': '80px',
  'ACTUAL': ''
};
