import {Component, Input} from '@angular/core';
import {Store} from '@ngrx/store';
import {State} from '../reducers/index';
import {FieldContainer} from '../models/FieldContainer';
import {ValidateFieldService} from '../services/validate-field.service';
import {ImageService} from '../services/image.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {GRAPHIC_SIZE, isOptionGraphicDisplay_Comment} from '../util/field-util';
import {Choice} from '../models/Choice';
import {Field} from '../models/Field';

@Component({
  selector: 'cbs-alert',
  templateUrl: './alert.component.html'
})
export class AlertComponent {
  @Input() content;
  @Input() fieldContainer: FieldContainer;
  @Input() optionColumnLayout: number;

  graphicSize = GRAPHIC_SIZE;
  textSize = {
    'SMALL': '.7em',
    'MEDIUM': '1em',
    'LARGE': '1.5em',
  };

  constructor(private store: Store<State>,
              private validateFieldService: ValidateFieldService,
              public image: ImageService,
              private sanitizer: DomSanitizer) {
  }

  getGraphicUrl(graphicUrl): Observable<SafeUrl> {
    return this.image.optionGraphicUrl(encodeURI(graphicUrl))
      .pipe(map(url => this.sanitizer.bypassSecurityTrustUrl(url)));
  }

  displayTitle(): boolean {
    return !(this.content.menuType && this.content.menuType.includes('notitle'))
      && this.content.value && this.content.description;
  }

  sizeMap(size: string): string {
    if (size) {
      return this.textSize[size];
    }
  }

  alertStyles() {
    return {
      'color': this.content.styles && this.content.styles.color || 'red',
      'font-style': (this.content.styles && this.content.styles.italic) ? 'italic' : '',
      'font-weight': (this.content.styles && this.content.styles.bold) ? 'bold' : '',
      'font-size': this.content.styles && this.sizeMap(this.content.styles.size)
    };
  }

  isOptionGraphicDisplay_Comment(container: Field | Choice) {
    return isOptionGraphicDisplay_Comment(container);
  }

}
