import {createAction, props} from '@ngrx/store';
import {Product} from '../models/Product';

export namespace ProductActions {
  export const deselect = createAction(
    '[product] Deselect',
    props<{ product: Product }>()
  );

  export const updateQuantity = createAction(
    '[product] Update Quantity',
    props<{ product: Product, quantity: string | number }>()
  );

  export const configureNode = createAction(
    '[product] Configure Node',
    props<{ product: Product }>()
  );

  export const reorder = createAction(
    '[product] Reorder',
    props<{ currentPosition: number, newPosition: number }>()
  );

  export const reorderSuccess = createAction(
    '[product] Reorder Success',
    props<{ response: any }>()
  );

  export const refresh = createAction(
    '[product] Refresh',
  );
}
