<mat-dialog-content>
  <div>{{'savePrompt' | cbsLabel}}</div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <cbs-loading [display]="saveInProgress" [diameter]="25" class="m-r-base"></cbs-loading>
  <ng-container *ngIf="!saveInProgress">
    <button class="c-button" test-id="YES_BUTTON" (click)="clickYes()">{{'yes' | cbsLabel}}</button>
    <button class="c-button m-l-base" test-id="NO_BUTTON" (click)="clickNo()">{{'no' | cbsLabel}}</button>
    <button class="c-text-button" test-id="CANCEL_BUTTON" (click)="clickCancel()">{{'cancel' | cbsLabel}}</button>
  </ng-container>
</mat-dialog-actions>
