import {PropertiesState} from '../reducers/properties.reducer';

export const watertronics = {
  // Watertronics: <PropertiesState>{
  //   po_subnavStrategy: 'none',
  //   save_SuccessShowRtfDocId: 'proposaldoc',
  //   labelOverride: {
  //     save: 'finish'
  //   }
  // }
};
