import {IfeIntegrationActions} from '../actions/ife-integration-info.action';
import {UrlUtil} from '../util/url-util';
import urlJoin from 'proper-url-join';
import {environment} from '../../environments/environment';
import {HttpErrorResponse} from '@angular/common/http';
import {createReducer, on} from '@ngrx/store';
import {CbsActions} from '../actions/cbs.action';

export interface IfeIntegrationInfoState {
  analyticsScripts: string;
  initFromUrl: boolean;
  initFromResponse: boolean;
  orgFolderHome: string;
  orgUnitFolderHome: string;
  configInitUrl: string;
  cssUrl: string;
  ifeDomain: string;
  imagesPath: string;
  globalImagesPath: string;
  docLinksUrl: string;
  workflowId?: string;
  configBaseDir: string;
  fromPortal: boolean;
  fromPortalToQM: boolean;
  itemContainerType: number;
  newItem: boolean;
  saveMenuType: string;
  saveToNewProjectAllowed: boolean;
  fromPortalCenter: boolean;
  originUrl: string;
  quoteHomeUrl: string;
  portalDashboardUrl: string;
  originContainerUrl: string;
  portalCenterDashboardUrl: string;
  originName?: string;
  customOriginName?: string;
  customOriginUrl?: string;
  privileges: Map<string, boolean>;
  env: string;
  viewAllInstancesUrl: string;
  groupingSymbol: string;
  decimalSymbol: string;
  userName: string;
  email: string;
  uid: string;
  newItemChoiceId: string;
  languages: Map<string, number>;
  globalNotificationMessage?: string;
  orgNotificationMessage?: string;
  optionImagesPath: string;
  error?: HttpErrorResponse;
  fromSelector?: boolean;
  selectorUrl?: string;
  globalInfoUrl?: string;
  openAccess?: boolean;
  knowledgeBaseChanged?: boolean;
  feedbackUrl?: string;
  versionTitle?: string;
  logoLink?: string;
  logoLinkTabTarget?: string;
  isLogoLinkToPortalCenter?: boolean;
  orgName: string;
  firstName: string;
  lastName: string;
  iqAuthAuthenticated: boolean;
  itemContainerId: number;
  isFromUrl: string;
}

const initialState = <IfeIntegrationInfoState>{
  env: environment.serverEnv
};

export const ifeIntegrationInfoReducer = createReducer(
  initialState,
  on(IfeIntegrationActions.initFromUrl, (state, {url}) => createState(url)),
  on(IfeIntegrationActions.initFromResponse, (state, {userInfo}) => ({...state, ...userInfo, initFromResponse: true})),
  on(IfeIntegrationActions.initFromResponseFailed, (state, {error}) => ({...state, error, initFromResponse: false})),
  on(IfeIntegrationActions.notificationMessage, (state, {orgNotificationMessage, globalNotificationMessage}) => ({
    ...state,
    orgNotificationMessage,
    globalNotificationMessage
  })),
  on(
    CbsActions.itemSaveSuccess,
    CbsActions.itemSaveAsSuccess,
    CbsActions.saveToNewQuoteSuccess,
    CbsActions.saveToNewOrderSuccess,
    CbsActions.saveToNewProjectSuccess,
    (state, {response}) => ({
      ...state,
      originContainerUrl: response.originContainerUrl,
      newItem: false,
      ...response.cbsUserInfo
    })
  )
);

function createState(url: string): IfeIntegrationInfoState {
  const query = UrlUtil.parseUrlQuery(url);
  const configInitUrlParse = UrlUtil.parseUrlSafely(<string>(query.get('configInitUrl')));
  const ifeDomain = configInitUrlParse.protocol + '//' + configInitUrlParse.hostname;
  const orgFolderHome = stripTrailingSlash(<string>(query.get('orgFolderHome')));
  const orgUnitFolderHome = stripTrailingSlash(<string>(query.get('orgUnitFolderHome')) || '');
  const ifeAssetsPath = urlJoin(ifeDomain, 'config', orgFolderHome, orgUnitFolderHome, '/');
  const viewAllInstancesUrl = urlJoin(ifeDomain, '/config', 'classListing');
  return <IfeIntegrationInfoState>{
    initFromUrl: true,
    orgFolderHome: orgFolderHome,
    orgUnitFolderHome: orgUnitFolderHome,
    configInitUrl: query.get('configInitUrl'),
    cssUrl: urlJoin(ifeAssetsPath, 'css'),
    ifeDomain: ifeDomain,
    imagesPath: urlJoin(ifeAssetsPath, 'images/', {trailingSlash: true}),
    globalImagesPath: urlJoin(ifeDomain, 'Party/images/', {trailingSlash: true}),
    docLinksUrl: urlJoin(ifeDomain, '/config/api/v1/documentLinks/configDocs'),
    configBaseDir: query.get('configBaseDir'),
    newItemChoiceId: query.get('newItemChoiceId'),
    env: query.get('env'),
    isFromUrl: query.get('isFromUrl'),
    viewAllInstancesUrl: viewAllInstancesUrl,
  };
}

function stripTrailingSlash(str: string) {
  return str && str.replace(/\/$/, '');
}

export class IfeIntegrationInfoSelectors {
  static getCssUrl = (state: IfeIntegrationInfoState) => state.cssUrl;
  static getIfeDomain = (state: IfeIntegrationInfoState) => state.ifeDomain;
  static getOrgFolderHome = (state: IfeIntegrationInfoState) => state.orgFolderHome;
  static getImagesPath = (state: IfeIntegrationInfoState) => state.imagesPath;
  static getGlobalImagesPath = (state: IfeIntegrationInfoState) => state.globalImagesPath;
  static getDocLinksUrl = (state: IfeIntegrationInfoState) => state.docLinksUrl;
  static getFromPortal = (state: IfeIntegrationInfoState) => state.fromPortal;
  static getFromPortalToQM = (state: IfeIntegrationInfoState) => state.fromPortalToQM;
  static getItemContainerType = (state: IfeIntegrationInfoState) => state.itemContainerType;
  static getItemContainerId = (state: IfeIntegrationInfoState) => state.itemContainerId;
  static isNewItem = (state: IfeIntegrationInfoState) => state.newItem;
  static getSaveMenuType = (state: IfeIntegrationInfoState) => state.saveMenuType;
  static isSaveToNewProjectAllowed = (state: IfeIntegrationInfoState) => state.saveToNewProjectAllowed;
  static getFromPortalCenter = (state: IfeIntegrationInfoState) => state.fromPortalCenter;
  static getOriginUrl = (state: IfeIntegrationInfoState) => state.originUrl;
  static getQuoteHomeUrl = (state: IfeIntegrationInfoState) => state.quoteHomeUrl;
  static getPortalDashboardUrl = (state: IfeIntegrationInfoState) => state.portalDashboardUrl;
  static getPortalCenterDashboardUrl = (state: IfeIntegrationInfoState) => state.portalCenterDashboardUrl;
  static isFromSelector = (state: IfeIntegrationInfoState) => state.fromSelector;
  static getSelectorUrl = (state: IfeIntegrationInfoState) => state.selectorUrl;
  static getGlobalInfoUrl = (state: IfeIntegrationInfoState) => state.globalInfoUrl;
  static getOriginContainerUrl = (state: IfeIntegrationInfoState) => state.originContainerUrl;
  static getOriginName = (state: IfeIntegrationInfoState) => state.originName || null;
  static getCustomOriginName = (state: IfeIntegrationInfoState) => state.customOriginName;
  static getCustomOriginUrl = (state: IfeIntegrationInfoState) => state.customOriginUrl;
  static getPrivileges = (state: IfeIntegrationInfoState) => state.privileges;
  static getViewAllInstancesUrl = (state: IfeIntegrationInfoState) => state.viewAllInstancesUrl;
  static getGroupingSymbol = (state: IfeIntegrationInfoState) => state.groupingSymbol;
  static getDecimalSymbol = (state: IfeIntegrationInfoState) => state.decimalSymbol;
  static getUserName = (state: IfeIntegrationInfoState) => state.userName;
  static getEmail = (state: IfeIntegrationInfoState) => state.email;
  static getIqAuthAuthenticated = (state: IfeIntegrationInfoState) => state.iqAuthAuthenticated;
  static getLanguages = (state: IfeIntegrationInfoState) => state.languages;
  static getOptionImagesUrl = (state: IfeIntegrationInfoState) => state.optionImagesPath;
  static isOpenAccess = (state: IfeIntegrationInfoState) => state.openAccess;
  static getGlobalNotificationMessage = (state: IfeIntegrationInfoState) => state.globalNotificationMessage;
  static getOrgNotificationMessage = (state: IfeIntegrationInfoState) => state.orgNotificationMessage;
  static getVersionTitle = (state: IfeIntegrationInfoState) => state.versionTitle;
  static getFeedbackUrl = (state: IfeIntegrationInfoState) => state.feedbackUrl;
  static getAnalyticsScripts = (state: IfeIntegrationInfoState) => state.analyticsScripts;
  static getError = (state: IfeIntegrationInfoState) => state.error;
  static isInitUrlValid = (state: IfeIntegrationInfoState) => {
    // this will only check for validity after initFromUrl is true
    return !state.initFromUrl || !!(state.orgFolderHome && state.configBaseDir && state.configInitUrl);
  };
  static isInitFromResponse = (state: IfeIntegrationInfoState) => state.initFromResponse;
  static isKnowledgeBaseChanged = (state: IfeIntegrationInfoState) => state.knowledgeBaseChanged;
  static isCfgSessionValid = (state: IfeIntegrationInfoState) => {
    if (state.initFromResponse === undefined) {
      return undefined;
    }
    return state.initFromResponse && !!state.uid;
  };
  static getLogoLink = (state: IfeIntegrationInfoState) => state.logoLink;
  static getLogoLinkTabTarget = (state: IfeIntegrationInfoState) => state.logoLinkTabTarget;
  static isLogoLinkToPortalCenter = (state: IfeIntegrationInfoState) => state.isLogoLinkToPortalCenter;
}
