import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DocumentLink} from '../../models/DocumentLink';
import {CbsRollbarService} from '../../rollbar/cbs-rollbar.service';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable()
export class DocumentService {

  constructor(private httpClient: HttpClient, private rollbar: CbsRollbarService) {
  }

  getDocumentLinks(documentLinksUrl: string): Observable<DocumentLink[]> {
    return this.httpClient.get<{ documentLinks: DocumentLink[] }>(documentLinksUrl, {withCredentials: true})
      .pipe(map(data => data.documentLinks));
  }
}
