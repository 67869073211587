import {Component, OnInit} from '@angular/core';
import {getCosParentSubcategory, getProperties, getSearchDesign, State} from '../reducers/index';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {ParentSubcategory} from '../models/ParentSubcategory';
import {map} from 'rxjs/operators';
import {assignFirstTruthy} from '../util/rx-utils';
import {WorkflowActions} from '../actions/workflow.action';

@Component({
  selector: 'cbs-basic-parts-search-route',
  templateUrl: './basic-parts-search-route.component.html',
})
export class BasicPartsSearchRouteComponent implements OnInit {

  displayCos = false;
  parentSubcat$: Observable<ParentSubcategory>;
  singleColumnCos$: Observable<boolean>;

  constructor(private store: Store<State>) {
  }

  ngOnInit() {
    this.store.dispatch(WorkflowActions.displayBreadcrumbs({
      breadcrumbs: {select: {display: true, target: 'parts-search'}}
    }));
    this.store.dispatch(WorkflowActions.displayBreadcrumbs({
      breadcrumbs: {documents: {display: true, target: 'documents'}}
    }));
    this.store.dispatch(WorkflowActions.displayData({
      payload: {
        displayPrice: true,
        displaySaveButton: true,
        displaySearchButton: false,
        createCustomOptions: false
      }
    }));
    this.parentSubcat$ = this.store.pipe(select(getCosParentSubcategory));
    assignFirstTruthy(this.store, getSearchDesign, design => this.displayCos = design.searchBarLocation === 'results');
    this.singleColumnCos$ = this.store.pipe(
      select(getProperties),
      map(properties => properties.cos_singleColumn));

  }

  toggleDisplayCos() {
    this.displayCos = !this.displayCos;
  }

}
