<ng-container *ngIf="initUrlValid$|async; else initError">

  <cbs-global-css></cbs-global-css>
  <ng-container *ngIf="(initFromResponse$ | async) !== undefined">
    <ng-container *ngIf="cfgSessionValid$ | async; else initError">
      <cbs-analytics></cbs-analytics>
      <cbs-global-notifications></cbs-global-notifications>

      <div>
        <cbs-nav-bar></cbs-nav-bar>

        <main class="main-container">
          <router-outlet></router-outlet>
          <br/>
        </main>

        <cbs-footer></cbs-footer>
      </div>

    </ng-container>
  </ng-container>

</ng-container>
<ng-template #initError>
  <cbs-app-init-error [errorMessage]=""></cbs-app-init-error>
</ng-template>
