import {CbsRollbarService} from '../rollbar/cbs-rollbar.service';
import {ActionReducer} from '@ngrx/store';
import {alertUser} from '../services/alert.service';

let logger: CbsRollbarService;

function stateWithoutLabels(state: any) {
  return {
    ...state,
    labels: 'removed for rollbar error reporting'
  };
}

export function actionLog(reducer): ActionReducer<any> {
  return (state, action) => {
    let nextState = state;
    try {
      nextState = reducer(state, action);
    } catch (e) {
      if (!logger) {
        logger = new CbsRollbarService(undefined);
      }
      state = stateWithoutLabels(state);
      logger.initFromState(state);
      logger.error(`Error during action "${action.type}"`, e, {state, action});
      alertUser();
    }
    return nextState;
  };
}
