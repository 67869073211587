import {LocalStorageService} from 'angular-2-local-storage';
import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {getOrgFolderHome, getProperties, State} from '../reducers/index';
import {first} from 'rxjs/operators';
import {assignFirstTruthy} from '../util/rx-utils';

const INITIALIZE_ACCORDIONS_EXPANDED = true;

@Injectable()
export class AccordionService {
  private orgFolderHome;
  private accordionMode = false;
  private expandedParentSubcat: string;
  private expanded: { [accordionId: string]: boolean } = {};

  constructor(private localStorageService: LocalStorageService,
              private store: Store<State>) {
    assignFirstTruthy(this.store, getOrgFolderHome, orgFolderHome => this.orgFolderHome = orgFolderHome);
    this.store.pipe(select(getProperties), first(properties => properties.po_onlyOneOpenAccordion))
      .subscribe(props => this.accordionMode = props.po_onlyOneOpenAccordion);
    this.expandedParentSubcat = this.localStorageService.get('expandedParentSubcat');
  }

  isExpanded(accordionId: string) {
    return this.expanded[accordionId];
  }

  expandAccordion(accordionId: string) {
    if (!this.isExpanded(accordionId)) {
      this.setExpanded(accordionId, true);
    }
  }

  collapseAccordion(accordionId: string) {
    if (this.isExpanded(accordionId)) {
      this.setExpanded(accordionId, false);
    }
  }

  expandAll(accordionIds: string[]) {
    accordionIds.forEach(id => this.expandAccordion(id));
  }

  collapseAll(accordionIds: string[]) {
    accordionIds.forEach(id => this.collapseAccordion(id));
  }

  initAccordionItem(accordionId: string, keepOpen?: boolean, initCollapsed?: boolean) {
    const initialValue = <boolean>this.localStorageService.get(this.getLocalStorageKey(accordionId));
    if (initialValue == null) {
      this.setExpanded(accordionId, keepOpen || (!(this.accordionMode || initCollapsed) && INITIALIZE_ACCORDIONS_EXPANDED), keepOpen);
    } else {
      this.expanded[accordionId] = initialValue;
    }
  }

  private setExpanded(accordionId: string, expanded: boolean, keepOpen?: boolean) {
    if (this.accordionMode && expanded && !keepOpen) {
      if (this.expandedParentSubcat) {
        this.setExpanded(this.expandedParentSubcat, false);
      }
      this.expandedParentSubcat = accordionId;
      this.localStorageService.set('expandedParentSubcat', this.expandedParentSubcat);
      const element = document.getElementById(accordionId);
      setTimeout(() => element.scrollIntoView(), 0);
    }
    this.expanded[accordionId] = expanded;
    this.localStorageService.set(this.getLocalStorageKey(accordionId), expanded);
  }

  private getLocalStorageKey(accordionId: string) {
    return `${this.orgFolderHome}.${accordionId}.accordion_expanded`;
  }

}
