<ng-container *ngIf="!saving">
  <ng-container *ngIf="previewRowData">
    <div style="margin-bottom: 10px">
      <button class="c-button" test-id="SAVE_BUTTON" (click)="savePasteData()">{{ 'save' | cbsLabel }}</button>
      <button class="c-text-button" test-id="CANCEL_BUTTON" (click)="cancelPasteData()">{{ 'cancel' | cbsLabel }}</button>
    </div>
    <div class="cbs-section p-base" style="flex: 1 1 auto">
      <ag-grid-angular class="ag-theme-balham"
                       [defaultColDef]="defaultColDef"
                       [columnDefs]="columnDefs"
                       [rowData]="previewRowData"
                       [stopEditingWhenGridLosesFocus]="true"
                       style="width: 100%; height: 100%;"></ag-grid-angular>
    </div>
  </ng-container>
  <ng-container *ngIf="!previewRowData">
    <textarea style="margin-bottom: 10px" rows="1" placeholder="{{ 'addFromSpreadsheetPlaceholder' | cbsLabel }}"
              test-id="SPREADSHEET_DATA_FIELD" (change)="onPaste($event)" (input)="onPaste($event)"></textarea>
    <div *ngIf="showSuccessMessage" class="text-success" test-id="SAVE_SUCCESS_MESSAGE">{{ 'saveSuccessful' | cbsLabel }}</div>
    <div *ngIf="showErrorMessage" class="text-alert" test-id="SAVE_ERROR_MESSAGE">{{ 'addFromSpreadsheetError' | cbsLabel }}</div>
  </ng-container>

</ng-container>
<div *ngIf="saving" style="text-align: center; margin-top: 20px">
  <cbs-loading [display]="true" [diameter]="50"></cbs-loading>
  <div>{{ 'savingMessage' | cbsLabel }}</div>
</div>
