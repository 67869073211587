import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from} from 'rxjs';
import {DocumentService} from '../services/web/document.service';
import {DocumentLink} from '../models/DocumentLink';
import {CbsRollbarService} from '../rollbar/cbs-rollbar.service';
import {catchError, map} from 'rxjs/operators';
import {DocumentsActions} from '../actions/documents.action';
import {concatMapEmitLast} from '../util/rx-utils';

/**
 * Effects offer a way to isolate and easily test side-effects.
 */
@Injectable()
export class DocumentEffects {

  //noinspection JSUnusedGlobalSymbols
  fetchDocumentLinks$ = createEffect(() => this.allActions.pipe(
    ofType(DocumentsActions.fetchDocLinks),
    concatMapEmitLast(({url}) =>
      this.docService.getDocumentLinks(url).pipe(
        map((links: DocumentLink[]) => DocumentsActions.fetchDocLinksSuccess({links})),
        catchError(error => {
          this.rollbar.apiError(error, {url});
          return from([DocumentsActions.fetchDocLinksFailure()]);
        })
      )
    )
  ));

  constructor(private allActions: Actions, private docService: DocumentService, private rollbar: CbsRollbarService) {
  }
}
