<header class="ife-header">
  <div *ngIf="logoLink$|async as logoLink; else logoWithoutLinkBlock">
    <a [href]="logoLink" target="{{logoLinkTabTarget$|async}}">
      <img alt="logo" [src]="image.url('logo-intuitive.png') | async" class="orgLogo">
    </a>
  </div>
  <ng-template #logoWithoutLinkBlock><img alt="logo" [src]="image.url('logo-intuitive.png') | async" class="orgLogo">
  </ng-template>

  <div class="ife-header__menus js-ife-navbar-wrapper is-large-screen">
    <ng-container *ngTemplateOutlet="navBarContent"></ng-container>
  </div>

  <button class="ife-header__navbar-toggle js-ife-navbar-toggle" test-id="HEADER_MENU_TOGGLE_BUTTON"
          (click)="displayHeaderMenu = !displayHeaderMenu">
    <span class="fa fa-fw fa-bars"></span>
    {{'menu' | cbsLabel}}
  </button>

  <div class="ife-header__menus js-ife-navbar-wrapper is-open" [ngStyle]="{'display': displayHeaderMenu ? '' : 'none'}">
    <ng-container *ngTemplateOutlet="navBarContent"></ng-container>
  </div>

  <ng-template #navBarContent>
    <div class="ife-navbar" style="width: 100%;">
      <nav class="bp-menu">
        <ul class="bp-menu__list">
          <ng-container *ngIf="!(customOriginName$ | async)">
            <ng-container *ngIf="fromPortalCenter$ | async; else QMPortal">
              <li class="bp-menu__item" *ngIf="!(isLogoLinkToPortalCenter$|async) && portalCenterDashboardUrl$|async as url">
                <a class="bp-menu__link" (click)="verifyLink(url)" test-id="PORTAL_CENTER_LINK">
                  <i class="fas fa-home fa-fw"></i>
                </a>
              </li>
            </ng-container>
            <ng-template #QMPortal>
              <ng-container *ngIf="(fromPortalToQM$| async) && !(fromPortal$); else homeLink">
                <cbs-hover-menu [menu]="{childLinks: [
                  {description: 'projectDashboard' | cbsLabel, target$: portalDashboardUrl$, popup: false},
                  {description: 'home' | cbsLabel, target$: quoteHomeUrl$, popup: false}
                  ]}">
                  <li class="bp-menu__item">
                    <a class="bp-menu__link">
                      <i class="fas fa-home fa-fw"></i>
                    </a>
                  </li>
                </cbs-hover-menu>
              </ng-container>
              <ng-template #homeLink>
                <li class="bp-menu__item" *ngIf="originUrl$|async as url">
                  <a class="bp-menu__link" test-id="HOME_LINK" (click)="verifyLink(url)">
                    <i class="fas fa-home fa-fw"></i>
                  </a>
                </li>
              </ng-template>
            </ng-template>
            <ng-container *ngIf="!withoutAssociatedItemContainer">
              <li class="bp-menu__item" *ngIf="specificUrl$|async as url">
                {{' '}}<a class="bp-menu__link" test-id="SPECIFIC_URL_LINK" (click)="verifyLink(url)"
                          [matTooltip]="(originName$|async) ? (determineTextLabel(itemContainerType$|async) | cbsLabel) + ': ' + (originName$|async) : ''"
                          matTooltipPosition="above">
                {{determineTextLabel(itemContainerType$|async) | cbsLabel}}
              </a>
              </li>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="customOriginName$ | async as name">
            <li class="bp-menu__item" *ngIf="customOriginUrl$|async as url">
              {{' '}}<a class="bp-menu__link" test-id="CUSTOM_ORIGIN_LINK" (click)="goToUrl(url)">
              {{name}}
            </a>
            </li>
          </ng-container>
          <li [ngClass]="{'bp-menu__item': true,'has-workflow': !withoutAssociatedItemContainer}" *ngIf="fromSelector$ | async">
            <ng-container *ngIf="selectorUrl$ | async as selectorUrl; else placeholder">
              {{' '}}<a class="bp-menu__link" href="{{selectorUrl}}" test-id="SELECTOR_LINK">
              {{'selector' | cbsLabel}}
            </a>
            </ng-container>
            <ng-template #placeholder>
              {{' '}}{{'selector' | cbsLabel}}
            </ng-template>
          </li>

          <ng-container *ngIf="displayBreadcrumbs$|async as displayBreadcrumbs">
            <li
              [ngClass]="{'bp-menu__item': true,'has-workflow': !withoutAssociatedItemContainer || (fromSelector$| async) || (customOriginName$ | async)}"
              *ngIf="safeDisplayCheck(displayBreadcrumbs['inputs'])">
              {{' '}}<a class="bp-menu__link" routerLink="{{displayBreadcrumbs['inputs'].target}}"
                        routerLinkActive="active-page" test-id="INPUTS_LINK">{{'inputs' | cbsLabel}}</a>
            </li>
            <li class="bp-menu__item has-workflow" *ngIf="safeDisplayCheck(displayBreadcrumbs['search'])">
              {{' '}}<a class="bp-menu__link" routerLink="{{displayBreadcrumbs['search'].target}}"
                        routerLinkActive="active-page" test-id="SEARCH_LINK">{{'search' | cbsLabel}}</a>
            </li>
            <li
              [ngClass]="{'bp-menu__item': true,'has-workflow': !withoutAssociatedItemContainer || (fromSelector$| async) || (customOriginName$ | async)}"
              *ngIf="safeDisplayCheck(displayBreadcrumbs['select'])" matTooltip="{{'selectTooltip' | cbsLabel}}"
              matTooltipPosition="above">
              {{' '}}<a class="bp-menu__link" routerLink="{{displayBreadcrumbs['select'].target}}"
                        routerLinkActive="active-page" test-id="SELECT_LINK">{{'select' | cbsLabel}}</a>
            </li>
            <li class="bp-menu__item has-workflow" *ngIf="safeDisplayCheck(displayBreadcrumbs['configure'])"
                matTooltip="{{'configureTooltip' | cbsLabel}}" matTooltipPosition="above">
              {{' '}}<a class="bp-menu__link" routerLink="{{displayBreadcrumbs['configure'].target}}"
                        routerLinkActive="active-page" test-id="CONFIGURE_LINK">{{'Configure' | cbsLabel}}</a>
            </li>
            <li class="bp-menu__item has-workflow" *ngIf="viewDocsPrivilege && safeDisplayCheck(displayBreadcrumbs['documents'])"
                matTooltip="{{'documentsTooltip' | cbsLabel}}" matTooltipPosition="above">
              {{' '}}<a class="bp-menu__link" routerLink="{{displayBreadcrumbs['documents'].target}}"
                        routerLinkActive="active-page" test-id="DOCUMENTS_LINK">{{'documents' | cbsLabel}}</a>
            </li>
            <li class="bp-menu__item has-workflow" *ngIf="safeDisplayCheck(displayBreadcrumbs['product'])">
              {{' '}}<a class="bp-menu__link" routerLink="{{displayBreadcrumbs['product'].target}}"
                        routerLinkActive="active-page" test-id="PRODUCT_LINK">{{'product' | cbsLabel}}</a>
            </li>
          </ng-container>
          <li style="margin-left: auto"></li> <!-- empty element, just to enforce proper spacing -->
          <li class="bp-menu__item" *ngIf="!(customOriginName$ | async)">
            <cbs-header-link class="bp-menu__link"></cbs-header-link>
          </li>
          <li class="bp-menu__item" *ngIf="uomType$|async as uomType">
            <cbs-hover-menu [menu]="{childLinks: [
              {description: 'US' | cbsLabel, clickFunction: changeUnit, functionInput: 'US', popup: false},
              {description: 'Metric' | cbsLabel, clickFunction: changeUnit, functionInput: 'METRIC', popup: false}
              ], styles: {'text-align':  'right'}}">
              <button type="button" class="bp-menu__link bp-menu__dropdown-button" test-id="UNITS_BUTTON">
                {{uomType + ' ' + ('units' | cbsLabel)}}
              </button>
            </cbs-hover-menu>
          </li>
          <li class="bp-menu__item" *ngIf="viewDebugPrivilege">
            <cbs-debug-menu></cbs-debug-menu>
          </li>
          <ng-container *ngIf="languages$|async">
            <li class="bp-menu__item" *ngIf="languageLinks.length > 1">
              <cbs-hover-menu [menu]="{childLinks: languageLinks,styles: {'text-align':  'right'}}">
                <button type="button" class="bp-menu__link bp-menu__dropdown-button">
                  <span class="fas fa-globe"></span>
                </button>
              </cbs-hover-menu>
            </li>
          </ng-container>
          <li class="bp-menu__item" *ngIf="!(hideNewItemButton$| async) && !(customOriginName$ | async)">
            <cbs-new-item></cbs-new-item>
          </li>
          <li class="bp-menu__item" *ngIf="!(openAccessUser$ | async) && !(customOriginName$ | async)">
            <cbs-hover-menu [menu]="{childLinks: [
              {description: 'preferences' | cbsLabel, target$: sessionPrefsUrl$, popup: true},
              {description: 'defaultPreferences' | cbsLabel, target$: defaultPrefsUrl$, popup: true, display : isfromUrl != null && isfromUrl != 'true' },
              {description: '<hr/>', display: !!(globalInfoUrl$|async)},
              {description: 'globalInfoURL' | cbsLabel, target$: globalInfoUrl$, popup: true},
              {description: '<hr/>'},
              {description: 'feedback' | cbsLabel, target$: feedbackUrl$, popup: true},
              {description: 'help' | cbsLabel, target$: helpUrl$, popup: true},
              {description: versionTitle$|async},
              {description: '<hr/>'},
              {description: 'logout' | cbsLabel, target$: logoutUrl$, popup: false}
              ], styles: {'text-align':  'right'}}">
              <button type="button" class="bp-menu__link bp-menu__dropdown-button">
                {{ternaryDisplay(((fromPortalCenter$|async) || (iqAuthAuthenticated$|async)), email$|async, userName$|async)}}
              </button>
            </cbs-hover-menu>
          </li>
        </ul>
      </nav>
    </div>
  </ng-template>
</header>
