import {Injectable} from '@angular/core';
import {InvalidFieldComponent} from '../components/invalid-field.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Store} from '@ngrx/store';
import {
  getCosParentSubcategory, getDecimalSymbol, getGroupingSymbol, getProductOptionCategories, getProductOptionPages, State
} from '../reducers/index';
import {FieldContainer} from '../models/FieldContainer';
import {assignFirstTruthy, selectFirstTruthy} from '../util/rx-utils';
import {FieldActions} from '../actions/field.action';
import {isNullOrUndefined} from '../util/js-utils';
import {first} from 'rxjs/operators';
import {Category} from '../models/Category';

@Injectable()
export class ValidateFieldService {

  allValid = true;
  cosValid = true;
  productValid = true;
  mandatoryValid = true;
  pendingInvalid: any[];
  groupingSymbol: string;
  decimalSymbol: string;

  constructor(public snackBar: MatSnackBar, private store: Store<State>) {
    this.pendingInvalid = [];
    assignFirstTruthy(this.store, getGroupingSymbol, symbol => this.groupingSymbol = symbol);
    assignFirstTruthy(this.store, getDecimalSymbol, symbol => this.decimalSymbol = symbol);
  }

  validateFields(response) {
    this.mandatoryValid = true;
    this.allValid = true;
    this.productValid = true;
    this.cosValid = true;
    if (response.pages && response.pages.length > 0) {
      response.pages.forEach(page =>
        page.categories.forEach(category =>
          category.parentSubcategories.forEach(parentSubcat =>
            this.flattenFields(parentSubcat).forEach(pair => {
              const field = pair[1];
              this.checkPending(pair, true, category.id, parentSubcat.id, page.id);
              field.invalid = this.numericInvalid(field);
              if (field.invalid) {
                page.invalid = true;
                category.invalid = true;
                parentSubcat.invalid = true;
                this.productValid = false;
              }
            })
          )
        )
      );
    } else if (response.categories) {
      response.categories.forEach(category =>
        category.parentSubcategories.forEach(parentSubcat =>
          this.flattenFields(parentSubcat).forEach(pair => {
            const field = pair[1];
            this.checkPending(pair, true, category.id, parentSubcat.id);
            field.invalid = this.numericInvalid(field);
            if (field.invalid) {
              category.invalid = true;
              parentSubcat.invalid = true;
              this.productValid = false;
            }
          })));
    }
    if (response.parentSubcategory) {
      const parentSubcat = response.parentSubcategory;
      this.flattenFields(parentSubcat).forEach(pair => {
        const field = pair[1];
        this.checkPending(pair, false);
        field.invalid = this.numericInvalid(field);
        if (field.invalid) {
          this.cosValid = false;
        }
      });
    }
    this.allValid = (this.cosValid && this.productValid);
    if (this.allValid) {
      this.snackBar.dismiss();
    }
    return response;
  }

  flattenFields(parentSubcat) {
    return parentSubcat.subcategories
      .map(subcat => subcat.fields.map(field => [subcat.id, field])).concat([[]])
      .reduce((list, fields) => list.concat(fields))
      .concat(parentSubcat.fields.map(field => [null, field]));
  }

  updatePending(params) {
    this.splicePending(params);
    this.pendingInvalid.push(params);
  }

  splicePending(params) {
    this.pendingInvalid.forEach(field => {
      if (field[0] === params[0]) {
        this.pendingInvalid.splice(this.pendingInvalid.indexOf(field), 1);
      }
    });
  }

  checkPending(pair, isProductOptions, categoryId?, parentSubcatId?, pageId?) {
    let field, subcatId = null;
    if (Array.isArray(pair)) {
      [subcatId, field] = pair;
    } else {
      field = pair;
    }
    this.pendingInvalid.forEach(pending => {
      if (pending[0] === field.httpNumericValueName) {
        field.value = pending[1];
        field.numericValue = this.parseValue(pending[1], field.numericValue);
        this.splicePending(pending);
        if (!this.numericInvalid(field)) {
          this.store.dispatch(FieldActions.changeField({
            categoryId: categoryId,
            parentSubcatId: parentSubcatId,
            subcatId: subcatId,
            fieldId: field.id,
            changeParams: [pending],
            fieldContainer: isProductOptions ? FieldContainer.Product : FieldContainer.Cos,
            pageId: pageId
          }));
        } else {
          this.updatePending(pending);
          this.store.dispatch(FieldActions.invalidField({
            fieldId: field.id,
            fieldContainer: isProductOptions ? FieldContainer.Product : FieldContainer.Cos,
            subcatId: subcatId,
            categoryId: categoryId,
            parentSubcatId: parentSubcatId,
            pageId: pageId
          }));
        }
      }
    });
  }

  validateParameters(changeParams) {
    this.allValid = true;
    if (this.includesUomChange(changeParams)) {
      this.numericParams(changeParams).forEach(param => this.updatePending(param));
      return true;
    }
    changeParams.forEach(param => {
      this.splicePending(param);
      selectFirstTruthy(this.store, getProductOptionCategories).subscribe(categories =>
        categories.forEach(category =>
          category.parentSubcategories.forEach(parentSubcat =>
            this.flattenFields(parentSubcat)
              .filter(pair => {
                const field = pair[1];
                return param[0] === field.httpNumericValueName;
              })
              .forEach(pair => {
                const field = pair[1];
                if (this.numericInvalid(field, param)) {
                  this.updatePending(param);
                }
              })
          )));
      selectFirstTruthy(this.store, getCosParentSubcategory).subscribe(parentSubcat =>
        this.flattenFields(parentSubcat)
          .filter(pair => {
            const field = pair[1];
            return param[0] === field.httpNumericValueName;
          })
          .forEach(pair => {
            const field = pair[1];
            if (this.numericInvalid(field, param)) {
              this.updatePending(param);
            }
          })
      );
    });
    if (!this.allValid) {
      this.openInvalidDisplay();
    }
    return this.allValid;
  }

  private includesUomChange(params) {
    let uomChange = false;
    params.forEach(param => {
      if (param[0].includes('inputUnit')) {
        uomChange = true;
      }
    });
    return uomChange;
  }

  // todo send in the field type
  private numericParams(params) {
    return params.filter(param => param[0].includes('numeric'));
  }

  // todo extract conditionals from prop setting and opening
  public numericInvalid(field, param?) {
    let invalid;
    const value = param ? this.parseValue(param[1], field.numericValue) : field.numericValue;
    if (field.displayType === 'NUMERIC_INPUT' || field.displayType === 'NUMERIC_SPINNER') {

      if (this.isBounded(field)
        && (value < field.minValue || value > field.maxValue)
        && this.notClose(field, value, param)) {
        invalid = true;
        this.allValid = false;
      } else {
        invalid = false;
      }
    }
    return invalid;
  }

  isBounded(field): boolean {
    return (!isNullOrUndefined(field.minValue) && !(field.minValue === 0 && field.maxValue === 0));
  }

  // todo - this has a side effect, it modifies the param[1]
  // todo - rename?
  private notClose(field, value, param?): boolean {
    if (param) {
      if (this.getPrecisionTrimmed(field, value) === this.getPrecisionTrimmed(field, field.minValue)) {
        param[1] = '' + field.minValue;
        return false;
      } else if (this.getPrecisionTrimmed(field, value) === this.getPrecisionTrimmed(field, field.maxValue)) {
        param[1] = '' + field.maxValue;
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  private getPrecisionTrimmed(field, value) {
    if (field.unitOfMeasureField) {
      if (value < field.unitOfMeasureField.decimalLimitBaseValue) {
        return value.toFixed(field.unitOfMeasureField.precisionBelowBaseValue);
      } else {
        return value.toFixed(field.unitOfMeasureField.precisionAboveBaseValue);
      }
    }
    return value;
  }

  public parseValue(value: string, failVal): number {
    if (this.decimalSymbol && this.groupingSymbol) {
      value = value.replace(this.groupingSymbol, '');
      value = value.replace(this.decimalSymbol, '.');
      return parseFloat(value);
    }
    return failVal;
  }

  private mandatoryStringCheck(field): boolean {
    return (isNullOrUndefined(field.value) || field.value === '');
  }

  private mandatoryDropdownCheck(field): boolean {
    const value = field.choices.find(c => c.selected).description;
    return (isNullOrUndefined(value) || value === '');
  }

  private mandatoryNumericCheck(field): boolean {
    return (field.numericValue === 0);
  }

  public mandatoryFieldCheck(field): boolean {
    if (field.mandatory) {
      switch (field.displayType) {
        case 'TEXT_INPUT':
          return this.mandatoryStringCheck(field);
        case 'NUMERIC_INPUT':
          return this.mandatoryNumericCheck(field);
        case 'DROPDOWN':
          return this.mandatoryDropdownCheck(field);
      }
    }
    return false;
  }

  public validateMandatory(diagnostic = false) {
    let mandatoryValid = true;
    this.store.select(getProductOptionPages).pipe(first()).subscribe(pages => {
      if (pages && pages.length > 0) {
        pages.forEach(page => {
          if (!this.validateMandatoryCategories(page.categories, diagnostic, page.id)) {
            mandatoryValid = false;
          }
        });
      } else {
        this.store.select(getProductOptionCategories).pipe(first()).subscribe(categories => {
          if (categories) {
            if (!this.validateMandatoryCategories(categories, diagnostic)) {
              mandatoryValid = false;
            }
          }
        });
      }
    });
    this.store.select(getCosParentSubcategory).pipe(first()).subscribe(parentSubcat => {
      if (parentSubcat) {
        this.flattenFields(parentSubcat).forEach(pair => {
          const [subcatID, field] = pair;
          if (this.mandatoryFieldCheck(field)) {
            mandatoryValid = false;
            if (!diagnostic) {
              this.store.dispatch(FieldActions.invalidField({
                fieldId: field.id,
                subcatId: subcatID,
                fieldContainer: FieldContainer.Cos,
                parentSubcatId: parentSubcat.id
              }));
            }
          }
        });
      }
    });
    if (!diagnostic) {
      this.mandatoryValid = mandatoryValid;
      if (!mandatoryValid || !this.allValid) {
        this.openInvalidDisplay();
      }
    }
    return mandatoryValid && this.allValid;
  }

  private validateMandatoryCategories(categories: Category[], diagnostic: boolean, pageId?: string) {
    let mandatoryValid = true;
    categories.forEach(category =>
      category.parentSubcategories.forEach(parentSubcat =>
        this.flattenFields(parentSubcat).forEach(pair => {
          const [subcatID, field] = pair;
          if (this.mandatoryFieldCheck(field)) {
            mandatoryValid = false;
            if (!diagnostic) {
              this.store.dispatch(FieldActions.invalidField({
                fieldId: field.id,
                subcatId: subcatID,
                fieldContainer: FieldContainer.Product,
                categoryId: category.id,
                parentSubcatId: parentSubcat.id,
                pageId
              }));
            }
          }
        })));
    return mandatoryValid;
  }

  public diagnosticValidation() {
    return this.validateMandatory(true);
  }

  private openInvalidDisplay() {
    this.snackBar.openFromComponent(InvalidFieldComponent, {
      duration: 3000,
      data: {
        invalidMandatory: !this.mandatoryValid
      }
    });
  }
}
