<cbs-hover-menu [menu]="{childLinks: [
    {description: 'View output elements', target$: viewOutputElements$, popup: true, display: (viewLogsPrivilege$|async)},
    {description: 'View all selected options', target$: viewSelectedOptions$, popup: true, display: (viewLogsPrivilege$|async)},
    {description: 'View Item Xml', target$: viewItemXml$, popup: true, display: (viewLogsPrivilege$|async)},
    {description: 'View Quote Xml', target$: viewQuoteXml$, popup: true, display: (viewLogsPrivilege$|async)},
    {description: 'View Pdf Xml', target$: viewPdfXml$, popup: true, display: (viewLogsPrivilege$|async)},
    {description: 'Performance Log', target$: viewPerfLogs$, popup: true, display: (viewLogsPrivilege$|async)},
    {description: 'Config Engine Log', target$: viewKELogs$, popup: true, display: (viewLogsPrivilege$|async)},
    {description: 'View All Instances', target$: viewAllInstancesUrl$, popup: true, display: (viewLogsPrivilege$|async)},
    {description: 'Reload Kb', clickFunction: reloadKb, popup: false},
    {description: 'View Org Setup Xml', target$: viewOrgSetupXml$, popup: true, display: (viewLogsPrivilege$|async)},
    {description: 'View Page Xml', target$: viewPageXml$, popup: true, display: (viewLogsPrivilege$|async) && this.currentPage!='none'},
    {description: 'View Custom Action Xml', target$: viewCustomActionXml$, popup: true, display: (viewLogsPrivilege$|async)},
    {description: 'Search Combinations Log', target$: searchCombinationsLog$, popup: true, display: displaySearchCombinations},
    {description: 'Adv Config COS', target$: advConfigCos$, popup: false, display: displayAdvCos},
    {description: 'Adv Config Option Selection', target$: advConfigMos$, popup: false, display: displayAdvOptionSelection}
    ], styles: {'text-align':  'right'}}">
  <button type="button" class="bp-menu__link bp-menu__dropdown-button">
    <span class="fas fa-bug"></span>
  </button>
</cbs-hover-menu>
