import {ProductOptionsFieldChangeResponse} from '../services/web/product-options-field-change-response';
import {
  ChangeAttributePayload,
  ChangeFieldPayload, FieldId, TempChangeCheckboxPayload, TempChangeDropdownPayload, TempChangeFieldValuePayload,
  TempChangeNumericFieldPayload
} from './field.action';
import {createAction, props} from '@ngrx/store';
import {FieldContainer} from '../models/FieldContainer';

export namespace ProductOptionActions {
  export const initSuccess = createAction(
    '[product-options] Init Success',
    props<{ response: any }>()
  );

  export const changeField = createAction(
    '[product-options] Change Field',
    props<{ payload: ChangeFieldPayload }>()
  );

  export const changeFieldSuccess = createAction(
    '[product-options] Change Field Success',
    props<{ response: ProductOptionsFieldChangeResponse }>()
  );

  export const tempChangeFieldValue = createAction(
    '[product-options] Temp Change Field Value',
    props<{ payload: TempChangeFieldValuePayload }>()
  );

  export const tempChangeNumericField = createAction(
    '[product-options] Temp Change Numeric Field',
    props<{ payload: TempChangeNumericFieldPayload }>()
  );

  export const tempChangeDropdownField = createAction(
    '[product-options] Temp Change Dropdown Field',
    props<{ payload: TempChangeDropdownPayload }>()
  );

  export const tempChangeCheckboxField = createAction(
    '[product-options] Temp Change Checkbox Field',
    props<{ payload: TempChangeCheckboxPayload }>()
  );

  export const invalidField = createAction(
    '[product-options] Invalid Field',
    props<{ payload: FieldId }>()
  );

  export const choiceOverride = createAction(
    '[product-options] Choice Override',
    props<{ payload: any }>()
  );

  export const addCustomOption = createAction(
    '[product-options] Add Custom Option',
    props<{ payload: any }>()
  );

  export const addCustomOptionSuccess = createAction(
    '[product-options] Add Custom Option Success',
    props<{ response: any }>()
  );

  export const deleteCustomOption = createAction(
    '[product-options] Delete Custom Option',
    props<{ payload: any }>()
  );

  export const deleteCustomOptionSuccess = createAction(
    '[product-options] Delete Custom Option Success',
    props<{ response: any }>()
  );

  export const changePage = createAction(
    '[product-options] Change Page',
    props<{ pageId: string }>()
  );
  export const tempChangeAttributeValue = createAction(
    '[product-options] Change Attribute Value',
    props<{ payload: {
      categoryId: string,
      parentSubcatId: string,
      subcatId: string,
      fieldId: string,
      choiceId?: string,
      attributeName: string,
      fieldContainer: FieldContainer.Product,
      value: string }}>()
  );
  export const changeAttribute = createAction(
    '[product-options] Change Attribute',
    props<{ payload: ChangeAttributePayload}>()
  );
}
