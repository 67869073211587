<ng-container *ngIf="categories$ | async">
  <ng-container *ngIf="product$ | async as product">
    <div *ngIf="product.description" class="product-options-title">
      <h1 class="product-options-title__title">{{ product.description }}</h1>
      <span class="product-options-title__override">
        <button *ngIf="!product.displayLoading && canOverride(product)" test-id="OVERRIDE_BUTTON" (click)="openOverrideDialog(product)">
          <span><i class="fas fa-edit"></i></span>
        </button>
        <span><cbs-loading [display]="product.displayLoading"></cbs-loading></span>
      </span>
    </div>
  </ng-container>
</ng-container>
