import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {getDocumentLinks, getIfeDomain, getPrivileges, getSelectedDocLinks, isDocLinksRequestInProcess, State} from '../reducers/index';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {DocumentLink} from '../models/DocumentLink';
import {dispatchFetchDocLinksAction} from '../actions/documents-action-helpers';
import {HeaderComponent} from './header.component';
import urlJoin from 'proper-url-join';
import {map} from 'rxjs/operators';
import {assignFirstTruthy, selectFirstTruthy} from '../util/rx-utils';
import {DocumentsActions} from '../actions/documents.action';

@Component({
  selector: 'cbs-documents',
  templateUrl: './documents.component.html'
})
export class DocumentsComponent implements OnInit {

  docLinks$: Observable<DocumentLink[]>;
  loading$: Observable<boolean>;
  viewDocsPrivilege$: Observable<boolean>;
  selectedDocLinks$: Observable<any>;
  ifeDomain: string;

  constructor(private store: Store<State>, private dialog: MatDialog) {
  }

  ngOnInit() {
    dispatchFetchDocLinksAction(this.store);
    this.docLinks$ = this.store.select(getDocumentLinks);
    this.selectedDocLinks$ = this.store.select(getSelectedDocLinks);
    this.loading$ = this.store.select(isDocLinksRequestInProcess);
    assignFirstTruthy(this.store, getIfeDomain, ifeDomain => this.ifeDomain = ifeDomain);
    this.viewDocsPrivilege$ = selectFirstTruthy(this.store, getPrivileges)
      .pipe(map(privileges => privileges['viewDocsPrivilege']));
  }

  getFileType(extension: string) {
    switch (extension) {
      case 'docx':
        return 'doc';
      case 'xlsx':
        return 'xls';
      case 'text':
        return 'txt';
      case 'pptx':
        return 'ppt';
      case 'jpeg':
        return 'jpg';
      default:
        return extension.slice(0, 5);
    }
  }

  openHeader() {
    this.dialog.open(HeaderComponent, {
      panelClass: 'modal',
    });
  }

  selectedDocs(docLinks, selectedDocLinks): boolean {
    return docLinks.filter(docLink => selectedDocLinks[docLink.labelId]).length > 0;
  }

  updateSelectedDocLink(docLink, checked, selectedDocLinks) {
    const newSelectedDocLinks = {};
    Object.keys(selectedDocLinks).forEach(key => {
      if (key === docLink.labelId) {
        newSelectedDocLinks[key] = checked;
      } else {
        newSelectedDocLinks[key] = selectedDocLinks[key];
      }
    });
    this.store.dispatch(DocumentsActions.updateSelectedDocLinks({links: newSelectedDocLinks}));
  }

  downloadSelectedUrl(downloadType: string, selectedDocLinks) {
    let combinePdfString = this.ifeDomain + '/config/savePDFDocumentsConfigurator';
    combinePdfString += '?downloadType=' + downloadType;
    Object.keys(selectedDocLinks).filter(key => selectedDocLinks[key]).forEach(key => combinePdfString += '&savePDF=' + key);
    window.location.href = combinePdfString;
  }

  updateAllSelectedDocs(checked: boolean, selectedDocLinks) {
    const newSelectedDocLinks = {};
    Object.keys(selectedDocLinks).forEach(key => {
      newSelectedDocLinks[key] = checked;
    });
    this.store.dispatch(DocumentsActions.updateSelectedDocLinks({links: newSelectedDocLinks}));
  }

  allSelected(selectedDocLinks) {
    return selectedDocLinks.length > 0 && Object.keys(selectedDocLinks).filter(key => !selectedDocLinks[key]).length === 0;
  }

  appendIfe(url: string) {
    if (!this.isExternalDocumentLink(url)) {
      return urlJoin(this.ifeDomain, 'config', url);
    } else {
      return url;
    }
  }

  isExternalDocumentLink(url: string): boolean {
    return url && (url.includes('http'));
  }
}
