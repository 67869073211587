import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'cbs-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent {

  confirmationPrompt: string;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.confirmationPrompt = data ? data.confirmationPrompt : '';
  }

  clickYes() {
    this.dialogRef.close(true);
  }

  clickNo() {
    this.dialogRef.close(false);
  }

}
