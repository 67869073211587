<ng-container *ngIf="!(saveInProgress$ | async); else saveInProgress">
  <ng-container [ngSwitch]="saveMenuType$ | async">

    <ng-container *ngSwitchCase="'SAVE_BUTTON'">
      <ng-container *ngIf="savePrivilege$ | async; then saveButton">
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'SAVE_DROPDOWN'">
      <ng-container *ngIf="savePrivilege$ | async; then saveDropdown">
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'SAVE_DROPDOWN_WITHOUT_ITEM_CONTAINER'">
      <ng-container *ngIf="savePrivilegeWithoutItemContainer$() | async; then saveDropdownWithoutItemContainer">
      </ng-container>
    </ng-container>

  </ng-container>

  <ng-template #saveButton>
    <button class="c-button" (click)="processSave()">
      <i class="far fa-save fa-sm m-r-base"></i>
      <span>{{'save' | cbsLabel}}</span>
    </button>
  </ng-template>

  <ng-template #saveDropdown>
    <cbs-hover-menu [menu]="{childLinks: [
        {description: 'save' | cbsLabel, clickFunction: processSave, popup: false},
        {description: 'saveAs' | cbsLabel, clickFunction: openSaveAsPopup, popup: false},
        {description: 'createNewQuote_Intuitive_' | cbsLabel, clickFunction: openNewQuoteOrOrderWindow, functionInput: newQuoteLink, popup: true, display:!!createNewQuotePrivilege},
        {description: 'saveToNewOrder' | cbsLabel, clickFunction: openNewQuoteOrOrderWindow, functionInput: newOrderLink, popup: true, display:!!(saveToNewOrderAllowed$|async)}]}">
      <button class="c-button c-button--dropdown">
        <i class="far fa-save fa-sm m-r-base"></i>
        <span>{{'save' | cbsLabel}}</span>
      </button>
    </cbs-hover-menu>
  </ng-template>

  <ng-template #saveDropdownWithoutItemContainer>
    <cbs-hover-menu [menu]="{childLinks: [
        {description: 'saveToNewProject' | cbsLabel, clickFunction: openSaveToNewProjectDialog, popup: false, display:!!(saveToNewProjectAllowed$|async)},
        {description: 'saveToDefaultQuote' | cbsLabel, clickFunction: processSave, popup: false, display:!!(savePrivilege$|async)},
        {description: 'createNewQuote_Intuitive_' | cbsLabel, clickFunction: openNewQuoteOrOrderWindow, functionInput: newQuoteLink, popup: true, display:!!createNewQuotePrivilege},
        {description: 'saveToNewOrder' | cbsLabel, clickFunction: openNewQuoteOrOrderWindow, functionInput: newOrderLink, popup: true, display:!!(saveToNewOrderAllowed$|async)}]}">
      <button class="c-button c-button--dropdown">
        <i class="far fa-save fa-sm m-r-base"></i>
        <span>{{'save' | cbsLabel}}</span>
      </button>
    </cbs-hover-menu>
  </ng-template>

</ng-container>

<ng-template #saveInProgress>
  <button class="c-button">
    <cbs-loading [display]="true" [diameter]="15" class="m-r-base"></cbs-loading>
    <span>{{'savingMessage' | cbsLabel}}</span>
  </button>
</ng-template>
