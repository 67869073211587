import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, Injectable, NgModule} from '@angular/core';
import {CbsRollbarService} from './cbs-rollbar.service';

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {

  constructor(private cbsRollbar: CbsRollbarService) {
  }

  handleError(err: any): void {
    this.cbsRollbar.error(err.originalError || err);
  }
}

@NgModule({
  imports: [BrowserModule],
  declarations: [],
  bootstrap: [],
  providers: [
    // {provide: ErrorHandler, useClass: RollbarErrorHandler},
    CbsRollbarService,
  ]
})
export class RollbarModule {
}
