<div>
  <ng-container *ngIf="viewDocsPrivilege$|async; else noViewDocsPrivilege">
    <table class="c-table--documents">
      <thead>
      <tr>
        <th colspan="4">{{'documents' | cbsLabel}}</th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngIf="loading$|async; else documentLinks">
        <tr>
          <td colspan="4" class="text-center">
            <cbs-loading [display]="true" [diameter]="50" [strokeWidth]="5"></cbs-loading>
          </td>
        </tr>
      </ng-container>
      <ng-template #documentLinks>
        <tr style="width: 2rem">
          <ng-container *ngIf="selectedDocLinks$|async as selectedDocLinks">
            <td>
              <div class="c-switch">
                <input type="checkbox" id="selectAll" [checked]="allSelected(selectedDocLinks)"
                       (change)="updateAllSelectedDocs(selectAll.checked, selectedDocLinks)" #selectAll>
                <label for="selectAll" class="m-none"></label>
              </div>
            </td>
            <td colspan="3"><h3 class="m-none">Select All</h3></td>
          </ng-container>
        </tr>
        <tr *ngFor="let docLink of docLinks$ | async">
          <td style="width: 1.5em">
            <ng-container *ngIf="selectedDocLinks$|async as selectedDocLinks">
              <div class="c-switch" *ngIf="docLink.downloadableFile">
                <input type="checkbox" [id]="docLink.labelId" [checked]="selectedDocLinks[docLink.labelId]"
                       (change)="updateSelectedDocLink(docLink, select.checked, selectedDocLinks)" #select>
                <label [for]="docLink.labelId" class="m-none"></label>
              </div>
            </ng-container>
          </td>
          <td style="width: 2em">
            <h3 class="m-none">
              <a [href]="appendIfe(docLink.url)" target="_blank" name="{{docLink.labelId}}-file" id="{{docLink.labelId}}-file">
                <span class="fa-layers fa-fw">
                  <i class="far fa-file fa-lg">
                  </i>
                  <span class="fa-layers-text" data-fa-transform="shrink-10 down-3">{{getFileType(docLink.extension)}}</span>
                </span>
              </a>
            </h3>
          </td>
          <td style="width: 2em" *ngIf="docLink.directRtfUrl">
            <h3 class="m-none">
              <a [href]="appendIfe(docLink.directRtfUrl)" target="_blank" name="{{docLink.labelId}}-rtf" id="{{docLink.labelId}}-rtf">
              <span class="fa-layers fa-fw">
                <i class="far fa-file fa-lg">
                </i>
                <span class="fa-layers-text" data-fa-transform="shrink-10 down-3">rtf</span>
              </span>
              </a>
            </h3>
          </td>
          <td [attr.colspan]="docLink.directRtfUrl ? '1' : '2'">
            <a [href]="appendIfe(docLink.url)" target="_blank" name="{{docLink.labelId}}" id="{{docLink.labelId}}">
              <h3 class="m-none">
                <span style="color: black">
                {{docLink.description ? docLink.description : docLink.labelId | cbsLabel}}
              </span>
              </h3>
            </a>
          </td>
        </tr>
      </ng-template>
      </tbody>
      <tfoot *ngIf="!(loading$|async)">
      <tr>
        <ng-container *ngIf="selectedDocLinks$|async as selectedDocLinks">
          <ng-container *ngIf="docLinks$|async as docLinks">
            <td colspan="4">
              <div class="flex-grid flex-shrink">
                <button class="c-button" [disabled]="!selectedDocs(docLinks, selectedDocLinks)" test-id="GENERATE_PDF_BUTTON"
                        (click)="downloadSelectedUrl('pdf', selectedDocLinks)">
                  <span class="fas fa-edit"></span> {{'generatePDF' | cbsLabel}}
                </button>
                <button class="c-button" style="margin-right: auto" [disabled]="!selectedDocs(docLinks, selectedDocLinks)" test-id="GENERATE_ZIP_BUTTON"
                        (click)="downloadSelectedUrl('zip', selectedDocLinks)">
                  <span class="fas fa-edit"></span> {{'generateZIP' | cbsLabel}}
                </button>
                <button class="c-button" test-id="EDIT_HEADER_BUTTON" (click)="openHeader()">
                  <span class="fas fa-edit"></span> {{'editHeaderInformation' | cbsLabel}}
                </button>
              </div>
            </td>
          </ng-container>
        </ng-container>
      </tr>
      </tfoot>
    </table>
  </ng-container>
  <ng-template #noViewDocsPrivilege>
    <div class="text-center">
      <h1>Access Denied</h1>
      <p>Missing privilege: View Print And Documents</p>
    </div>
  </ng-template>
</div>
