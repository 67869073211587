import {createAction, props} from '@ngrx/store';
import {
  ChangeFieldPayload, TempChangeCheckboxPayload, TempChangeDropdownPayload, TempChangeFieldValuePayload, TempChangeNumericFieldPayload
} from './field.action';

export namespace HeaderActions {
  export const init = createAction(
    '[header] Init',
    props<{ response: any }>()
  );

  export const localUpdate = createAction(
    '[header] Local Change',
    props<{ payload: ChangeFieldPayload }>()
  );

  export const changeFieldSuccess = createAction(
    '[header] Change Field Success',
    props<{ response: any }>()
  );

  export const submit = createAction(
    '[header] Submit',
    props<{ payload: any }>()
  );

  export const reset = createAction(
    '[header] Reset'
  );

  export const tempChangeFieldValue = createAction(
    '[header] Temp Change Field Value',
    props<{ payload: TempChangeFieldValuePayload }>()
  );

  export const tempChangeNumericField = createAction(
    '[header] Temp Change Numeric Field',
    props<{ payload: TempChangeNumericFieldPayload }>()
  );

  export const tempChangeDropdownField = createAction(
    '[header] Temp Change Dropdown Field',
    props<{ payload: TempChangeDropdownPayload }>()
  );

  export const tempChangeCheckboxField = createAction(
    '[header] Temp Change Checkbox Field',
    props<{ payload: TempChangeCheckboxPayload }>()
  );
}
