import {Component, Input} from '@angular/core';
import {Field} from '../models/Field';
import {Store} from '@ngrx/store';
import {FieldContainer} from '../models/FieldContainer';
import {State} from '../reducers/index';
import {ValidateFieldService} from '../services/validate-field.service';
import {FieldActions} from '../actions/field.action';

@Component({
  selector: 'cbs-spinner',
  templateUrl: './spinner.component.html',
  styles: [`
    :host {
      display: flex;
      flex-direction: column;
      margin-left: 0.25rem;
    }
  `]
})
export class SpinnerComponent {

  @Input() field: Field;
  @Input() parentSubcatId: string;
  @Input() categoryId: string;
  @Input() subcatId: string = null;
  @Input() pageId: string;
  @Input() fieldContainer: FieldContainer;

  constructor(private store: Store<State>, private validateFieldService: ValidateFieldService) {
  }

  stepSpinner(shouldAdd: boolean) {
    const newNumericVal = this.getNewVal(shouldAdd, this.field.numericValue);
    if (this.isValid(newNumericVal)) {
      const displayValue = this.getRoundedAndFormattedVal(newNumericVal);
      this.submitSpinnerUpdate(newNumericVal, displayValue);
    }
  }

  private isValid(numericVal: number) {
    const spinner = this.field.spinner;
    return spinner.minValue <= numericVal && numericVal <= spinner.maxValue;
  }

  private getNewVal(shouldAdd: boolean, currentVal: number) {
    const spinner = this.field.spinner;
    if (shouldAdd) {
      return currentVal + spinner.increment;
    } else {
      return currentVal - spinner.increment;
    }
  }

  submitSpinnerUpdate(numericValue: number, displayValue: string) {
    this.store.dispatch(FieldActions.tempChangeNumericField({
      fieldId: this.field.id,
      parentSubcatId: this.parentSubcatId,
      categoryId: this.categoryId,
      value: displayValue,
      subcatId: this.subcatId,
      numericValue: numericValue,
      fieldContainer: this.fieldContainer,
      pageId: this.pageId
    }));
    if (this.validateFieldService.validateParameters([[this.field.httpNumericValueName, displayValue]])) {
      this.store.dispatch(FieldActions.changeField({
        fieldId: this.field.id,
        parentSubcatId: this.parentSubcatId,
        categoryId: this.categoryId,
        subcatId: this.subcatId,
        changeParams: this.createChangeParams(numericValue),
        fieldContainer: this.fieldContainer,
        pageId: this.pageId
      }));
    } else {
      this.store.dispatch(FieldActions.invalidField({
        categoryId: this.categoryId,
        parentSubcatId: this.parentSubcatId,
        subcatId: this.subcatId,
        fieldId: this.field.id,
        fieldContainer: this.fieldContainer,
        pageId: this.pageId
      }));
    }
  }

  private createChangeParams(numericValue: number) {
    const formattedValue = numericValue.toString().replace('.', this.field.spinner.decimalSymbol);
    return [[this.field.httpNumericValueName, formattedValue]];
  }

  private getRoundedAndFormattedVal(newVal: number): string {
    const spinner = this.field.spinner;
    let maxFractionDigits = spinner.defaultMaxFractionDigits;
    let minFractionDigits = spinner.defaultMinFractionDigits;
    if (spinner.shouldUseDecimalLimits === true) {
      if (newVal < spinner.decimalLimitBaseValue) {
        maxFractionDigits = spinner.precisionBelowBaseValue;
        minFractionDigits = spinner.precisionBelowBaseValue;
      } else {
        maxFractionDigits = spinner.precisionAboveBaseValue;
        minFractionDigits = spinner.precisionAboveBaseValue;
      }
    }

    let newFormattedVal = Number(newVal).toLocaleString('en-US', {
      minimumFractionDigits: minFractionDigits,
      maximumFractionDigits: maxFractionDigits
    });
    newFormattedVal = this.applyUserGroupingAndDecimalSymbols(newFormattedVal, spinner);
    return newFormattedVal;
  }

  private applyUserGroupingAndDecimalSymbols(val: string, spinner: any) {
    return val.replace(/[.,]/g, function (m) {
      switch (m) {
        case '.':
          return spinner.decimalSymbol;
        case ',':
          return spinner.digitGroupingSymbol;
      }
    });
  }
}
