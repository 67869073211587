import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {Menu} from '../models/Menu';
import {WindowService} from '../services/window.service';
import {isNullOrUndefined} from '../util/js-utils';

@Component({
  selector: 'cbs-hover-menu',
  templateUrl: './hover-menu.component.html',
  styles: [`
    .mat-inner-content {
      padding-top: 8px;
      padding-bottom: 8px
    }

    .hover-menu-item--disabled {
      pointer-events: none;
      cursor: default;
    }
  `]
})
export class HoverMenuComponent {
  @Input() menu: Menu;
  @ViewChild('menuTrigger', {static: false}) menuTrigger: MatMenuTrigger;
  @ViewChild('hoverButton', {static: false}) button: ElementRef;
  onDescription = false;
  onMenu = false;

  constructor(private windowService: WindowService) {
  }

  openMenu(trigger: string) {
    if (this.isMobile()) {
      return;
    }
    if (!(this.onDescription || this.onMenu)) {
      this.menuTrigger.openMenu();
    }
    if (trigger === 'description') {
      this.onDescription = true;
    } else if (trigger === 'menu') {
      this.onMenu = true;
    }
  }

  closeMenu(trigger: string) {
    if (this.isMobile()) {
      return;
    }
    if (trigger === 'description') {
      this.onDescription = false;
    } else if (trigger === 'menu') {
      this.onMenu = false;
    }
    setTimeout(() => {
      if (!(this.onDescription || this.onMenu)) {
        this.menuTrigger.closeMenu();
        this.button.nativeElement.blur();
      }
    }, 100);
  }

  display(menuItem) {
    return isNullOrUndefined(menuItem.display) || menuItem.display;
  }

  isMobile() {
    return this.windowService.getWidth() <= 640;
  }
}
