import {Store} from '@ngrx/store';
import {getLabelOverrides, getLabels, State} from '../reducers/index';
import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class LabelService {
  labelsSubject = new BehaviorSubject([{}, {}]);

  constructor(private store: Store<State>) {
    combineLatest([
      this.store.select(getLabelOverrides),
      this.store.select(getLabels)
    ]).subscribe(([overrides, labels]) => {
      this.labelsSubject.next([overrides, labels]);
    });
  }

  get(key: string): Observable<string> {
    return this.labelsSubject.pipe(map(([overrides, labels]) => {
      if (overrides && overrides[key]) {
        key = overrides[key];
      }
      return labels.hasOwnProperty(key) ? labels[key] : '';
    }));
  }
}
