<mat-dialog-content>
  <div *ngIf="discounts$|async as discountObj; else noDiscounts" class="text-center">
    <div class="flex-grid">
      <!--COLUMN 1-->
      <div *ngIf="is3Col(discountObj)">
        <table>
          <ng-container *ngIf="filteredColumn(discountObj, 1) as columnList">
            <th class="cbs-section-header" colSpan="{{displayMargin ? '3' : '2'}}">
              {{'Intercompany' | cbsLabel}}
            </th>
            <ng-container *ngTemplateOutlet="discountTableTemplate; context: {columnList: columnList}"></ng-container>
          </ng-container>
        </table>
      </div>
      <!--COLUMN 2-->
      <div>
        <table>
          <ng-container *ngIf="is3Col(discountObj) ? filteredColumn(discountObj, 2) : filteredColumn(discountObj)  as columnList">
            <th *ngIf="is3Col(discountObj)" class="cbs-section-header" colSpan="{{displayMargin ? '3' : '2'}}">
              {{'EquipmentAndAccessories' | cbsLabel}}
            </th>
            <ng-container *ngTemplateOutlet="discountTableTemplate; context: {columnList: columnList}"></ng-container>
          </ng-container>
        </table>
      </div>
      <!--COLUMN 3-->
      <div *ngIf="is3Col(discountObj)">
        <table>
          <ng-container *ngIf="filteredColumn(discountObj, 3)  as columnList">
            <th class="cbs-section-header" colSpan="{{displayMargin ? '3' : '2'}}">
              {{'orderRelatedEnggAndProjectMgmt' | cbsLabel}}
            </th>
            <ng-container *ngTemplateOutlet="discountTableTemplate; context: {columnList: columnList}"></ng-container>
          </ng-container>
        </table>
      </div>
    </div>
  </div>
  <ng-template #noDiscounts>{{'noDiscountsAvaliable' | cbsLabel}}</ng-template>
  <ng-template #discountTableTemplate let-columnList="columnList">
    <tr style="font-weight: 600" class="cbs-section-background">
      <td>
        {{'discountClass' | cbsLabel}}
      </td>
      <td>
        {{'override' | cbsLabel}}
      </td>
      <td *ngIf="displayMargin">
        {{'distributorMargin' | cbsLabel}}
      </td>
    </tr>
    <ng-container *ngFor="let groupId of groupIdList(columnList); trackBy: groupId">
      <tr *ngIf="groupId !== ''" class="cbs-section-background">
        <td colSpan="{{displayMargin ? '3' : '2'}}">
          {{groupId}}
        </td>
      </tr>
      <tr *ngFor="let discount of discountSubList(columnList, groupId); trackBy: discount" class="cbs-card-background">
        <ng-container *ngTemplateOutlet="discountRowTemplate; context: {discount: discount}"></ng-container>
      </tr>
    </ng-container>
  </ng-template>
  <ng-template #discountRowTemplate let-discount="discount">
    <td>
      {{discount.description}}
    </td>
    <td>
      <div style="min-width: 100px">
        <mat-checkbox [ngStyle]="{'display': isEditable(discount) ? '' : 'none'}" class="m-r-base"
                      [checked]="discount.overrideType === 'Item'" test-id="EDIT_OVERRIDE_FIELD"
                      (change)="updateOverrideType(discount, editOverride.checked, overrideInput.value)" #editOverride></mat-checkbox>
        <mat-form-field style="width: 4rem" class="slim-input" test-id="DISCOUNT_VALUE_FIELD"
                        [ngStyle]="{'display': (isEditable(discount) && editOverride.checked) ? 'inline-block' : 'none'}">
          <input matInput [value]="determineDiscountValue(discount).toFixed(2)"
                 style="padding: 0" (blur)="updateDiscount(discount, overrideInput.value)" #overrideInput>
        </mat-form-field>
        <ng-container *ngIf="!editOverride || !editOverride.checked">
          {{discountDefaultDisplay(discount).toFixed(2)}}
        </ng-container>
      </div>
    </td>
    <td *ngIf="displayMargin">
      <mat-form-field style="width: 4rem; margin: auto;" class="slim-input" *ngIf="editResellerMargin; else disabledResellerMargin"
                      test-id="CHANGE_MARGIN_FIELD">
        <input matInput [value]="discount.resellerMargin > 0 ? discount.resellerMargin.toFixed(2) : ''" style="padding: 0"
               (change)="updateMargin(discount, marginInput.value)" #marginInput>
        <span matSuffix>%</span>
      </mat-form-field>
      <ng-template #disabledResellerMargin>
        {{discount.resellerMargin.toFixed(2)}}%
      </ng-template>
    </td>
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions>
  <div style="flex: auto; display: grid; align-items: center; grid-template-columns: minmax(0, 1fr) auto 1fr">
    <div></div>
    <div>
      <button class="c-button" test-id="APPLY_BUTTON" (click)="apply()">{{'apply' | cbsLabel}}</button>
      <button class="c-text-button" test-id="CLOSE_BUTTON" (click)="resetDiscounts()"
              mat-dialog-close>{{'closeButtonText' | cbsLabel}}</button>
    </div>
    <div class="text-right text-nowrap" style="font-size: 18px">
      <cbs-loading [display]="isLoading"></cbs-loading>
      <span *ngIf="!isLoading">{{'price' | cbsLabel}}: {{price$| async}}</span>
    </div>
  </div>
</mat-dialog-actions>
