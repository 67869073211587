import {Field} from '../models/Field';
import {Product} from '../models/Product';
import {createReducer, on} from '@ngrx/store';
import {HeaderActions} from '../actions/header.action';
import {ProductOptionActions} from '../actions/product-options.action';
import {DiscountActions} from '../actions/discount.action';
import {CbsActions} from '../actions/cbs.action';
import {updateCheckbox, updateDropdown, updateFieldNumericValue, updateFieldValue} from '../util/immer-utils';
import produce from 'immer';

export interface HeaderState {
  headers: Field[];
  price: string;
  leadTimeTotal: string;
  committedHeaders: Field[];
  savedItemNumber: string;
  product?: Product;
}

const initialState: HeaderState = {
  headers: null,
  price: '',
  leadTimeTotal: '',
  committedHeaders: null,
  savedItemNumber: null,
  product: null
};

export const headerReducer = createReducer(
  initialState,
  on(HeaderActions.init, (state, {response}) => ({
    ...state,
    headers: response.headers,
    price: response.extendedSellPrice,
    leadTimeTotal: response.leadTimeTotal,
    committedHeaders: response.headers,
    savedItemNumber: response.savedItemNumber,
    product: response.currentProduct
  })),
  on(HeaderActions.changeFieldSuccess, (state, {response}) => ({
    ...state,
    headers: response.headers,
    price: response.extendedSellPrice,
    committedHeaders: response.headers
  })),
  on(HeaderActions.tempChangeFieldValue, (state, {payload}) => updateFieldValue(state, payload)),
  on(HeaderActions.tempChangeNumericField, (state, {payload}) => {
    state = updateFieldValue(state, payload);
    return updateFieldNumericValue(state, payload);
  }),
  on(HeaderActions.tempChangeDropdownField, (state, {payload}) => updateDropdown(state, payload)),
  on(HeaderActions.tempChangeCheckboxField, (state, {payload}) => updateCheckbox(state, payload)),
  on(HeaderActions.reset, (state) => ({...state, headers: state.committedHeaders})),
  on(
    ProductOptionActions.changeFieldSuccess,
    ProductOptionActions.deleteCustomOptionSuccess,
    DiscountActions.updateDiscountSuccess,
    (state, {response}) => ({
      ...state,
      price: response.extendedSellPrice,
      leadTimeTotal: response.leadTimeTotal,
      product: response.currentProduct
    })
  ),
  on(ProductOptionActions.choiceOverride, (state, {payload}) => {
    if (payload.isProduct) {
      return setProductDisplayLoading(state);
    }
    return state;
  }),
  on(
    CbsActions.itemSaveSuccess,
    CbsActions.itemSaveAsSuccess,
    CbsActions.saveToNewQuoteSuccess,
    CbsActions.saveToNewOrderSuccess,
    CbsActions.saveToNewProjectSuccess,
    (state, {response}) => ({
      ...state,
      headers: response.headers,
      committedHeaders: response.headers,
      savedItemNumber: response.savedItemNumber,
      price: response.extendedSellPrice
    })
  )
);

export class HeaderSelectors {
  static getHeaderInfo = (state: HeaderState) => state.committedHeaders;
  static getHeaders = (state: HeaderState) => state.headers;
  static getPrice = (state: HeaderState) => state.price === 'notAvailable' ? '' : state.price;
  static getLeadTimeTotal = (state: HeaderState) => state.leadTimeTotal;
  static getSavedItemNumber = (state: HeaderState) => state.savedItemNumber;
  static getProduct = (state: HeaderState) => state.product;

}

export function setProductDisplayLoading(state: HeaderState): HeaderState {
  return produce(state, draftState => {
    if (draftState.product) {
      draftState.product.displayLoading = true;
    }
  });
}
