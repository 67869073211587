import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {getWorkflowId, State} from '../reducers/index';
import {Store} from '@ngrx/store';
import {CbsRollbarService} from '../rollbar/cbs-rollbar.service';
import {Router} from '@angular/router';
import {selectFirstTruthy} from '../util/rx-utils';

@Component({
  selector: 'app-workflow-dispatcher-route',
  templateUrl: './workflow-dispatcher-route.component.html',
  encapsulation: ViewEncapsulation.None
})
export class WorkflowDispatcherRouteComponent implements OnInit {

  constructor(private store: Store<State>, private rollbar: CbsRollbarService, private router: Router) {
  }

  ngOnInit() {
    selectFirstTruthy(this.store, getWorkflowId)
      .subscribe(workflowId => {
        return this.router.navigate([this.mapWorkflowToRoute(workflowId)]);
      });
  }

  mapWorkflowToRoute(workflowId: string) {
    let route = workflowRoutes[workflowId];
    if (!route) {
      route = '/cos';
      this.rollbar.error(null, new Error('Cannot determine workflow route: ' + workflowId), {workflowId});
    }
    return route;
  }
}

const workflowRoutes = {
  productOptionsOnly: '/product-options',
  cosFilterSearch: '/cos-filter-search',
  cosSearchMultiPage: '/cos',
  cosProductOptionsSinglePage: '/cos-product-options',
  basicPartsSearch: 'parts-search'
};
