import {combineLatest, from, Observable} from 'rxjs';
import {Component, OnInit} from '@angular/core';
import {getIfeDomain, getPrivileges, getViewAllInstancesUrl, State} from '../reducers/index';
import {select, Store} from '@ngrx/store';

import {CbsService} from '../services/web/cbs.service';
import urlJoin from 'proper-url-join';
import {ValidateFieldService} from '../services/validate-field.service';
import {map, take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {getDisplayBreadcrumbs} from '../reducers';
import {selectFirstTruthy} from '../util/rx-utils';
import {SafeStore} from '../util/safe-store';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'cbs-debug-menu',
  templateUrl: './debug-menu.component.html'
})
export class DebugMenuComponent implements OnInit {
  viewAllInstancesUrl$: Observable<string>;
  viewCustomActionXml$: Observable<string>;
  viewItemXml$: Observable<string>;
  viewQuoteXml$: Observable<string>;
  viewPdfXml$: Observable<string>;
  viewPageXml$: Observable<string>;
  currentPage: string;
  viewOrgSetupXml$: Observable<string>;
  viewOutputElements$: Observable<string>;
  viewSelectedOptions$: Observable<string>;
  viewKELogs$: Observable<string>;
  viewPerfLogs$: Observable<string>;
  viewLogsPrivilege$: Observable<boolean>;
  displayMenu: boolean;
  displayAdvCos: boolean;
  displayAdvOptionSelection: boolean;
  displaySearchCombinations: boolean;
  advConfigCos$: Observable<string>;
  advConfigMos$: Observable<string>;
  searchCombinationsLog$: Observable<string>;
  private safeStore: SafeStore<State>;

  constructor(private store: Store<State>,
              private cbsService: CbsService,
              private validateFieldService: ValidateFieldService,
              private router: Router) {
  }

  envAppend = (target) => {
    return this.store.pipe(select(getIfeDomain),
      map(ifeDomain => urlJoin(ifeDomain, target)));
  };

  ngOnInit() {
    this.safeStore = new SafeStore(this, this.store);
    this.viewAllInstancesUrl$ = this.store.pipe(select(getViewAllInstancesUrl));
    this.viewLogsPrivilege$ = selectFirstTruthy(this.store, getPrivileges)
      .pipe(map(privileges => privileges['viewKeOrAllLogsPrivilege']));
    this.displayMenu = false;
    this.advConfigCos$ = this.envAppend('/config/configtop?targetFrame=top&target=cos');
    this.advConfigMos$ = this.envAppend('/config/app/configtop?target=mainoptionselection');
    this.searchCombinationsLog$ = this.envAppend('/config/logview?viewType=combinations-logs');
    this.viewCustomActionXml$ = this.envAppend('/config/customActionCreateSelectorItem.jsp');
    this.viewItemXml$ = this.envAppend('/config/showitemxml?getXML=download');
    this.viewQuoteXml$ = this.envAppend('/config/showquotexml?getXML=download');
    this.viewPdfXml$ = this.envAppend('/config/app/pdfxml');
    this.viewOrgSetupXml$ = this.envAppend('/config/orgsetupxml?getXML=download');
    this.viewOutputElements$ = this.envAppend('/config/logview?viewType=output-elements');
    this.viewSelectedOptions$ = this.envAppend('/config/logview?viewType=options-selected');
    this.viewKELogs$ = this.envAppend('/config/logview?viewType=ke-logs');
    this.viewPerfLogs$ = this.envAppend('/config/logview?viewType=perf-logs');
    this.updateDebugMenuDisplay();
    this.router.events.pipe(untilDestroyed(this)).subscribe(() => this.updateDebugMenuDisplay());
    this.viewPageXml$ = combineLatest([this.store.pipe(select(getDisplayBreadcrumbs)),
      this.store.pipe(select(getIfeDomain))])
      .pipe(map(([breadCrumb, ifeDomain]) => urlJoin(ifeDomain, '/config/app/' + this.currentPage + '?getXML=download')));
  }

  updateDebugMenuDisplay() {
    this.displayAdvOptionSelection = false;
    this.displayAdvCos = false;
    this.displaySearchCombinations = false;
    switch (this.router.url) {
      case '/cos':
        this.displayAdvCos = true;
        this.currentPage = 'cos';
        from([this.checkCos()]).pipe(take(1)).subscribe((isValid) => this.displayAdvOptionSelection = isValid);
        break;
      case '/product-options':
        this.currentPage = 'mainoptionselection';
        this.displayAdvOptionSelection = true;
        break;
      case '/documents':
        this.currentPage = 'configDocuments';
        this.displayAdvOptionSelection = true;
        break;
      case '/cos-product-options':
        this.currentPage = 'none';
        this.displayAdvOptionSelection = true;
        this.displayAdvCos = true;
        break;
      case '/search':
        this.currentPage = 'searchresults';
        this.displaySearchCombinations = true;
        break;
      case '/cos-filter-search':
        this.currentPage = 'none';
        this.displaySearchCombinations = true;
        this.displayAdvCos = true;
        break;
      case '/parts-search':
      case '/parts-configuration':
      default:
        this.currentPage = 'none';
        break;
    }
  }

  reloadKb = () => {
    this.cbsService.reloadKb();
  };

  checkCos() {
    return this.validateFieldService.diagnosticValidation();
  }

  checkBreadcrumbDisplay(breadcrumbsObj, breadcrumbName: string) {
    return breadcrumbsObj[breadcrumbName] && breadcrumbsObj[breadcrumbName].display;
  }

}
