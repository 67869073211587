import {Labels} from '../models/Labels';
import {createReducer, on} from '@ngrx/store';
import {LabelActions} from '../actions/labels.action';

export interface LabelsState {
  labels: Labels;
}

const initialState: LabelsState = {
  labels: {}
};

export const labelsReducer = createReducer(
  initialState,
  on(LabelActions.init, (state, {labels}) => ({labels}))
);

export class LabelsSelectors {
  static getLabels = (state: LabelsState) => state.labels;
}
