import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {getDiscountChangeList, getDiscountList, getPrice, getPrivileges, getProperties, State} from '../reducers/index';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {Discount} from '../models/Discount';
import {tap} from 'rxjs/operators';
import {WindowService} from '../services/window.service';
import {assignFirstTruthy} from '../util/rx-utils';
import {SafeStore} from '../util/safe-store';
import {DiscountActions} from '../actions/discount.action';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'cbs-item-multiplier',
  templateUrl: './item-multiplier.component.html',
  styles: [
    `:host ::ng-deep .mat-form-field-label {
      padding: 0 16px;
    }

    :host ::ng-deep .mat-form-field-wrapper {
      padding: 0;
      border: 1px solid black;
    }

    :host ::ng-deep .mat-form-field-underline {
      display: none;
    }

    :host ::ng-deep .mat-form-field-suffix {
      width: auto;
    }

    :host ::ng-deep .mat-form-field-flex {
      padding: 0 .25rem;
    }

    th {
      text-align: inherit !important;
    }

    td {
      background-color: inherit !important;
    }

    th, td {
      border: 1px solid #ddd;
    }

    table {
      border: 2px solid #999 !important;
    }`
  ]
})
export class ItemMultiplierComponent implements OnInit {

  discounts$: Observable<Discount>;
  price$: Observable<string>;
  displayMargin: boolean;
  isLoading = false;
  editSellMultiplier: boolean;
  editResellerMargin: boolean;
  private safeStore: SafeStore<State>;

  constructor(public dialogRef: MatDialogRef<ItemMultiplierComponent>,
              private store: Store<State>, private snackBar: MatSnackBar, private windowService: WindowService) {
    this.safeStore = new SafeStore(this, this.store);
  }

  ngOnInit(): void {
    this.discounts$ = this.store.pipe(select(getDiscountList),
      tap(() => this.isLoading = false));
    this.safeStore.subscribe(getProperties, properties => this.displayMargin = properties.itemMultiplier_hideMargin === false);
    this.safeStore.subscribe(getPrivileges, privileges => {
      this.editSellMultiplier = privileges['editSellMultiplier'];
      this.editResellerMargin = privileges['editResellerMargin'];
    });
    this.price$ = this.store.pipe(select(getPrice));
  }

  is3Col(discountObj: Discount) {
    let is3Col = false;
    discountObj.discountIDList.forEach(id => {
      if (discountObj.discountList[id].editColumn > 0) {
        is3Col = true;
      }
    });
    return is3Col;
  }

  filteredColumn(discountObj: Discount, column?: number): any[] {
    const discountColumn = [];
    discountObj.discountIDList.forEach(id => {
      if (discountObj.discountList[id].editColumn === column || !column) {
        discountColumn.push(discountObj.discountList[id]);
      }
    });
    return discountColumn;
  }

  groupIdList(columnList: any[]): string[] {
    const groupNameList = [];
    columnList.forEach(discount => {
      if (!(groupNameList.indexOf(discount.groupName) >= 0)) {
        groupNameList.push(discount.groupName);
      }
    });
    return groupNameList;
  }

  discountSubList(columnList: any[], groupId: string): any[] {
    return columnList.filter(discount => discount.groupName === groupId);
  }

  updateMargin(discount, value: string) {
    this.store.dispatch(DiscountActions.updateDiscount({discount: discount, value: (+value) / 100, attribute: 'OvrDistMargin'}));
  }

  updateDiscount(discount, value) {
    if (discount.discountMinLimit > value || discount.discountMaxLimit < value) {
      this.snackBar.open('Discount must be between ' + discount.discountMinLimit + ' and ' + discount.discountMaxLimit, null, {
        duration: 2000
      });
    } else {
      this.store.dispatch(DiscountActions.updateDiscount({discount: discount, value: value, attribute: 'OvrDiscount'}));
    }
  }

  determineDiscountValue(discount: any) {
    if (discount.itemDiscount > 0) {
      return discount.itemDiscount;
    }
    return this.discountDefaultDisplay(discount);
  }

  isEditable(discount: any) {
    return this.editSellMultiplier && (!discount.limitsRange || (discount.limitsRange.min !== discount.limitsRange.max));
  }

  updateOverrideType(discount: any, value: boolean, overrideInputValue) {
    if (!value) {
      this.updateDiscount(discount, discount.discount);
    } else {
      this.updateDiscount(discount, overrideInputValue);
    }
    this.store.dispatch(DiscountActions.updateDiscount({discount: discount, value: +value, attribute: 'OvrType'}));
  }

  apply() {
    this.isLoading = true;
    assignFirstTruthy(this.store, getDiscountChangeList, changeList => this.store.dispatch(DiscountActions.submitDiscounts({changeList})));
  }

  resetDiscounts() {
    this.store.dispatch(DiscountActions.resetDiscounts());
  }

  discountDefaultDisplay(discount): number {
    return discount.quoteDiscount > 0 ? discount.quoteDiscount : discount.discount;
  }

  isMobile() {
    return this.windowService.getWidth() <= 640;
  }
}
