import {FieldContainer} from '../models/FieldContainer';
import {Action} from '@ngrx/store';
import {ProductOptionActions} from './product-options.action';
import {CosActions} from './cos.action';
import {HeaderActions} from './header.action';
import {EmailRfqActions} from './email-rfq.action';

export namespace FieldActions {
  export const changeField = (payload: ChangeFieldPayload): Action => {
    switch (payload.fieldContainer) {
      case FieldContainer.Product:
        return ProductOptionActions.changeField({payload});
      case FieldContainer.Cos:
        return CosActions.changeField({payload});
      case FieldContainer.Header:
        return HeaderActions.localUpdate({payload});
      case FieldContainer.EmailRfq:
        payload.parentSubcatId = 'EmailRfq'
        return EmailRfqActions.changeField({payload});
    }
  };

  export const tempChangeFieldValue = (payload: TempChangeFieldValuePayload): Action => {
    switch (payload.fieldContainer) {
      case FieldContainer.Product:
        return ProductOptionActions.tempChangeFieldValue({payload});
      case FieldContainer.Cos:
        return CosActions.tempChangeFieldValue({payload});
      case FieldContainer.Header:
        return HeaderActions.tempChangeFieldValue({payload});
      case FieldContainer.EmailRfq:
        return EmailRfqActions.tempChangeFieldValue({payload});
    }
  };

  export const tempChangeNumericField = (payload: TempChangeNumericFieldPayload): Action => {
    switch (payload.fieldContainer) {
      case FieldContainer.Product:
        return ProductOptionActions.tempChangeNumericField({payload});
      case FieldContainer.Cos:
        return CosActions.tempChangeNumericField({payload});
      case FieldContainer.Header:
        return HeaderActions.tempChangeNumericField({payload});
      case FieldContainer.EmailRfq:
        return EmailRfqActions.tempChangeNumericField({payload});
    }
  };

  export const tempChangeDropdownField = (payload: TempChangeDropdownPayload): Action => {
    switch (payload.fieldContainer) {
      case FieldContainer.Product:
        return ProductOptionActions.tempChangeDropdownField({payload});
      case FieldContainer.Cos:
        return CosActions.tempChangeDropdownField({payload});
      case FieldContainer.Header:
        return HeaderActions.tempChangeDropdownField({payload});
      case FieldContainer.EmailRfq:
        return EmailRfqActions.tempChangeDropdownField({payload});
    }
  };

  export const tempChangeCheckboxField = (payload: TempChangeCheckboxPayload): Action => {
    switch (payload.fieldContainer) {
      case FieldContainer.Product:
        return ProductOptionActions.tempChangeCheckboxField({payload});
      case FieldContainer.Cos:
        return CosActions.tempChangeCheckboxField({payload});
      case FieldContainer.Header:
        return HeaderActions.tempChangeCheckboxField({payload});
      case FieldContainer.EmailRfq:
        return EmailRfqActions.tempChangeCheckboxField({payload});
    }
  };

  export const invalidField = (payload: FieldId): Action => {
    switch (payload.fieldContainer) {
      case FieldContainer.Product:
        return ProductOptionActions.invalidField({payload});
      case FieldContainer.Cos:
        return CosActions.invalidField({payload});
    }
  };
}

export interface FieldId {
  fieldId: string;
  parentSubcatId?: string;
  pageId?: string;
  categoryId?: string;
  subcatId?: string,
  fieldContainer: FieldContainer;
}

export interface ChangeFieldPayload extends FieldId {
  parentSubcatId: string;
  changeParams: string[][];
}

export interface ChangeAttributePayload extends FieldId {
  parentSubcatId: string;
  changeParams: string[][];
  choiceId?: string,
  fieldName?: string
}

export interface TempChangeNumericFieldPayload extends FieldId {
  value: string;
  numericValue: number;
}

export interface TempChangeFieldValuePayload extends FieldId {
  value: string;
}

export interface TempChangeDropdownPayload extends FieldId {
  newSelectedChoiceId: string;
  previousSelectedChoiceId: string;
}

export interface TempChangeCheckboxPayload extends FieldId {
  choiceId: string;
  choiceSelected: boolean;
}
