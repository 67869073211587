import {Labels} from '../models/Labels';
import {createAction, props} from '@ngrx/store';

export namespace LabelActions {
  export const init = createAction(
    '[labels] Init',
    props<{ labels: Labels }>()
  );
}

