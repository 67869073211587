import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ProductOptionsInitResponse} from './product-options-init-response';
import {COSInitResponse} from './cos-init-response';
import {Store} from '@ngrx/store';
import {getIfeDomain, State} from '../../reducers/index';
import {ItemSaveResponse} from './item-save-response';
import urlJoin from 'proper-url-join';
import {CbsUserResponseInfo} from './cbs-user-info';
import {CbsRollbarService} from '../../rollbar/cbs-rollbar.service';
import {WindowService} from '../window.service';
import {Labels} from '../../models/Labels';
import {ProductOptionsFieldChangeResponse} from './product-options-field-change-response';
import {DisplayBreadcrumbs} from '../../reducers/workflow.reducer';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {PropertiesState} from '../../reducers/properties.reducer';
import {catchError, map} from 'rxjs/operators';
import {SaveToNewProjectPayload} from '../../actions/cbs.action';
import {SystemProperties} from '../../models/SystemProperties';
import {assignFirstTruthy} from '../../util/rx-utils';
import {DiscountChange} from '../../reducers/discount.reducer';

export type CbsInitResponse = (ProductOptionsInitResponse | COSInitResponse)
  & { labels: Labels }
  & CbsUserResponseInfo
  & { cbsUIInfo: PropertiesState }
  & { breadcrumbs: DisplayBreadcrumbs }
  & { display: string }
  & { systemProperties: SystemProperties }
  & { globalNotificationMessage?: string, orgNotificationMessage?: string };

@Injectable()
export class CbsService {
  private ifeDomain: string;

  constructor(private httpClient: HttpClient,
              private store: Store<State>,
              private rollbar: CbsRollbarService,
              private window: WindowService) {
    assignFirstTruthy(this.store, getIfeDomain, (ifeDomain: string) => this.ifeDomain = ifeDomain);
  }

  init(configInitUrl): Observable<CbsInitResponse> {
    return this.httpClient.get<CbsInitResponse>(configInitUrl, {withCredentials: true});
  }

  saveItem(asyncSave: boolean): Observable<ItemSaveResponse> {
    const options = this.getRequestOptions();
    const saveItemUrl = urlJoin(this.ifeDomain, `/config/api/v1/item/save?savingApplication=Cbs&asyncSave=${asyncSave}`);
    return this.httpClient
      .post(saveItemUrl, null, options).pipe(
        map(data => <ItemSaveResponse>data));
  }

  saveItemAs(changeParams: string[][]): Observable<ItemSaveResponse> {
    return this.saveItemWithParams(changeParams);
  }

  saveToNewQuoteOrOrder(quoteId): Observable<ItemSaveResponse> {
    return this.saveItemWithParams([['quoteId', quoteId]]);
  }

  saveToNewProject(payload: SaveToNewProjectPayload): Observable<ItemSaveResponse> {
    return this.saveItemWithParams([['newProjectName', payload.newProjectName], ['itemNumber', payload.itemNumber]]);
  }

  private saveItemWithParams(changeParams) {
    const options = this.getRequestOptions();
    const saveItemUrl = urlJoin(this.ifeDomain, `/config/api/v1/item/saveAs?savingApplication=Cbs`);
    let urlParams = new HttpParams();
    changeParams.forEach(([key, value]) => {
      urlParams = urlParams.set(key, value);
    });
    return this.httpClient
      .post(saveItemUrl, urlParams, options).pipe(
        map(data => <ItemSaveResponse>data));
  }

  private getRequestOptions() {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    return {headers: headers, withCredentials: true};
  }

  reloadKb() {
    const options = this.getRequestOptions();
    const reloadKbUrl = urlJoin(this.ifeDomain, '/config/api/v1/cbs/reloadKb');
    return this.httpClient
      .post(reloadKbUrl, null, options)
      .pipe(catchError(e => this.rollbar.apiError(e, {reloadKbUrl}, undefined, this.store)))
      .subscribe(r => this.window.getWindow().location.reload());
  }

  search() {
    const options = this.getRequestOptions();
    const searchUrl = urlJoin(this.ifeDomain, '/config/api/v1/cbs/search');
    const urlParams = new HttpParams();
    return this.httpClient.post(searchUrl, urlParams, options);
  }

  filter(params: string[][]) {
    const options = this.getRequestOptions()
    const filterSearchResultsUrl = urlJoin(this.ifeDomain, '/config/api/v1/cbs/search/results/filter');
    let urlParams = new HttpParams();
    params.forEach(([key, value]) => {
      urlParams = urlParams.set(key, value);
    });
    return this.httpClient
      .post(filterSearchResultsUrl, urlParams, options);
  }

  paginate(pageSize: number, pageNumber: number) {
    const options = this.getRequestOptions();
    const paginateSearchResultsUrl = urlJoin(this.ifeDomain, '/config/api/v1/cbs/search/results/paginate');
    let urlParams = new HttpParams();
    urlParams = urlParams.set('pageSize', '' + pageSize);
    urlParams = urlParams.set('pageNumber', '' + pageNumber);
    return this.httpClient
      .post(paginateSearchResultsUrl, urlParams, options);
  }

  select(value: string): Observable<ProductOptionsFieldChangeResponse> {
    const options = this.getRequestOptions();
    const selectUrl = urlJoin(this.ifeDomain, '/config/api/v1/cbs/product');
    let urlParams = new HttpParams();
    urlParams = urlParams.set('classIDs', value);
    return this.httpClient.post(selectUrl, urlParams, options).pipe(
      map(data => <ProductOptionsFieldChangeResponse>data));
  }

  changeLanguage(languageId: number) {
    const options = this.getRequestOptions();
    const changeLanguageUrl = urlJoin(this.ifeDomain, '/config/api/v1/cbs/changeLanguage');
    let urlParams = new HttpParams();
    urlParams = urlParams.set('languageId', '' + languageId);
    return this.httpClient
      .post(changeLanguageUrl, urlParams, options)
      .pipe(catchError(e => this.rollbar.apiError(e, {'url': changeLanguageUrl, 'languageId': languageId}, undefined, this.store)))
      .subscribe(r => this.window.getWindow().location.reload());
  }

  changeUnits(unit: string) {
    const options = this.getRequestOptions();
    const changeLanguageUrl = urlJoin(this.ifeDomain, '/config/api/v1/cbs/changeUnits');
    let urlParams = new HttpParams();
    urlParams = urlParams.set('units', unit);
    return this.httpClient
      .post(changeLanguageUrl, urlParams, options)
      .pipe(catchError(e => this.rollbar.apiError(e, {'url': changeLanguageUrl, 'unit': unit}, undefined, this.store)))
      .subscribe(r => this.window.getWindow().location.reload());
  }

  sortSearchResults(columnId: string) {
    const options = this.getRequestOptions();
    const sortSearchResultsUrl = urlJoin(this.ifeDomain, '/config/api/v1/cbs/search/results/sort?column=' + columnId);
    return this.httpClient
      .get(sortSearchResultsUrl, options)
      .pipe(catchError(e => this.rollbar.apiError(e, {'url': sortSearchResultsUrl, 'column': columnId}, undefined, this.store)));
  }

  updateDiscount(payload: DiscountChange[]) {
    const options = this.getRequestOptions();
    const updateDiscountUrl = urlJoin(this.ifeDomain, '/config/api/v1/cbs/updateDiscount');
    let urlParams = new HttpParams();
    payload.forEach(discount => {
      urlParams = urlParams.set(discount.discountId + '-' + discount.updateAttribute, String(discount.updateValue));
    });
    return this.httpClient
      .post(updateDiscountUrl, urlParams, options)
      .pipe(catchError(e => this.rollbar.apiError(e, {payload}, undefined, this.store)));
  }

  sendRfqEmail(payload) {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    const options = {headers: headers, withCredentials: true};
    const sendEmailRfqUrl = urlJoin(this.ifeDomain, '/config/api/v1/cbs/sendEmailRfq');
    let urlParams = new HttpParams();
    const params = [
      ['sendEmailRfqFrom' , payload.email],
      ['sendEmailRfqName', payload.name],
      ['sendEmailRfqComments', payload.comments]];
    params.forEach(([key, value]) => urlParams = urlParams.set(key, value));
    Object.keys(payload.additionalData).forEach(key  =>
      urlParams = urlParams.set('sendEmailRfqAdditionalInfo_' + key, payload.additionalData[key]));
    return this.httpClient
      .post(sendEmailRfqUrl, urlParams, options)
      .pipe(catchError(e => this.rollbar.apiError(e, {sendEmailRfqUrl, params}, undefined, this.store)))
  }
}
