import {Component, Inject} from '@angular/core';
import {Store} from '@ngrx/store';
import {State} from '../reducers/index';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Product} from '../models/Product';
import {isNullOrUndefined} from '../util/js-utils';
import {getPrivileges} from '../reducers';
import {SafeStore} from '../util/safe-store';
import {FieldContainer} from '../models/FieldContainer';
import {ProductOptionActions} from '../actions/product-options.action';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'cbs-option-override',
  templateUrl: './option-override.component.html',
  styles: [`
    :host {
      display: block;
      width: 50rem;
      max-width: 100%;
    }

    :host ::ng-deep .mat-form-field-label {
      padding: 0 16px;
    }

    :host ::ng-deep .mat-form-field-suffix {
      width: 8rem;
    }

    div.disabled-appearance {
      padding: 0.4375rem 3px;
      vertical-align: middle;
      line-height: 24px;
    }
  `
  ]
})
export class OptionOverrideComponent {

  parentSubcategoryId: number;
  categoryId: string;
  subcatId: string;
  fieldId: string;
  choice;
  overrideDescriptionPrivilege: boolean;
  overridePricePrivilege: boolean;
  description: string;
  price: number | string;
  quantity: number;
  leadTime;
  editableLeadTime: boolean;
  source: string;
  showCus: boolean;
  isProduct: boolean;
  priceEdited: boolean;
  viewListPrice: boolean;
  optional: boolean;
  overrideDescription: boolean;
  overridePrice: boolean;
  overrideRFQ: boolean;
  viewPricing: boolean;
  isCustomOptionInit: boolean;
  private safeStore: SafeStore<State>;
  overrideQuantity: boolean;
  overrideLeadTime: boolean;
  overrideSource: boolean;

  constructor(public dialogRef: MatDialogRef<OptionOverrideComponent>,
              private store: Store<State>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

    this.safeStore = new SafeStore(this, this.store);
    this.choice = data.choice;
    this.parentSubcategoryId = data.parentSubcatId;
    this.categoryId = data.categoryId;
    this.fieldId = data.fieldId;
    this.subcatId = data.subcatId;
    this.overrideDescriptionPrivilege = data.overrideDescription;
    this.description = this.choice.description;
    this.price = this.choice.price && 'rfq' !== this.choice.price.toLowerCase()
    && this.isEditablePriceType(this.choice) ? this.choice.priceValue : '';
    this.quantity = this.choice.quantity;
    this.leadTime = this.choice.leadTime;
    this.source = this.choice.source;
    this.optional = this.choice.optional;
    if (this.choice.showCus) {
      this.showCus = this.choice.showCus;
    }
    this.editableLeadTime = this.isEditablePriceType(this.choice);
    if (data.isProduct !== undefined) {
      this.isProduct = data.isProduct;
    } else if ((<Product>this.choice).title) {
      this.isProduct = true;
    }
    this.safeStore.subscribe(getPrivileges, privileges => {
      this.overrideDescription = privileges['overrideDescription'];
      this.overridePrice = privileges['overridePrice'];
      this.overrideRFQ = privileges['overrideRFQ'];
      this.viewPricing = privileges['viewPricingInConfigurator'];
      this.viewListPrice = privileges['viewListPrice'];
      this.overridePricePrivilege = (this.overridePrice && this.isEditablePriceType(this.choice)) || (this.overrideRFQ && this.choice.rfq);

    });
    this.isCustomOptionInit = data.isCustomOptionInit;
    // can override these by default
    this.overrideQuantity = data.overrideQuantity !== false;
    this.overrideLeadTime = data.overrideLeadTime !== false;
    this.overrideSource = data.overrideSource !== false;
  }

  update() {
    let isCustom;
    if (this.choice['displayType']) {
      isCustom = true;
    }
    const payload = {
      choice: this.choice, parentSubcatId: this.parentSubcategoryId, choiceId: this.choice.id,
      fieldId: this.fieldId, categoryId: this.categoryId, subcatId: this.subcatId, fieldContainer: FieldContainer.Product,
      description: this.description, quantity: this.quantity, isProduct: this.isProduct, isCustom: isCustom,
      source: this.source, leadTime: this.leadTime, showCus: this.showCus, selectionType: this.optional ? 'optional' : 'primary'
    };
    if (this.priceEdited) {
      payload['price'] = this.price;
    }
    this.store.dispatch(ProductOptionActions.choiceOverride({payload}));
  }

  toggleShowCus() {
    this.showCus = !this.showCus;
  }

  isEditablePriceType(option) {
    return !(option.price === 'noCharge' || option.price === 'included');
  }

  descriptionExists() {
    return !isNullOrUndefined(this.choice.description);
  }

  hasEditedPrice() {
    this.priceEdited = true;
  }

  updateIncluded(value) {
    this.optional = value;
  }

  deleteNewCustomField() {
    if (this.isCustomOptionInit) {
      this.store.dispatch(ProductOptionActions.deleteCustomOption({payload: {...this.choice, categoryId: this.categoryId}}));
    }
  }

  isSelected(option) {
    if (isNullOrUndefined(option.selected)) {
      return true;
    }
    return option.selected;
  }
}
