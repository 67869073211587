import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Observable} from 'rxjs';
import {getCssUrl, State} from '../reducers/index';
import urlJoin from 'proper-url-join';
import {map} from 'rxjs/operators';
import {selectFirstTruthy} from '../util/rx-utils';

@Component({
  selector: 'cbs-global-css',
  template: `
    <link rel="stylesheet" [href]="ifeFoxhoundUrl$|async">
    <link rel="stylesheet" [href]="ifeMaterialUrl$|async">
    <link rel="stylesheet" [href]="ifeNavbarUrl$|async">
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalCssComponent {
  ifeFoxhoundUrl$: Observable<SafeResourceUrl>;
  ifeMaterialUrl$: Observable<SafeResourceUrl>;
  ifeNavbarUrl$: Observable<SafeResourceUrl>;

  constructor(private store: Store<State>, private sanitizer: DomSanitizer) {
    const cssPath$ = selectFirstTruthy(this.store, getCssUrl);
    this.ifeFoxhoundUrl$ = cssPath$
      .pipe(map(cssPath => this.sanitizer.bypassSecurityTrustResourceUrl(urlJoin(<string>cssPath, 'ife-foxhound.css'))));
    this.ifeMaterialUrl$ = cssPath$
      .pipe(map(cssPath => this.sanitizer.bypassSecurityTrustResourceUrl(urlJoin(<string>cssPath, 'ife-material.css'))));
    this.ifeNavbarUrl$ = cssPath$
      .pipe(map(cssPath => this.sanitizer.bypassSecurityTrustResourceUrl(urlJoin(<string>cssPath, 'ife-navbar.css'))));
  }

}
