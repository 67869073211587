<h1 mat-dialog-title>{{'editOptionDetails' | cbsLabel}}</h1>
<mat-dialog-content>
  <label [attr.for]="choice.id" *ngIf="descriptionExists()">
    {{'description' | cbsLabel}}:
    <mat-form-field floatLabel="never">
      <textarea matInput [id]="choice.id+'-description'" [(ngModel)]="description"
                [disabled]="!overrideDescriptionPrivilege || !choice.overrideDesc" #overrideDescription>
      </textarea>
    </mat-form-field>
  </label>
  <div class="flex-grid">
    <label [attr.for]="choice.id" *ngIf="choice.price && viewListPrice">
      {{'enterBasePrice' | cbsLabel}}:
      <mat-form-field floatLabel="never" class="mat-align">
        <div matPrefix [ngClass]="{'disabled-appearance': !overridePricePrivilege}">{{choice.currency}}</div>
        <input matInput [id]="choice.id+'-price'" placeholder="{{choice.price | cbsLabel}}" [(ngModel)]="price"
               (change)="hasEditedPrice()" [disabled]="!overridePricePrivilege" #overridePrice>
        <div *ngIf="choice.sellMultiplier != 1.0 && isEditablePriceType(choice)"
             [ngClass]="{'disabled-appearance': !overridePricePrivilege}" style="font-style: italic" matSuffix>
          ({{'multiplier' | cbsLabel}}: {{choice.sellMultiplier}})
        </div>
        <mat-hint *ngIf="choice.priceOverridden" style="color: black">{{'priceOverriddenByUser' | cbsLabel}}</mat-hint>
      </mat-form-field>
    </label>
    <label [attr.for]="choice.id" *ngIf="overrideQuantity">
      {{'quantity' | cbsLabel}}:
      <mat-form-field floatLabel="never">
        <div matPrefix></div>
        <input matInput [id]="choice.id+'-quantity'" [placeholder]="choice.quantity" [(ngModel)]="quantity"
               [disabled]="!choice.quantityEditable || !isSelected(choice)" #overrideQuantity>
        <mat-hint align="start" *ngIf="choice.quantityEditable && !isSelected(choice)" style="color:red">
          Option must be selected to edit quantity
        </mat-hint>
      </mat-form-field>
    </label>
  </div>

  <div class="flex-grid">
    <label [attr.for]="choice.id" *ngIf="overrideLeadTime">
      {{'leadTime' | cbsLabel}}:
      <mat-form-field floatLabel="never">
        <div matPrefix></div>
        <input matInput [id]="choice.id+'-leadTime'" [(ngModel)]="leadTime"
               [disabled]="!editableLeadTime || !overridePricePrivilege" #overrideLeadTime>
      </mat-form-field>
    </label>

    <label [attr.for]="choice.id" *ngIf="overrideSource">
      {{'Source' | cbsLabel}}:
      <mat-form-field floatLabel="never">
        <div matPrefix></div>
        <textarea matInput [id]="choice.id+'-source'" [placeholder]="choice.source" [(ngModel)]="source"
                  #overrideSource></textarea>
      </mat-form-field>
    </label>
  </div>

  <div class="flex-grid">
    <label [attr.for]="choice.id" *ngIf="!isProduct">
      <mat-checkbox [id]="choice.id + '-showCus'" [checked]="showCus"
                    (change)="toggleShowCus()">
        {{'showCustomer' | cbsLabel}}
      </mat-checkbox>
    </label>

    <div class="flex-grid flex-shrink" *ngIf="choice.allowOptionalSelection">
      <div>{{'included' | cbsLabel}}</div>
      <div class="c-switch">
        <input type="checkbox" id="optionalSwitch" [checked]="optional" (change)="updateIncluded(optionalCheckbox.checked)"
               #optionalCheckbox>
        <label for="optionalSwitch" class="m-none"></label>
      </div>
      <div>{{'optional' | cbsLabel}}</div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button class="c-button" test-id="UPDATE_BUTTON" (click)="update()" mat-dialog-close>{{'update' | cbsLabel}}</button>
  <button class="c-text-button" test-id="CANCEL_BUTTON" (click)="deleteNewCustomField()" mat-dialog-close>{{'cancel' | cbsLabel}}</button>
</mat-dialog-actions>
