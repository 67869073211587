import {ProductOptionsSelectors, ProductOptionsState} from '../reducers/product-options.reducer';

export class ProductOptionUtils {

  static findCategory(categoryId: string, productOptions: ProductOptionsState) {
    return ProductOptionsSelectors.getCategories(productOptions).find(category => category.id === categoryId);
  }

  static findParentSubcatInCategories(parentSubcatId: string, productOptions: ProductOptionsState) {
    return ProductOptionsSelectors.getCategories(productOptions).map(category => category.parentSubcategories)
      .reduce((result, parentSubcat) => result.concat(parentSubcat), []) // flatten the parentSubcategories
      .find(parentSubcat => parentSubcat.id === parentSubcatId);
  }

  static findFieldInCategories(fieldId: string, productOptions: ProductOptionsState) {
    return ProductOptionsSelectors.getCategories(productOptions).map(category => category.parentSubcategories)
      .reduce((result, parentSubcat) => result.concat(parentSubcat), []) // flatten the parentSubcategories
      .map(parentSubcat => parentSubcat.fields)
      .reduce((result, field) => result.concat(field), []) // flatten the fields
      .find(field => field.id === fieldId);
  }

  static findFieldInResponse(fieldId: string, response) {
    return response['categories'].map(category => category.parentSubcategories)
      .reduce((result, parentSubcat) => result.concat(parentSubcat), []) // flatten the parentSubcategories
      .map(parentSubcat => parentSubcat.subcategories)
      .reduce((result, subcat) => result.concat(subcat), []) // flatten the subcategories
      .map(subcategory => subcategory.fields)
      .reduce((result, field) => result.concat(field), []) // flatten the fields
      .find(field => field.id === fieldId);
  }

  static findFieldInCategoriesWithData(fieldId: string, categories): { field, subcatId, parentSubcatId, categoryId } {
    return categories.map(category =>
      category.parentSubcategories.map(parentSubcat => {
        return {
          parentSubcat: parentSubcat,
          categoryId: category.id
        };
      }))
      .reduce((result, parentSubcat) => result.concat(parentSubcat), []) // flatten the parentSubcategories
      .map(parentSubcat => parentSubcat.parentSubcat.subcategories
        .map(subcat => {
          return {
            subcat: subcat,
            parentSubcatId: parentSubcat.parentSubcat.id,
            categoryId: parentSubcat.categoryId
          };
        }))
      .reduce((result, subcat) => result.concat(subcat), []) // flatten the subcategories
      .map(subcategory => subcategory.subcat.fields
        .map(field => {
          return {
            field: field,
            subcatId: subcategory.subcat.id,
            parentSubcatId: subcategory.parentSubcatId,
            categoryId: subcategory.categoryId
          };
        }))
      .reduce((result, field) => result.concat(field), [])// flatten the fields
      .find(field => {
        return field.field.id === fieldId;
      });
  }

  static findSubcatInResponse(subcatId: string, response) {
    return response['categories'].map(category => category.parentSubcategories)
      .reduce((result, parentSubcat) => result.concat(parentSubcat), []) // flatten the parentSubcategories
      .map(parentSubcat => parentSubcat.subcategories)
      .reduce((result, subcat) => result.concat(subcat), []) // flatten the subcategories
      .find(subcat => subcat.id === subcatId);
  }

  static findFieldChoice(fieldId: string, choiceId: string, productOptions: ProductOptionsState) {
    return ProductOptionUtils.findFieldInCategories(fieldId, productOptions).choices
      .reduce((result, choice) => result.concat(choice), []) // flatten the choices
      .find(choice => choice.id === choiceId);
  }
}
