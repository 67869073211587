import {ProductOptionActions} from '../actions/product-options.action';
import {Category} from '../models/Category';
import {HeaderActions} from '../actions/header.action';
import {SearchActions} from '../actions/search.action';
import {Product} from '../models/Product';
import {ProductActions} from '../actions/product.action';
import {DiscountActions} from '../actions/discount.action';
import produce from 'immer';
import {
  updateCheckbox, updateChoiceDisplayLoading, updateDropdown, updateOptionAttributeValue, updateFieldLoadingImage, updateFieldNumericValue,
  updateFieldValue,
  updatePOFieldInvalid
} from '../util/immer-utils';
import {createReducer, on} from '@ngrx/store';
import {CbsActions} from '../actions/cbs.action';
import {Page} from '../models/Page';
import {replaceUndefined} from '../util/js-utils';
import {CosActions} from '../actions/cos.action';
import {Field} from '../models/Field';
import {EmailRfqActions} from '../actions/email-rfq.action';

export interface ProductOptionsState {
  pages?: Page[];
  categories?: Category[];
  product: Product[];
  selectedNode?: string;
  selectedPage?: string;
  editPriceSheetColumns?: any[];
  currencies?: any[];
  additionalEmailRfqFields?: Field[];
}

const initialState: ProductOptionsState = {
  categories: [],
  product: []
};

export const productOptionsReducer = createReducer(
  initialState,
  on(
    HeaderActions.init,
    (state, {response}) => ({
      ...state,
      editPriceSheetColumns: replaceUndefined(response.editPriceSheetColumns, state.editPriceSheetColumns),
      currencies: replaceUndefined(response.currencies, state.currencies)
    })
  ),
  on(
    ProductOptionActions.initSuccess,
    ProductOptionActions.changeFieldSuccess,
    ProductOptionActions.addCustomOptionSuccess,
    ProductOptionActions.deleteCustomOptionSuccess,
    HeaderActions.changeFieldSuccess,
    CbsActions.saveToNewQuoteSuccess,
    CbsActions.saveToNewOrderSuccess,
    CbsActions.saveToNewProjectSuccess,
    (state, {response}) => ({
      ...state,
      product: replaceUndefined(response.product, state.product),
      categories: replaceUndefined(response.categories, state.categories),
      selectedNode: replaceUndefined(response.selectedNode, state.selectedNode),
      pages: replaceUndefined(response.pages, state.pages) || [],
      editPriceSheetColumns: replaceUndefined(response.editPriceSheetColumns, state.editPriceSheetColumns),
      currencies: replaceUndefined(response.currencies, state.currencies),
      additionalEmailRfqFields: replaceUndefined(response.additionalEmailRfqFields, state.additionalEmailRfqFields)
    })
  ),
  on(ProductOptionActions.changeField, (state, {payload}) => {
    if (payload.fieldId) {
      return updateFieldLoadingImage(state, payload);
    }
    return state;
  }),
  on(ProductOptionActions.tempChangeFieldValue, (state, {payload}) => updateFieldValue(state, payload)),
  on(ProductOptionActions.tempChangeNumericField, (state, {payload}) => {
    state = updateFieldValue(state, payload);
    return updateFieldNumericValue(state, payload);
  }),
  on(ProductOptionActions.tempChangeDropdownField, (state, {payload}) => updateDropdown(state, payload)),
  on(ProductOptionActions.tempChangeCheckboxField, (state, {payload}) => {
    state = updateFieldValue(state, payload as any);
    return updateCheckbox(state, payload);
  }),
  on(EmailRfqActions.changeField, (state, {payload}) => {
    if (payload.fieldId) {
      return updateFieldLoadingImage(state, payload);
    }
    return state;
  }),
  on(EmailRfqActions.tempChangeFieldValue, (state, {payload}) => updateFieldValue(state, payload)),
  on(EmailRfqActions.tempChangeNumericField, (state, {payload}) => {
    state = updateFieldValue(state, payload);
    return updateFieldNumericValue(state, payload);
  }),
  on(EmailRfqActions.tempChangeDropdownField, (state, {payload}) => updateDropdown(state, payload)),
  on(EmailRfqActions.tempChangeCheckboxField, (state, {payload}) => {
    state = updateFieldValue(state, payload as any);
    return updateCheckbox(state, payload);
  }),
  on(ProductOptionActions.invalidField, (state, {payload}) => updatePOFieldInvalid(state, payload)),
  on(ProductOptionActions.choiceOverride, (state, {payload}) => {
    if (payload.isProduct) {
      return updateProductDisplayLoading(state, payload, true);
    }
    if (payload.isCustom) {
      return updateFieldLoadingImage(state, payload);
    }
    return updateChoiceDisplayLoading(state, payload, true);
  }),
  on(SearchActions.selectProduct, (state) => ({...state, categories: null, pages: null, selectedPage: null})),
  on(ProductActions.configureNode, (state, {product}) => ({
    ...state,
    categories: null,
    pages: null,
    selectedPage: null,
    selectedNode: product.nodeID
  })),
  on(ProductActions.reorderSuccess, (state, {response}) => ({...state, product: response.product})),
  on(ProductActions.deselect, (state, {product}) => updateProductDeselected(state, product)),
  on(DiscountActions.updateDiscountSuccess, (state, {response}) => ({
    ...state,
    product: response.product,
    categories: response.categories
  })),
  on(ProductOptionActions.tempChangeAttributeValue, (state, {payload}) => updateOptionAttributeValue(state, payload)),
  on(ProductOptionActions.changePage, (state, {pageId}) => ({...state, selectedPage: pageId})));

// [WIP] Product handling for future testing
export function updateProductDisplayLoading(state: ProductOptionsState, payload, value: boolean): ProductOptionsState {
  return produce(state, draftState => {
    if (draftState.product) {
      draftState.product.filter(product => product.id === payload.choice.id).forEach(product => product.displayLoading = value);
    }
  });
}

export function updateProductDeselected(state: ProductOptionsState, product: Product): ProductOptionsState {
  return produce(state, draftState => {
    if (draftState.product) {
      draftState.product.filter(p => p.id === product.id).forEach(p => p.isDeselected = true);
    }
  });
}

export class ProductOptionsSelectors {
  static getPages = (state: ProductOptionsState) => state.pages;
  static getCategories = (state: ProductOptionsState) => {
    if (state.pages && state.selectedPage) {
      const selectedPage = state.pages.find((page) => page.id === state.selectedPage);
      return selectedPage ? selectedPage.categories : [];
    } else {
      return state.categories;
    }
  };
  static getAllCategories = (state: ProductOptionsState) => state.categories;
  static getProducts = (state: ProductOptionsState) => state.product;
  static getSelectedNode = (state: ProductOptionsState) => state.selectedNode;
  static getSelectedPage = (state: ProductOptionsState) => state.selectedPage;
  static getEditPriceSheetColumns = (state: ProductOptionsState) => state.editPriceSheetColumns;
  static getCurrencies = (state: ProductOptionsState) => state.currencies;
  static getAdditionalEmailRfqFields = (state: ProductOptionsState) => state.additionalEmailRfqFields;
}
