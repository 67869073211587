import {createAction, props} from '@ngrx/store';
import {
  ChangeFieldPayload, TempChangeCheckboxPayload, TempChangeDropdownPayload, TempChangeFieldValuePayload, TempChangeNumericFieldPayload
} from './field.action';
import {ProductOptionsFieldChangeResponse} from '../services/web/product-options-field-change-response';

export namespace EmailRfqActions {
  export const changeField = createAction(
    '[email-rfq] Change Field',
    props<{ payload: ChangeFieldPayload }>()
  );

  export const changeFieldSuccess = createAction(
    '[email-rfq] Change Field Success',
    props<{ response: ProductOptionsFieldChangeResponse }>()
  );

  export const tempChangeFieldValue = createAction(
    '[email-rfq] Temp Change Field Value',
    props<{ payload: TempChangeFieldValuePayload }>()
  );

  export const tempChangeNumericField = createAction(
    '[email-rfq] Temp Change Numeric Field',
    props<{ payload: TempChangeNumericFieldPayload }>()
  );

  export const tempChangeDropdownField = createAction(
    '[email-rfq] Temp Change Dropdown Field',
    props<{ payload: TempChangeDropdownPayload }>()
  );

  export const tempChangeCheckboxField = createAction(
    '[email-rfq] Temp Change Checkbox Field',
    props<{ payload: TempChangeCheckboxPayload }>()
  );
}
