import {PropertiesState} from '../reducers/properties.reducer';

export const gemc = {
  GE_MC_FGM: <PropertiesState>{
    po_subnavStrategy: 'none',
    searchBarSize: 'large',
    searchBarLocation: 'results',
    po_singleColumn: true,
    po_hideExpandCollapseAll: true,
    po_displayPartNumber: true,
    itemMultiplier_hideMargin: false,
    po_rowShading: true,
    displayWidescreen: true
  }
};
