import {Component, OnInit} from '@angular/core';
import {getSelectedNode, getWorkflowId, State} from '../reducers/index';
import {Store} from '@ngrx/store';
import {isNotSupported} from '../util/workflow-utils';
import {assignFirstTruthy, selectFirstTruthy} from '../util/rx-utils';
import {WorkflowActions} from '../actions/workflow.action';

@Component({
  selector: 'cbs-documents-route',
  templateUrl: './documents-route.component.html'
})
export class DocumentsRouteComponent implements OnInit {

  workflowId: string;

  constructor(private store: Store<State>) {
    assignFirstTruthy(this.store, getWorkflowId, workflowId => this.workflowId = workflowId);
  }

  ngOnInit(): void {
    selectFirstTruthy(this.store, getSelectedNode).subscribe(nodeId => {
      if (+nodeId > 1) {
      } else {
        this.store.dispatch(WorkflowActions.displayBreadcrumbs({
          breadcrumbs: {configure: {display: false, target: 'parts-configuration'}}
        }));
      }
    });

    if (!isNotSupported(this.workflowId, 'productDocumentsLink')) {
      this.store.dispatch(WorkflowActions.displayBreadcrumbs({
        breadcrumbs: {product: {display: true, target: 'documents'}}
      }));
    } else {
      this.store.dispatch(WorkflowActions.displayBreadcrumbs({breadcrumbs: {}}));
    }
    this.store.dispatch(WorkflowActions.displayData({
      payload: {
        displayPrice: true,
        displaySaveButton: true,
        displaySearchButton: false,
        createCustomOptions: false
      }
    }));
  }
}
