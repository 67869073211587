import {Component, Input} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ColDef, GridApi, GridReadyEvent} from 'ag-grid-community';
import {SuppressKeyboardEventParams} from 'ag-grid-community/dist/lib/entities/colDef';
import {PartNumberAliasService} from '../../services/web/part-number-alias.service';

@UntilDestroy()
@Component({
  selector: 'cbs-part-number-spreadsheet',
  templateUrl: './part-number-spreadsheet.component.html',
  styles: [`
    :host {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  `]
})
export class PartNumberSpreadsheetComponent {

  @Input()
  orgUnit: any;

  private gridApi: GridApi;

  defaultColDef: ColDef = {
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    flex: 1,
    editable: true,
    suppressKeyboardEvent: (params) => this.handleKeyboardEvent(params)
  };

  columnDefs: ColDef[] = [
    {
      headerName: this.aliasService.partNumberLabel,
      field: 'partNumber'
    },
    {
      headerName: this.aliasService.aliasLabel,
      field: 'alias'
    }
  ];

  previewRowData: any[];
  saving = false;
  showSuccessMessage = false;
  showErrorMessage = false;

  constructor(public aliasService: PartNumberAliasService) {
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  handleKeyboardEvent(params: SuppressKeyboardEventParams) {
    const event = params.event;
    if (!params.editing) {
      if (event.key === 'Delete') {
        this.previewRowData.splice(params.node.rowIndex, 1);
        this.previewRowData = [...this.previewRowData];
        return true;
      }
    }
    return false;
  }

  onPaste(event: any) {
    this.doPasteRows(this.parseClipboard(event.target.value));
    event.target.value = '';
  }

  parseClipboard(text: string): { orgUnit: number, partNumber: string, alias: string }[] {
    if (text && text.length > 1) {
      const entries = [];
      const rows = text.split(/[\r\n]+/g);
      for (const row of rows) {
        if (row.trim().length) {
          const values = row.split(/[\t]+/g);
          if (values.length !== 2 || !values[0].length || !values[1].length) {
            return null;
          }
          entries.push({
            orgUnit: this.orgUnit.id,
            partNumber: values[0],
            alias: values[1]
          });
        }
      }
      return entries;
    }
    return [];
  }

  doPasteRows(mappingsToPaste: any[]) {
    if (mappingsToPaste) {
      if (mappingsToPaste.length) {
        this.showErrorMessage = false;
        this.previewRowData = mappingsToPaste;
        this.aliasService.setDirty(true);
      }
    } else {
      this.showErrorMessage = true;
    }
  }

  savePasteData() {
    this.saving = true;
    this.aliasService.savePartNumberAliases(this.previewRowData).subscribe(() => {
      this.saving = false;
      this.showSuccessMessage = true;
      delete this.previewRowData;
      this.aliasService.setDirty(false);
    });
  }

  cancelPasteData() {
    delete this.previewRowData;
    this.aliasService.setDirty(false);
  }
}
