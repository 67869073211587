import {Injectable} from '@angular/core';
import {Angulartics2GoogleTagManager} from 'angulartics2/gtm';

/** google tag manager dataLayer */
declare var dataLayer: any[];

@Injectable()
export class GoogleTagManagerService {

  constructor(gtm: Angulartics2GoogleTagManager) {
    /* must inject Angulartics2GoogleTagManager to init */
  }

  push(data: any) {
    if (typeof dataLayer !== 'undefined' && dataLayer) {
      dataLayer.push(data);
    }
  }

}
