<ng-container *ngIf="searchFilter$ | async">
  <ng-container *ngIf="display$ | async">
    <ng-container *ngIf="!(cosSearchFilter$|async)">
      <ng-container *ngIf="searchField$ | async as searchField">
        <div class="cbs-section-background" [ngClass]="{'p-base': !inCos}">
          <label [attr.for]="searchField.id" style="width: 100%">
            <mat-form-field floatLabel="never">
              <div matPrefix class="m-l-base fas fa-search" style="align-self: center"></div>
              <input matInput [name]="searchField.httpName" [id]="searchField.id" [value]="searchField.value"
                     placeholder="{{'RefineSearchResults' | cbsLabel}}"
                     (change)="submitSearchFieldChange(searchField, textInputFieldVar.value)" #textInputFieldVar>
              <div class="m-r-base">
                <ng-content></ng-content>
              </div>
            </mat-form-field>
          </label>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
