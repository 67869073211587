import {createAction, props} from '@ngrx/store';
import {DiscountChange} from '../reducers/discount.reducer';

export namespace DiscountActions {
  export const updateDiscount = createAction(
    '[discount] Update Discount',
    props<{ discount: any, value: number, attribute: string }>()
  );

  export const updateDiscountSuccess = createAction(
    '[discount] Update Discount Success',
    props<{ response: any }>()
  );

  export const resetDiscounts = createAction(
    '[discount] Reset'
  );

  export const submitDiscounts = createAction(
    '[discount] Submit',
    props<{ changeList: DiscountChange[] }>()
  );
}

