import {Injectable} from '@angular/core';
import {Category} from '../models/Category';
import {ParentSubcategory} from '../models/ParentSubcategory';
import {Field} from '../models/Field';
import {Choice} from '../models/Choice';
import {PriceSummaryRow} from '../components/price-summary.component';
import {MatDialog} from '@angular/material/dialog';

@Injectable()
export class PriceSummaryService {
  constructor(private dialog: MatDialog) {
  }

  convertCategories(categories: Category[], rowState: Set<string>) {
    const rows: PriceSummaryRow[] = [];
    categories.filter(category => category.displayOnPriceSummary).forEach(category => {
      const catIdData = {categoryId: category.id};
      const cat = {
        name: category.description,
        data: category,
        expanded: !rowState.has(category.id),
        children: [],
        idData: catIdData
      };
      if (category.parentSubcategories) {
        category.parentSubcategories.forEach(parentSubcategory => {
          const parentIdData = {...catIdData};
          parentIdData['parentSubcatId'] = parentSubcategory.id;
          const subcategory = this.convertSubcategory(parentSubcategory, parentIdData, rowState);
          if (subcategory.children.length > 0) {
            cat.children.push(subcategory);
          }
        });
      }
      if (cat.children.length > 0) {
        rows.push(cat);
      }
    });
    return rows;
  }

  convertSubcategory(subcategory: ParentSubcategory, parentIdData, rowState: Set<string>): PriceSummaryRow {
    const sub: PriceSummaryRow = {
      name: subcategory.description,
      data: subcategory,
      expanded: !rowState.has(
        parentIdData.subcatId || PriceSummaryService.getUniqueRowNodeIdForParentSubcatId(parentIdData.parentSubcatId)),
      children: [],
      idData: parentIdData
    };
    if (subcategory.fields) {
      subcategory.fields.forEach(field => {
        const fieldIdData = {...parentIdData};
        fieldIdData['fieldId'] = field.id;
        if (field.choices) {
          if (field.displayType === 'MULTIPICK') {
            const multi: PriceSummaryRow = {
              name: field.description,
              data: field,
              expanded: !rowState.has(fieldIdData.fieldId),
              children: [],
              idData: fieldIdData
            };
            sub.children.push(multi);

            const selectedChoices = this.getSelectedChoices(field);
            selectedChoices.forEach(choice => {
              const choiceIdData = {...fieldIdData};
              choiceIdData['choiceId'] = choice.id;
              multi.children.push({
                name: choice.description,
                expanded: !rowState.has(choiceIdData.choiceId),
                data: field,
                choice: choice,
                idData: choiceIdData
              });
            });
          } else {
            const selectedChoices = this.getSelectedChoices(field);
            selectedChoices.forEach(choice => {
              const choiceIdData = {...fieldIdData};
              choiceIdData['choiceId'] = choice.id;
              sub.children.push({
                name: this.getChoiceDescription(field, choice),
                expanded: !rowState.has(choiceIdData.choiceId),
                data: field,
                choice: choice,
                idData: choiceIdData
              });
            });
          }
        } else {
          // TODO: Do we want fields in here that won't have prices?
          sub.children.push({
            name: field.description,
            expanded: !rowState.has(fieldIdData.fieldId),
            data: field,
            idData: fieldIdData
          });
        }
      });
    }
    if (subcategory.subcategories) {
      subcategory.subcategories.forEach(childSubcategory => {
        const subcatIdData = {...parentIdData};
        subcatIdData['subcatId'] = childSubcategory.id;
        const child = this.convertSubcategory(childSubcategory, subcatIdData, rowState);
        if (child.children.length > 0) {
          sub.children.push(child);
        }
      });
    }
    return sub;
  }

  getSelectedChoices(field: Field) {
    if (field.choices) {
      return field.choices.filter(choice => choice.selected);
    } else {
      return [];
    }
  }

  getChoiceDescription(field: Field, choice: Choice) {
    if (field.description) {
      if (choice.description) {
        // TODO: read showSubcat from org-setup.xml
        return field.description + ': ' + choice.description;
      }
      return field.description;
    }
    return choice.description;
  }

  flattenRows(rows: PriceSummaryRow[], level: number, priceOnly: boolean): PriceSummaryRow[] {
    const uniqueIdSet: Set<any> = new Set();
    return this._flattenRows(rows, level, priceOnly, uniqueIdSet);
  }

  private _flattenRows(rows: PriceSummaryRow[], level: number, priceOnly: boolean, uniqueIdSet: Set<any>): PriceSummaryRow[] {
    let newRows: PriceSummaryRow[] = [];
    rows.forEach(row => {
      row.level = level;
      if (row.name && (!priceOnly || row.choice?.price || row.children)) {
        const rowNodeId = this.getRowNodeId(row);
        if (!uniqueIdSet.has(rowNodeId)) {
          newRows.push(row);
          uniqueIdSet.add(rowNodeId);
        }
        if (row.expanded && row.children) {
          newRows = newRows.concat(this._flattenRows(row.children, level + 1, priceOnly, uniqueIdSet));
        }
      } else if (row.children) {
        newRows = newRows.concat(this._flattenRows(row.children, level, priceOnly, uniqueIdSet));
      }
    });
    return newRows;
  }

  getRowsToRemove(parent: PriceSummaryRow): PriceSummaryRow[] {
    let rows = [];
    if (parent.children) {
      parent.children.forEach(child => {
        if (child.name) {
          rows.push(child);
        }
        rows = rows.concat(this.getRowsToRemove(child));
      });
    }
    return rows;
  }

  getRowNodeId(data: PriceSummaryRow) {
    if (!data.idData) {
      return 'product';
    }
    const idData = data.idData;
    // return idData.choiceId || idData.fieldId || idData.subcatId || idData.parentSubcatId || idData.categoryId;
    if (idData.choiceId) {
      return idData.choiceId;
    } else if (idData.fieldId) {
      return idData.fieldId;
    } else if (idData.subcatId) {
      return idData.subcatId;
    } else if (idData.parentSubcatId) {
      return PriceSummaryService.getUniqueRowNodeIdForParentSubcatId(idData.parentSubcatId);
    } else {
      return idData.categoryId;
    }
  }

  private static getUniqueRowNodeIdForParentSubcatId(parentSubcatId) {
    // some KBs contain duplicate ids with parent subcategories and child subcategories
    //  so make the parent id unique so the row in AG Grid is happy
    return 'p|' + parentSubcatId;
  }

  getNameIndent(data: PriceSummaryRow) {
    return data.level * 20 + 10 + (data.children ? 0 : 10) + 'px';
  }

  isEditablePriceType(value) {
    return !(value.price === 'noCharge' || value.price === 'included');
  }

}
