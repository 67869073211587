import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Observable} from 'rxjs';
import {ColDef, GridApi, GridReadyEvent, NewValueParams} from 'ag-grid-community';
import {PartNumberAliasService} from '../../services/web/part-number-alias.service';
import {first} from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'cbs-part-number-missing',
  templateUrl: './part-number-missing.component.html',
  styles: [`
    :host {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  `]
})
export class PartNumberMissingComponent implements OnInit, OnChanges {

  @Input()
  orgUnit: any;

  private gridApi: GridApi;

  defaultColDef: ColDef = {
    resizable: true,
    sortable: true,
    sortingOrder: ['asc', 'desc'],
    flex: 1
  };

  columnDefs: ColDef[] = [
    {
      headerName: this.aliasService.partNumberLabel,
      field: 'partNumber'
    },
    {
      headerName: this.aliasService.aliasLabel,
      field: 'alias',
      editable: true,
      onCellValueChanged: (newValueParams: NewValueParams) => {
        this.aliasService.setDirty(true);
        this.dirtyRows[newValueParams.data.partNumber] = newValueParams.newValue;
      }
    }
  ];

  rowData: Observable<any[]>;
  dirtyRows = {};

  constructor(public aliasService: PartNumberAliasService) {
  }

  ngOnInit() {
    this.rowData = this.aliasService.getMissingPartNumbers(this.orgUnit?.id);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.orgUnit.previousValue) {
      this.rowData = this.aliasService.getMissingPartNumbers(this.orgUnit.id);
    }
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
  }

  selectAll() {
    this.gridApi.selectAll();
  }

  copySelected() {
    this.aliasService.copyRowsToClipboard(this.gridApi.getSelectedRows());
    this.gridApi.deselectAll();
  }

  save() {
    const toSave = [];
    Object.keys(this.dirtyRows).forEach(partNumber => {
      const alias = this.dirtyRows[partNumber];
      if (alias && alias.trim().length) {
        toSave.push({orgUnit: this.orgUnit.id, partNumber, alias});
      }
    });
    this.aliasService.savePartNumberAliases(toSave).pipe(first()).subscribe(() => {
      this.rowData = this.aliasService.getMissingPartNumbers(this.orgUnit.id);
      this.aliasService.setDirty(false);
      this.dirtyRows = {};
    });
  }

  cancel() {
    this.rowData = this.aliasService.getMissingPartNumbers(this.orgUnit.id);
    this.aliasService.setDirty(false);
    this.dirtyRows = {};
  }
}
