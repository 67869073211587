import {CbsActions} from '../actions/cbs.action';
import {createReducer, on} from '@ngrx/store';
import {CosActions} from '../actions/cos.action';
import {ProductOptionActions} from '../actions/product-options.action';
import {HeaderActions} from '../actions/header.action';
import {DiscountActions} from '../actions/discount.action';
import {ProductActions} from '../actions/product.action';

export interface CbsState {
  itemId?: number;
  quoteId?: number;
  saveInProgress: boolean;
  dirty: boolean;
  saveSuccess?: boolean;
}

export const initialState: CbsState = {
  dirty: true,
  saveInProgress: false
};

export const cbsReducer = createReducer(
  initialState,
  on(
    CbsActions.itemSave,
    CbsActions.itemSaveAs,
    CbsActions.saveToNewOrder,
    CbsActions.saveToNewQuote,
    CbsActions.saveToNewProject, (state) => ({...state, saveInProgress: true})),
  on(
    CbsActions.itemSaveSuccess,
    CbsActions.itemSaveAsSuccess,
    CbsActions.saveToNewQuoteSuccess,
    CbsActions.saveToNewOrderSuccess,
    CbsActions.saveToNewProjectSuccess,
    (state, {response}) => (
      {
        ...state,
        itemId: response.itemId,
        quoteId: response.quoteId,
        saveInProgress: false,
        dirty: false,
        saveSuccess: true
      }
    )
  ),
  on(CbsActions.itemSaveFail, (state) => ({...state, saveInProgress: false, saveSuccess: false})),
  on(CbsActions.resetSaveSuccess, (state) => ({...state, saveSuccess: undefined})),
  on(
    CosActions.changeFieldSuccess,
    ProductOptionActions.changeFieldSuccess,
    HeaderActions.changeFieldSuccess,
    DiscountActions.updateDiscountSuccess,
    ProductOptionActions.deleteCustomOptionSuccess,
    ProductActions.reorderSuccess,
    (state) => ({...state, dirty: true})
  )
);

export class CbsSelectors {
  static getSaveInProgress = (state: CbsState) => state.saveInProgress;
  static isDirty = (state: CbsState) => state.dirty;
  static isSaveSuccess = (state: CbsState) => state.saveSuccess;
}
