import {select, Store} from '@ngrx/store';
import {Observable, Subscription} from 'rxjs';
import {untilDestroyed} from '@ngneat/until-destroy';

/**
 * Helper methods that automatically unsubscribe during the host's OnDestroy lifecycle hook.
 */
export class SafeStore<T> {
  constructor(private host, private store: Store<T>) {
  }

  /**
   * Use when more operator must be piped into the returned Observable, before the subscription is created.
   * DO NOT pipe in other Observables, as this will block the unsubscribe.
   */
  select<Props, K>(mapFn: (state: T) => K): Observable<K> {
    return this.store.pipe(select(mapFn), untilDestroyed(this.host));
  }

  subscribe<Props, K>(
    mapFn: (state: T) => K,
    subscribeFn: (val: K) => void): Subscription {

    return this.select(mapFn).subscribe(subscribeFn);
  }
}
