import {ParentSubcategory} from '../models/ParentSubcategory';
import {HeaderActions} from '../actions/header.action';
import {
  updateCheckbox, updateDropdown, updateFieldInvalid, updateFieldLoadingImage, updateFieldNumericValue, updateFieldValue
} from '../util/immer-utils';
import {createReducer, on} from '@ngrx/store';
import {CosActions} from '../actions/cos.action';

export interface CosState {
  parentSubcategory: ParentSubcategory;
  requestInProcess: boolean;
}

const initialState: CosState = {
  parentSubcategory: null,
  requestInProcess: false
};

export const cosReducer = createReducer(
  initialState,
  on(CosActions.initSuccess, (state, {response}) => ({...state, parentSubcategory: response.parentSubcategory})),
  on(CosActions.changeField, (state, {payload}) => {
    state = updateFieldLoadingImage(state, payload);
    return {...state, requestInProcess: true};
  }),
  on(CosActions.changeFieldSuccess, (state, {response}) => ({
    ...state,
    requestInProcess: false,
    parentSubcategory: response.parentSubcategory
  })),
  on(CosActions.tempChangeFieldValue, (state, {payload}) => updateFieldValue(state, payload)),
  on(CosActions.tempChangeDropdownField, (state, {payload}) => updateDropdown(state, payload)),
  on(CosActions.tempChangeCheckboxField, (state, {payload}) => updateCheckbox(state, payload)),
  on(CosActions.tempChangeNumericField, (state, {payload}) => {
    state = updateFieldValue(state, payload);
    return updateFieldNumericValue(state, payload);
  }),
  on(CosActions.invalidField, (state, {payload}) => updateFieldInvalid(state, payload)),
  on(HeaderActions.changeFieldSuccess, (state, {response}) => ({...state, parentSubcategory: response.parentSubcategory}))
);

export class CosSelectors {
  static getParentSubcategory = (state: CosState) => state.parentSubcategory;
  static isRequestInProcess = (state: CosState) => state.requestInProcess;
}
