<ng-container *ngIf="headerFields$|async as headerFields">
  <h1 mat-dialog-title>{{'headerInformation' | cbsLabel}}</h1>
  <mat-dialog-content>
    <div class="flex-grid" [ngStyle]="{'max-width': isMobile() ? '100%' : '500px'}">
      <div *ngFor="let field of headerFields; trackBy: trackByField" [ngStyle]="{'flex': headerFields.length > 3 ? '1 1 200px' : ''}">
        <cbs-field [field]="field" [fieldContainer]="fieldContainer"></cbs-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button class="c-button" test-id="UPDATE_BUTTON" (click)="saveAndClose()" mat-dialog-close>
      {{'update' | cbsLabel}}
    </button>
    <button class="c-text-button" test-id="CANCEL_BUTTON" (click)="resetHeaders()" mat-dialog-close>
      {{'cancel' | cbsLabel}}
    </button>
  </mat-dialog-actions>
</ng-container>
