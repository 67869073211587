import {concatMap, filter, first, mergeMap, tap} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {isTruthy} from './js-utils';
import {Selector} from '@ngrx/store/src/models';

export const firstTruthy = () => first(isTruthy);

/**
 * Only use this when the observable / subscription will be disposed of immediately after use.
 * Be careful... the 'first truthy' value may not yet be initialized properly, but still truthy.
 */
export function selectFirstTruthy<State, Props, Result>
(store: Store<State>, selectFn: Selector<State, Result>): Observable<Result> {
  return store.pipe(select(selectFn), firstTruthy());
}

/**
 * Combines selectFirstTruthy with a subscribe to easily assign values.
 * The observable / subscription will be disposed of immediately after use.
 */
export function assignFirstTruthy<State, Result>
(store: Store<State>, selectFn: Selector<State, Result>, assignmentFn: (selectResult: Result) => any) {
  selectFirstTruthy(store, selectFn).subscribe(assignmentFn);
}

export function concatMapEmitLast<T>(mapFn: (value: T, index: number) => Observable<any>) {
  let counter = 0;
  return (source: Observable<T>) => {
    return source.pipe(
      tap(_ => counter++),
      concatMap(mapFn),
      tap(_ => counter--),
      filter(_ => counter === 0)
    );
  };
}

export function mergeMapEmitLast<T>(mapFn: (value: T, index: number) => Observable<any>) {
  let counter = 0;
  return (source: Observable<T>) => {
    return source.pipe(
      tap(_ => counter++),
      mergeMap(mapFn),
      tap(_ => counter--),
      filter(_ => counter === 0)
    );
  };
}

export function selectValue<State, Result>(store: Store<State>, selectFn: Selector<State, Result>): Result {
  let value;
  store.select(selectFn).pipe(first()).subscribe(v => value = v);
  return value;
}
