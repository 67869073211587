import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {FieldContainer} from '../models/FieldContainer';
import {select, Store} from '@ngrx/store';
import {ParentSubcategory} from '../models/ParentSubcategory';
import {Observable} from 'rxjs';
import {getCosParentSubcategory, getWorkflowId, State} from '../reducers/index';
import {isSupported} from '../util/workflow-utils';
import {map} from 'rxjs/operators';

@Component({
  selector: 'cbs-cos',
  templateUrl: 'cos.component.html'
})
export class CosComponent implements OnInit {

  @Input() footerTemplate: TemplateRef<Component>;
  @Input() optionColumnLayout = 4;
  @Input() singleColumnProperty = false;
  fieldContainer = FieldContainer.Cos;
  parentSubcategory$: Observable<ParentSubcategory>;
  cosSearchFilter$: Observable<boolean>;

  constructor(private store: Store<State>) {
  }

  ngOnInit() {
    this.parentSubcategory$ = this.store.pipe(select(getCosParentSubcategory));
    this.cosSearchFilter$ = this.store.pipe(select(getWorkflowId), map(workflowId => isSupported(workflowId, 'cosSearchFilter')));
  }

}
