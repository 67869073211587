import {Component, ElementRef, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import {getProductOptionCategories} from '../reducers';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Category} from '../models/Category';

@UntilDestroy()
@Component({
  selector: 'cbs-visualization',
  templateUrl: './visualization.component.html'
})
export class VisualizationComponent {

  @ViewChild('frame') frame: ElementRef;

  private payload = {
    connections: [
      {
        parts: [
          {
            id: '1',
            connectionPoint: 'CableEnd'
          },
          {
            id: '3',
            connectionPoint: 'End1'
          }
        ]
      },
      {
        parts: [
          {
            id: '2',
            connectionPoint: 'CableEnd'
          },
          {
            id: '3',
            connectionPoint: 'End2'
          }
        ]
      }
    ]
  };

  private lastPayload: string;

  constructor(private store: Store) {
  }

  frameLoaded() {
    this.store.select(getProductOptionCategories).pipe(untilDestroyed(this)).subscribe(categories => this.parseCategories(categories));
  }

  parseCategories(categories: Category[]) {
    const parts = [];
    if (categories) {
      categories.forEach(category => {
        category.parentSubcategories.forEach(parentSubcategory => {
          parentSubcategory.subcategories.forEach(subcat => {
            subcat.fields.forEach(field => {
              if (field.visualizationId && field.choices) {
                const selectedChoice = field.choices.find(choice => choice.selected);
                const choiceIdSplit = selectedChoice.visualizationId.split('?');
                const part = {id: field.visualizationId, sku: choiceIdSplit[0]};
                if (choiceIdSplit.length > 1) {
                  new URLSearchParams(choiceIdSplit[1]).forEach((value, key) => {
                    part[key] = value;
                  });
                }
                parts.push(part);
              }
            });
          });
        });
      });
    }
    this.renderModel(parts);
  }

  renderModel(parts) {
    const partsString = JSON.stringify(parts);
    if (partsString !== this.lastPayload) {
      this.lastPayload = partsString;
      const frameWindow = this.frame.nativeElement.contentWindow;
      frameWindow.postMessage({
        type: 'render',
        json: {
          ...this.payload,
          parts
        },
        updateCamera: false
      }, '*');
    }
  }
}
