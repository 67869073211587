import {Component, OnInit} from '@angular/core';
import {ImageService} from 'app/services/image.service';
import {Observable, of} from 'rxjs/index';
import {Breadcrumb, DisplayBreadcrumbs} from '../reducers/workflow.reducer';
import {select, Store} from '@ngrx/store';
import {
  getCustomOriginName, getCustomOriginUrl, getDisplayBreadcrumbs, getEmail, getFeedbackUrl, getFromPortal, getFromPortalCenter,
  getFromPortalToQM, getGlobalInfoUrl, getIfeDomain, getIfeIntegrationInfo, getIqAuthAuthenticated, getItemContainerId,
  getItemContainerType, getLanguages, getLogoLink, getLogoLinkTabTarget, getOriginContainerUrl, getOriginName, getOriginUrl,
  getPortalCenterDashboardUrl, getPortalDashboardUrl, getPrivileges, getProperties, getQuoteHomeUrl, getSelectorUrl, getUomType,
  getUserName, getVersionTitle, getWorkflowId, isFromSelector, isLogoLinkToPortalCenter, isOpenAccess, State
} from '../reducers';
import {SaveVerificationService} from '../services/web/save-verification.service';
import {first, map} from 'rxjs/operators';
import {isNotSupported} from '../util/workflow-utils';
import {CbsService} from '../services/web/cbs.service';
import urlJoin from 'proper-url-join';
import {SafeStore} from '../util/safe-store';
import {ItemcontainerUtil} from '../util/itemcontainer-util';
import {UntilDestroy} from '@ngneat/until-destroy';
import {WindowService} from '../services/window.service';
import {IfeIntegrationInfoState} from '../reducers/ife-integration-info.reducer';

@UntilDestroy()
@Component({
  selector: 'cbs-nav-bar',
  templateUrl: './nav-bar.component.html'
})
export class NavBarComponent implements OnInit {
  displayBreadcrumbs$: Observable<DisplayBreadcrumbs>;
  fromPortal$: Observable<boolean>;
  fromPortalToQM$: Observable<boolean>;
  fromPortalCenter$: Observable<boolean>;
  fromSelector$: Observable<boolean>;
  originUrl$: Observable<string>;
  quoteHomeUrl$: Observable<string>;
  portalDashboardUrl$: Observable<string>;
  portalCenterDashboardUrl$: Observable<string>;
  selectorUrl$: Observable<string>;
  specificUrl$: Observable<string>;
  originName$: Observable<string>;
  customOriginName$: Observable<string>;
  customOriginUrl$: Observable<string>;
  withoutAssociatedItemContainer: boolean;
  nonLinearBreadcrumbs$: Observable<boolean>;
  hideNewItemButton$: Observable<boolean>;
  viewDebugPrivilege: boolean;
  viewDocsPrivilege: boolean;
  logoLink$: Observable<string>;
  logoLinkTabTarget$: Observable<string>;
  isLogoLinkToPortalCenter$: Observable<boolean>;
  itemContainerType$: Observable<number>;

  userName$: Observable<String>;
  email$: Observable<String>;
  logoutUrl$: Observable<String>;
  sessionPrefsUrl$: Observable<String>;
  defaultPrefsUrl$: Observable<String>;
  globalInfoUrl$: Observable<String>;
  languages$: Observable<Map<string, number>>;
  uomType$: Observable<string>;
  openAccessUser$: Observable<boolean>;
  versionTitle$: Observable<string>;
  feedbackUrl$: Observable<string>;
  languageLinks;
  helpUrl$: Observable<string>;
  iqAuthAuthenticated$: Observable<boolean>;
  private safeStore: SafeStore<State>;
  private isItemContainerTypeProject: boolean;
  displayHeaderMenu = false;
  isfromUrl: string;

  constructor(private store: Store<State>,
              private saveVerificationService: SaveVerificationService,
              public image: ImageService,
              private cbsService: CbsService,
              private windowService: WindowService) {

    this.safeStore = new SafeStore(this, this.store);
    this.safeStore.subscribe(getItemContainerId, itemContainerId => this.withoutAssociatedItemContainer = itemContainerId <= 0);
  }

  ngOnInit() {
    this.fromPortal$ = this.store.pipe(select(getFromPortal));
    this.fromPortalToQM$ = this.store.pipe(select(getFromPortalToQM));
    this.fromPortalCenter$ = this.store.pipe(select(getFromPortalCenter));
    this.originUrl$ = this.store.pipe(select(getOriginUrl));
    this.logoLink$ = this.store.pipe(select(getLogoLink));
    this.logoLinkTabTarget$ = this.store.pipe(select(getLogoLinkTabTarget));
    this.isLogoLinkToPortalCenter$ = this.store.pipe(select(isLogoLinkToPortalCenter));
    this.quoteHomeUrl$ = this.store.pipe(select(getQuoteHomeUrl));
    this.portalDashboardUrl$ = this.store.pipe(select(getPortalDashboardUrl));
    this.portalCenterDashboardUrl$ = this.store.pipe(select(getPortalCenterDashboardUrl));
    this.fromSelector$ = this.store.pipe(select(isFromSelector));
    this.selectorUrl$ = this.store.pipe(select(getSelectorUrl));
    this.specificUrl$ = this.store.pipe(select(getOriginContainerUrl));
    this.originName$ = this.store.pipe(select(getOriginName));
    this.customOriginName$ = this.store.pipe(select(getCustomOriginName));
    this.customOriginUrl$ = this.store.pipe(select(getCustomOriginUrl));
    this.displayBreadcrumbs$ = this.store.pipe(select(getDisplayBreadcrumbs));
    this.nonLinearBreadcrumbs$ = this.store.pipe(select(getWorkflowId),
      map(workflowId => isNotSupported(workflowId, 'linearBreadcrumbs')));

    this.userName$ = this.store.pipe(select(getUserName));
    this.email$ = this.store.pipe(select(getEmail));
    this.iqAuthAuthenticated$ = this.store.pipe(select(getIqAuthAuthenticated));
    this.logoutUrl$ = this.store.pipe(select(getIfeDomain), map(domain => urlJoin(domain, 'config/logout')));
    this.defaultPrefsUrl$ = this.store.pipe(select(getIfeIntegrationInfo), first(ife => !!ife.uid), map(ife =>
      ife.ifeDomain + '/config/SaveSettingsRedirect?destAppTarget=/Party/app/preferences&' +
      'preferencesTab=details&showDetailsPage=true&isPopupRequest=true&dirtyUserXT=false&UID='
      + ife.uid + '&returnURL=' + ife.configInitUrl));
    this.sessionPrefsUrl$ = this.store.pipe(select(getIfeIntegrationInfo), first(ife => !!ife.uid), map(ife =>
      ife.ifeDomain + '/config/SaveSettingsRedirect?destAppTarget=/Party/app/preferences&' +
      'preferencesTab=general&showDetailsPage=false&isPopupRequest=true&dirtyUserXT=true&isRequestFromExternalApp=true&UID='
      + ife.uid + '&returnURL=' + ife.configInitUrl));
    this.globalInfoUrl$ = this.store.pipe(select(getGlobalInfoUrl));
    this.languages$ = this.store.pipe(select(getLanguages));
    this.uomType$ = this.store.pipe(select(getUomType));
    this.openAccessUser$ = this.store.pipe(select(isOpenAccess));
    this.languages$.pipe(first(languages => !!languages))
      .subscribe(languages => this.languageLinks = this.getLanguageList(languages));
    this.versionTitle$ = this.store.pipe(select(getVersionTitle));
    this.feedbackUrl$ = this.store.pipe(select(getFeedbackUrl));
    this.helpUrl$ = of('https://docs.fpx.com/ife/#../Subsystems/quick_cbs_help/content/iq/quick_cbs_help.htm');
    this.hideNewItemButton$ = this.store.pipe(select(getProperties), map(properties => properties.hideNewItemButton));
    this.safeStore.subscribe(getItemContainerType,
      itemContainerType => this.isItemContainerTypeProject = ItemcontainerUtil.isProject(itemContainerType));
    this.itemContainerType$ = this.store.pipe(select(getItemContainerType));
    this.safeStore.subscribe(getPrivileges, privileges => {
      this.viewDebugPrivilege = privileges['viewKeOrAllLogsPrivilege'];
      this.viewDocsPrivilege = privileges['viewDocsPrivilege'];
    });
  this.store.pipe(select(getIfeIntegrationInfo)).subscribe((ife: IfeIntegrationInfoState) => {
    this.isfromUrl = ife.isFromUrl
    if ( this.isfromUrl === 'true') {
      this.originUrl$ =  this.store.pipe(select(getIfeDomain), map(domain => urlJoin(domain, 'config/logout')));
     }
    });
  }

  safeDisplayCheck(breadcrumb: Breadcrumb): boolean {
    return breadcrumb && breadcrumb.display;
  }

  verifyLink(url) {
    this.saveVerificationService.verifyLink(url);
  }

  goToUrl(url) {
    this.windowService.getWindow().location.href = url;
  }

  determineTextLabel(itemContainerType: number) {
    switch (itemContainerType) {
      case 1:
        return 'quote';
      case 2:
        return 'order';
      case 3:
        return 'project';
    }
  }

  languageList(languages: Map<string, number>): string[] {
    return Object.keys(languages).sort((a, b) => languages[a] - languages[b]);
  }

  changeLanguage = (languageObject: { language: string, languages: Map<string, number> }) => {
    this.cbsService.changeLanguage(languageObject.languages[languageObject.language]);
  };
  changeUnit = (unit: string) => {
    this.cbsService.changeUnits(unit);
  };

  getLanguageList(languages) {
    const languageList = [];
    for (const language of this.languageList(languages)) {
      languageList.push({description: language, clickFunction: this.changeLanguage, functionInput: {language, languages}});
    }
    return languageList;
  }

  ternaryDisplay(conditional: boolean, truecase: string, falsecase: string): string {
    return conditional ? truecase : falsecase;
  }
}
