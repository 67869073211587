import {Component, OnInit} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {PartNumberAliasService} from '../../services/web/part-number-alias.service';

@UntilDestroy()
@Component({
  templateUrl: './part-number-dialog.component.html',
  styles: [`
    :host {
      display: block;
      width: 600px;
    }
  `],
  providers: [PartNumberAliasService]
})
export class PartNumberDialogComponent implements OnInit {

  orgUnit: { id: number; name: string };
  orgUnits: any[];
  selectedTab: 'data' | 'missing' | 'spreadsheet' = 'data';

  constructor(public aliasService: PartNumberAliasService) {
  }

  ngOnInit() {
    this.aliasService.getAdmin().subscribe((value: any) => {
      this.orgUnit = value.orgUnit;
      this.orgUnits = value.orgUnits;
    });
  }

  changeTab(tab) {
    this.selectedTab = tab;
  }

  compareById(item1, item2) {
    return item1?.id === item2?.id;
  }
}
