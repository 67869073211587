import {Component} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {getIfeIntegrationInfo, State} from '../reducers/index';
import {Observable} from 'rxjs';

import urlJoin from 'proper-url-join';
import {SaveVerificationService} from '../services/web/save-verification.service';
import {map} from 'rxjs/operators';

@Component({
  selector: 'cbs-new-item',
  templateUrl: './new-item.component.html'
})
export class NewItemComponent {
  newItemUrl$: Observable<string>;

  constructor(private store: Store<State>, private saveVerificationService: SaveVerificationService) {
    this.newItemUrl$ = this.store.pipe(select(getIfeIntegrationInfo), map(ife => {
      return urlJoin(ife.ifeDomain, 'config/newItem?newItemID=' + ife.newItemChoiceId);
    }));
  }

  verifyLink(url) {
    this.saveVerificationService.verifyLink(url);
  }
}
