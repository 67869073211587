import {Component, OnInit} from '@angular/core';
import {getProperties, State} from '../reducers/index';
import {Store} from '@ngrx/store';
import {assignFirstTruthy} from '../util/rx-utils';
import {WorkflowActions} from '../actions/workflow.action';

@Component({
  selector: 'cbs-cos-route',
  templateUrl: './cos-route.component.html'
})
export class CosRouteComponent implements OnInit {

  cos_columnCount;

  constructor(private store: Store<State>) {
    assignFirstTruthy(store, getProperties, properties => this.cos_columnCount = properties.cos_singleColumn ? 1 : 4);
  }

  ngOnInit(): void {
    this.store.dispatch(WorkflowActions.displayBreadcrumbs({
      breadcrumbs: {inputs: {display: true, target: 'cos'}}
    }));
    this.store.dispatch(WorkflowActions.displayData({
      payload: {
        displayPrice: false,
        displaySaveButton: false,
        displaySearchButton: true,
        createCustomOptions: false
      }
    }));
  }

}
