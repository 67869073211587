<div [ngSwitch]="field.displayType">
  <cbs-visualization *ngSwitchCase="'VISUALIZATION'"></cbs-visualization>

  <div *ngSwitchCase="'DROPDOWN'">
    <label [attr.for]="field.id">
      <ng-container *ngTemplateOutlet="titleDisplay"></ng-container>
      <div class="mat-align">
        <ng-container *ngTemplateOutlet="dropdownAndOverride;context:{selectedChoice: selectedChoice()}"></ng-container>
        <ng-template #dropdownAndOverride let-selectedChoice="selectedChoice">
          <mat-form-field>
            <mat-select [name]="field.httpName" [(ngModel)]="selectValue" [id]="field.id" [disabled]="isDisabled()"
                        (selectionChange)="submitDropdownFieldChange(selectValue)" #dropdownFieldVar>
              <mat-select-trigger style="display: block;"
                                  [ngClass]="{'graphic-top': selectedChoice?.graphicDisplayLocation === 'INLINE_TOP',
                                              'graphic-left': selectedChoice?.graphicDisplayLocation === 'INLINE_LEFT'}">
                <ng-container *ngTemplateOutlet="optionGraphicInline;context:{container:selectedChoice}"></ng-container>
                <div class="selected-option-text">{{displayVal(selectedChoice)}}
                  <ng-container *ngTemplateOutlet="popOverTrigger;context:{option: selectedChoice}"></ng-container>
                  <span style="float: right">{{selectedChoice && ((selectedChoice.price | cbsLabel) || selectedChoice.price)}}</span>
                </div>
              </mat-select-trigger>
              <mat-option *ngFor="let choice of field.choices"
                          [ngClass]="{'graphic-top': choice.graphicDisplayLocation === 'INLINE_TOP',
                                              'graphic-left': choice.graphicDisplayLocation === 'INLINE_LEFT'}"
                          [ngStyle]="{'height': isOptionGraphicDisplay_Inline(choice) && graphicSize[choice.graphicDisplaySize],
                                        'margin-bottom': isOptionGraphicDisplay_Inline(choice)? '5px' : ''}"
                          [value]="choice.httpValue" [id]="choice.id" [selected]="choice.selected">
                <ng-container *ngTemplateOutlet="optionGraphicInline;context:{container:choice}"></ng-container>
                <div class="selected-option-text">
                  {{choice.description}}
                  <ng-container *ngTemplateOutlet="popOverTrigger;context:{option: choice}"></ng-container>
                  <span style="float: right">{{ (choice.price | cbsLabel) || choice.price}}</span>
                </div>
              </mat-option>
            </mat-select>
            <mat-hint align="start" *ngIf="isInvalid()" class="text-alert">
              {{field.description}} cannot be blank
            </mat-hint>
          </mat-form-field>
          <button *ngIf="canOverride() && selectedChoice" (click)="openOverrideDialog(selectedChoice)" class="p-l-base"
                  style="margin-bottom: 1.25em; tab-index: -1;" test-id="EDIT_OPTION_DETAILS_BUTTON"
                  matTooltip="{{'editOptionDetails' | cbsLabel}}">
            <i class="far fa-edit"></i>
          </button>
        </ng-template>
      </div>
    </label>
  </div>

  <div *ngSwitchCase="'AUTOCOMPLETE'">
    <label [attr.for]="field.id">
      <ng-container *ngTemplateOutlet="titleDisplay"></ng-container>
      <div class="mat-align">
        <mat-form-field floatLabel="never" class="mat-autocomplete" *ngIf="!isDisabled(true); else disabledDropdown">
          <input matInput [formControl]="filterControl" [matAutocomplete]="autoComplete" [id]="field.id"
                 [placeholder]="displayVal(selectedChoice())" #autocompleteField (keyup.enter)="verify(autocompleteField.value)"/>
          <mat-autocomplete #autoComplete="matAutocomplete" (optionSelected)="autoCompleteSelected($event)"
                            [displayWith]="displayFn">
            <mat-option *ngFor="let choice of autoFilter$ | async" [id]="choice.id" [value]="choice">
              {{choice.description}}
              <ng-container *ngTemplateOutlet="popOverTrigger;context:{option: choice}"></ng-container>
            </mat-option>
          </mat-autocomplete>
          <mat-hint align="start" *ngIf="isInvalid()" class="text-alert">
            {{field.description}} cannot be blank
          </mat-hint>
          <mat-select matSuffix tabindex="-1" (click)="preventDropdown"
                      style="cursor: text !important;"></mat-select>
        </mat-form-field>
        <ng-template #disabledDropdown>
          <mat-form-field>
            <mat-select [name]="field.httpName" [(ngModel)]="selectValue" [id]="field.id" [disabled]="true"
                        (selectionChange)="submitDropdownFieldChange(selectValue)" #dropdownFieldVar>
              <mat-option *ngFor="let choice of field.choices"
                          [value]="choice.httpValue" [id]="choice.id" [selected]="choice.selected">
                {{choice.description}}
                <ng-container *ngTemplateOutlet="popOverTrigger;context:{option: choice}"></ng-container>
              </mat-option>
            </mat-select>
            <mat-hint align="start" *ngIf="isInvalid()" class="text-alert">
              {{field.description}} cannot be blank
            </mat-hint>
          </mat-form-field>
        </ng-template>
      </div>
    </label>
  </div>

  <div *ngSwitchCase="'RADIO'" style="padding-bottom: 1.25em">
    <label [attr.for]="field.id">
      <ng-container *ngTemplateOutlet="titleDisplay"></ng-container>
      <mat-radio-group [name]="field.id" style="flex-direction: column; display: inline-flex;">
        <div *ngFor="let choice of paginatedChoices()"
             style="display: table; table-layout: fixed; padding-left: .5rem; position: relative;"
             [ngClass]="{'alternating': alternateShading}">
          <ng-template #alert>
            <cbs-alert [content]="choice" [fieldContainer]="fieldContainer"
                       [optionColumnLayout]="optionColumnLayout"></cbs-alert>
          </ng-template>
          <ng-container *ngIf="!choice.alert; else alert">
            <mat-radio-button style=" align-items: inherit"
                              [ngStyle]="{'width': isMobile() ? '' : '100%', 'display': isMobile() ? '' : 'table-cell'}"
                              [value]="choice.httpValue" [id]="choice.id" [checked]="choice.selected"
                              [name]="field.id" [disableRipple]="isMobile()"
                              (change)="submitDropdownFieldChange(choice.httpValue)" [disabled]="isDisabled(true)"
                              [ngClass]="{'graphic-top': choice.graphicDisplayLocation === 'INLINE_TOP',
                                            'graphic-left': choice.graphicDisplayLocation === 'INLINE_LEFT'}">
              <div class="option-price"
                   [ngStyle]="{'width': getPartNumberWidth(displayPartNumber$|async) + 'rem',
                     'padding-right': getPartNumberWidth(displayPartNumber$|async) > 0 ? '.5rem' : '0'}">
                <ng-container *ngIf="getPartNumberWidth(displayPartNumber$|async) > 0">{{choice.partNumber}}
                </ng-container>
              </div>
              <ng-container *ngTemplateOutlet="optionGraphicInline;context:{container:choice, marginTop:'5px'}"></ng-container>
              <div style="display: table-cell; text-align: left; vertical-align: middle; word-break: break-word"
                   [ngStyle]="{'height': isOptionGraphicDisplay_Inline(choice) && graphicSize[choice.graphicDisplaySize],
                                   'margin-top': isOptionGraphicDisplay_Inline(choice)? '5px' : ''}">
                <span class="option-description">{{choice.description}}</span>
                <ng-container *ngTemplateOutlet="popOverTrigger;context:{option: choice}"></ng-container>
              </div>
            </mat-radio-button>
            <div class="option-price hide-for-small-only text-right"
                 [ngStyle]="{'width': getPriceWidth() + additionalSpace(choice) + 'rem'}">
                  <span style="float: left; width: 0"><cbs-loading
                    [display]="choice.displayLoading && !(field.description && displayTitle() && field.displayLoading)"></cbs-loading></span>
              <ng-container
                *ngTemplateOutlet="priceDisplay;context:{option: choice, width: getPriceWidth() + additionalSpace(choice) - 2}"></ng-container>
              <button *ngIf="canOverride()" test-id="EDIT_OPTION_DETAILS_BUTTON" (click)="openOverrideDialog(choice)"
                      matTooltip="{{'editOptionDetails' | cbsLabel}}"><i
                class="far fa-edit"></i>
              </button>
            </div>
          </ng-container>
          <div *ngIf="canOverride() || getPriceWidth()" class="show-for-small-only hidden-input-padding"
               style="float: right; text-align: end">
                <span style="float: left; width: 0;"><cbs-loading
                  [display]="choice.displayLoading && !(field.description && displayTitle() && field.displayLoading)"></cbs-loading></span>
            <ng-container
              *ngTemplateOutlet="priceDisplay;context:{option: choice, width: getPriceWidth()}"></ng-container>
            <button *ngIf="canOverride()" test-id="EDIT_OPTION_DETAILS_BUTTON" (click)="openOverrideDialog(choice)"
                    matTooltip="{{'editOptionDetails' | cbsLabel}}"><i
              class="far fa-edit"></i>
            </button>
          </div>
        </div>
      </mat-radio-group>
      <mat-paginator *ngIf="isPaginated()" #paginator style="background: none"
                     [hidePageSize]="true"
                     [length]="field.choices.length"
                     [pageIndex]="this.choicesPage"
                     [pageSize]="this.choicesPerPage"
                     (page)="updateChoices($event)">
      </mat-paginator>
    </label>
  </div>

  <div *ngSwitchCase="'MULTIPICK'" style="padding-bottom: 1.25em">
    <label [attr.for]="field.id">
      <ng-container *ngIf="field.description">
        <ng-container *ngTemplateOutlet="titleDisplay"></ng-container>
      </ng-container>
      <div>
        <div *ngFor="let choice of paginatedChoices(); trackBy: trackByChoice" [ngClass]="{'alternating': alternateShading}"
             style="padding-left: .5rem">
          <ng-template #alert>
            <cbs-alert [content]="choice" [fieldContainer]="fieldContainer"
                       [optionColumnLayout]="optionColumnLayout"></cbs-alert>
          </ng-template>
          <ng-container *ngIf="!choice.alert; else alert">
            <label [attr.for]="choice.id"
                   [ngStyle]="{'display: table; width: 100%; table-layout: fixed': true, 'font-style': choice.optional ? 'italic' : 'normal'}">
              <ng-container *ngIf="choice.numericValue != null; else checkbox">
                <div style="display: flex; justify-content: space-between">
                  <span style="margin: auto 0" class="option-description">{{choice.description}}</span>
                  <span style="max-width: 16rem; display: flex; padding: 5px 0; justify-content: flex-end; align-items: flex-start;">
                      <input [name]="choice.httpName" [id]="choice.id" [value]="choice.value"
                             [ngStyle]="{'max-width': hasUnitOfMeasure(choice) ? 'calc(100% - 6rem)' : '80%'}"
                             (change)="submitNumericChoiceChange(choice, numericChoiceVar.value, paginator.pageIndex)"
                             type="text" #numericChoiceVar>
                      <select style="width: 6rem;margin: 0" [disabled]="isDisabled(true)" *ngIf="hasUnitOfMeasure(choice)"
                              [name]="choice.unitOfMeasureField.httpName"
                              [id]="choice.unitOfMeasureField.id"
                              (change)="submitUOMChange(uomVar.value, choice, paginator.pageIndex)"
                              #uomVar>
                        <option *ngFor="let unit of choice.unitOfMeasureField.choices" [selected]="unit.selected"
                                [value]="unit.httpValue" [id]="unit.id">
                          {{unit.description}}
                        </option>
                      </select>
                    </span>
                </div>
              </ng-container>
              <ng-template #checkbox>
                <mat-checkbox style="position: relative" [ngClass]="{'graphic-top': choice.graphicDisplayLocation === 'INLINE_TOP',
                      'graphic-left': choice.graphicDisplayLocation === 'INLINE_LEFT'}"
                              [ngStyle]="{'width': isMobile() ? '' : '100%', 'display': isMobile() ? '' : 'table-cell',
                            'clear': isMobile() ? 'left' : ''}"
                              [value]="choice.httpValue" [id]="choice.id" [checked]="choice.selected"
                              [disabled]="isDisabled(true) && !choice.editable"
                              [disableRipple]="isMobile()"
                              (change)="submitMultipickFieldChange(choice, paginator.pageIndex)">
                  <div class="option-price" [ngStyle]="{'width': getPartNumberWidth(displayPartNumber$|async) + 'rem'}">
                    <ng-container *ngIf="getPartNumberWidth(displayPartNumber$|async) > 0">{{choice.partNumber}}</ng-container>
                  </div>
                  <ng-container *ngTemplateOutlet="optionGraphicInline;context:{container:choice,marginBottom:'5px'}"></ng-container>
                  <div [ngStyle]="{'height': isOptionGraphicDisplay_Inline(choice) && graphicSize[choice.graphicDisplaySize],
                         'margin-top': isOptionGraphicDisplay_Inline(choice) ? '5px' : '',
                         'vertical-align': isMobile() ? '' : 'middle'}">
                      <span style="display: flex; align-items: center">
                        <span class="option-description">{{choice.description}}</span>
                        <ng-container *ngTemplateOutlet="popOverTrigger;context:{option: choice}"></ng-container>
                      </span>
                  </div>
                </mat-checkbox>
              </ng-template>
              <div class="option-price hide-for-small-only text-right"
                   [ngStyle]="{'width': getPriceWidth() + additionalSpace(choice) + 'rem'}">
                  <span style="float: left; width: 0; margin-left: -1rem"><cbs-loading
                    [display]="choice.displayLoading && !(field.description && displayTitle() && field.displayLoading)"></cbs-loading></span>
                <ng-container
                  *ngTemplateOutlet="priceDisplay;context:{option: choice, width: getPriceWidth() + additionalSpace(choice) - 2}"></ng-container>
                <button *ngIf="canOverride()" test-id="EDIT_OPTION_DETAILS_BUTTON" (click)="openOverrideDialog(choice)"
                        matTooltip="{{'editOptionDetails' | cbsLabel}}"><i
                  class="far fa-edit"></i>
                </button>
              </div>
              <div *ngIf="canOverride() || getPriceWidth()" class="show-for-small-only hidden-input-padding"
                   style="min-height: 1.5rem;float: right; text-align: end">
                  <span style="float: left; width: 0; margin-left: -1rem"><cbs-loading
                    [display]="choice.displayLoading && !(field.description && displayTitle() && field.displayLoading)"></cbs-loading></span>
                <ng-container
                  *ngTemplateOutlet="priceDisplay;context:{option: choice, width: getPriceWidth()}"></ng-container>
                <button *ngIf="canOverride()" test-id="EDIT_OPTION_DETAILS_BUTTON" (click)="openOverrideDialog(choice)"
                        matTooltip="{{'editOptionDetails' | cbsLabel}}"><i
                  class="far fa-edit"></i>
                </button>
              </div>
            </label>
          </ng-container>
        </div>
        <mat-paginator [ngStyle]="{'display': isPaginated() ? '' : 'none' }" #paginator style="background: none"
                       [length]="field.choices.length"
                       [pageIndex]="this.choicesPage"
                       [pageSize]="this.choicesPerPage"
                       (page)="updateChoices($event)">
        </mat-paginator>
      </div>
    </label>
  </div>

  <div *ngSwitchCase="'READONLY'">
    <label style="padding-bottom:.8rem">
      <div *ngIf="!field.alert; else alert" [ngClass]="{'flex-grid flex-shrink': field.labelLocation === 'LEFT'}">
        <ng-container *ngTemplateOutlet="titleDisplay"></ng-container>
        <div [id]="field.id"
             [ngClass]="{'graphic-top': field.graphicDisplayLocation === 'INLINE_TOP',
                        'graphic-left': field.graphicDisplayLocation === 'INLINE_LEFT'}">
          <ng-container *ngTemplateOutlet="optionGraphicInline;context:{container:field}"></ng-container>
          <span style="padding-left:.5rem; display:inline-block" [innerHtml]="field.value"></span>
          <ng-container *ngIf="displayQuantity(field)">
            <i class="fas fa-times" data-fa-transform="shrink-4 down-1"></i>
            {{field.quantity}}
          </ng-container>
        </div>
      </div>
    </label>
  </div>

  <div *ngSwitchCase="'CUSTOM_OPTION'">
    <label style="padding-bottom:.8rem; padding-top: 1rem">
      <div [id]="field.id" [ngStyle]="{'font-style': field.optional ? 'italic' : 'normal'}"
           [ngClass]="{'graphic-top': field.graphicDisplayLocation === 'INLINE_TOP',
                       'graphic-left': field.graphicDisplayLocation === 'INLINE_LEFT'}">
        <ng-container *ngTemplateOutlet="optionGraphicInline;context:{container:field}"></ng-container>
        {{field.description}}
        <div style="display: inline-flex; float: right">
          <cbs-loading [display]="field.displayLoading"></cbs-loading>
          <ng-container *ngTemplateOutlet="priceDisplay;context:{option: field}"></ng-container>
          <button (click)="openOverrideDialog(field)" test-id="EDIT_OPTION_DETAILS_BUTTON" matTooltip="{{'editOptionDetails' | cbsLabel}}">
            <i class="far fa-edit"></i>
          </button>
          <button test-id="DELETE_CUSTOM_FIELD_BUTTON" (click)="deleteCustomField(field)"><i class="fas fa-times-circle"></i></button>
        </div>
      </div>
    </label>
  </div>

  <div *ngSwitchCase="isNumericDisplay()">
    <ng-container *ngIf="hasUnitOfMeasure(); else numeric">
      <label [attr.for]="field.id">
        <ng-container *ngTemplateOutlet="titleDisplay"></ng-container>
        <div class="mat-align" *ngIf="!field.alert; else alert">
          <mat-form-field [ngClass]="{'skinny-cos': optionColumnLayout == 1}">
            <input matInput [name]="field.httpName" [id]="field.id" [value]="field.value"
                   (focus)="setFocus()" [disabled]="isDisabled()"
                   (change)="fieldChanged()"
                   (blur)="checkSubmit(numericFieldVar.value, selectValue)"
                   (keyup.enter)="uomSubmit(numericFieldVar.value, selectValue)"
                   #numericFieldVar/>
            <mat-hint align="start" *ngIf="isInvalid()" class="text-alert">
              Value must be between {{field.minValue|number}} and {{field.maxValue|number}}
            </mat-hint>
            <mat-select matSuffix [disabled]="isDisabled()" *ngIf="hasUnitOfMeasureChoices(); else readOnly"
                        [name]="field.unitOfMeasureField.httpName" [(ngModel)]="selectValue"
                        [id]="field.unitOfMeasureField.id"
                        (click)="setFocus()"
                        (focus)="setFocus()"
                        (selectionChange)="uomChanged()"
                        (blur)="checkSubmit(numericFieldVar.value, selectValue)"
                        (keyup.enter)="uomSubmit(numericFieldVar.value, selectValue)"
                        #uomVar>
              <mat-option *ngFor="let unit of field.unitOfMeasureField.choices"
                          [value]="unit.httpValue" [id]="unit.id">
                {{unit.description}}
              </mat-option>
            </mat-select>
            <ng-template #readOnly>
              <span matSuffix>{{unitOfMeasureSymbol()}}</span>
            </ng-template>
          </mat-form-field>
          <cbs-spinner *ngIf="field.displayType === 'NUMERIC_SPINNER'"
                       [field]="field"
                       [categoryId]="categoryId" [parentSubcatId]="parentSubcatId" [subcatId]="subcatId"
                       [fieldContainer]="fieldContainer" [pageId]="pageId"></cbs-spinner>

        </div>
      </label>
    </ng-container>
  </div>

  <ng-template #numeric>
    <label [attr.for]="field.id">
      <ng-container *ngTemplateOutlet="titleDisplay"></ng-container>
      <div class="mat-align" *ngIf="!field.alert; else alert">
        <mat-form-field>
          <input matInput [name]="field.httpName" [id]="field.id" [value]="field.value"
                 [disabled]="isDisabled()" autocomplete="off" (keypress)="numberOnlyValidatingQty($event)"
                 (change)="submitNumericFieldChangeValidatingQty(numericFieldVar)"
                 #numericFieldVar>
          <mat-hint align="start" *ngIf="isInvalid()" class="text-alert">
            Value must be between {{field.minValue|number}} and {{field.maxValue|number}}
          </mat-hint>
        </mat-form-field>

        <cbs-spinner *ngIf="field.displayType === 'NUMERIC_SPINNER'"
                     [field]="field"
                     [categoryId]="categoryId" [parentSubcatId]="parentSubcatId" [subcatId]="subcatId"
                     [fieldContainer]="fieldContainer" [pageId]="pageId"></cbs-spinner>
      </div>
    </label>
  </ng-template>

  <div *ngSwitchCase="'TEXT_INPUT'">
    <label [attr.for]="field.id">
      <ng-container *ngTemplateOutlet="titleDisplay"></ng-container>
      <div *ngIf="!field.alert; else alert">
        <mat-form-field>
          <ng-container *ngIf="field.textArea==='NO'; else textArea">
            <input matInput [name]="field.httpName" [id]="field.id" [value]="field.value" [disabled]="isDisabled()"
                   autocomplete="off"
                   (change)="submitTextFieldChange(textInputFieldVar.value)" #textInputFieldVar>
          </ng-container>
          <mat-hint align="start" *ngIf="isInvalid()" class="text-alert">
            {{'mandatory' | cbsLabel}}: {{field.description}}
          </mat-hint>
          <ng-template #textArea>
          <textarea matInput rows="{{numRows[field.textArea]}}" [name]="field.httpName" [id]="field.id"
                    [value]="field.value" [disabled]="isDisabled()"
                    (change)="submitTextFieldChange(textInputFieldVar.value)" #textInputFieldVar>
          </textarea>
          </ng-template>
        </mat-form-field>
      </div>
    </label>
  </div>
  <div *ngSwitchCase="'CHART'">
    <label [attr.for]="field.id">
      <!--Currently Description isn't supported for chart items-->
      <!--<ng-container *ngTemplateOutlet="titleDisplay"></ng-container>-->
      <div id="chart">
        <ng-container *ngIf="chartUrl; else loading">
          <object id="svgObject" type="image/svg+xml" [data]="chartUrl"></object>
        </ng-container>
      </div>
    </label>
  </div>
</div>

<ng-container *ngIf="(displayPartNumber$|async)"></ng-container>

<ng-template #infoUrl let-option="option">
  <ng-container *ngIf="option.infoUrl">
    <sup><a [href]="option.infoUrl" target="_blank" matTooltip="Click to view external webpage"
            matTooltipPosition="above" test-id="EXTERNAL_LINK">
      <span class="fas fa-external-link-alt fa-fw"></span></a></sup>
  </ng-container>
</ng-template>

<ng-template #popOverTrigger let-option="option">
  <ng-container *ngTemplateOutlet="infoUrl; context:{option: option}"></ng-container>
  <sup *ngIf="isOptionGraphicDisplay_Comment(option)||option.optionComments"
       [ngbPopover]="tooltip" container="body" placement="right bottom left">
    <span class="fas fa-info-circle fa-fw"></span>
  </sup>
  <ng-template #tooltip>
    <div>
      <img *ngIf="isOptionGraphicDisplay_Comment(option)"
           [ngStyle]="{'height': graphicSize[option.graphicDisplaySize]}"
           [src]="getGraphicUrl(option.optionGraphic)|async">
      <span [innerHtml]="option.optionComments"></span>
    </div>
  </ng-template>
</ng-template>

<ng-template #alert>
  <cbs-alert [content]="field" [fieldContainer]="fieldContainer"
             [optionColumnLayout]="optionColumnLayout"></cbs-alert>
</ng-template>

<ng-template #priceDisplay let-option="option" let-priceWidth="width">
<span style="display: inline-block; padding-right: 2.5px"
      [ngStyle]="{'white-space': option.price && (option.price.toLowerCase() | cbsLabel) ? 'normal' : '',
      'width': option.price && viewPricing ? priceWidth + 'rem' : ''}">
    <ng-container *ngIf="displayQuantity(option)">{{option.quantity}}&times;</ng-container>
    <ng-container *ngIf="option.price && viewPricing">
  {{ (option.price | cbsLabel) || option.price}}<span *ngIf="option.priceOverridden"
                                                      matTooltip="{{'priceOverriddenByUser' | cbsLabel}}">*</span>
</ng-container></span>
</ng-template>

<ng-template #titleDisplay>
  <div *ngIf="displayTitle() && !!field.description" style="font-weight: 600"
       [ngStyle]="{width: field.labelLocation === 'LEFT' ? '30%' : '100%'}">
    {{fieldDescriptionDisplay()}}
    <span *ngIf="field.mandatory" class="text-alert">*</span>
    <ng-container *ngTemplateOutlet="popOverTrigger;context:{option: field}"></ng-container>
    <cbs-loading [display]="field.displayLoading"></cbs-loading>
  </div>
</ng-template>

<ng-template #optionGraphicInline let-container="container" let-marginTop="marginTop" let-marginBottom="marginBottom">
  <img *ngIf="isOptionGraphicDisplay_Inline(container)"
       [ngStyle]="{'height': graphicSize[container.graphicDisplaySize],
                   'margin-top': marginTop, 'margin-bottom': marginBottom}"
       [src]="getGraphicUrl(container.optionGraphic)|async">
</ng-template>
