import {Injectable} from '@angular/core';
import * as Rollbar from 'rollbar';
import {Store} from '@ngrx/store';
import {IfeIntegrationInfoState} from '../reducers/ife-integration-info.reducer';
import {environment} from '../../environments/environment';
import {alertUser} from '../services/alert.service';
import {EMPTY, Observable} from 'rxjs';
import {UrlUtil} from '../util/url-util';
import {HttpErrorResponse} from '@angular/common/http';
import {selectFirstTruthy} from '../util/rx-utils';

/** duplicated to avoid a circular dependency with reducers/index.ts */
const _getIfeIntegrationInfo = (state: any) => state.ifeIntegrationInfo;
const _getLabels = (state: any) => state.labels;

@Injectable()
export class CbsRollbarService extends Rollbar {
  ife: IfeIntegrationInfoState;

  constructor(store?: Store<any>) {
    super(getRollbarConfig());
    this.checkForDisabledRollbar();

    // if (store) {
    //   store.pipe(select(_getIfeIntegrationInfo), first(ifeInfo => !!ifeInfo.initFromResponse))
    //     .subscribe(ifeInfo => {
    //       this.ife = ifeInfo;
    //       this.configureRollbar(ifeInfo);
    //     });
    // }
  }

  /** This is only necessary when manually creating the CbsRollbarService without the Store param */
  initFromState(state: any) {
    this.ife = _getIfeIntegrationInfo(state);
    this.configureRollbar(this.ife);
  }

  error(message: String, error?: Error, data = {}, callback?: Function): any {
    message = message || (error && error.message);
    const compressedData = {payload: data, ife: this.compress(this.ife, 'languages')};

    if (!environment.rollbar.enabled) {
      console.error(message, error, compressedData);
    }
    return super.error(message, compressedData, error);
  }

  compress(obj: Object, elem: string): Object {
    if (obj) {
      return {...obj, [elem]: [obj[elem]]};
    } else {
      return obj;
    }
  }

  /**
   * Indicates a web api error occurred.
   * Returns an empty Observable to simplify the caller's Observable.catch usage.
   */
  apiError(err: Response | any, data?: Object, alertUserMessage?: string, store?: Store<any>): Observable<any> {
    const message = alertUserMessage || 'Http Error';
    err = (err instanceof Error || err instanceof HttpErrorResponse) ? err : new Error(err);

    // noinspection JSIgnoredPromiseFromCall
    if (err.status === 401) {
      selectFirstTruthy(store, _getLabels).subscribe(labels => {
        alertUser(labels.labels['sessionTimeoutWarning3']);
        console.error(message, err, data);
      });
      return EMPTY;
    }
    this.error(message, err, data);
    alertUser(alertUserMessage);

    return EMPTY;
  }

  initError(e?: HttpErrorResponse) {
    let message = 'User Session not set';
    let userMessage = message + ', please log in.';
    if (e.status !== 400) {
      message = e.statusText;
      userMessage = message + ', ' + e.error;
    }
    this.error(message, new Error(message), UrlUtil.getCurrentUrl());
    alertUser(userMessage);
  }

  private configureRollbar(ifeInfo: IfeIntegrationInfoState) {
    super.global({maxItems: 50});
    if (environment.rollbar.enabled) {
      console.log('configuring rollbar');
      super.configure({
        payload: {
          environment: ifeInfo.env ? ifeInfo.env : 'unknown',
          person: {
            id: ifeInfo.userName ? ifeInfo.userName : 'unknown',
            email: ifeInfo.email ? ifeInfo.email : ''
          }
        },
        autoInstrument: {
          network: true,
          log: true,
          dom: true,
          navigation: true,
          connectivity: true
        }
      });
    }
  }

  private checkForDisabledRollbar() {
    if (!environment.rollbar.enabled) {
      super.configure({enabled: false});
      console.log('Rollbar disabled');
    }
  }

}

export const getRollbarConfig = () => {
  return {
    accessToken: '31c35e3b6ef6457eaec066c3ab691f0b',
    captureUncaught: true,
    captureUnhandledRejections: true,
    verbose: true,

    payload: {
      client: {
        javascript: {
          code_version: environment.serverEnv,
          source_map_enabled: true,
          guess_uncaught_frames: true,
        }
      }
    },

    // replace domain in sourcemap url with 'dynamichost' to support all domains
    transform: function (payload) {
      const trace = payload.body.trace;
      const locRegex = getLocRegex();
      if (trace && trace.frames) {
        for (let i = 0; i < trace.frames.length; i++) {
          const filename = trace.frames[i].filename;
          if (filename) {
            const m = filename.match(locRegex);
            // Be sure that the minified_url when uploading includes 'dynamichost'
            trace.frames[i].filename = m[1] + '://dynamichost' + m[2];
          }
        }
      }
    }
  };
};

export const getLocRegex = () => {
  // Change 'yourdomainhere' to your domain.
  // var locRegex = /^(https?):\/\/[a-zA-Z0-9._-]+\.yourdomainhere\.com(.*)/;
  const escapedHost = UrlUtil.getHost().replace('.', '\\.');
  return new RegExp('^(https?):\\/\\/' + escapedHost + '(.*)');
};
