import {Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {getEditPriceSheetColumns, getPrivileges, getProductOptionPages, getProperties, State} from '../reducers/index';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {selectFirstTruthy} from '../util/rx-utils';
import {CategoryMenuStrategy, SubNavStrategy} from '../reducers/properties.reducer';
import {UntilDestroy} from '@ngneat/until-destroy';
import {SafeStore} from '../util/safe-store';
import {Page} from '../models/Page';
import {Router} from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'cbs-left-nav',
  templateUrl: './left-nav.component.html',
  styles: [`
    :host, aside {
      display: flex;
      flex-direction: column;
    }

    aside {
      max-height: 100%;
    }
  `]
})
export class LeftNavComponent implements OnInit {
  @Input() configRoot = '/product-options';
  @Input() selectedPage: string;
  viewDocsPrivilege$: Observable<boolean>;
  pages$: Observable<Page[]>;
  priceSummaryColumns$: Observable<any[]>;
  showPages = false;
  showCategoryMenu = false;
  categoryMenuStrategy: CategoryMenuStrategy;

  constructor(private store: Store<State>, private router: Router) {
  }

  ngOnInit(): void {
    const safeStore = new SafeStore(this, this.store);
    safeStore.subscribe(getProperties, (properties) => {
      this.showPages = properties.po_subnavStrategy === SubNavStrategy.LEFT;
      this.categoryMenuStrategy = properties.po_categoryMenuStrategy;
      this.showCategoryMenu = this.categoryMenuStrategy === CategoryMenuStrategy.CATEGORY ||
        this.categoryMenuStrategy === CategoryMenuStrategy.SUB_CATEGORY;
    });
    this.pages$ = this.store.select(getProductOptionPages);
    this.viewDocsPrivilege$ = selectFirstTruthy(this.store, getPrivileges).pipe(
      map(privileges => privileges['viewDocsPrivilege']));
    this.priceSummaryColumns$ = this.store.select(getEditPriceSheetColumns);
  }

  isDocumentsPageActive() {
    return this.router.url === '/documents';
  }

  isPriceSummaryPageActive() {
    return this.router.url === '/price-summary';
  }

  isConfigurePageActive() {
    return this.router.url === this.configRoot;
  }

  isCustomPageActive(page: Page) {
    return page.id === this.selectedPage;
  }
}
