import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {getGroupingSymbol, getIfeDomain, State} from '../../reducers/index';
import urlJoin from 'proper-url-join';
import {CosFieldChangeResponse} from './cos-field-change-response';
import {CbsRollbarService} from '../../rollbar/cbs-rollbar.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {assignFirstTruthy} from '../../util/rx-utils';

@Injectable()
export class CosService {
  private ifeDomain: string;
  private groupingSymbol: string;

  constructor(private httpClient: HttpClient, private store: Store<State>, private rollbar: CbsRollbarService) {
    assignFirstTruthy(this.store, getIfeDomain, ifeDomain => this.ifeDomain = ifeDomain);
    assignFirstTruthy(this.store, getGroupingSymbol, sym => this.groupingSymbol = sym);
  }

  submitCosFieldChange(params: string[][]): Observable<CosFieldChangeResponse> {
    const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
    const options = {headers: headers, withCredentials: true};
    let urlParams = new HttpParams();
    params.forEach(([key, value]) => {
      if (key.includes('numeric_')) {
        value = value.replace(this.groupingSymbol, '');
      }
      urlParams = urlParams.set(key, value);
    });

    return this.httpClient
      .post(getCosOptionSubmitUrl(this.ifeDomain), urlParams, options).pipe(
        map(data => <CosFieldChangeResponse>data));
  }
}

function getCosOptionSubmitUrl(ifeDomain: string): string {
  return urlJoin(ifeDomain, '/config/api/v1/cos-option');
}
