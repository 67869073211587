import {Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {
  getAllCategories, getCurrencies, getEditPriceSheetColumns, getLabels, getOrgFolderHome, getPrice, getPrivileges, getProduct, State
} from '../reducers/index';
import {Store} from '@ngrx/store';
import {
  CellClassParams, CellClickedEvent, ColDef, ColumnApi, ColumnEvent, GridApi, GridOptions, GridReadyEvent, ICellRendererParams, RowNode,
  ValueGetterParams, ValueSetterParams
} from 'ag-grid-community';
import {SafeStore} from '../util/safe-store';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Category} from '../models/Category';
import {PriceSummaryService} from '../services/price-summary.service';
import {combineLatest} from 'rxjs';
import {FieldContainer} from '../models/FieldContainer';
import {ProductOptionActions} from '../actions/product-options.action';
import {LocalStorageService} from 'angular-2-local-storage';
import {assignFirstTruthy} from '../util/rx-utils';

export interface PriceSummaryRow {
  level?: number;
  name: string;
  data?: any;
  choice?: any;
  expanded?: boolean;
  children?: PriceSummaryRow[];
  newPrice?: any;
  idData?: any;
}

@UntilDestroy()
@Component({
  selector: 'cbs-price-summary',
  templateUrl: './price-summary.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [PriceSummaryService],
  styles: [`
    .ag-floating-bottom {
      font-weight: 500;
      font-size: 14px;
    }

    .price-summary__toggle {
      margin-right: 5px;
    }

    .ag-grid-cell-editable {
      font-style: italic;
    }
  `]
})
export class PriceSummaryComponent implements OnInit {
  @ViewChild('agGridParent') agGridParent: ElementRef;
  private mainContainerEl: Element = document.querySelector('.main-container');
  public gridHeightStyle = this.calculateGridHeightStyle();

  private safeStore: SafeStore<State>;
  private gridApi: GridApi;

  privileges: any;
  allData: PriceSummaryRow[];
  product: PriceSummaryRow;
  pinnedBottomRowData: PriceSummaryRow[] = [{name: 'Total'}];
  rowData: PriceSummaryRow[];
  defaultColumnDef: ColDef = {resizable: true, minWidth: 45, width: 100};
  currencies = [];
  // a few special labels we want to cache locally
  labels = {
    rfq: 'rfq'
  };
  private orgFolderHome;

  priceCalcMethodDefs = [
    {
      key: 'CostMult',
      labelKey: 'CostMultiplier',
      label: 'Cost multiplier',
      visible: (params) => {
        const option = this.getOptionFromParams(params);
        return (this.isCostVisible(option) || option.priceCalcMethod === 'CostMult');
      }
    },
    {
      key: 'CostMargin',
      label: 'Cost margin',
      visible: (params) => {
        const option = this.getOptionFromParams(params);
        return (option.costToBasePriceMarginDefinedInKB && (this.isCostVisible(option) || option.priceCalcMethod === 'CostMargin'));
      }
    },
    {
      key: 'EnterPrice',
      label: 'Enter price',
      visible: (params) => {
        return this.getOptionFromParams(params).ovrBasePriceDefinedInKB;
      }
    }
  ];
  priceCalcMethodsRefData = {};

  columnDefs: ColDef[] = [
    {
      headerName: 'Price',
      field: 'value.price',
      editable: (params) => {
        if (params.data.choice) {
          return this.priceSummaryService.isEditablePriceType(params.data.choice) &&
            (this.privileges.overridePrice || (this.privileges.overrideRFQ && params.data.choice.rfq));
        }
      },
      cellStyle: {
        'text-align': 'right'
      },
      valueGetter: (params: ValueGetterParams) => {
        if (params.data.newPrice) {
          return params.data.newPrice;
        } else if (params.data.choice) {
          return params.data.choice.price;
        } else {
          return params.data.data.price;
        }
      },
      valueSetter: (params: ValueSetterParams) => {
        // this.store.dispatch(ProductOptionActions.choiceOverride({payload}));
        // TODO: Setting to a different attribute since field data is read only, maybe we submit price change event, but we wouldn't
        // TODO: want to have the whole grid update unless necessary
        params.data.newPrice = params.newValue;
        return true;
      }
    }
  ];
  priceOnly = false;

  priceSummaryColumnMap = {
    description: {
      colId: 'name',
      headerName: 'Category',
      field: 'expanded',
      width: 350,
      cellRenderer: (params: ICellRendererParams) => {
        if (params.data && params.data.children) {
          if (params.data.expanded) {
            return '<span class="fas fa-chevron-down price-summary__toggle"></span><span>' + params.data.name + '</span>';
          } else {
            return '<span class="fas fa-chevron-right price-summary__toggle"></span><span>' + params.data.name + '</span>';
          }
        } else {
          return params.data.name;
        }
      },
      cellStyle: (params: CellClassParams) => {
        if (params.data) {
          return {
            'padding-left': this.priceSummaryService.getNameIndent(params.data),
            'font-weight': params.data.children ? 500 : null,
            cursor: params.data.children ? 'pointer' : 'inherit'
          };
        }
      },
      onCellClicked: (event: CellClickedEvent) => {
        if (event.data.children) {
          this.toggle(event.node);
        }
      },
    },
    qty: {
      colId: 'qty',
      headerName: 'Quantity',
      width: 50,
      editable: (params) => {
        return this.getOptionFromParams(params).quantityEditable;
      },
      valueGetter: (params: ValueGetterParams) => {
        const quantity = this.getOptionFromParams(params).quantity;
        return quantity > 0 ? quantity : '';
      },
      valueSetter: (params: ValueSetterParams) => {
        this.updateAndChangeValue(params.newValue, 'quantity', 'quantity', params.data.data, params.data.idData)
        return true;
      }
    },
    purchaseCost: {
      colId: 'purchaseCost',
      headerName: 'Purchase Cost',
      width: 101,
      editable: (params) => {
        return this.isEditablePriceType(this.getOptionFromParams(params));
      },
      valueGetter: (params: ValueGetterParams) => {
        const option = this.getOptionFromParams(params);
        if (this.isEditablePriceType(option)) {
          if (option.costOverridden) {
            return option.purchasePrice;
          } else if (option.costRFQ) {
            return this.labels.rfq;
          } else {
            return option.purchaseCost;
          }
        }
        return '';
      },
      valueSetter: (params: ValueSetterParams) => {
        this.updateAndChangeValue(params.newValue, 'purchaseCost', 'purchaseCost', this.getOptionFromParams(params), params.data.idData)
        return true;
      }
    },
    value: {
      colId: 'value',
      headerName: 'Value',
      valueGetter: (params: ValueGetterParams) => {
        if (params.data.choice) {
          return params.data.choice.description;
        } else {
          return params.data.data.value;
        }
      }
    },
    markup1: {
      colId: 'markup1',
      headerName: 'Markup1',
      width: 75,
      editable: (params) => {
        return this.isEditablePriceType(this.getOptionFromParams(params));
      },
      valueGetter: (params: ValueGetterParams) => {
        return this.onlyDisplayEditablePriceType(params, 'markup1');
      },
      valueSetter: (params: ValueSetterParams) => {
        this.updateAndChangeValue(params.newValue, 'markup1', 'markup1', this.getOptionFromParams(params), params.data.idData);
        return true;
      }
    },
    markup2: {
      colId: 'markup2',
      headerName: 'Markup2',
      width: 77,
      editable: (params) => {
        return this.isEditablePriceType(this.getOptionFromParams(params));
      },
      valueGetter: (params: ValueGetterParams) => {
        return this.onlyDisplayEditablePriceType(params, 'markup2');
      },
      valueSetter: (params: ValueSetterParams) => {
        this.updateAndChangeValue(params.newValue, 'markup2', 'markup2', this.getOptionFromParams(params), params.data.idData);
        return true;
      }
    },
    partNumber: {
      colId: 'partNumber',
      headerName: 'PartNumber',
      editable: (params) => {
        if (params.data.choice) {
          return params.data.choice.descOverride && this.privileges.overrideDescription;
        }
        if (params.data.data.displayType === 'CUSTOM_OPTION') {
          return this.privileges.overrideDescription;
        }
      },
      valueGetter: (params: ValueGetterParams) => {
        return this.getOptionFromParams(params).partNumber;
      },
      valueSetter: (params: ValueSetterParams) => {
        this.updateAndChangeValue(params.newValue, 'partNumber', 'partNumber', params.data.data, params.data.idData)
        return true;
      }
    },
    interCompanySellPrice: {
      colId: 'interCompanySellPrice',
      headerName: 'interCompanySellPrice',
      valueGetter: (params: ValueGetterParams) => {
        return this.getOptionFromParams(params).interCompanySellPrice;
      }
    },
    basePriceCalc: {
      colId: 'basePriceCalc',
      headerName: 'Base Price Calc',
      width: 105,
      editable: (params) => {
        return this.isPriceEditable(this.getOptionFromParams(params));
      },
      valueGetter: (params: ValueGetterParams) => {
        const option = this.getOptionFromParams(params);
        const method = option.priceCalcMethod;
        if (method === 'CostMult') {
          return option.costToBasePriceMult;
        } else if (method === 'CostMargin') {
          return option.costToBasePriceMargin;
        } else { // 'EnterPrice'
          return option.basePrice;
        }
      },
      valueSetter: (params: ValueSetterParams) => {
        const option = this.getOptionFromParams(params);
        const method = option.priceCalcMethod;
        if (method === 'CostMult') {
          this.updateAndChangeValue(params.newValue, 'costToBasePriceMult', 'costToBasePriceMult', this.getOptionFromParams(params),
            params.data.idData);
        } else if (method === 'CostMargin') {
          this.updateAndChangeValue(params.newValue, 'costToBasePriceMargin', 'costToBasePriceMargin', this.getOptionFromParams(params),
            params.data.idData);
        } else { // 'EnterPrice'
          this.updateAndChangeValue(params.newValue, 'basePrice', 'basePriceOverride', this.getOptionFromParams(params),
            params.data.idData);
        }
        return true;
      }
    },
    priceCalcMethod: {
      colId: 'priceCalcMethod',
      headerName: 'Price Calc Method',
      width: 125,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params) => {
        return {
          values: this.priceCalcMethodDefs.filter(priceCalc => priceCalc.visible(params)).map(priceCalc => priceCalc.key)
        };
      },
      refData: this.priceCalcMethodsRefData,
      editable: (params) => {
        return this.isPriceEditable(this.getOptionFromParams(params));
      },
      valueGetter: (params: ValueGetterParams) => {
        return this.onlyDisplayEditablePriceType(params, 'priceCalcMethod');
      },
      valueSetter: (params: ValueSetterParams) => {
        this.updateAndChangeValue(params.newValue, 'priceCalcMethod', 'priceCalcMethod', this.getOptionFromParams(params),
          params.data.idData);
        return true;
      }
    },

    customerDescription: {
      colId: 'customerDescription',
      headerName: 'Customer Description',
      width: 350,
      valueGetter: (params: ValueGetterParams) => {
        return this.getOptionFromParams(params).description;
      },
    },
    bookingCompanyCost: {
      colId: 'bookingCompanyCost',
      headerName: 'Base Cost',
      valueGetter: (params: ValueGetterParams) => {
        if (this.isEditablePriceType(this.getOptionFromParams(params))) {
          return this.getOptionFromParams(params).bookingCompanyCost;
        }
        return '';
      }
    },

    overrideCurrency: {
      colId: 'overrideCurrency',
      headerName: 'Currency Code',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: params => {
        return {
          values: Object.keys(this.currencies).map(key => this.currencies[key].display),
          cellRenderer: this.onlyDisplayEditablePriceType(params, 'currency')
        };
      },
      editable: (params) => {
        return this.isEditablePriceType(this.getOptionFromParams(params));
      },
      valueGetter: (params: ValueGetterParams) => this.onlyDisplayEditablePriceType(params, 'currency'),
      valueSetter: (params: ValueSetterParams) => {
        const newCurrencyCode = this.findCurrencyCode(params.newValue);
        this.updateAndChangeValue(newCurrencyCode, 'currency', 'ovrSourceCurrency', this.getOptionFromParams(params), params.data.idData);
        return true;
      }
    },
    miscAdder: {
      colId: 'miscAdder',
      headerName: 'Misc. Adder',
      width: 90,
      editable: (params) => {
        return this.isEditablePriceType(this.getOptionFromParams(params));
      },
      valueGetter: (params: ValueGetterParams) => {
        if (this.isEditablePriceType(this.getOptionFromParams(params)) && !this.getOptionFromParams(params).baseRow) {
          return this.getOptionFromParams(params).miscAdder || '0.00';
        }
        return '';
      },
      valueSetter: (params: ValueSetterParams) => {
        this.updateAndChangeValue(params.newValue, 'miscAdder', 'miscAdder', this.getOptionFromParams(params), params.data.idData)
        return true;
      }
    },
    baseCost: {
      colId: 'baseCost',
      headerName: 'Base Cost',
      valueGetter: (params: ValueGetterParams) => {
        const option = this.getOptionFromParams(params);
        if (this.isEditablePriceType(option)) {
          if (option.costRFQ && !option.costOverridden) {
            return this.labels.rfq;
          } else {
            return option.baseCost;
          }
        }
        return '';
      }
    },
    interCompanyMult: {
      colId: 'interCompanyMult',
      headerName: 'InterCompany Multiplier',
      editable: (params) => {
        return this.isEditablePriceType(this.getOptionFromParams(params));
      },
      valueGetter: (params: ValueGetterParams) => {
        if (this.isEditablePriceType(this.getOptionFromParams(params))) {
          return this.getOptionFromParams(params).interCompanyMult;
        }
        return '';
      },
      valueSetter: (params: ValueSetterParams) => {
        this.updateAndChangeValue(params.newValue, 'interCompanyMult', 'interCompanyMult', this.getOptionFromParams(params), params.data.idData)
        return true;
      }
    },
    icFactors: {
      colId: 'icFactors',
      headerName: 'IC Factors',
      editable: (params) => {
        return this.isEditablePriceType(this.getOptionFromParams(params));
      },
      valueGetter: (params: ValueGetterParams) => {
        if (this.isEditablePriceType(this.getOptionFromParams(params))) {
          return this.getOptionFromParams(params).icFactors;
        }
        return '';
      },
      valueSetter: (params: ValueSetterParams) => {
        this.updateAndChangeValue(params.newValue, 'icFactors', 'icFactors', this.getOptionFromParams(params), params.data.idData)
        return true;
      }
    },
    basePrice: {
      colId: 'basePrice',
      headerName: 'Base Price',
      valueGetter: (params: ValueGetterParams) => {
        const option = this.getOptionFromParams(params);
        if (this.isEditablePriceType(option)) {
          if (option.rfq && !option.priceOverridden) {
            return this.labels.rfq;
          } else {
            return option.basePrice;
          }
        }
        return option.price || '';
      }
    },
    obsoletePriceDisplay: {
      colId: 'obsoletePriceDisplay',
      headerName: 'Obsolete?',
      editable: (params) => {
        return this.isEditablePriceType(this.getOptionFromParams(params));
      },
      valueGetter: (params: ValueGetterParams) => {
        if (this.isEditablePriceType(this.getOptionFromParams(params))) {
          return this.getOptionFromParams(params).miscAdder || '0.00';
        }
        return '';
      },
      valueSetter: (params: ValueSetterParams) => {
        this.updateAndChangeValue(params.newValue, 'miscAdder', 'miscAdder', this.getOptionFromParams(params), params.data.idData)
        return true;
      }
    },
    multiplier: {
      colId: 'multiplier',
      headerName: 'Sell Multiplier',
      width: 80,
      editable: (params) => {
        return this.isEditablePriceType(this.getOptionFromParams(params));
      },
      valueGetter: (params: ValueGetterParams) => {
        if (this.isEditablePriceType(this.getOptionFromParams(params))) {
          return this.getOptionFromParams(params).sellMultiplier;
        }
        return '';
      },
      valueSetter: (params: ValueSetterParams) => {
        this.updateAndChangeValue(params.newValue, 'sellMultiplier', 'sellMult', this.getOptionFromParams(params), params.data.idData)
        return true;
      }
    },
    mfgUnitSellPrice: {
      colId: 'mfgUnitSellPrice',
      headerName: 'Mfg Unit Sell Price',
      width: 126,
      valueGetter: (params: ValueGetterParams) => {
        const option = this.getOptionFromParams(params);
        if (this.isEditablePriceType(option)) {
          if (option.rfq && !option.priceOverridden) {
            return this.labels.rfq;
          } else {
            return option.mfgUnitSellPrice;
          }
        }
        return option.price || '';
      },
    },
    resellerMargin: {
      colId: 'resellerMargin',
      headerName: 'Margin',
      width: 65,
      editable: (params) => {
        return this.isEditablePriceType(this.getOptionFromParams(params));
      },
      valueGetter: (params: ValueGetterParams) => {
        if (this.isEditablePriceType(this.getOptionFromParams(params))) {
          return this.getOptionFromParams(params).margin * 100 + '%';
        }
        return '';
      },
      valueSetter: (params: ValueSetterParams) => {
        this.updateAndChangeValue(params.newValue, 'margin', 'resellerMargin', this.getOptionFromParams(params), params.data.idData)
        return true;
      }
    },
    unitSellPrice: {
      colId: 'unitSellPrice',
      headerName: 'Unit Sell Price',
      valueGetter: (params: ValueGetterParams) => {
        const option = this.getOptionFromParams(params);
        if (this.isEditablePriceType(option)) {
          if (option.rfq && !option.priceOverridden) {
            return this.labels.rfq;
          } else {
            return option.unitSellPrice;
          }
        }
        return option.price || '';
      },
    },
    exchangeRate: {
      colId: 'exchangeRate',
      headerName: 'Exchange Rate',
      valueGetter: (params: ValueGetterParams) => {
        if (this.isEditablePriceType(this.getOptionFromParams(params))) {
          return this.getOptionFromParams(params).exchangeRate;
        }
        return '';
      },
    },
    extendedSellPrice: {
      colId: 'extendedSellPrice',
      headerName: 'Extended Sell Price',
      valueGetter: (params: ValueGetterParams) => {
        if (this.isEditablePriceType(this.getOptionFromParams(params))) {
          return this.getOptionFromParams(params).extendedSellPrice;
        }
        return '';
      },
    },
    leadTime: {
      colId: 'leadTime',
      headerName: 'Lead Time',
      width: 80,
      editable: (params) => {
        return this.isEditablePriceType(this.getOptionFromParams(params));
      },
      valueGetter: (params: ValueGetterParams) => {
        if (this.isEditablePriceType(this.getOptionFromParams(params))) {
          return this.getOptionFromParams(params).leadTime;
        }
        return '';
      },
      valueSetter: (params: ValueSetterParams) => {
        this.updateAndChangeValue(params.newValue, 'leadTime', 'leadTime', this.getOptionFromParams(params), params.data.idData)
        return true;
      }
    },
    weight: {
      colId: 'weight',
      headerName: 'Weight',
      editable: (params) => {
        return this.isEditablePriceType(this.getOptionFromParams(params));
      },
      valueGetter: (params: ValueGetterParams) => {
        if (this.isEditablePriceType(this.getOptionFromParams(params))) {
          return this.getOptionFromParams(params).weight || 'no Weight?';
        }
        return '';
      },
      valueSetter: (params: ValueSetterParams) => {
        this.updateAndChangeValue(params.newValue, 'weight', 'weight', this.getOptionFromParams(params), params.data.idData);
        return true;
      }
    },

  }

  gridOptions: GridOptions = {};

  constructor(private store: Store<State>, private localStorageService: LocalStorageService, public priceSummaryService: PriceSummaryService) {
    assignFirstTruthy(this.store, getOrgFolderHome, orgFolderHome => this.orgFolderHome = orgFolderHome);
    this.safeStore = new SafeStore(this, store);
  }

  @HostListener('window:resize')
  onResize() {
    this.gridHeightStyle = this.calculateGridHeightStyle();
  }

  calculateGridHeightStyle() {
    let heightOffset;
    if (this.mainContainerEl && this.agGridParent) {
      const gridParentRect = this.agGridParent.nativeElement.getBoundingClientRect();
      heightOffset = gridParentRect.top +
        (this.mainContainerEl.getBoundingClientRect().bottom - gridParentRect.bottom) +
        parseInt(window.getComputedStyle(this.mainContainerEl).getPropertyValue('margin-bottom'), 10);
    } else {
      // default offset based on window width to be a value close to final calculated value
      const windowWidth = window.innerWidth;
      if (windowWidth > 1199) {
        heightOffset = 230;
      } else if (windowWidth < 641) {
        heightOffset = 315;
      } else {
        heightOffset = 400;
      }
    }
    return {
      height: (window.innerHeight - heightOffset) + 'px'
    };
  }

  ngOnInit() {
    this.safeStore.subscribe(getPrivileges, privileges => this.privileges = privileges);
    combineLatest([
      this.safeStore.select(getProduct),
      this.safeStore.select(getAllCategories)
    ]).subscribe(([product, categories]) => {
      this.allData = [
        {
          name: product.description,
          data: product
        },
        ...this.priceSummaryService.convertCategories(categories, this.getRowState())
      ];
      this.refreshAll();
    });
    this.safeStore.select(getPrice).subscribe(price => {
      this.pinnedBottomRowData = [{
        name: 'Total: ' + price,
        choice: {
          baseRow: true
        }
      }];
    });
    combineLatest([
      this.safeStore.select(getEditPriceSheetColumns),
      this.safeStore.select(getLabels)
    ]).subscribe(([columns , labels]) => {
      this.columnDefs = [];
      columns.forEach(column => {
        if (column.display) {
          if (column.name === 'basePriceCalc') {
            this.columnDefs.push({
              ...this.priceSummaryColumnMap['priceCalcMethod'],
              headerName: labels['priceCalcMethod'] || this.priceSummaryColumnMap['priceCalcMethod'].headerName,
              cellClassRules: {
                'ag-grid-cell-editable': params =>
                  this.priceSummaryColumnMap['priceCalcMethod'].editable && this.priceSummaryColumnMap['priceCalcMethod'].editable(params)
              },
            });
          }
          this.columnDefs.push({
            ...this.priceSummaryColumnMap[column.name],
            headerName: labels[column.name] || this.priceSummaryColumnMap[column.name].headerName,
            cellClassRules: {
              'ag-grid-cell-editable': params =>
                this.priceSummaryColumnMap[column.name].editable && this.priceSummaryColumnMap[column.name].editable(params)
            },
          });
        }
      });
      this.priceCalcMethodDefs.forEach((priceCalc) => {
        this.priceCalcMethodsRefData[priceCalc.key] = labels[priceCalc.labelKey || priceCalc.key] || priceCalc.label;
      });
      if (labels.rfq) {
        this.labels.rfq = labels.rfq;
      }
    });
    this.safeStore.select(getCurrencies).subscribe(currencies => {
      this.currencies = currencies;
    });
    this.gridOptions = {
      defaultColDef: this.defaultColumnDef,
      columnDefs: this.columnDefs,
      suppressCellSelection: true,
      suppressScrollOnNewData: true,
      rowData: this.rowData
    };
  }

  updateGrid() {
    this.gridOptions.api?.redrawRows();
  }

  onGridReady(event: GridReadyEvent) {
    this.gridApi = event.api;
    this.restoreColumnSizesAndPositions(event.columnApi);
    this.onResize();
    // resize a second time after slight delay to ensure main page scrollbar isn't visible
    setTimeout(() => {
      this.onResize();
    }, 200);
  }

  toggle(node: RowNode) {
    node.data.expanded = !node.data.expanded;
    this.toggleRowState(node.data);
    if (node.data.expanded) {
      const childRows = this.priceSummaryService.flattenRows(node.data.children, node.data.level + 1, this.priceOnly);
      this.gridApi.applyTransaction({add: childRows, addIndex: node.rowIndex + 1});
    } else {
      const rowsToRemove = this.priceSummaryService.getRowsToRemove(node.data)
        .filter(r => !!this.gridApi.getRowNode(this.priceSummaryService.getRowNodeId(r)));
      this.gridApi.applyTransaction({remove: rowsToRemove});
    }
    setTimeout(() => {
      // redraw cell to change toggle icon
      this.gridApi.refreshCells({columns: ['name'], rowNodes: [node]});
    });
  }

  toggleAll(rows: PriceSummaryRow[], expand: boolean) {
    const rowState = new Set<string>();
    this.doToggleAll(rows, expand, rowState);
    this.saveRowState(rowState);
    this.refreshAll();
  }

  doToggleAll(rows: PriceSummaryRow[], expand: boolean, rowState: Set<string>) {
    rows.forEach(row => {
      if (row.children) {
        row.expanded = expand;
        if (!expand) {
          rowState.add(this.priceSummaryService.getRowNodeId(row));
        }
        this.doToggleAll(row.children, expand, rowState);
      }
    });
  }

  private getRowState(): Set<string> {
    return new Set(this.localStorageService.get(this.getRowStateLocalStorageKey()) || []);
  }

  private saveRowState(rowState: Set<string>) {
    this.localStorageService.set(this.getRowStateLocalStorageKey(), Array.from(rowState));
  }

  private toggleRowState(row) {
    const rowState = this.getRowState();
    const rowId = this.priceSummaryService.getRowNodeId(row);
    if (!row.expanded) {
      rowState.add(rowId);
    } else { // expanded
      rowState.delete(rowId);
    }
    this.saveRowState(rowState);
  }

  onlyDisplayEditablePriceType(params, key) {
    if (this.getOptionFromParams(params).baseRow && key !== 'price') {
      return '';
    }
    return this.isEditablePriceType(this.getOptionFromParams(params)) ? this.getOptionFromParams(params)[key] : '';
  }

  refreshAll() {
    this.rowData = this.priceSummaryService.flattenRows(this.allData, 0, this.priceOnly);
    this.updateGrid();
  }

  getOptionFromParams(params) {
    return params.data.choice || params.data.data || params.data;
  }

  isEditablePriceType(option) {
    return option.price && !(option.price === 'noCharge' || option.price === 'included');
  }

  isPriceEditable(option) {
    return ((this.privileges.overrideRFQ && option.rfq) || (this.privileges.overridePrice && this.isEditablePriceType(option)));
  }

  isCostEditable(option) {
    return ((this.privileges.overrideRFQ && option.costRFQ) || (this.privileges.overridePrice && this.isEditablePriceType(option)));
  }

  isCostVisible(option) {
    return (this.privileges.viewOptionCostOrProfit || this.isCostEditable(option));
  }

  findCurrencyCode(currency) {
    return this.currencies[Object.keys(this.currencies).find(key => this.currencies[key].display === currency)].code;
  }

  updateAndChangeValue(newValue: string, attributeName: string, fieldName: string, option: any, idData: any) {
    if (idData) {
      this.updateTempValue(newValue, attributeName, option, idData);
      this.changeValue(newValue, fieldName, option, idData);
    } else {
      this.updateProductAttribute(newValue, attributeName, fieldName, option);
    }
  }

  updateTempValue(newValue: string, attributeName: string, option: any, idData: any) {
    this.store.dispatch(ProductOptionActions.tempChangeAttributeValue({
      payload: {
        categoryId: idData.categoryId,
        parentSubcatId: idData.parentSubcatId,
        subcatId: idData.subcatId,
        fieldId: idData.fieldId,
        choiceId: idData.choiceId,
        attributeName: attributeName,
        fieldContainer: FieldContainer.Product,
        value: newValue
      }
    }));
  }

  changeValue(newValue: string, field: string, option: any, idData: any) {
    const params = [[field + '_' + (option.httpValue || option.id), newValue]];
    this.store.dispatch(ProductOptionActions.changeAttribute({
      payload: {
        categoryId: idData.categoryId,
        parentSubcatId: idData.parentSubcatId,
        subcatId: idData.subcatId,
        fieldId: idData.fieldId,
        choiceId: idData.choiceId,
        changeParams: params,
        fieldContainer: FieldContainer.Product
      }
    }));
  }

  updateProductAttribute(newValue: string, attributeName: string, field: string, option: any) {
    const params = [[field + '_' + (option.httpValue || option.id), newValue]];
    this.store.dispatch(ProductOptionActions.changeAttribute({
      payload: {
        categoryId: undefined,
        parentSubcatId: undefined,
        subcatId: undefined,
        fieldId: undefined,
        choiceId: undefined,
        changeParams: params,
        fieldName: field,
        fieldContainer: FieldContainer.Product
      }
    }));
  }

  saveColumnSizesAndPositions(event: ColumnEvent) {
    if (event.source !== 'api') {
      this.localStorageService.set(this.getColumnStateLocalStorageKey(), event.columnApi.getColumnState().map((state) => {
        return {
          colId: state.colId,
          width: state.width
        };
      }));
    }
  }

  private restoreColumnSizesAndPositions(columnApi: ColumnApi) {
    const columnState: any = this.localStorageService.get(this.getColumnStateLocalStorageKey());
    if (columnState?.length) {
      columnApi.applyColumnState({applyOrder: true, state: columnState});
    }
  }

  private getColumnStateLocalStorageKey() {
    return `${this.orgFolderHome}.price_summary.column_state`;
  }

  private getRowStateLocalStorageKey() {
    return `${this.orgFolderHome}.price_summary.row_state`;
  }
}
