import {Routes} from '@angular/router';
import {ProductOptionsRouteComponent} from './components/product-options-route.component';
import {CosFilterSearchRouteComponent} from './components/cos-filter-search-route.component';
import {DocumentsRouteComponent} from './components/documents-route.component';
import {WorkflowDispatcherRouteComponent} from './components/workflow-dispatcher-route.component';
import {CosRouteComponent} from './components/cos-route.component';
import {SearchRouteComponent} from './components/search-route.component';
import {CosProductOptionsRouteComponent} from './components/cos-product-options-route.component';
import {ValidationGuard} from './services/validation-guard.service';
import {BasicPartsSearchRouteComponent} from './components/basic-parts-search-route.component';
import {PartsConfigurationRouteComponent} from './components/parts-configuration-route.component';
import {PriceSummaryRouteComponent} from './components/price-summary-route.component';

export const routes: Routes = [
  {path: 'documents', component: DocumentsRouteComponent},
  {path: 'product-options', component: ProductOptionsRouteComponent, canDeactivate: [ValidationGuard]},
  {path: 'cos-product-options', component: CosProductOptionsRouteComponent, canDeactivate: [ValidationGuard]},
  {path: 'cos-filter-search', component: CosFilterSearchRouteComponent, canDeactivate: [ValidationGuard]},
  {path: 'cos', component: CosRouteComponent, canDeactivate: [ValidationGuard]},
  {path: 'search', component: SearchRouteComponent},
  {path: 'parts-search', component: BasicPartsSearchRouteComponent},
  {path: 'parts-configuration', component: PartsConfigurationRouteComponent, canDeactivate: [ValidationGuard]},
  {path: 'price-summary', component: PriceSummaryRouteComponent},
  {path: '**', component: WorkflowDispatcherRouteComponent}
];
