<div #hoverButton #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="hoverMenu"
     (mouseenter)="openMenu('description')" (mouseleave)="closeMenu('description')">
  <ng-content></ng-content>
</div>
<mat-menu #hoverMenu [overlapTrigger]="false" [hasBackdrop]="isMobile()" [xPosition]="'before'" class="p-none">
  <div class="mat-inner-content" (mouseenter)="openMenu('menu')" (mouseleave)="closeMenu('menu')">
    <ng-container *ngFor="let menuItem of menu?.childLinks">
      <ng-container *ngIf="display(menuItem)">
        <ng-container *ngIf="menuItem.target$|async as target">
          <a mat-menu-item href="{{target}}" target="{{menuItem.popup ?  '_blank' : ''}}" [ngStyle]="menu.styles">
            {{menuItem.description}}
          </a>
        </ng-container>
        <ng-container *ngIf="menuItem.clickFunction as clickFunction">
          <a mat-menu-item (click)="clickFunction(menuItem.functionInput)" [ngStyle]="menu.styles">
            {{menuItem.description}}
          </a>
        </ng-container>
        <ng-container>
          <span mat-menu-item [ngStyle]="menu.styles" class="hover-menu-item--disabled"
                *ngIf="!menuItem.clickFunction && !menuItem.target$" [innerHtml]="menuItem.description">
          </span>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</mat-menu>
