import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {EMPTY} from 'rxjs';
import {Store} from '@ngrx/store';
import {WorkflowService} from '../services/web/workflow.service';
import {CbsRollbarService} from '../rollbar/cbs-rollbar.service';
import {State} from '../reducers';
import {catchError} from 'rxjs/operators';
import {concatMapEmitLast} from '../util/rx-utils';
import {WorkflowActions} from '../actions/workflow.action';

@Injectable()
export class WorkflowEffects {

  //noinspection JSUnusedGlobalSymbols
  displayBreadcrumbs$ = createEffect(() => this.allActions.pipe(
    ofType(WorkflowActions.displayBreadcrumbs),
    concatMapEmitLast(({breadcrumbs}) => {
      this.workflowService.updateBreadcrumbs(breadcrumbs).pipe(
        catchError(error => this.rollbar.apiError(error, breadcrumbs, undefined, this.store)))
        .subscribe();
      return EMPTY;
    })
  ), {dispatch: false});

  constructor(private workflowService: WorkflowService,
              private rollbar: CbsRollbarService,
              private allActions: Actions,
              private store: Store<State>) {
  }
}
