import {DocumentLink} from '../models/DocumentLink';
import {createReducer, on} from '@ngrx/store';
import {DocumentsActions} from '../actions/documents.action';

export interface DocumentsState {
  requestInProcess: boolean,
  docLinks: DocumentLink[];
  selectedDocuments: any;
}

const initialState: DocumentsState = {
  requestInProcess: false,
  docLinks: [],
  selectedDocuments: {}
};

export const documentsReducer = createReducer(
  initialState,
  on(DocumentsActions.fetchDocLinksSuccess, (state, {links}) => {
    const selectedDocumentsObject = {};
    links.forEach(docLink => selectedDocumentsObject[docLink.labelId] = false);
    return {
      requestInProcess: false,
      docLinks: links,
      selectedDocuments: selectedDocumentsObject
    };
  }),
  on(DocumentsActions.fetchDocLinksFailure, (state) => ({...state, requestInProcess: false})),
  on(DocumentsActions.fetchDocLinks, (state) => ({...state, requestInProcess: true})),
  on(DocumentsActions.updateSelectedDocLinks, (state, {links}) => ({...state, selectedDocuments: links}))
);

export class DocumentsSelectors {
  static getDocLinks = (state: DocumentsState) => state.docLinks;
  static isRequestInProcess = (state: DocumentsState) => state.requestInProcess;
  static getSelectedDocLinks = (state: DocumentsState) => state.selectedDocuments;
}
