import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {getSearch, getSearchDesign, getSearchResults, getSearchSort, isSearchShowLoading, State} from '../reducers/index';
import {Observable} from 'rxjs';
import {ValidateFieldService} from '../services/validate-field.service';
import {Sort} from '@angular/material/sort';
import {SearchState} from '../reducers/search.reducer';
import {isNullOrUndefined} from '../util/js-utils';
import {map} from 'rxjs/operators';
import {selectFirstTruthy} from '../util/rx-utils';
import {SearchActions} from '../actions/search.action';

@Component({
  selector: 'cbs-search-results',
  templateUrl: './search-results.component.html',
})
export class SearchResultsComponent implements OnInit {

  @Input() headerTemplate: TemplateRef<Component>;
  isLoading$: Observable<boolean>;
  dataColumns$: Observable<string[]>;
  searchDesign$: Observable<any>;
  sorting$: Observable<Sort>;
  searchResultsExists$: Observable<boolean>;

  constructor(private store: Store<State>, public validateFieldService: ValidateFieldService) {
    this.sorting$ = this.store.pipe(select(getSearchSort));
  }

  ngOnInit() {
    this.isLoading$ = this.store.pipe(select(isSearchShowLoading));
    this.searchDesign$ = this.store.pipe(select(getSearchDesign));
    this.dataColumns$ = this.store.pipe(select(getSearchDesign),
      map(design => design.searchReturnDisplayOrder.searchReturnIds));
    this.updateSearch();
    this.searchResultsExists$ = this.store.pipe(select(getSearchResults),
      map(result => !isNullOrUndefined(result) && result.length > 0));
  }

  updateSearch() {
    selectFirstTruthy(this.store, getSearch).subscribe((search: SearchState) => {
      if (!search.requestInProcess && (search.results === null || search.dirty)) {
        this.store.dispatch(SearchActions.attemptStartSearch());
      }
    });
  }

  displaySearchResults() {
    return this.validateFieldService.allValid && this.validateFieldService.mandatoryValid;
  }

}
