import {Action} from '@ngrx/store';

export interface UnsafeAction extends Action {
  payload: any;
}

export interface Message {
  message: string;
}

export function messageAction(payload: Message) {
  alert(payload.message);
}

export function logAction(payload: any) {
  console.log(payload);
}
