import {Observable, zip as observableZip} from 'rxjs';
import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {getProducts, getProperties, getSelectedNode, State} from '../reducers/index';
import {Product} from '../models/Product';
import {Router} from '@angular/router';
import {first, map} from 'rxjs/operators';
import {PropertiesState} from '../reducers/properties.reducer';
import {ProductActions} from '../actions/product.action';
import {WorkflowActions} from '../actions/workflow.action';

@Component({
  selector: 'cbs-parts-configuration-route',
  templateUrl: './parts-configuration-route.component.html',
})
export class PartsConfigurationRouteComponent implements OnInit {

  maxColumnOptionLayout: Observable<number>;

  constructor(private store: Store<State>, private router: Router) {
  }

  ngOnInit(): void {
    observableZip(this.store.pipe(select(getSelectedNode)), this.store.pipe(select(getProducts))).pipe(map((zippedItem) => {
      const node = zippedItem[0];
      const products = zippedItem[1];
      let selectedProduct: Product;
      products.forEach(product => {
        if (product.nodeID === node) {
          selectedProduct = product;
        }
      });
      return selectedProduct;
    }), first()).subscribe(selectedProduct => {
      if (selectedProduct) {
        this.store.dispatch(ProductActions.configureNode({product: selectedProduct}));
        this.store.dispatch(WorkflowActions.displayBreadcrumbs({
          breadcrumbs: {configure: {display: true, target: 'parts-configuration'}}
        }));
        this.store.dispatch(WorkflowActions.displayData({
          payload: {
            displayPrice: true,
            displaySaveButton: true,
            displaySearchButton: false,
            createCustomOptions: false
          }
        }));
        this.maxColumnOptionLayout = this.store.pipe(select(getProperties),
          map((properties: PropertiesState) => properties.po_singleColumn ? 1 : 4));
      } else {
        this.router.navigate(['parts-search']);
      }
    });
  }

}
