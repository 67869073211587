<div class="c-card main-content">
  <cbs-button-bar class="p-v-none"></cbs-button-bar>
  <div class="flex-grid p-t-none">
    <div class="flex-2 sticky-content m-t-base">
      <cbs-selected-products></cbs-selected-products>
    </div>
    <div class="flex-3">
      <cbs-search-filter>
        <button *ngIf="(parentSubcat$ | async).fields.length > 1" matTooltip="{{'displayFilterOptions' | cbsLabel}}"
                test-id="DISPLAY_FILTER_OPTIONS_BUTTON" (click)="toggleDisplayCos()"><i class="fas fa-filter"></i></button>
      </cbs-search-filter>
      <ng-container *ngIf="displayCos; else soloTable">
        <cbs-cos [footerTemplate]="searchResultsTable" [optionColumnLayout]="singleColumnCos$ | async"></cbs-cos>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #soloTable>
  <div class="cbs-section">
    <ng-container *ngTemplateOutlet="searchResultsTable"></ng-container>
  </div>
</ng-template>

<ng-template #searchResultsTable>
  <cbs-search-results [headerTemplate]="searchResultsFilter">
    <ng-container *ngIf="displayCos">
      <hr>
    </ng-container>
  </cbs-search-results>
</ng-template>
<ng-template #searchResultsFilter>
  <cbs-search-filter [location]="'results'" [inCos]="displayCos"></cbs-search-filter>
</ng-template>
