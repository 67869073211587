import {SystemProperties} from '../models/SystemProperties';
import {createReducer, on} from '@ngrx/store';
import {SystemPropertiesActions} from '../actions/system-properties.action';

export interface SystemPropertiesState {
  systemProperties: SystemProperties;
}

const initialState: SystemPropertiesState = {
  systemProperties: {}
};

export const systemPropertiesReducer = createReducer(
  initialState,
  on(SystemPropertiesActions.init, (state, {systemProperties}) => ({systemProperties}))
);

export class SystemPropertiesSelectors {
  static getSystemProperties = (state: SystemPropertiesState) => state.systemProperties;
}
