import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {State} from '../reducers';
import {ValidateFieldService} from '../services/validate-field.service';
import {getItemNumber$} from '../util/field-util';
import {firstTruthy} from '../util/rx-utils';
import {CbsActions} from '../actions/cbs.action';

@Component({
  selector: 'cbs-save-project',
  templateUrl: './save-project.component.html',
})
export class SaveProjectComponent implements OnInit {

  newProjectName = '';
  itemNumberValue = '';

  constructor(private store: Store<State>, private validateFieldService: ValidateFieldService) {
  }

  ngOnInit() {
    getItemNumber$(this.store).pipe(firstTruthy()).subscribe(itemNumber => this.itemNumberValue = itemNumber);
  }

  saveToNewProject() {
    setTimeout(() => {
      if (this.validateFieldService.validateMandatory()) {
        this.store.dispatch(
          CbsActions.saveToNewProject({payload: {newProjectName: this.newProjectName, itemNumber: this.itemNumberValue}}));
      }
    }, 300);
  }

}
