export interface UomState {
  uomType: string;
}

const initialState: UomState = {
  uomType: 'Custom'
};

export function uomReducer(state = initialState, action: any): UomState {
  if (!!action.response) {
    switch (action.response.uomType) {
      case 'METRIC':
        return {uomType: 'Metric'};
      case 'US':
        return {uomType: 'US'};
      case 'ALL':
        return {uomType: 'Custom'};
      default:
        return state;
    }
  }
  return state;
}

export class UomSelectors {
  static getUomType = (state: UomState) => state.uomType;
}
