import {createAction, props} from '@ngrx/store';
import {Sort} from '@angular/material/sort';

export namespace SearchActions {
  /**
   * DO NOT call this function!!!
   * Instead, use {@link attemptStartSearch}
   */
  export const startSearch = createAction(
    '[search] Start'
  );

  export const attemptStartSearch = createAction(
    '[search] Attempt Start'
  );

  export const searchNeeded = createAction(
    '[search] Needed'
  );

  export const searchReturn = createAction(
    '[search] Return',
    props<{ response: any }>()
  );

  export const sort = createAction(
    '[search] Sort',
    props<{ sort: Sort }>()
  );

  export const showLoadingForSort = createAction(
    '[search] Show Loading For Sort'
  );

  export const returnSearchSort = createAction(
    '[search] Return Sort',
    props<{ response: any }>()
  );

  export const selectProduct = createAction(
    '[search] Select Product',
    props<{ product: string }>()
  );

  export const filter = createAction(
    '[search] Filter',
    props<{ changeParams: string[][] }>()
  );

  export const paginate = createAction(
    '[search] Paginate',
    props<{ pageSize: number, pageNumber: number }>()
  );
}
