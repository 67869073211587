<div mat-dialog-title style="display: flex; align-items: center">
  <div style="flex: 1 1 auto;">{{'managePartNumberAlias' | cbsLabel}}</div>
  <div>
    <button class="c-text-button" [disabled]="aliasService.isDirty()" mat-dialog-close style="padding: 0 !important;"
            test-id="CLOSE_BUTTON">
      {{'closeButtonText' | cbsLabel}}
    </button>
  </div>
</div>
<mat-dialog-content style="display: flex; flex-direction: column; height: 65vh">
  <div style="display: flex; align-items: center; margin-bottom: 10px">
    <div style="flex: 0 0 auto; font-weight: 500">{{'orgUnit' | cbsLabel}}:</div>
    <ng-container *ngIf="orgUnits">
      <mat-select *ngIf="orgUnits" [(value)]="orgUnit" [compareWith]="compareById" [disabled]="aliasService.isDirty()">
        <mat-option *ngFor="let orgUnit of orgUnits" [value]="orgUnit">
          {{orgUnit.name}}
        </mat-option>
      </mat-select>
    </ng-container>
    <ng-container *ngIf="!orgUnits && orgUnit">
      <div style="flex: 1 1 auto; margin: 0 5px">{{orgUnit.name}}</div>
    </ng-container>
  </div>

  <nav mat-tab-nav-bar [@.disabled]="true" style="margin-bottom: 10px">
    <a mat-tab-link [active]="selectedTab === 'data'" [disabled]="aliasService.isDirty()" test-id="EXISTING_DATA_LINK"
       (click)="changeTab('data')">{{ 'existingData' | cbsLabel }}</a>
    <a mat-tab-link [active]="selectedTab === 'missing'" [disabled]="aliasService.isDirty()" test-id="MISSING_DATA_LINK"
       (click)="changeTab('missing')">{{ 'missingData' | cbsLabel }}</a>
    <a mat-tab-link [active]="selectedTab === 'spreadsheet'" [disabled]="aliasService.isDirty()" test-id="ADD_FROM_SPREADSHEET_LINK"
       (click)="changeTab('spreadsheet')">{{ 'addFromSpreadsheet' | cbsLabel }}</a>
  </nav>

  <cbs-part-number-existing *ngIf="selectedTab === 'data'" [orgUnit]="orgUnit"></cbs-part-number-existing>
  <cbs-part-number-missing *ngIf="selectedTab === 'missing'" [orgUnit]="orgUnit"></cbs-part-number-missing>
  <cbs-part-number-spreadsheet *ngIf="selectedTab === 'spreadsheet'" [orgUnit]="orgUnit"></cbs-part-number-spreadsheet>
</mat-dialog-content>
