<a class="category-menu__item" *ngIf="showCategoryLevel" (click)="select()">
  <span *ngIf="hasSubCategories" (click)="$event.stopPropagation(); toggle()" class="category-menu__toggle">
    <span *ngIf="isExpanded()"><i class="fas fa-angle-down"></i></span>
    <span *ngIf="!isExpanded()"><i class="fas fa-angle-right"></i></span>
  </span>
  <span class="category-menu__title" [ngClass]="{'category-menu__title--no-toggle': !hasSubCategories}">{{category.description}}</span>
  <span *ngIf="category.invalid" matTooltip="{{'invalidFieldValue' | cbsLabel}}"><i class="fas fa-exclamation-triangle"></i></span>
</a>
<ng-container *ngIf="hasSubCategories && isExpanded()">
  <cbs-category-menu-subcategory *ngFor="let subCategory of category.parentSubcategories; trackBy:trackBySubCategoryId"
                                 [subCategory]="subCategory" [showCategoryLevel]="showCategoryLevel"></cbs-category-menu-subcategory>
</ng-container>
