import {Component, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {getAdditionalEmailRfqFields, State} from '../reducers';
import {CbsActions} from '../actions/cbs.action';
import {Observable} from 'rxjs/index';
import {Field} from '../models/Field';
import {SafeStore} from '../util/safe-store';
import {UntilDestroy} from '@ngneat/until-destroy';
import {FieldContainer} from '../models/FieldContainer';

@UntilDestroy()
@Component({
  selector: 'cbs-email-rfq',
  templateUrl: './email-rfq.component.html',
})
export class EmailRfqComponent implements OnInit {

  email: string;
  name: string;
  comments: string;
  fieldContainer = FieldContainer.EmailRfq;

  additionalEmailRfqFields: Field[]

  constructor(private store: Store<State>) { }

  ngOnInit(): void {
    const safeStore = new SafeStore(this, this.store);
    safeStore.select(getAdditionalEmailRfqFields)
      .subscribe(additionalEmailRfqFields => this.additionalEmailRfqFields = additionalEmailRfqFields);
  }

  sendEmailRfq(additionalEmailFields) {
    const additionalData = {};
    additionalEmailFields.forEach(field => {
      if (field.choices && field.choices.length > 0) {
        field.choices.forEach(choice => {
          additionalData[choice.description] = choice.selected;
        })
      } else {
        additionalData[field.description] = field.value
      }
    });
    this.store.dispatch(CbsActions.sendRfqEmail({payload: {email: this.email, name: this.name, comments: this.comments,
        additionalData: additionalData}}));
  }
}
