import {first} from 'rxjs/operators';
import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {select, Store} from '@ngrx/store';
import {getSaveInProgress, State} from '../reducers/index';
import {ValidateFieldService} from '../services/validate-field.service';
import {CbsActions} from '../actions/cbs.action';

@Component({
  selector: 'cbs-save-verification',
  templateUrl: './save-verification.component.html',
})
export class SaveVerificationComponent {

  saveInProgress = false;

  constructor(public dialogRef: MatDialogRef<SaveVerificationComponent>,
              private store: Store<State>,
              private validateFieldService: ValidateFieldService) {
  }

  clickYes() {
    if (this.validateFieldService.validateMandatory()) {
      this.saveInProgress = true;
      this.store.dispatch(CbsActions.itemSave()); // todo - if navigating to portal, set asyncSave=false?
      setTimeout(() => {
        this.store.pipe(select(getSaveInProgress),
          first(saveInProgress => !saveInProgress))
          .subscribe(() => {
            this.dialogRef.close(true);
          });
      }, 0);
    } else {
      this.dialogRef.close(false);
    }
  }

  clickNo() {
    this.dialogRef.close(true);
  }

  clickCancel() {
    this.dialogRef.close(false);
  }
}
