import {ActionReducerMap, createSelector, MetaReducer} from '@ngrx/store';
import {environment} from '../../environments/environment';
import {ifeIntegrationInfoReducer, IfeIntegrationInfoSelectors, IfeIntegrationInfoState} from './ife-integration-info.reducer';
import {productOptionsReducer, ProductOptionsSelectors, ProductOptionsState} from './product-options.reducer';
import {documentsReducer, DocumentsSelectors, DocumentsState} from './documents.reducer';
import {labelsReducer, LabelsSelectors, LabelsState} from './labels.reducer';
import {cosReducer, CosSelectors, CosState} from './cos.reducer';
import {cbsReducer, CbsSelectors, CbsState} from './cbs.reducer';
import {actionLog} from './log.reducer';
import {propertiesReducer, PropertiesSelectors, PropertiesState} from './properties.reducer';
import {headerReducer, HeaderSelectors, HeaderState} from './header.reducer';
import {uomReducer, UomSelectors, UomState} from './uom.reducer';
import {workflowReducer, WorkflowSelectors, WorkflowState} from './workflow.reducer';
/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */
import {storeFreeze} from 'ngrx-store-freeze';
import {searchReducer, SearchSelectors, SearchState} from './search.reducer';
import {discountReducer, DiscountSelectors, DiscountState} from './discount.reducer';
import {systemPropertiesReducer, SystemPropertiesSelectors, SystemPropertiesState} from './systemPropertiesReducer';

/**
 * State for the entire store.
 * NOTE: the keys in State must match the keys in reducers
 */
export interface State {
  ifeIntegrationInfo: IfeIntegrationInfoState;
  workflow: WorkflowState;
  productOptions: ProductOptionsState;
  documents: DocumentsState;
  labels: LabelsState;
  systemProperties: SystemPropertiesState;
  cos: CosState;
  cbs: CbsState;
  properties: PropertiesState;
  header: HeaderState;
  uom: UomState;
  search: SearchState;
  discount: DiscountState;
}

/**
 * Reducers for the entire store.
 * NOTE: the keys in State must match the keys in reducers
 */
export const reducers: ActionReducerMap<State> = {
  ifeIntegrationInfo: ifeIntegrationInfoReducer,
  workflow: workflowReducer,
  productOptions: productOptionsReducer,
  documents: documentsReducer,
  labels: labelsReducer,
  systemProperties: systemPropertiesReducer,
  cos: cosReducer,
  cbs: cbsReducer,
  properties: propertiesReducer,
  header: headerReducer,
  uom: uomReducer,
  search: searchReducer,
  discount: discountReducer,
};

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = environment.production
  ? [actionLog]
  : [actionLog, storeFreeze];

/*--------------
 selectors
 --------------*/
export const getIfeIntegrationInfo = (state: State) => state.ifeIntegrationInfo;
export const getCssUrl = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getCssUrl);
export const getDocLinksUrl = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getDocLinksUrl);
export const getIfeDomain = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getIfeDomain);
export const getOrgFolderHome = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getOrgFolderHome);
export const getImagesPath = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getImagesPath);
export const getGlobalImagesPath = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getGlobalImagesPath);
export const getFromPortal = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getFromPortal);
export const getFromPortalToQM = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getFromPortalToQM);
export const getItemContainerType = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getItemContainerType);
export const getItemContainerId = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getItemContainerId);
export const isNewItem = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.isNewItem);
export const getSaveMenuType = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getSaveMenuType);
export const isSaveToNewProjectAllowed = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.isSaveToNewProjectAllowed);
export const getFromPortalCenter = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getFromPortalCenter);
export const isFromSelector = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.isFromSelector);
export const getSelectorUrl = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getSelectorUrl);
export const getGlobalInfoUrl = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getGlobalInfoUrl);
export const getOriginUrl = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getOriginUrl);
export const getQuoteHomeUrl = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getQuoteHomeUrl);
export const getPortalDashboardUrl = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getPortalDashboardUrl);
export const getPortalCenterDashboardUrl = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getPortalCenterDashboardUrl);
export const getOriginContainerUrl = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getOriginContainerUrl);
export const getOriginName = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getOriginName);
export const getCustomOriginName = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getCustomOriginName);
export const getCustomOriginUrl = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getCustomOriginUrl);
export const getPrivileges = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getPrivileges);
export const getViewAllInstancesUrl = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getViewAllInstancesUrl);
export const getGroupingSymbol = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getGroupingSymbol);
export const getDecimalSymbol = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getDecimalSymbol);
export const getUserName = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getUserName);
export const getEmail = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getEmail);
export const getIqAuthAuthenticated = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getIqAuthAuthenticated);
export const getLanguages = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getLanguages);
export const getOptionImagesUrl = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getOptionImagesUrl);
export const isOpenAccess = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.isOpenAccess);
export const getGlobalNotificationMessage = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getGlobalNotificationMessage);
export const getOrgNotificationMessage = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getOrgNotificationMessage);
export const getVersionTitle = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getVersionTitle);
export const getFeedbackUrl = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getFeedbackUrl);
export const getError = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getError);
export const isInitUrlValid = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.isInitUrlValid);
export const isInitFromResponse = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.isInitFromResponse);
export const isCfgSessionValid = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.isCfgSessionValid);
export const getLogoLink = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getLogoLink);
export const getLogoLinkTabTarget = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getLogoLinkTabTarget);
export const isLogoLinkToPortalCenter = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.isLogoLinkToPortalCenter);
export const getAnalyticsScripts = createSelector(getIfeIntegrationInfo, IfeIntegrationInfoSelectors.getAnalyticsScripts);

export const getWorkflow = (state: State) => state.workflow;
export const getWorkflowId = createSelector(getWorkflow, WorkflowSelectors.getWorkflowId);
export const getDisplayBreadcrumbs = createSelector(getWorkflow, WorkflowSelectors.getDisplayBreadcrumbs);
export const getDisplayPrice = createSelector(getWorkflow, WorkflowSelectors.getDisplayPrice);
export const getDisplaySaveButton = createSelector(getWorkflow, WorkflowSelectors.getDisplaySaveButton);
export const getDisplaySearchButton = createSelector(getWorkflow, WorkflowSelectors.getDisplaySearchButton);
export const getCreateCustomOptions = createSelector(getWorkflow, WorkflowSelectors.getCreateCustomOptions);

export const getProductOptions = (state: State) => state.productOptions;
export const getProductOptionPages = createSelector(getProductOptions, ProductOptionsSelectors.getPages);
export const getProductOptionCategories = createSelector(getProductOptions, ProductOptionsSelectors.getCategories);
export const getAllCategories = createSelector(getProductOptions, ProductOptionsSelectors.getAllCategories);
export const getProducts = createSelector(getProductOptions, ProductOptionsSelectors.getProducts);
export const getSelectedNode = createSelector(getProductOptions, ProductOptionsSelectors.getSelectedNode);
export const getSelectedPage = createSelector(getProductOptions, ProductOptionsSelectors.getSelectedPage);
export const getEditPriceSheetColumns = createSelector(getProductOptions, ProductOptionsSelectors.getEditPriceSheetColumns);
export const getCurrencies = createSelector(getProductOptions, ProductOptionsSelectors.getCurrencies);
export const getAdditionalEmailRfqFields = createSelector(getProductOptions, ProductOptionsSelectors.getAdditionalEmailRfqFields);
export const getDocumentLinks = createSelector((state: State) => state.documents, DocumentsSelectors.getDocLinks);
export const isDocLinksRequestInProcess = createSelector((state: State) => state.documents, DocumentsSelectors.isRequestInProcess);
export const getSelectedDocLinks = createSelector((state: State) => state.documents, DocumentsSelectors.getSelectedDocLinks);
export const getLabels = createSelector((state: State) => state.labels, LabelsSelectors.getLabels);
export const getSystemProperties = createSelector((state: State) => state.systemProperties, SystemPropertiesSelectors.getSystemProperties);
export const getCos = (state: State) => state.cos;
export const getCosParentSubcategory = createSelector(getCos, CosSelectors.getParentSubcategory);
export const isCosRequestInProcess = createSelector(getCos, CosSelectors.isRequestInProcess);

export const getDiscounts = (state: State) => state.discount;
export const getDiscountList = createSelector(getDiscounts, DiscountSelectors.getDiscountList);
export const getDiscountChangeList = createSelector(getDiscounts, DiscountSelectors.getDiscountChangeList);

export const getCbs = (state: State) => state.cbs;
export const getSaveInProgress = createSelector(getCbs, CbsSelectors.getSaveInProgress);
export const isDirty = createSelector(getCbs, CbsSelectors.isDirty);
export const isSaveSuccess = createSelector(getCbs, CbsSelectors.isSaveSuccess);

export const getHeader = (state: State) => state.header;
export const getHeaderInfo = createSelector(getHeader, HeaderSelectors.getHeaderInfo);
export const getHeaders = createSelector(getHeader, HeaderSelectors.getHeaders);
export const getPrice = createSelector(getHeader, HeaderSelectors.getPrice);
export const getLeadTimeTotal = createSelector(getHeader, HeaderSelectors.getLeadTimeTotal);
export const getSavedItemNumber = createSelector(getHeader, HeaderSelectors.getSavedItemNumber);
export const getProduct = createSelector(getHeader, HeaderSelectors.getProduct);

export const getProperties = (state: State) => state.properties;
export const isPropertiesInit = createSelector(getProperties, PropertiesSelectors.isInit);
export const getLabelOverrides = createSelector(getProperties, PropertiesSelectors.getLabelOverrides);

export const getUom = (state: State) => state.uom;
export const getUomType = createSelector(getUom, UomSelectors.getUomType);

export const getSearch = (state: State) => state.search;
export const getSearchResults = createSelector(getSearch, SearchSelectors.getSearchResults);
export const isSearchShowLoading = createSelector(getSearch, SearchSelectors.isShowLoading);
export const isSearchRequestInProcess = createSelector(getSearch, SearchSelectors.isRequestInProcess);
export const getSearchDesign = createSelector(getSearch, SearchSelectors.getSearchDesign);
export const getSearchSort = createSelector(getSearch, SearchSelectors.getSearchSort);
export const getSearchDirty = createSelector(getSearch, SearchSelectors.getSearchDirty);
export const getPageSize = createSelector(getSearch, SearchSelectors.getPageSize);
export const getPageNumber = createSelector(getSearch, SearchSelectors.getPageNumber);
export const getTotalResults = createSelector(getSearch, SearchSelectors.getTotalResults);
