import {DisplayBreadcrumbs} from '../reducers/workflow.reducer';
import {createAction, props} from '@ngrx/store';

export namespace WorkflowActions {
  export const init = createAction(
    '[workflow] Init',
    props<{ payload: InitWorkflowPayload }>()
  );

  export const displayBreadcrumbs = createAction(
    '[workflow] Display Breadcrumbs',
    props<{ breadcrumbs: DisplayBreadcrumbs }>()
  );

  export const displayData = createAction(
    '[workflow] Display Data',
    props<{ payload: DisplayDataPayload }>()
  );
}

export interface InitWorkflowPayload {
  workflowId: string;
}

export interface DisplayDataPayload {
  displayPrice: boolean;
  displaySaveButton: boolean;
  displaySearchButton: boolean;
  createCustomOptions: boolean;
}
