<nav mat-tab-nav-bar *ngIf="pages$|async as pages" class="m-b-base">
  <ng-container *ngIf="pages.length > 0">
    <a *ngFor="let page of pages" mat-tab-link role="tab" attr.test-id="{{page.id}}"
       [routerLink]="configRoot" [queryParams]="{page: page.id}"
       routerLinkActive #rla="routerLinkActive"
       [active]="rla.isActive">
      <span>{{page.description}}</span>
      <span *ngIf="page.invalid" style="margin-left: 10px" matTooltip="{{'invalidFieldValue' | cbsLabel}}">
        <i class="fas fa-exclamation-triangle"></i>
      </span>
    </a>
  </ng-container>
  <a *ngIf="pages.length === 0" mat-tab-link id="configure_tab" aria-controls="configure_tab" role="tab"
     [routerLink]="configRoot"
     routerLinkActive #rla="routerLinkActive"
     [active]="rla.isActive">
    {{'Configure' | cbsLabel}}
  </a>
  <a *ngIf="priceSummaryColumns$|async" mat-tab-link id="priceSummary_tab" aria-controls="priceSummary_tab" role="tab"
     [routerLink]="'/price-summary'"
     routerLinkActive #rla="routerLinkActive"
     [active]="rla.isActive">
    {{'priceSummary' | cbsLabel}}
  </a>
  <a *ngIf="viewDocsPrivilege$|async" mat-tab-link id="documents_tab" aria-controls="documents_tab" role="tab"
     [routerLink]="'/documents'"
     routerLinkActive #rla="routerLinkActive"
     [active]="rla.isActive">
    {{'documents' | cbsLabel}}
  </a>
</nav>
