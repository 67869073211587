import {Component, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Category} from '../models/Category';
import {CategoryMenuService} from '../services/category-menu.service';
import {ParentSubcategory} from '../models/ParentSubcategory';
import {CategoryMenuStrategy} from '../reducers/properties.reducer';

@Component({
  selector: 'cbs-category-menu-category',
  templateUrl: './category-menu-category.component.html',
  styles: [`
    :host {
      display: block;
      float: inherit !important;
      margin-bottom: 0 !important;
    }

    .category-menu__item {
      cursor: pointer;
      display: flex !important;
    }

    .category-menu__title {
      align-self: center;
      flex-grow: 1;
    }

    :host.category-menu--sub-category-strategy .category-menu__title--no-toggle {
      padding-left: 25px;
    }

    :host.category-menu--sub-category-strategy .category-menu__item {
      padding-left: 0;
    }

    .category-menu__toggle {
      text-align: center;
      align-self: stretch;
      display: flex;
      align-items: center;
    }

    .category-menu__toggle span {
      width: 25px;
    }
  `]
})
export class CategoryMenuCategoryComponent implements OnChanges {
  @HostBinding('class.category-menu--sub-category-strategy') subCategoryStrategy = false;
  @Input() category: Category;
  @Input() strategy: CategoryMenuStrategy;
  @Input() showCategoryLevel = true;
  hasSubCategories = false;

  constructor(private categoryMenuService: CategoryMenuService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.strategy === CategoryMenuStrategy.SUB_CATEGORY && changes.category && changes.category.currentValue) {
      this.subCategoryStrategy = true;
      this.hasSubCategories = this.category.parentSubcategories &&
        this.category.parentSubcategories.some(subCategory => !!subCategory.description);
    }
  }

  select() {
    this.categoryMenuService.selectCategory(this.category);
  }

  isExpanded() {
    return this.categoryMenuService.isExpanded(this.category);
  }

  toggle() {
    this.categoryMenuService.toggle(this.category);
  }

  trackBySubCategoryId(index: number, item: ParentSubcategory) {
    return item.id;
  }
}
