/**
 * Injects scripts into the head of the app.
 *
 * @param analyticsScripts String that can be parsed as HTML.
 */
export function injectAnalyticsScripts(analyticsScripts: string): void {
  if (typeof analyticsScripts !== 'string') {
    return;
  }
  const tempElem = document.createElement('div');
  // script elements inserted using innerHTML or insertAdjacentHTML do not execute
  // so use the temporary element to parse the text as HTML and then create executable scripts
  tempElem.insertAdjacentHTML('afterbegin', analyticsScripts);
  const nonExecutableScripts = tempElem.querySelectorAll('script');
  nonExecutableScripts.forEach((nonExecutableScript) => {
    const executableScript = document.createElement('script');
    if (nonExecutableScript.src) {
      executableScript.src = nonExecutableScript.src;
      executableScript.async = true;
    } else if (nonExecutableScript.text) {
      executableScript.text = nonExecutableScript.text;
    }
    document.head.appendChild(executableScript);
  });
}
