import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ValidateFieldService} from '../services/validate-field.service';
import {State} from '../reducers/index';
import {Store} from '@ngrx/store';
import {getProperties} from '../reducers';
import {SafeStore} from '../util/safe-store';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'cbs-search-button',
  templateUrl: './search-button.component.html'
})
export class SearchButtonComponent implements OnInit {
  @Input() location: string;
  eachCosChangeRunsSearch: boolean;
  private safeStore: SafeStore<State>;

  constructor(private router: Router, private store: Store<State>, private validateFieldService: ValidateFieldService) {
    this.safeStore = new SafeStore(this, this.store);
  }

  ngOnInit() {
    this.safeStore.subscribe(getProperties, properties => this.eachCosChangeRunsSearch = properties.eachCosChangeRunsSearch);
  }

  navigateToSearch() {
    setTimeout(() => {
      if (this.validateFieldService.validateMandatory()) {
        this.router.navigate(['/search']);
      }
    }, 300);
  }
}
