<h3 class="cbs-underlined" [id]="subcategory.id" *ngIf="!!subcategory.description">
  {{subcategory.description}}
</h3>
<div [ngStyle]="{'padding': !subcategory.menuType.includes('notitle') ? '.5rem .5rem 0': ''}" data-tab-content>
  <div class="grid-container"
       [ngClass]="{'multi-column': !(optionColumnLayout ===1 || (optionColumnLayout == 2 && subcategory.colSpan === 1)), 'single-column': (optionColumnLayout == 1 || (optionColumnLayout == 2 && subcategory.colSpan === 1))}">
    <div *ngFor="let field of nonSearchFields(); trackBy: trackByField" class="grid-item field-item"
         [ngClass]="['row-span-' + field.rowSpan, 'column-span-' + field.colSpan, 'row-' + field.rowPos, 'column-' + field.colPos, 'mobile-' + field.mobilePos]">
      <cbs-field style=" width: 100%" [field]="field" [categoryId]="categoryId" [parentSubcatId]="parentSubcategory.id" [pageId]="pageId"
                 [subcatId]="subcategory.id" [fieldContainer]="fieldContainer" [optionColumnLayout]="optionColumnLayout">
      </cbs-field>
    </div>
  </div>
</div>
