<div align="center" *ngIf="displaySearchResults(); else noResults" [ngStyle]="inputStyle">
  <div *ngIf="isLoading$|async as loading; else resultTable" class="p-3">
    <cbs-loading [display]="true" [diameter]="50" [strokeWidth]="5"></cbs-loading>
  </div>
  <ng-template #resultTable>
    <ng-container *ngIf="searchResultsExists$|async; else noResults">
      <ng-container *ngIf="searchDesign$|async as searchDesign; else noResults">
        <ng-container *ngIf="dataColumns$|async as dataColumns; else noResults">
          <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
          <ng-container></ng-container>
          <ng-content></ng-content>
          <div class="m-b-none" style="overflow-x: auto; overflow-y: hidden">
            <ng-container *ngIf="sorting$|async as sorting">
              <cbs-search-results-table class="p-none" style="display: block" [sorting]="sorting"
                                        [dataColumns]="dataColumns" [searchDesign]="searchDesign">

              </cbs-search-results-table>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-template>
</div>
<ng-template #noResults>
  <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
  <br/><br/>
  <p class="text-center lead">{{'noResultsMessage' | cbsLabel}}</p>
  <h1 class="text-center"><span><i class="fas fa-search-minus text-subdued"></i></span></h1>
  <p class="text-center">{{'updateSearchInputs' | cbsLabel}}</p>
  <br/>
</ng-template>
