import {PropertiesState} from '../reducers/properties.reducer';

export const pumpworks610 = {
  Spares: <PropertiesState>{
    po_subnavStrategy: 'none',
    searchBarSize: 'large',
    searchBarLocation: 'results',
    po_hideExpandCollapseAll: true,
    po_displayPartNumber: true,
  }
};
