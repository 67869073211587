import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {AppComponent} from './components/app.component';
import {ProductOptionsService} from './services/web/product-options.service';
import {CategoryComponent} from './components/category.component';
import {SpinnerComponent} from './components/spinner.component';
import {ParentSubcategoryComponent} from './components/parent-subcategory.component';
import {FieldComponent} from './components/field.component';
import {LoadingComponent} from './components/loading.component';
import {LocalStorageModule, LocalStorageService} from 'angular-2-local-storage';
import {AccordionService} from './services/accordion.service';
import {ProductOptionsComponent} from './components/product-options.component';
import {ProductOptionsTitleComponent} from './components/product-options-title.component';
import {NavBarComponent} from './components/nav-bar.component';
import {LeftNavComponent} from './components/left-nav.component';
import {FooterComponent} from './components/footer.component';
import {StoreModule} from '@ngrx/store';
import {metaReducers, reducers} from './reducers/index';
import {EffectsModule} from '@ngrx/effects';
import {ProductOptionsEffects} from './effects/product-options.effect';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {GlobalCssComponent} from './components/global-css.component';
import {RouterModule} from '@angular/router';
import {DocumentsComponent} from './components/documents.component';
import {DocumentEffects} from './effects/documents.effect';
import {DocumentService} from './services/web/document.service';
import {ImageService} from './services/image.service';
import {CbsService} from './services/web/cbs.service';
import {CbsEffects} from './effects/cbs.effect';
import {CosComponent} from './components/cos.component';
import {CosService} from './services/web/cos.service';
import {CosEffects} from './effects/cos.effect';
import {routes} from './routes';
import {SaveButtonComponent} from './components/save-button.component';
import {RollbarModule} from './rollbar/rollbar.module';
import {DebugMenuComponent} from './components/debug-menu.component';
import {WindowService} from './services/window.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HeaderComponent} from './components/header.component';
import {HeaderService} from './services/web/header.service';
import {HeaderEffects} from './effects/header.effect';
import {ValidateFieldService} from './services/validate-field.service';
import {InvalidFieldComponent} from './components/invalid-field.component';
import {CbsMaterialModule} from './util/cbs-material.module';
import {SearchResultsComponent} from './components/search-results.component';
import {SearchEffects} from './effects/search.effect';
import {SubcategoryComponent} from './components/subcategory.component';
import {isDevEnv, isEdgeEnv} from './util/environment-utils';
import {NewItemComponent} from './components/new-item.component';
import {HeaderLinkComponent} from './components/header-link.component';
import {CosFilterSearchRouteComponent} from './components/cos-filter-search-route.component';
import {ProductOptionsRouteComponent} from './components/product-options-route.component';
import {DocumentsRouteComponent} from './components/documents-route.component';
import {WorkflowDispatcherRouteComponent} from './components/workflow-dispatcher-route.component';
import {ProductContentComponent} from './components/product-content.component';
import {CosRouteComponent} from './components/cos-route.component';
import {SearchButtonComponent} from './components/search-button.component';
import {SearchRouteComponent} from './components/search-route.component';
import {AppInitErrorComponent} from './components/app-init-error.component';
import {CosProductOptionsRouteComponent} from './components/cos-product-options-route.component';
import {WorkflowEffects} from './effects/workflow.effect';
import {WorkflowService} from './services/web/workflow.service';
import {ButtonBarComponent} from './components/button-bar.component';
import {TabNavComponent} from './components/tab-nav.component';
import {GlobalNotificationsComponent} from './components/global-notifications.component';
import {ToggledButtonLinkComponent} from './components/toggled-button-link.component';
import {ValidationGuard} from './services/validation-guard.service';
import {Angulartics2Module} from 'angulartics2';
import {SearchFilterComponent} from './components/search-filter.component';
import {LabelService} from './services/label.service';
import {SearchResultsTableComponent} from './components/search-results-table.component';
import {HttpClientModule} from '@angular/common/http';
import {BasicPartsSearchRouteComponent} from './components/basic-parts-search-route.component';
import {SelectedProductsComponent} from './components/selected-products.component';
import {ProductEffect} from './effects/product.effect';
import {ProductService} from './services/web/product.service';
import {Angulartics2GoogleTagManager} from 'angulartics2/gtm';
import {NearMissReasonsComponent} from './components/near-miss-reasons.component';
import {GoogleTagManagerService} from './services/google-tag-manager.service';
import {SaveVerificationComponent} from './components/save-verification.component';
import {PartsConfigurationRouteComponent} from './components/parts-configuration-route.component';
import {SaveVerificationService} from './services/web/save-verification.service';
import {DragulaModule} from 'ng2-dragula';
import {OptionOverrideComponent} from './components/option-override.component';
import {ItemMultiplierComponent} from './components/item-multiplier.component';
import {AlertComponent} from './components/alert.component';
import {ConfirmDialogComponent} from './components/confirm-dialog.component';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {HoverMenuComponent} from './components/hover-menu.component';
import {CustomOptionButtonComponent} from './components/custom-option-button.component';
import {SaveAsComponent} from './components/save-as.component';
import {SaveProjectComponent} from './components/save-project.component';
import {AnalyticsComponent} from './components/analytics.component';
import {CbsLabelPipe} from './pipes/label.pipe';
import {CategoryMenuMenuComponent} from './components/category-menu-menu.component';
import {CategoryMenuCategoryComponent} from './components/category-menu-category.component';
import {CategoryMenuSubCategoryComponent} from './components/category-menu-subcategory.component';
import {VisualizationComponent} from './components/visualization.component';
import {EmailRfqComponent} from './components/email-rfq.component';
import {PriceSummaryComponent} from './components/price-summary.component';
import {PriceSummaryRouteComponent} from './components/price-summary-route.component';
import {AgGridModule} from 'ag-grid-angular';
import {PartNumberDialogComponent} from './components/part-number/part-number-dialog.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {PartNumberExistingComponent} from './components/part-number/part-number-existing.component';
import {PartNumberSpreadsheetComponent} from './components/part-number/part-number-spreadsheet.component';
import {PartNumberMissingComponent} from './components/part-number/part-number-missing.component';

@NgModule({
    declarations: [
        AppComponent,
        ParentSubcategoryComponent,
        CategoryComponent,
        FieldComponent,
        SpinnerComponent,
        LoadingComponent,
        ProductOptionsComponent,
        ProductOptionsTitleComponent,
        NavBarComponent,
        LeftNavComponent,
        FooterComponent,
        GlobalCssComponent,
        DocumentsComponent,
        CosComponent,
        SaveButtonComponent,
        DebugMenuComponent,
        HeaderComponent,
        InvalidFieldComponent,
        SearchResultsComponent,
        SubcategoryComponent,
        NewItemComponent,
        HeaderLinkComponent,
        CosFilterSearchRouteComponent,
        ProductOptionsRouteComponent,
        DocumentsRouteComponent,
        WorkflowDispatcherRouteComponent,
        ProductContentComponent,
        CosRouteComponent,
        SearchButtonComponent,
        SearchRouteComponent,
        AppInitErrorComponent,
        CosProductOptionsRouteComponent,
        ButtonBarComponent,
        TabNavComponent,
        GlobalNotificationsComponent,
        ToggledButtonLinkComponent,
        SearchFilterComponent,
        SearchResultsTableComponent,
        BasicPartsSearchRouteComponent,
        SelectedProductsComponent,
        NearMissReasonsComponent,
        SaveVerificationComponent,
        PartsConfigurationRouteComponent,
        OptionOverrideComponent,
        ItemMultiplierComponent,
        AlertComponent,
        ConfirmDialogComponent,
        HoverMenuComponent,
        CustomOptionButtonComponent,
        SaveAsComponent,
        SaveProjectComponent,
        AnalyticsComponent,
        CbsLabelPipe,
        CategoryMenuMenuComponent,
        CategoryMenuCategoryComponent,
        CategoryMenuSubCategoryComponent,
        VisualizationComponent,
        EmailRfqComponent,
        PriceSummaryRouteComponent,
        PriceSummaryComponent,
        PartNumberDialogComponent,
        PartNumberExistingComponent,
        PartNumberSpreadsheetComponent,
        PartNumberMissingComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        RollbarModule,
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
                strictStateSerializability: true,
                strictActionSerializability: true,
                strictActionWithinNgZone: true
            },
        }),
        isDevEnv() || isEdgeEnv() ? StoreDevtoolsModule.instrument({ maxAge: 50 }) : [],
        EffectsModule.forRoot([
            ProductOptionsEffects,
            CbsEffects,
            HeaderEffects,
            DocumentEffects,
            CosEffects,
            SearchEffects,
            WorkflowEffects,
            ProductEffect
        ]),
        LocalStorageModule.forRoot({
            prefix: 'cbs',
            storageType: 'sessionStorage'
        }),
        RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' }),
        CbsMaterialModule,
        Angulartics2Module.forRoot([Angulartics2GoogleTagManager]),
        BrowserAnimationsModule,
        DragulaModule,
        NgbPopoverModule,
        AgGridModule.withComponents([]),
        ClipboardModule
    ],
    providers: [
        ProductOptionsService,
        CbsService,
        LocalStorageService,
        AccordionService,
        DocumentService,
        ImageService,
        CosService,
        WindowService,
        HeaderService,
        ValidateFieldService,
        WorkflowService,
        ValidationGuard,
        LabelService,
        ProductService,
        Angulartics2GoogleTagManager,
        GoogleTagManagerService,
        SaveVerificationService
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
