import {PropertiesState} from '../reducers/properties.reducer';

export const acme = {
  AcmeMixers: <PropertiesState>{},
  Acme_Parts: <PropertiesState>{
    po_subnavStrategy: 'none',
    searchBarSize: 'large',
    searchBarLocation: 'results'
  }
};
