import {LocalStorageService} from 'angular-2-local-storage';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {getOrgFolderHome, getProductOptionCategories, getProperties, State} from '../reducers/index';
import {assignFirstTruthy} from '../util/rx-utils';
import {Category} from '../models/Category';
import {ParentSubcategory} from '../models/ParentSubcategory';
import {ViewportScroller} from '@angular/common';
import {AccordionService} from './accordion.service';
import {SafeStore} from '../util/safe-store';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable()
export class CategoryMenuService {
  private orgFolderHome: string;
  private expanded: { [categoryId: string]: boolean } = {};
  private accordionMode = false;

  constructor(private localStorageService: LocalStorageService, private viewportScroller: ViewportScroller,
              store: Store<State>, private accordionService: AccordionService) {
    assignFirstTruthy(store, getOrgFolderHome, orgFolderHome => this.orgFolderHome = orgFolderHome);
    const safeStore = new SafeStore(this, store);
    safeStore.subscribe(getProperties, props => this.accordionMode = props.po_onlyOneOpenAccordion);
    safeStore.subscribe(getProductOptionCategories, (categories) => {
      this.expanded = {};
      if (categories) {
        categories.forEach((category) => {
          const result = <boolean>this.localStorageService.get(this.getLocalStorageKey(category.id));
          this.expanded[category.id] = (result !== false); // default to true if not defined
        });
      }
    });
  }

  isExpanded(category: Category) {
    return this.expanded[category.id] || false;
  }

  toggle(category: Category) {
    const newState = !this.isExpanded(category);
    this.setExpanded(category.id, newState);
  }

  expandAll() {
    for (const categoryId of Object.keys(this.expanded)) {
      this.setExpanded(categoryId, true);
    }
  }

  collapseAll() {
    for (const categoryId of Object.keys(this.expanded)) {
      this.setExpanded(categoryId, false);
    }
  }

  selectCategory(category: Category) {
    this.viewportScroller.scrollToAnchor(category.id);
  }

  selectSubCategory(subCategory: ParentSubcategory) {
    if (this.accordionService.isExpanded(subCategory.id)) {
      this.viewportScroller.scrollToAnchor(subCategory.id);
    } else {
      this.accordionService.expandAccordion(subCategory.id);
      if (!this.accordionMode) { // in accordion mode, we automatically scroll after expanding
        setTimeout(() => {
          this.viewportScroller.scrollToAnchor(subCategory.id);
        });
      }
    }
  }

  isSelected(subCategory: ParentSubcategory) {
    return this.accordionMode && this.accordionService.isExpanded(subCategory.id);
  }

  private setExpanded(categoryId: string, value: boolean) {
    this.expanded[categoryId] = value;
    this.localStorageService.set(this.getLocalStorageKey(categoryId), value);
  }

  private getLocalStorageKey(categoryId: string) {
    return `${this.orgFolderHome}.${categoryId}.menu_expanded`;
  }
}
