import {Component, Input, OnInit} from '@angular/core';
import {ParentSubcategory} from '../models/ParentSubcategory';
import {FieldContainer} from '../models/FieldContainer';
import {AccordionService} from '../services/accordion.service';
import {WorkflowUtils} from '../reducers/workflow.reducer';
import {trackByField, trackById} from '../util/track-by-util';
import {SEARCH_INPUT_ID} from './search-filter.component';
import {WindowService} from '../services/window.service';

@Component({
  selector: 'cbs-subcategory',
  templateUrl: './subcategory.component.html',
})
export class SubcategoryComponent implements OnInit {

  @Input() pageId: string;
  @Input() subcategory: ParentSubcategory;
  @Input() parentSubcategory: ParentSubcategory;
  @Input() categoryId: string;
  @Input() fieldContainer: FieldContainer;
  @Input() optionColumnLayout: number;
  @Input() includeSearch = false;

  constructor(private accordionService: AccordionService, private windowService: WindowService) {
  }

  ngOnInit() {
    this.accordionService.initAccordionItem(this.subcategory.id, !this.categoryId || this.subcategory.description === '');
    this.optionColumnLayout = this.isMedium() ? Math.min(this.optionColumnLayout, 2) : this.optionColumnLayout;
  }

  isExpanded(parentSubcategoryId) {
    return this.accordionService.isExpanded(parentSubcategoryId);
  }

  nonSearchFields() {
    return this.subcategory.fields
      .filter(field => (field.id !== SEARCH_INPUT_ID || this.includeSearch));
  }

  trackByField(index, field) {
    return trackByField(index, field);
  }

  trackById(index, field) {
    return trackById(index, field);
  }

  isTwoColumnFieldLayout(workflowId: string) {
    return WorkflowUtils.isTwoColumnFieldLayout(workflowId);
  }

  isMedium() {
    return this.windowService.getWidth() <= 889;
  }
}
