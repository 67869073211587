import {Discount} from '../models/Discount';
import {createReducer, on} from '@ngrx/store';
import {ProductOptionActions} from '../actions/product-options.action';
import {DiscountActions} from '../actions/discount.action';

export interface DiscountState {
  discountList?: Discount;
  discountChangeList?: DiscountChange[];
}

export interface DiscountChange {
  discountId: string;
  updateValue: number;
  updateAttribute: string;
}

const initialState: DiscountState = {
  discountChangeList: []
};

export const discountReducer = createReducer(
  initialState,
  on(ProductOptionActions.initSuccess, (state, {response}) => ({...state, discountList: response.discounts})),
  on(ProductOptionActions.changeFieldSuccess, (state, {response}) => {
    if (response.discounts) {
      return {...state, discountList: response.discounts};
    } else {
      return state;
    }
  }),
  on(DiscountActions.updateDiscount, (state, {discount, value, attribute}) => {
    const updatedChangeList = [].concat(state.discountChangeList);
    const change: DiscountChange = {
      discountId: discount.objectID,
      updateValue: value, updateAttribute: attribute
    };
    updatedChangeList.push(change);
    return {
      ...state,
      discountChangeList: updatedChangeList
    };
  }),
  on(DiscountActions.resetDiscounts, (state) => ({...state, discountChangeList: []})),
  on(DiscountActions.updateDiscountSuccess, (state, {response}) => ({...state, discountList: response.discounts, discountChangeList: []}))
);

export class DiscountSelectors {
  static getDiscountList = (state: DiscountState) => state.discountList;
  static getDiscountChangeList = (state: DiscountState) => state.discountChangeList;
}
