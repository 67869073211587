<div class="c-card main-content">
  <cbs-button-bar class="sticky-content p-v-none"></cbs-button-bar>
  <div class="flex-grid flex-y-start">
    <cbs-cos [optionColumnLayout]="cos_filterColumnCount" class="m-t-base"></cbs-cos>
    <div class="cbs-section flex-4 m-b-base" style="overflow-x: hidden; align-self: start">
      <cbs-search-filter></cbs-search-filter>
      <ng-container *ngTemplateOutlet="soloTable"></ng-container>
    </div>
  </div>
</div>
<ng-template #soloTable>
  <cbs-search-results [headerTemplate]="searchResultsFilter">
    <ng-container *ngIf="(location$|async) === 'results'">
      <hr>
    </ng-container>
  </cbs-search-results>
</ng-template>
<ng-template #searchResultsFilter>
  <cbs-search-filter [location]="'results'"></cbs-search-filter>
</ng-template>
