import {ChangeDetectionStrategy, Component, Inject, OnInit, Renderer2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Store} from '@ngrx/store';
import {getIfeIntegrationInfo, getSystemProperties, getUserName, State} from '../reducers';
import {SafeStore} from '../util/safe-store';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'cbs-analytics',
  templateUrl: './analytics.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AnalyticsComponent implements OnInit {
  private safeStore: SafeStore<State>;
  private orgFolderHome: string;
  private userName: string;
  private email: string;
  private orgName: string;
  private firstName: string;
  private lastName: string;
  private gainsightAnalyticsTag: string;

  constructor(private renderer2: Renderer2, @Inject(DOCUMENT) private _document, private store: Store<State>) {
    this.safeStore = new SafeStore(this, this.store);

    this.safeStore.subscribe(getUserName, userName => this.userName = userName);
    this.safeStore.subscribe(getSystemProperties, systemProperties => this.gainsightAnalyticsTag = systemProperties.gainsightAnalyticsTag);
    this.safeStore.subscribe(getIfeIntegrationInfo, ifeIntegrationInfo => {
      this.firstName = ifeIntegrationInfo.firstName;
      this.lastName = ifeIntegrationInfo.lastName;
      this.email = ifeIntegrationInfo.email;
      this.orgName = ifeIntegrationInfo.orgName;
      this.userName = ifeIntegrationInfo.userName;
      this.orgFolderHome = ifeIntegrationInfo.orgFolderHome;
    });
  }

  ngOnInit() {
    if (!this.gainsightAnalyticsTag) {
      return;
    }
    const scriptElement = this.renderer2.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.text = this.getGainsightJavascript();
    this.renderer2.appendChild(this._document.body, scriptElement);
  }

  private getGainsightJavascript() {
    return `(function(n,t,a,e){var i="aptrinsic";n[i]=n[i]||function(){
    (n[i].q=n[i].q||[]).push(arguments)},n[i].p=e;
     var r=t.createElement("script");r.async=!0,r.src=a+"?a="+e;
     var c=t.getElementsByTagName("script")[0];c.parentNode.insertBefore(r,c)
     })(window,document,"https://web-sdk.aptrinsic.com/api/aptrinsic.js",'${this.gainsightAnalyticsTag}');

     //User fields tracking JS
    aptrinsic("identify",
    {
      //User Fields
      "id": '${this.userName}', // Required for logged in app users
      "email": '${this.email}',
      "firstName": '${this.firstName}',
      "lastName": '${this.lastName}'
      },
      {
      //Account Fields
      "id":'${this.orgFolderHome}', // orgFolderHome, without the trailing '/'
      "name":'${this.orgName}'  //org name
    });`;
  }

}
