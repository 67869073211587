import {Component, Input, OnInit} from '@angular/core';
import {Field} from '../models/Field';
import {Observable} from 'rxjs';
import {getCosParentSubcategory, getSearchDesign, getWorkflowId, State} from '../reducers/index';
import {select, Store} from '@ngrx/store';
import {FieldContainer} from '../models/FieldContainer';
import {isSupported} from '../util/workflow-utils';
import {map} from 'rxjs/operators';
import {SearchActions} from '../actions/search.action';
import {FieldActions} from '../actions/field.action';

@Component({
  selector: 'cbs-search-filter',
  templateUrl: './search-filter.component.html',
  styles: [
    `
      :host ::ng-deep div {
        display: flex;
        align-items: baseline;
      }

      :host ::ng-deep .mat-form-field-underline {
        display: none;
      }

      :host ::ng-deep .mat-form-field-appearance-legacy .mat-form-field-wrapper {
        padding-bottom: 0;
      }

      :host ::ng-deep .mat-form-field-prefix {
        align-self: center;
      }
    `
  ],
})
export class SearchFilterComponent implements OnInit {

  searchField$: Observable<Field>;
  fieldContainer = FieldContainer.Cos;
  parentSubcatId: string;
  subcatId: string;
  searchFilter$: Observable<boolean>;
  cosSearchFilter$: Observable<boolean>;
  display$: Observable<boolean>;
  @Input() location = 'default';
  @Input() inCos = false;

  constructor(private store: Store<State>) {
    this.searchFilter$ = this.store.pipe(select(getSearchDesign), map(design => {
      return design && design.textSearchableList.length > 0;
    }));
    this.cosSearchFilter$ = this.store.pipe(select(getWorkflowId), map(workflowId => isSupported(workflowId, 'cosSearchFilter')));
    this.searchField$ = this.store.pipe(select(getCosParentSubcategory),
      map(parent => {
        this.parentSubcatId = parent.id;
        const searchField = this.flattenFields(parent).find(field => field.id === SEARCH_INPUT_ID);
        const searchSubcat = parent.subcategories.find(
          subcat => subcat.fields.filter(field => field === searchField).length > 0);
        this.subcatId = searchSubcat ? searchSubcat.id : null;
        return searchField;
      }));
    this.display$ = this.store.pipe(select(getSearchDesign),
      map(design => (design.searchBarLocation || 'default') === this.location));
  }

  ngOnInit() {
  }

  flattenFields(parentSubcat) {
    return parentSubcat.subcategories
      .map(subcat => subcat.fields).reduce((fields, field, i, []) => fields.concat(field))
      .concat(parentSubcat.fields);
  }

  submitSearchFieldChange(field, value) {
    this.store.dispatch(FieldActions.tempChangeFieldValue({
      categoryId: null,
      parentSubcatId: this.parentSubcatId,
      subcatId: this.subcatId,
      fieldId: SEARCH_INPUT_ID,
      value: value,
      fieldContainer: this.fieldContainer
    }));
    const params = [[field.httpName, value]];
    this.submitSearch(params);
  }

  submitSearch(changeParams: string[][]) {
    this.store.dispatch(SearchActions.filter({changeParams}));
  }
}

/* special field extracted from cos, for use on the SearchFilterComponent */
export const SEARCH_INPUT_ID = 'SearchInput';
