import {CosFieldChangeResponse} from '../services/web/cos-field-change-response';
import {createAction, props} from '@ngrx/store';
import {
  ChangeFieldPayload, FieldId, TempChangeCheckboxPayload, TempChangeDropdownPayload, TempChangeFieldValuePayload,
  TempChangeNumericFieldPayload
} from './field.action';
import {COSInitResponse} from '../services/web/cos-init-response';

export namespace CosActions {
  export const initSuccess = createAction(
    '[cos] Init Success',
    props<{ response: COSInitResponse }>()
  );

  export const changeField = createAction(
    '[cos] Change Field',
    props<{ payload: ChangeFieldPayload }>()
  );

  export const changeFieldSuccess = createAction(
    '[cos] Change Field Success',
    props<{ response: CosFieldChangeResponse }>()
  );

  export const tempChangeFieldValue = createAction(
    '[cos] Temp Change Field Value',
    props<{ payload: TempChangeFieldValuePayload }>()
  );

  export const tempChangeNumericField = createAction(
    '[cos] Temp Change Numeric Field',
    props<{ payload: TempChangeNumericFieldPayload }>()
  );

  export const tempChangeDropdownField = createAction(
    '[cos] Temp Change Dropdown Field',
    props<{ payload: TempChangeDropdownPayload }>()
  );

  export const tempChangeCheckboxField = createAction(
    '[cos] Temp Change Checkbox Field',
    props<{ payload: TempChangeCheckboxPayload }>()
  );

  export const invalidField = createAction(
    '[cos] Invalid Field',
    props<{ payload: FieldId }>()
  );
}

