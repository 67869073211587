import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {getHeaders, State} from '../reducers/index';
import {Observable} from 'rxjs';
import {FieldContainer} from '../models/FieldContainer';
import {trackById} from '../util/track-by-util';
import {WindowService} from '../services/window.service';
import {firstTruthy} from '../util/rx-utils';
import {HeaderActions} from '../actions/header.action';

@Component({
  selector: 'cbs-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  headerFields$: Observable<any>;
  fieldContainer = FieldContainer.Header;

  constructor(private store: Store<State>, private windowService: WindowService) {
  }

  ngOnInit() {
    this.headerFields$ = this.store.pipe(select(getHeaders));
  }

  ngOnDestroy(): void {
    this.resetHeaders();
  }

  trackByField(index, field) {
    return trackById(index, field);
  }

  saveAndClose() {
    const changeParams = [];
    this.headerFields$.pipe(firstTruthy()).subscribe(fields => {
      fields.forEach(field => {
        if (field.displayType.toLowerCase().includes('numeric')) {
          changeParams.push([field.httpNumericValueName, field.value]);
        } else if (!!field.choices) {
          changeParams.push([field.httpName, field.choices.filter((choice) => choice.selected)[0].httpValue]);
        } else {
          changeParams.push([field.httpName, field.value]);
        }
      });
      this.store.dispatch(HeaderActions.submit({
        payload: {
          changeParams: changeParams,
          fieldContainer: this.fieldContainer
        }
      }));
    });
  }

  resetHeaders() {
    this.store.dispatch(HeaderActions.reset());
  }

  isMobile() {
    return this.windowService.getWidth() <= 640;
  }
}
