<div *ngIf="!aliasService.isDirty()">
  <button class="c-text-button" test-id="SELECT_ALL_BUTTON" (click)="selectAll()">{{ 'selectAll' | cbsLabel }}</button>
  |
  <button class="c-text-button" test-id="COPY_SELECTED_BUTTON" (click)="copySelected()">{{ 'copyToClipboard' | cbsLabel }}</button>
</div>
<div *ngIf="aliasService.isDirty()" style="margin-bottom: 10px">
  <button class="c-button" test-id="SAVE_BUTTON" (click)="save()">{{ 'save' | cbsLabel }}</button>
  <button class="c-text-button" test-id="CANCEL_BUTTON" (click)="cancel()">{{ 'cancel' | cbsLabel }}</button>
</div>
<div class="cbs-section p-base" style="flex: 1 1 auto">
  <ag-grid-angular class="ag-theme-balham"
                   [defaultColDef]="defaultColDef"
                   [columnDefs]="columnDefs"
                   [rowData]="rowData | async"
                   rowSelection="multiple"
                   (gridReady)="onGridReady($event)"
                   [suppressNoRowsOverlay]="true"
                   [stopEditingWhenGridLosesFocus]="true"
                   [overlayLoadingTemplate]="aliasService.overlayTemplate"
                   style="width: 100%; height: 100%"></ag-grid-angular>
</div>
