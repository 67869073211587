import {Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {WorkflowActions} from '../actions/workflow.action';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'cbs-price-summary-route',
  templateUrl: './price-summary-route.component.html'
})
export class PriceSummaryRouteComponent implements OnInit {

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(WorkflowActions.displayBreadcrumbs({
      breadcrumbs: {product: {display: true, target: 'price-summary'}}
    }));
    this.store.dispatch(WorkflowActions.displayData({
      payload: {
        displayPrice: true,
        displaySaveButton: true,
        displaySearchButton: false,
        createCustomOptions: false
      }
    }));
  }
}
