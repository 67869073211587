<ng-container *ngIf="properties$ | async as properties">
  <ng-container *ngIf="(displaySearchButton$|async) || (displaySaveButton$|async)">
    <div class="flex-grid flex-shrink flex-x-end m-b-none">
      <button *ngIf="displayManagePartNumberAliasButton && isPartsSearchPageActive()" class="c-button" test-id="MANAGE_ALIASES_BUTTON"
              (click)="openManagePartNumberAliases()">
        {{'managePartNumberAlias' | cbsLabel}}
      </button>
      <ng-container *ngIf="(openAccessUser$|async) && (displaySaveButton$|async)">
        <button class="c-button" test-id="REQUEST_FOR_QUOTE_BUTTON" (click)="openEmailDialog()">
          <i class="fas fa-envelope"></i> {{'requestForQuote' | cbsLabel}}
        </button>
      </ng-container>
      <ng-container *ngIf="displayCustomOptionButton$|async">
        <cbs-custom-option *ngIf="displayCustomOptionButton" (checkDisplay)="updateCustomOptionDisplay($event)"></cbs-custom-option>
      </ng-container>
      <cbs-search-button *ngIf="displaySearchButton$|async"></cbs-search-button>
      <ng-container *ngIf="displaySaveButton$|async">
        <cbs-save-button ></cbs-save-button>
        <ng-container *ngIf="customOriginUrl$ | async">
          <button class="c-button" (click)="cancel()">
            <span>{{'cancel' | cbsLabel}}</span>
          </button>
          <button class="c-button" *ngIf="isNewItem$ | async" (click)="startOver()">
            <span>{{'startOver' | cbsLabel}}</span>
          </button>
        </ng-container>
      </ng-container>
    </div>
    <ng-container *ngIf="displaySaveSuccess || displaySaveFail">
      <div class="c-flash c-callout text-black" [ngClass]="{'c-callout--success': displaySaveSuccess, 'c-callout--alert': displaySaveFail}"
           test-id="SAVE_MESSAGE" (click)="closeSaveMessage()">
        <b>{{displaySaveSuccess ? 'Success' : 'Failed'}}:</b>
        {{'item' | cbsLabel}} &#160;<b>{{itemNumber$ | async}}</b> {{hasBeenSavedToLabel | cbsLabel}} <b>{{originName$
        | async}}</b>.
      </div>
    </ng-container>
  </ng-container>
</ng-container>
