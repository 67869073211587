import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {CbsRollbarService} from '../rollbar/cbs-rollbar.service';
import {HeaderService} from '../services/web/header.service';
import {HeaderFieldChangeResponse} from '../services/web/header-field-change-response';
import {State} from '../reducers';
import {catchError, map} from 'rxjs/operators';
import {concatMapEmitLast} from '../util/rx-utils';
import {HeaderActions} from '../actions/header.action';
import {ValidateFieldService} from '../services/validate-field.service';

@Injectable()
export class HeaderEffects {

  //noinspection JSUnusedGlobalSymbols
  changeHeaderField$ = createEffect(() => this.allActions.pipe(
    ofType(HeaderActions.submit),
    concatMapEmitLast(({payload}) =>
      this.headerService.submitHeaderFieldChange(payload.changeParams).pipe(
        map(response => this.validation.validateFields(response)),
        map((response: HeaderFieldChangeResponse) => HeaderActions.changeFieldSuccess({response})),
        catchError(error => this.rollbar.apiError(error, {payload}, undefined, this.store)))
    )
  ));

  constructor(private allActions: Actions,
              private headerService: HeaderService,
              private rollbar: CbsRollbarService,
              private store: Store<State>,
              private validation: ValidateFieldService) {
  }
}
