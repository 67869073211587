<div class="cbs-section">
  <ng-container *ngIf="products$ | async as products">
    <ng-container *ngIf="products.length>0; else empty">
      <div style="font-weight: 500; padding-left: 10px; padding-right: 5px" class="selected-product-row m-none p-v-half">
        <div><span class="fa-layers fa-fw"></span>Model</div>
        <div class="text-center" style="width: 50px;">{{'qty' | cbsLabel}}</div>
        <div *ngIf="displayPriceCol(products); else blank" style="min-width: 4em"
             [ngStyle]="{'width': priceColWidth(products)*.6 + 'em'}" class="text-right">{{'price' | cbsLabel}}
        </div>
      </div>
      <div style="padding: 0; margin: 0 .1rem; background: #fefefe;" class="selected-products-scroll" [dragula]="'selected-products'">
        <ng-container *ngFor="let product of products; let i = index">
          <div style="padding: 5px" class="selected-product-row"
               [ngClass]="{'selected-product': isSelected(product)}" [attr.data-index]="i">
            <div class="vertically-centered" style="min-width: 0; word-wrap: break-word" (click)="configure(product)"
                 [ngClass]="{'configurable': product.nestedConfig}">
              <span class="fa-layers fa-fw" (mouseover)="tooltipToggle.open()" (click)="openAndNoPropogation(this.event, tooltipToggle)"
                    (mouseleave)="tooltipToggle.close()">
                <i *ngIf="product.nestedConfig" class="fas fa-cog fa-sm" data-fa-transform="left-2"></i>
                <i *ngIf="!product.reviewed" class="fas fa-exclamation" data-fa-transform="shrink-6 up-8 right-8"
                   style="color: #ff0000;"></i>
              </span>
              <span [ngbPopover]="tooltip" #tooltipToggle="ngbPopover" triggers="none"
                    [ngClass]="{'configurable-title': product.nestedConfig}">
                {{product.title}}
                <sup *ngIf="product.optionGraphic || product.optionComments"
                     [ngbPopover]="cartTooltip" placement="right bottom left">
                  <i class="fas fa-info-circle fa-fw"></i>
                </sup>
              </span>
              <ng-template #cartTooltip>
                <div>
                  <img *ngIf="product.optionGraphic"
                       [src]="getGraphicUrl(product.optionGraphic)|async"/>
                  <span>{{product.optionComments}}</span>
                </div>
              </ng-template>
            </div>
            <div style="display: flex; width: 50px;" class="vertically-centered">
              <input style="width: 100%; margin: auto" test-id="PRODUCT_QUANTITY_FIELD" (change)="updateQuantity(product, quantity.value)"
                     value="{{product.quantity}}" #quantity/>
              <div style="display: flex; flex-direction: column">
                <button class="spinner-button" tabindex="-1" id="{{product.id}}__spinUp"
                        (click)="updateQuantity(product, product.quantity + 1)">▲
                </button>
                <button class="spinner-button" tabindex="-1" id="{{product.id}}__spinDown"
                        (click)="updateQuantity(product, product.quantity - 1 || 1)">▼
                </button>
              </div>
            </div>
            <div class="text-right vertically-centered" [ngStyle]="{'width': priceColWidth(products)*.6 + 'em'}"
                 *ngIf="displayPriceCol(products); else blank" matTooltip="{{'unitSellPrice' | cbsLabel}}">
              {{(product.price.toLowerCase() | cbsLabel) || product.price}}
            </div>
            <button *ngIf="!product.nestedConfig && canOverride(product)" test-id="OVERRIDE_BUTTON" (click)="openOverrideDialog(product)"
                    style="padding: 0 10px; grid-column: 4; outline: none; width: 2em">
              <ng-container *ngIf="productLoading(product); else isLoading">
                <span><i class="far fa-edit"></i></span>
              </ng-container>
              <ng-template #isLoading>
                <cbs-loading [display]="true" color="accent"></cbs-loading>
              </ng-template>
            </button>
            <button test-id="DESELECT_BUTTON" (click)="deselectProduct(product)" style="padding: 0 10px; grid-column: 5; outline: none; width: 2em">
              <ng-container *ngIf="isNotDeselected(product); else isDeselecting">
                <span><i class="fas fa-times-circle"></i></span>
              </ng-container>
              <ng-template #isDeselecting>
                <cbs-loading [display]="true" color="accent"></cbs-loading>
              </ng-template>
            </button>
            <ng-template #tooltip>
              <div>
                <ng-container *ngIf="product.nestedConfig">{{'clickToConfigure' | cbsLabel}}<br></ng-container>
                {{'dragAndDropToSort' | cbsLabel}}
              </div>
            </ng-template>
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="viewPricing; else bottomBorder">
        <div *ngIf="price$|async as price" style="font-weight: 500;" class="p-v-half">
          <div class="text-right" style="width: 90%; margin: auto">{{'totalCost' | cbsLabel}}
            : {{allReviewed(products) ? (price.toLowerCase() | cbsLabel) || price : '-'}}
            <button *ngIf="overridePrice" test-id="OPEN_MULTIPLIER_BUTTON" (click)="openMultiplierDialog()"
                    style="padding: 0 10px; grid-column: 4; outline: none; width: 2em"><span><i class="far fa-edit"></i></span>
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #empty>
    <br/>
    <p class="text-center lead">{{'noSelectedProducts' | cbsLabel}}</p>
    <h1 class="text-center"><i class="fas fa-shopping-cart text-subdued"></i></h1>
    <p class="text-center">{{'selectProductToAdd' | cbsLabel}}</p>
    <br/>
  </ng-template>
</div>


<ng-template #blank>
  <div style="width: 0"></div>
</ng-template>
<ng-template #bottomBorder>
  <div style="padding-bottom: 2px"></div>
</ng-template>
<!-- Binds subscription to the page to prevent multiple subscriptions -->
<ng-container *ngIf="dragging$|async"></ng-container>
